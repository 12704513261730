import {formFields} from './feedback';

export const errorMessages = {
  required: {
    [formFields.rate]: 'Please choose your star rating',
    [formFields.content]: 'Feedback message is required',
    [formFields.email]: 'Email is required',
    [formFields.year]: 'Human verification is required',
  },
  invalid: {
    [formFields.content]: 'Feedback message should have 15 characters or more',
    [formFields.email]: 'Invalid email address',
    [formFields.year]: 'Invalid human verification',
  },
  emptyOptions: 'Feedback system temporary unavailable due to server invalid response',
};

export const successMessages = {
  feedbackSent: 'Your feedback successfully sent',
};
