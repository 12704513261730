"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effector_1 = require("effector");
var getCounterFromObjectId = function (objectId) {
    return parseInt(objectId.slice(18, 24), 16);
};
exports.default = (function (Messages$, MessagesOrder$) {
    return effector_1.combine(Messages$, MessagesOrder$, function (allMessages, order) {
        var orderMap = Object.entries(order);
        return orderMap.reduce(function (acc, _a) {
            var _b;
            var dialogId = _a[0], messageIds = _a[1];
            var messages = messageIds
                // Filter for case we have an ID but message already deleted from store
                .filter(function (messageId) { return allMessages[messageId]; })
                .map(function (messageId, idx) {
                return __assign(__assign({}, allMessages[messageId]), { idx: idx });
            })
                .filter(Boolean)
                .sort(function (a, b) {
                if (a.date_sent === b.date_sent) {
                    return getCounterFromObjectId(a._id) - getCounterFromObjectId(b._id);
                }
                return a.date_sent - b.date_sent;
            });
            return __assign(__assign({}, acc), (_b = {}, _b[dialogId] = messages, _b));
        }, {});
    });
});
