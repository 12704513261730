import {MODAL_SHOW, MODAL_HIDE} from './modalsActionTypes';
import {TModalIds} from './types';

export const modalShowAction = (id: TModalIds, props?: Record<string, unknown>) => {
  return {
    type: MODAL_SHOW,
    payload: {id, props},
  } as const;
};

export const modalHideAction = (id: TModalIds) => {
  return {
    type: MODAL_HIDE,
    payload: {id},
  } as const;
};
