"use strict";
/**
 * @packageDocumentation @module Messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMessage = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * Return message by id
 */
function useMessage(messageId) {
    return chat_1.Messages.Messages$.getState()[messageId];
}
exports.useMessage = useMessage;
