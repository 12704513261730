import {allSignInTypesRegexp} from '~/modules/Auth/constants/tabs';

import {allProfileTypesRegexp} from './profiles';
import {PaymentMethods} from './payments';

const settingsPrefix = '/account';
const shemalePrefix = '/shemale';
const chatsPrefix = '/chats';
const contactsPrefix = '/contacts';

/**
 * Mapping of route string name representation to path string or path function
 * @type {{}}
 */

const routeByName = {
  home: '/',
  // allLocations: '/all-locations',

  /**
   * Auth module
   */

  passwordResetRequestFull: `/resetting/request/:selectedTab(${allSignInTypesRegexp})`,
  passwordResetRequest: `/resetting/request`,
  passwordReset: '/resetting/reset',
  signUp: '/registration/request',
  userPhoneVerification: '/registration/verification',
  userPhoneChange: '/registration/change-phone',
  signIn: '/login',
  postProfile: '/post-profile',
  userImpersonate: '/user/impersonate',
  twoFactorAuth: '/login/verification',

  /**
   * Account
   */

  changeLanguage: `/change-language`,
  changeEmail: `/change-email`,
  changeEmailConfirm: `/change-email-confirm`,
  changePhone: `/change-phone`,
  verifyPhone: `${settingsPrefix}/verify-phone`,
  changePassword: `/change-password`,
  setPinCode: `${settingsPrefix}/pin-code`,
  closeAccount: `${settingsPrefix}/close`,
  billingAndPayments: `${settingsPrefix}/payments`,
  settingsAddCard: `/pay/card/new`,
  textSettings: `${settingsPrefix}/text-configuration`,
  blockedList: `${settingsPrefix}/blocked-list`,
  refund: '/refund/:transactionId',

  paymentCreate: '/pay/create',
  paymentRedirect: '/pay/billing/:paymentId',
  chooseMembership: `/pay/choose`,
  purchaseMembership: (
    membershipId?: string | number,
    paymentId?: string,
    paymentMethod?: PaymentMethods
  ): string =>
    `${settingsPrefix}/memberships/${membershipId || ':membershipId'}/${
      paymentId || ':paymentId'
    }/${paymentMethod || ':paymentMethod'}`,
  purchaseVip: `${settingsPrefix}/purchase-vip`,

  chooseRechargeBudget: `/pay/recharge`,
  purchaseRechargeBudget: (
    rechargeId?: string,
    paymentId?: string,
    paymentMethod?: PaymentMethods
  ): string =>
    `/recharge/${rechargeId || ':rechargeId'}/${paymentId || ':paymentId'}/${
      paymentMethod || ':paymentMethod'
    }`,

  complimentaryActivation: `${settingsPrefix}/complimentary-activation`,

  buyTopUps: `/pay/choose/top-up`,

  goldMembershipChoose: `/goldMembership/choose`,

  chooseProfileType: `${settingsPrefix}/choose-profile-type`,
  createProfile: `${settingsPrefix}/create-profile`,
  profileForm: `/p/edit/personal`,
  moveAd: `${settingsPrefix}/move-profile`,
  favorites: `/favorite`,
  identityVerification: `/pay/verification`,
  identityVerificationId: `/verification/id`,
  profileReport: `/profile/report`,

  /**
   * Chats
   */

  chats: {
    index: `${chatsPrefix}`,
    create: `${chatsPrefix}/create`,
    createGroup: `${chatsPrefix}/create-group`,
    single: (id = ':chatId'): string => `${chatsPrefix}/${id}`,
  },

  contacts: {
    index: `${contactsPrefix}`,
  },

  /**
   * Other
   */

  /**
   * Static
   */

  privacyPolicy: '/privacy-policy',
  aboutUs: '/about-us',
  terms: '/terms',
  contact: '/contact',
  // faq: '/faq',
  faqGuides: '/faq/guides',
  allowCookieGuides: '/allow-cookie',
  allowCookieGuideDetails: (id = ':id'): string => `/allow-cookie/${id}`,

  /**
   * Location pages
   */
  profileListUserLocation: '/profiles/my-location',
  locationPage: `/:country/:state?/:city?/:borough?/:profileTypeUrl(${allProfileTypesRegexp})`,
  locationPageAlias: `/:country/:state/:city?/:borough?`,

  /**
   * Profiles
   */

  profileDetailsPage: `/(${allProfileTypesRegexp})/:username`,

  /**
   * Shemale References
   */

  shemaleReferences: shemalePrefix,
  shemaleSingle: (pageId?: string): string => `${shemalePrefix}/${pageId || ':reference'}`,

  /**
   * Other
   */

  artBoard: '/art-board',

  notFound: '/404',
};

export default routeByName;
