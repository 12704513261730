'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.consoleNodeColorful = void 0;
var utils_1 = require("../utils");
var codes = {
    reset: [0, 0],
    cyan: [36, 39],
    red: [31, 39],
    green: [32, 39],
    yellow: [33, 39],
    gray: [90, 39],
};
var styles = {
    reset: function () { return ''; },
    cyan: function () { return ''; },
    red: function () { return ''; },
    green: function () { return ''; },
    yellow: function () { return ''; },
    gray: function () { return ''; },
};
utils_1.objectKeys(codes).forEach(function (key) {
    var open = '\u001b[' + codes[key][0] + 'm';
    var close = '\u001b[' + codes[key][1] + 'm';
    var makeStyle = function (open, close) { return function (message) {
        return open + message + close;
    }; };
    styles[key] = makeStyle(open, close);
});
var map = {
    verbose: styles.gray,
    debug: styles.cyan,
    error: styles.red,
    info: styles.green,
    warn: styles.yellow,
    metric: styles.gray,
    event: styles.gray,
};
function consoleNodeColorful(logObject) {
    var style = map[logObject.level];
    var prefix = logObject.loggerName.length > 0 ? "[" + logObject.loggerName + "]" : '';
    var moduleName = style("[" + logObject.moduleName + "]");
    var message = "" + prefix + moduleName + " " + logObject.message;
    var args = [message, logObject.extendedData].filter(Boolean);
    console.log.apply(console, args);
}
exports.consoleNodeColorful = consoleNodeColorful;
