"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var index_1 = require("./index");
var getUserId = function () {
    var session = index_1.Chat$.getState().session;
    if (session) {
        return session.user_id;
    }
    return null;
};
exports.default = getUserId;
