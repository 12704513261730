export const PAYMENTS_SET_CARDS_LIST = 'PAYMENTS_SET_CARDS_LIST';
export const PAYMENTS_SET_RECHARGE_BUDGET_OPTIONS = 'PAYMENTS_SET_RECHARGE_BUDGET_OPTIONS';
export const PAYMENTS_SET_VIP_OPTIONS = 'PAYMENTS_SET_VIP_OPTIONS';
export const PAYMENTS_SET_TOP_UP_PRICE = 'PAYMENTS_SET_TOP_UP_PRICE';
export const PAYMENTS_SET_MEMBERSHIPS_LIST = 'PAYMENTS_SET_MEMBERSHIPS_LIST';

export const COMPLIMENTARY_ACTIVATION_PAGE_UPDATE = 'LOAD_COMPLIMENTARY_ACTIVATION_PAGE';
export const COMPLIMENTARY_ACTIVATION_PAGE_LOADING = 'COMPLIMENTARY_ACTIVATION_PAGE_LOADING';
export const PAYMENTS_SET_RECHARGE_BACK_ROUTE = 'PAYMENTS_SET_RECHARGE_BACK_ROUTE';
export const PAYMENTS_SET_FROM_PAGE_GOLD = 'PAYMENTS_SET_FROM_PAGE_GOLD';
