import {useEffect} from 'react';

import logger from '~/utils/logger';

const log = logger.module('useCloseKeyboardOnOrientation');

const useCloseKeyboardOnOrientation = (): null => {
  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.document.activeElement) {
        try {
          (window.document.activeElement as HTMLElement).blur();
        } catch (error) {
          log.error('failed blur element in useCloseKeyboardOnOrientation', {
            error,
          });
        }
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return null;
};

export default useCloseKeyboardOnOrientation;
