import {FC} from 'react';

import {CDNParams} from '~/types/Media';
import {getImageUrl} from '~/helpers/images';

import pictureConfig from './config';
import {PictureProps} from './types';

const Picture: FC<PictureProps> = ({
  rootClassName,
  imgClassName,
  alt,
  type: pictureType,
  image,
  onLoad,
  placeholder,
  isLazy = true,
}) => {
  const {[pictureType]: config} = pictureConfig;
  const cdn_slug = image?.cdn_slug;
  const fakeUrl = image?.fakeUrl;
  const {sources, fallback} = config;

  const loadingType = isLazy ? 'lazy' : 'eager';

  const getUrl = (options: CDNParams) => {
    if (!cdn_slug) {
      return fakeUrl ?? placeholder;
    }

    return getImageUrl(cdn_slug, options) ?? placeholder;
  };

  return (
    <picture className={rootClassName}>
      {sources.map(({type, selector, options}) => (
        <source type={type} key={`${type}_${selector}`} srcSet={getUrl(options)} media={selector} />
      ))}
      <img
        src={getUrl(fallback.options)}
        className={imgClassName}
        alt={alt}
        onLoad={onLoad}
        loading={loadingType}
      />
    </picture>
  );
};

Picture.displayName = 'Picture';

export default Picture;
