import {createTheme} from '@material-ui/core/styles';

import {PINCODE_ZINDEX} from '~/modules/CurrentUser/PinCode/PinCodeComponent';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import props from './props';
import spacing from './spacing';

const theme = createTheme({
  palette,
  typography,
  overrides,
  // @ts-expect-error The types of 'MuiButton.color' is not assignable to type 'ComponentsProps'
  props,
  spacing,
  zIndex: {
    pinCodeComponent: PINCODE_ZINDEX,
  },
});

export default theme;
