import React from 'react';
import {MenuItem} from '@material-ui/core';
import {useField} from 'react-final-form';
import {useSelector} from 'react-redux';

import {NavigatorPermissions} from '~/constants/navigator';
import useModalById from '~/customHooks/useModalById';
import {MODAL_LOCATION_DISABLED_FLASH} from '~/rootStore/modals/modalsIds';
import {navigatorPermissionsSelector} from '~/modules/App/store/selectors';
import {requestGeolocation} from '~/modules/App/store/geoActions';

import {TSearchType} from '../../types';
import BaseControl from './BaseControl';
import BaseControlSelect from './BaseControlSelect';

const SearchTypeControl = () => {
  const field = useField('searchType');
  const navigatorPermissions = useSelector(navigatorPermissionsSelector);
  const modalLocationDisabledFlash = useModalById(MODAL_LOCATION_DISABLED_FLASH);
  const handleClick = async () => {
    if (
      (navigatorPermissions.geolocation === NavigatorPermissions.BLOCK ||
        navigatorPermissions.geolocation === NavigatorPermissions.ASK) &&
      !modalLocationDisabledFlash.isOpen
    ) {
      const {position} = await requestGeolocation();

      if (!position) {
        modalLocationDisabledFlash.open();
        field.input.onChange(TSearchType.Global);
      }
    }
  };

  return (
    <BaseControl title="Location">
      <BaseControlSelect {...field.input}>
        <MenuItem value={TSearchType.Global}>Global</MenuItem>
        <MenuItem onClick={handleClick} value={TSearchType.CurrentLocation}>
          My current location
        </MenuItem>
        s
      </BaseControlSelect>
    </BaseControl>
  );
};

export default SearchTypeControl;
