import React from 'react';
import {Calls} from '@yeobill/react-chat';
import {
  TWebRTCSession,
  //  TSessionState,
  TRoxySessionState,
} from '@yeobill/chat/lib/types';

import config from '~/constants/config';

import ModalDialing from './ModalDialing';
import ModalCall from './ModalCall';

const CallsProvider: React.FC = () => {
  const callSessionEntries = Calls.useSessions();

  if (!config.chat.callsEnabled) {
    return null;
  }

  // eslint-disable-next-line
  console.log({callSessionEntries});

  const outgoingSessions = callSessionEntries.filter((s) => s.state === TRoxySessionState.OUTGOING);

  const activeSessions = callSessionEntries.filter((s) => s.state === TRoxySessionState.ACTIVE);

  const incomingSessions = callSessionEntries.filter((s) => s.state === TRoxySessionState.INCOMING);

  const onAccept = async (session: TWebRTCSession) => {
    await Calls.getUserMedia(session);
    session.accept({});
  };

  return (
    <>
      {outgoingSessions.map(({session}) => {
        return (
          <ModalDialing
            key={session.ID}
            session={session}
            direction="outgoing"
            onClose={() => session.stop({})}
          />
        );
      })}
      {incomingSessions.map(({session}) => {
        return (
          <ModalDialing
            key={session.ID}
            session={session}
            direction="incoming"
            onClose={() => session.reject({})}
            onAccept={() => onAccept(session)}
          />
        );
      })}
      {activeSessions.map(({session}) => {
        return <ModalCall key={session.ID} session={session} onClose={() => session.stop({})} />;
      })}
    </>
  );
};

export default CallsProvider;
