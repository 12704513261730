import React from 'react';
import {Button, Divider} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import useModalById from '~/customHooks/useModalById';
import {MODAL_CHOOSE_LOCATION} from '~/rootStore/modals/modalsIds';

import ProfileChooseLocationBendr from '../ProfileFilters/ProfileChooseLocationBendr';
import ModalSearchCityBendr from './ModalSearchCityBendr';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    backgroundColor: '#F8F8F8',
  },
  Button: {
    background: '#FDFDFD',
    borderRadius: 0,
    color: 'black',
    fontSize: 18,
    justifyContent: 'flex-start',
    padding: 11,
  },
  discoverySettings: {
    color: '#898989',
    fontSize: 15,
    fontWeight: 'bold',
    padding: '7px 10px',
  },
}));

const ProfileChooseLocationModal: React.FC = () => {
  const modal = useModalById(MODAL_CHOOSE_LOCATION);
  const s = useStyles();

  return (
    <Modal disableScrollLock open={modal.isOpen} onClose={modal.close}>
      <div className={s.wrapper}>
        <div>
          <Button variant="text" className={s.Button} onClick={() => modal.close()}>
            <SpriteIcon name="back" className="mr-1" fontSize="normal" color="black" />
            <span>Location</span>
            <div className="flex-1" />
            <SpriteIcon name="gallery_close" />
          </Button>
          <Divider />
        </div>
        <div className={s.discoverySettings}>DISCOVERY SETTINGS</div>
        <Divider />
        <ProfileChooseLocationBendr />
        <ModalSearchCityBendr />
      </div>
    </Modal>
  );
};

export default ProfileChooseLocationModal;
