import {Reducer} from 'redux';

import {TMembership} from '~/types/Membership';

import {GOLD_MEMBERSHIP_PAGE_LOAD_OPTIONS, FAQ_PAGE_UPDATE_CURRENT_SLIDE} from './pagesActionTypes';

const initialState = {
  goldMembershipPage: {
    membership: null,
  },
  FAQPage: {
    currentTab: 0,
  },
};

export interface TPagesState {
  goldMembershipPage: {
    membership: TMembership | null;
  };
  FAQPage: {
    currentTab: number;
  };
}

export const pagesReducer: Reducer<TPagesState> = (state = initialState, {type, payload}) => {
  switch (type) {
    case GOLD_MEMBERSHIP_PAGE_LOAD_OPTIONS: {
      const {membership} = payload;
      const prevPageState = state.goldMembershipPage;
      return {
        ...state,
        goldMembershipPage: {...prevPageState, membership},
      };
    }
    case FAQ_PAGE_UPDATE_CURRENT_SLIDE: {
      const prevPageState = state.FAQPage;
      return {
        ...state,
        FAQPage: {...prevPageState, currentTab: payload},
      };
    }

    default:
      return state;
  }
};
