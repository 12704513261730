import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import config from '~/constants/config';
import pageTitles from '~/constants/pageTitles';
import {BaseLayout, LayoutControl} from '~/components/Layout';

const ErrorPage: React.FC = () => {
  return (
    <BaseLayout>
      <LayoutControl title={pageTitles.ErrorPage} hideFooter />
      <Card>
        <CardContent className="text-center">
          <div className="mb-2">
            <Typography variant="h1">Something went wrong :(</Typography>
          </div>
          <Button href={config.refreshAppUrl}>Reload</Button>
          <Box mt={1}>
            <div>
              app version: {config.build.version}@{config.build.commit}
            </div>
          </Box>
        </CardContent>
      </Card>
    </BaseLayout>
  );
};

export default ErrorPage;
