import {isEmpty} from 'lodash-es';
import {createSelector} from 'reselect';

import {TProfile} from '~/types/Profile';
import {defaultProfileTypeFilter, TProfileType} from '~/constants/profiles';
import {TAppState, TLocationFiltersParams, TProfileFiltersListParams} from '~/types/appTypes';

import {currentProfileSelector} from '../../CurrentUser/store/selectors';
import {leafLocationsSelector} from '../../Locations/store/selectors';
import fakeProfiles from '../common/fakeProfiles';
import {
  getStoredLocationFilters,
  getStoredProfileFiltersOptions,
  getStoredProfileTypeFilter,
} from './localStorage';

export const profileByRouteSelector = (routeString: string) => (state: TAppState) => {
  const {routeToProfileId, profiles} = state.profiles;
  return profiles[routeToProfileId[routeString]];
};

export const isProfileOwnerSelector =
  (profile: TProfile) =>
  (state: TAppState): boolean => {
    const {user} = state.currentUser;
    if (user && profile) {
      return profile.id === user.profileId;
    }
    return false;
  };

export const getProfileByIdSelector = (profileId: string | number) => (state: TAppState) => {
  return state.profiles.profiles[profileId] || null; // eslint-disable-line react/destructuring-assignment
};

export const filterProfileTypeSelector = (state: TAppState): TProfileType => {
  if (state.profiles.filters.profileType !== null) {
    return state.profiles.filters.profileType;
  }

  const profile = currentProfileSelector(state);
  const storedProfileType = getStoredProfileTypeFilter();
  const chosenProfile = profile?.looking_for;
  const defaultProfileType = defaultProfileTypeFilter(profile?.type);
  return [storedProfileType, chosenProfile, defaultProfileType].find(
    (p) => p !== undefined && p !== null
  ) as TProfileType;
};

const defaultProfileFilters = {};

export const profileFiltersSelector = createSelector(
  (state: TAppState) => state.profiles.filters.profileFilters,
  (profileFilters) => {
    if (!isEmpty(profileFilters)) {
      return profileFilters;
    }

    return defaultProfileFilters as TProfileFiltersListParams;
  }
);

export const profileFiltersAsListSelector = createSelector(profileFiltersSelector, (filters) =>
  Object.keys(filters).reduce((acc, filter) => {
    return {...acc, [filter]: Object.values(filters[filter])};
  }, {})
);

export const profileFiltersCount = createSelector(profileFiltersSelector, (filters) => {
  const filterKeys = Object.keys(filters) as Array<keyof TProfileFiltersListParams>;

  return filterKeys.reduce<number>((count, key) => count + Object.keys(filters[key]).length, 0);
});

const defaultLocationFilters = {};

export const profileLocationFiltersSelector = createSelector(
  (state: TAppState) => state.profiles.filters.locationFilters,
  (locationFilters) => {
    if (!isEmpty(locationFilters)) {
      return locationFilters;
    }
    const storedLocationFilters = getStoredLocationFilters();
    return storedLocationFilters !== null
      ? storedLocationFilters
      : (defaultLocationFilters as TLocationFiltersParams);
  }
);

export const navigationFiltersSelector = (state: TAppState) => {
  return state.profiles.filters.navigationFilters;
};

export const lastFiltersQuerySelector = createSelector(
  (state: TAppState) => state.profiles.lastFiltersQuery,
  (lastFiltersQuery) => lastFiltersQuery
);

export const getProfileReportOptionsSelector = createSelector(
  (state: TAppState) => state.currentUser.profileReportOptions,
  (profileReportOptions) => {
    if (!isEmpty(profileReportOptions)) {
      return profileReportOptions;
    }
    return null;
  }
);

export const profileIdsToOpponentSelector = (profileId: number | undefined) =>
  createSelector(
    (state: TAppState) => state.profiles.profileIdToOpponent,
    (profileIdToOpponent) => {
      if (!profileId) {
        return undefined;
      }

      return profileIdToOpponent[profileId];
    }
  );

export const profileIdsToChatSelector = (profileId: number | undefined) =>
  createSelector(
    (state: TAppState) => state.profiles.profileIdToChat,
    (profileIdToChat) => {
      if (!profileId) {
        return undefined;
      }

      return profileIdToChat[profileId];
    }
  );

export const profileIdsToChatLoading = (profileId: number | undefined) =>
  createSelector(
    (state: TAppState) => state.profiles.profileIdToChatLoading,
    (profileIdToChatLoading) => {
      if (!profileId) {
        return undefined;
      }

      return profileIdToChatLoading[profileId];
    }
  );

export const profileListLocationIdSelector = (state: TAppState) => {
  return state.profiles.locationId;
};

export const profileListLocationSelector = (state: TAppState) => {
  const locationId = profileListLocationIdSelector(state);
  if (locationId) {
    const leafLocations = leafLocationsSelector(state);
    return leafLocations[locationId];
  }

  return undefined;
};

export const profilesSortingMethodSelector = (state: TAppState) => {
  return state.profiles.sortingMethod;
};

export const locationProfilesSelector = createSelector(
  (state: TAppState) => state.profiles.locationProfiles,
  (state: TAppState) => state.profiles.profiles,
  (state: TAppState) => state.profiles.tutorial,
  (profileIds, profiles, tutorial) => {
    // Collect or mix fake data for tutorial
    if (tutorial.show) {
      if (profileIds.length === 0) {
        return fakeProfiles as TProfile[];
      }

      if (profileIds.length === 1) {
        return [profiles[profileIds[0]], (fakeProfiles as TProfile[])[1]];
      }
    }

    return profileIds.map((id) => profiles[id]);
  }
);

export const currentSwiperProfileSelector = createSelector(
  (state: TAppState) => state.profiles.filters.navigationFilters,
  (state: TAppState) => state.profiles.profiles,
  (navigationFilters, profiles) => profiles[navigationFilters?.currentProfileId ?? 0]
);

export const tutorialSelector = (state: TAppState) => {
  return state.profiles.tutorial;
};

export const swiperActiveTabSelector = (state: TAppState) => {
  return state.profiles.filters.navigationFilters.activeTab;
};

export const profileFiltersOptionsSelector = (state: TAppState) => {
  const filtersOptions = state.profiles.filters.profileFiltersOptions;
  if (!isEmpty(filtersOptions)) {
    return filtersOptions;
  }
  const storedOptions = getStoredProfileFiltersOptions();
  return storedOptions;
};

export const activeProfileFiltersSelector = (state: TAppState) => {
  const selectedFilters = profileFiltersSelector(state);
  const filtersOptions = profileFiltersOptionsSelector(state);

  if (isEmpty(filtersOptions)) {
    return profileFiltersAsListSelector(state);
  }

  return Object.keys(selectedFilters).reduce((acc, filter) => {
    const values = Object.keys(selectedFilters[filter]).reduce<string[]>(
      (filterValues, optionName) => {
        const filterOption = filtersOptions[filter].options[optionName];

        return [...filterValues, filterOption.value.toString()];
      },
      []
    );
    return {...acc, [filter]: values};
  }, {});
};
