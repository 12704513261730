import {isEmpty} from 'lodash-es';

// eslint-disable-next-line import/prefer-default-export
export function creditCardsResponseTransformer({cards}) {
  if (Array.isArray(cards)) {
    return {};
  }

  return Object.keys(cards).reduce((accum, cardId) => {
    const {...card} = cards[cardId];
    // eslint-disable-next-line no-param-reassign
    accum[cardId] = {
      ...card,
    };
    return accum;
  }, {});
}

export function paymentsTransformer(payments) {
  if (!payments || isEmpty(payments)) {
    return null;
  }

  const getMethodName = (methodName) => {
    switch (methodName) {
      case 'card':
        return 'card';
      case 'bitcoin':
        return 'btc';
      case 'budget':
        return 'balance';

      default:
        return null;
    }
  };

  return Object.values(payments).reduce(
    (accumArray, payment) => [
      ...accumArray,
      {
        ...payment,
        paymentMethod: getMethodName(payment.paymentMethod),
      },
    ],
    []
  );
}
