"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.consoleBrowserColorful = void 0;
var styles = {
    verbose: 'color: gray; font-weight: bold;',
    debug: 'color: cornflowerblue; font-weight: bold;',
    info: 'color: green; font-weight: bold;',
    error: 'color: red; font-weight: bold;',
    warn: 'color: orange; font-weight: bold;',
    metric: 'color: gray; font-weight: bold;',
    event: 'color: gray; font-weight: bold;',
    reset: 'color: inherit; font-weight: inherit;',
};
function consoleBrowserColorful(logObject) {
    var prefix = logObject.loggerName.length > 0 ? "[" + logObject.loggerName + "]" : '';
    var message = prefix + "%c[" + logObject.moduleName + "]%c " + logObject.message;
    var args = [
        message,
        styles[logObject.level],
        styles.reset,
        logObject.extendedData,
    ].filter(Boolean);
    console.log.apply(console, args);
}
exports.consoleBrowserColorful = consoleBrowserColorful;
