import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {TGeoLocation} from '~/types';

import SpriteIcon from './icons/SpriteIcon';

interface Props {
  lat: number;
  lng: number;
}

const useStyles = makeStyles((theme) => ({
  marker: {
    color: '#ED3868',
    left: theme.spacing(-1.5),
    top: theme.spacing(-3),
    position: 'absolute',
  },
}));

const Marker: React.FC<TGeoLocation> = ({children}) => <>{children}</>;

const MapMarkerSimple: React.FC<Props> = ({lat, lng}) => {
  const s = useStyles();
  return (
    <Marker lat={lat} lng={lng}>
      <div className={s.marker}>
        <SpriteIcon name="location-marker" />
      </div>
    </Marker>
  );
};

export default MapMarkerSimple;
