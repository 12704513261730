const enableOutgoingVideoElement: () => void = () => {
  const el = document.getElementById('video-webrtc');
  if (!el) {
    throw new Error(
      'Element #video-webrtc is needed for setting outgoing video and cannot be found on the page'
    );
  }
  el.style.width = '150px';
  el.style.display = 'block';
  el.style.position = 'fixed';
  el.style.zIndex = '1500';
  el.style.top = '10px';
  el.style.right = '10px';
};

const disableOutgoingVideoElement: () => void = () => {
  const el = document.getElementById('video-webrtc');
  if (!el) {
    throw new Error(
      'Element #video-webrtc is needed for setting outgoing video and cannot be found on the page'
    );
  }
  el.style.display = 'none';
};

const displayDuration: (timestamp: number) => () => string =
  (callAcceptedTimestamp: number) => () => {
    if (!callAcceptedTimestamp) {
      return '';
    }

    const difference = Date.now() - callAcceptedTimestamp;
    return new Date(difference).toISOString().substr(11, 8);
  };

const utils = {
  enableOutgoingVideoElement,
  disableOutgoingVideoElement,
  displayDuration,
};

export default utils;
