import {isEmpty} from 'lodash-es';

import {requestGeolocation} from '~/modules/App/store/geoActions';
import {currentLocationSelector} from '~/modules/CurrentUser/store/selectors';
import {addErrorNotificationAction} from '~/rootStore/notifications/notificationsReducer';
import {TThunkAction} from '~/types/appTypes';

import ProfileService, {TProfileFiltersParams} from '../../ProfileService';
import {setProfilesFiltersActionCreator} from '../actionCreators';
import {setStoredProfileFiltersOptions} from '../localStorage';
import {
  activeProfileFiltersSelector,
  filterProfileTypeSelector,
  profileListLocationIdSelector,
  profileLocationFiltersSelector,
} from '../selectors';
import {TprofileFiltersOptions} from '../types';

type TLoadLocationProfileAction = () => TThunkAction;

const loadProfileFiltersAction: TLoadLocationProfileAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const locationId = profileListLocationIdSelector(state);
    const activeFilters = activeProfileFiltersSelector(state);
    const currentLocation = currentLocationSelector(state);
    const locationFilters = profileLocationFiltersSelector(state);
    const profileTypeFromSelector = filterProfileTypeSelector(state);

    const isGlobalSearch = locationFilters.searchType === 'global' || isEmpty(locationFilters);
    let location = {lat: currentLocation?.lat, lng: currentLocation?.lng};

    if (!currentLocation) {
      const {position} = await requestGeolocation();
      if (position) {
        location = {lat: position.lat, lng: position.lng};
      }
    }

    const params: TProfileFiltersParams = {
      ...activeFilters,
      searchType: locationFilters.searchType ?? 'global',
      profileType: profileTypeFromSelector,
    };

    if (currentLocation && isGlobalSearch) {
      params.position = {
        lat: location.lat,
        lng: location.lng,
      };
    }

    if (isGlobalSearch && locationId) {
      params.location = {id: locationId};
    }

    if (!isGlobalSearch) {
      params.current_location = {
        lat: location.lat,
        lng: location.lng,
        distance: locationFilters.distance ?? 10,
      };
    }

    const filtersOptions = await ProfileService.getProfileFilters(params);
    const profileFilters: TprofileFiltersOptions = Object.values(filtersOptions).reduce(
      (acc, filter) => {
        return {
          ...acc,
          [filter.value]: {
            ...filter,
            options:
              filter.options?.reduce(
                (accOpt, option) => ({...accOpt, [option.name]: option}),
                {}
              ) ?? {},
          },
        };
      },
      {}
    );
    setStoredProfileFiltersOptions(profileFilters);
    dispatch(setProfilesFiltersActionCreator(profileFilters));
  } catch (err) {
    console.log(err);
    dispatch(addErrorNotificationAction('Unable to load profile filters'));
  }
};

export default loadProfileFiltersAction;
