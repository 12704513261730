import {useState} from 'react';

import localStorageWrapper from '~/utils/localStorageWrapper';
import logger from '~/utils/logger';

const log = logger.module('LocalStorage');

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useLocalStorage(key: string, initialValue: unknown) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorageWrapper.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      log.error('error during define initial value from local storage', {
        error,
      });
      return initialValue;
    }
  });

  const setValue = (value: unknown) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      localStorageWrapper.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      log.error('error during save to local storage', {error});
    }
  };

  const refreshValue = () => {
    try {
      const storedData = localStorageWrapper.getItem(key);
      if (storedData) {
        setStoredValue(JSON.parse(storedData));
      }
    } catch (error) {
      log.error('error during save to local storage on refresh value', {error});
    }
  };

  return [storedValue, setValue, refreshValue];
}

export default useLocalStorage;
