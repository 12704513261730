import {TThunkAsyncAction} from '~/types/appTypes';
import {removeQueryParams} from '~/helpers/urlUtils';
import {history} from '~/helpers/routes/history';
import logger from '~/utils/logger';
import {navigateRefreshAction} from '~/actions/navigateActions';

import AuthService from '../AuthService';

const log = logger.module('AutologinUser');

// eslint-disable-next-line import/prefer-default-export
export const autologinAction: TThunkAsyncAction<string> = (autologinCode) => async (dispatch) => {
  const originUrl = `${history.location.pathname}${history.location.search}`;
  try {
    if (!autologinCode) {
      return;
    }
    const resultSearch = removeQueryParams(history.location.search, ['al']);
    const url = `${history.location.pathname}${resultSearch}`;
    log.info('Autologin start', {autologinCode, url});
    await AuthService.autologin(autologinCode);
    dispatch(navigateRefreshAction(url));
    return;
  } catch (error) {
    log.error('Error during autologin', {error, originUrl});
  }
};
