import {createStore, compose, applyMiddleware} from 'redux';
import {pick} from 'lodash-es';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';
import ReactGA from 'react-ga';

import {createProfileForm} from '~/modules/FinalForm/constants/forms';
import {history} from '~/helpers/routes/history';
import config from '~/constants/config';

import createRootReducer from './rootReducer';

const middlewares = [thunk];
if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_REDUX_LOGGER) {
  // eslint-disable-next-line
  const {createLogger} = require('redux-logger');

  const logger = createLogger({
    collapsed: !!process.env.REACT_APP_REDUX_LOGGER_COLLAPSED,
  });

  middlewares.push(logger);
}

window.scrollTo(0, 0);

history.listen(() => {
  window.scrollTo(0, 0);
});

history.listen((location) => {
  if (config.googleAnalyticsId) {
    ReactGA.pageview(location.pathname + location.search);
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = composeEnhancers(
  applyMiddleware(...middlewares),
  persistState([], {
    slicer: function customStoreSlicer() {
      return (state) => {
        return pick(state, [`finalForm.${createProfileForm}`, 'chatUsageInfo']);
      };
    },
  })
);

const store = createStore(createRootReducer(), {}, enhancers);

window.store = store;

if (module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const createRootReducerHot = require('./rootReducer').default;
    store.replaceReducer(createRootReducerHot(history));
  });
}

export default store;
