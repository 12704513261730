import {BaseAxiosInstance} from '~/utils/axios';

const FeedbackService = {
  getFeedbackTypes() {
    return BaseAxiosInstance.get('feedback/types').then((response) =>
      Object.values(response).map(({id, label}) => ({value: id, label}))
    );
  },
  sendFeedback(params) {
    return BaseAxiosInstance.post('feedback', {...params, message: ''});
  },
};

export default FeedbackService;
