import {useContext, useEffect, useCallback, memo} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {navigateBackAction} from '~/actions/navigateActions';

import {layoutSetPropsAction} from '../../store/actions';
import {TLayoutControlProps} from '../types';
import LayoutContext from './LayoutContext';

const LayoutControl: React.FC<TLayoutControlProps> = ({
  contentClass,
  headerBottomContent,
  headerClass,
  headerContent = null,
  hideFooter = false,
  hideHeader = false,
  hideHeaderOnScroll = true,
  imageBody = false,
  loading = false,
  paperBody = false,
  hideTopBarTitle = false,
  onBack,
  title,
  description,
  keywords,
  content,
}) => {
  const {setOnBackHandler} = useContext(LayoutContext);
  const dispatch = useDispatch();
  const history = useHistory();

  // in case of onBack === true, default navigateBackAction will be dispatched
  const defaultOnBack = useCallback(() => {
    if (history.length > 1) {
      dispatch(navigateBackAction());
    }
  }, [history, dispatch]);

  useEffect(() => {
    dispatch(
      layoutSetPropsAction({
        contentClass,
        headerBottomContent,
        headerClass,
        headerContent,
        hideFooter,
        hideHeader,
        hideHeaderOnScroll,
        hideTopBarTitle,
        imageBody,
        loading,
        paperBody,
        title,
        description,
        keywords,
        content,
      })
    );

    if (onBack) {
      setOnBackHandler(onBack === true ? defaultOnBack : onBack);
    } else {
      setOnBackHandler();
    }
  }, [
    contentClass,
    headerBottomContent,
    headerClass,
    headerContent,
    hideFooter,
    hideHeader,
    hideHeaderOnScroll,
    hideTopBarTitle,
    imageBody,
    loading,
    paperBody,
    title,
    description,
    keywords,
    content,
    onBack,
    setOnBackHandler,
    defaultOnBack,
    dispatch,
  ]);
  return null;
};

export default memo(LayoutControl);
