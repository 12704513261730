import {createSelector} from 'reselect';

import {TAppState} from '~/types/appTypes';
import {hasGoldMembershipSelector} from '~/modules/CurrentUser/store/selectors';

const MESSAGE_PER_HOUR_FOR_MALE = 10;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const chatUsageInfoSelector = (state: TAppState) => {
  return state.chatUsageInfo;
};

export const userChatLimitSelector = createSelector(
  chatUsageInfoSelector,
  hasGoldMembershipSelector,
  (chatUsageInfo, hasGoldMembership) => {
    const isReachMessageLimit = chatUsageInfo.messageSendCount >= MESSAGE_PER_HOUR_FOR_MALE;

    return {
      ...chatUsageInfo,
      hasGoldMembership,
      reachLimit: isReachMessageLimit,
    };
  }
);
