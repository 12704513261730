import girlStub1 from './img/stub-girl1.jpg';
import girlStub2 from './img/stub-girl2.jpg';

const fakeProfiles = [
  {
    id: 0,
    name: 'SuderLexi',
    type: 0,
    images: [
      {
        fakeUrl: girlStub1,
      },
    ],
    realIndex: 0,
  },
  {
    id: 1,
    name: 'Elizabeth',
    type: 0,
    images: [
      {
        fakeUrl: girlStub2,
      },
    ],
    realIndex: 1,
  },
];

export default fakeProfiles as unknown;
