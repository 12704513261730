"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.searchUsersByQuery = exports.getUserByUserChatId = exports.getUsersByArea = void 0;
var getUserFromQBData_1 = require("./users/getUserFromQBData");
function getUsersByArea(_a) {
    var url = _a.url, longitude = _a.longitude, latitude = _a.latitude, _b = _a.radius, radius = _b === void 0 ? 15000 : _b;
    return fetch(url + "/near-me?longitude=" + longitude + "&latitude=" + latitude + "&radius=" + radius).then(function (res) { return res.json(); });
}
exports.getUsersByArea = getUsersByArea;
function getUserByUserChatId(_a) {
    var url = _a.url, chatUserId = _a.chatUserId;
    return fetch(url + "/users/" + chatUserId)
        .then(function (res) { return res.json(); })
        .then(function (result) { return getUserFromQBData_1.getUserFromQBData(result.data); });
}
exports.getUserByUserChatId = getUserByUserChatId;
function searchUsersByQuery(_a) {
    var url = _a.url, searchQuery = _a.searchQuery;
    return fetch(url + "/users/?search=" + encodeURIComponent(searchQuery))
        .then(function (res) { return res.json(); })
        .then(function (_a) {
        var items = _a.items;
        return items.map(function (_a) {
            var user = _a.user;
            return getUserFromQBData_1.getUserFromQBData(user);
        });
    });
}
exports.searchUsersByQuery = searchUsersByQuery;
