import qs from 'qs';

type TBuildUrl = (params: {path: string; query?: Record<string, unknown>}) => string;

const buildUrl: TBuildUrl = ({path, query = {}}) => {
  const queryString = qs.stringify(query);

  if (queryString) {
    return `${path}?${queryString}`;
  }

  return path;
};

export default buildUrl;
