import AppService from '../AppService';
import {APP_SET_PRICE_RANGE} from './constants';

export const setPriceRangeAction = (currencyId) => async (dispatch) => {
  if (!currencyId) {
    return;
  }

  const priceRange = await AppService.getPriceRange(currencyId);

  if (!priceRange) {
    return;
  }

  dispatch({
    type: APP_SET_PRICE_RANGE,
    payload: {currencyId, priceRange},
  });
};

export const syncPriceRangeAction = () => async (dispatch, getState) => {
  const {
    currentUser: {profile},
  } = getState();

  if (!profile || !profile.currency) {
    return;
  }

  await dispatch(setPriceRangeAction(profile.currency.id));
};
