import * as React from 'react';

import routeByName from '~/constants/routes';

import WarningButton from '../ui/buttons/WarningButton';

const VerifyProfileIdentityBtn: React.FC = () => (
  <WarningButton
    backgroundVariant="warn"
    to={routeByName.identityVerification}
    title="Your account needs to be verified"
    subtitle="You are one step away from verifying your account"
    iconName="warning-sign-triangle"
  />
);

export default VerifyProfileIdentityBtn;
