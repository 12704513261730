import jwtDecode from 'jwt-decode';

import appConfig from '~/constants/config';
import extendAxiosInstance from '~/helpers/extendAxiosInstance';
import logger from '~/utils/logger';
import AuthService from '~/modules/Auth/AuthService';

import BaseAxiosInstance from './BaseAxiosInstance';

const log = logger.module('ChatServiceRequester');

const ChatAxiosInstance: BaseAxiosInstance = extendAxiosInstance(BaseAxiosInstance, {
  baseURL: appConfig.chat.serviceUrl,
  headers: {
    'X-Transaction-ID': appConfig.transactionId,
  },
}) as BaseAxiosInstance;

ChatAxiosInstance.interceptors.request.use(
  async (config) => {
    const headers = {...config.headers};

    if (!headers.Authorization && AuthService.hasToken()) {
      const token = AuthService.getClearToken() || '';
      const {exp: tokenExpirationDate} = jwtDecode<{exp: number}>(token);

      const isTokenExpired = Date.now() > tokenExpirationDate * 1000;
      if (isTokenExpired) {
        log.info('Token is expired');
        AuthService.removeToken();
        try {
          const result = await AuthService.refreshToken();

          if (!result) {
            throw Error(`Can't refresh token`);
          }
        } catch (e) {
          log.error('Error during pre-check token', {
            error: e,
          });
        }
      }

      headers.Authorization = AuthService.getAuthHeader();
    }

    return {
      ...config,
      headers,
    };
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

ChatAxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const result = await AuthService.refreshToken();

        if (result) {
          return await ChatAxiosInstance.request({
            ...error.config,
            headers: {
              ...(error.config.headers || {}),
              Authorization: AuthService.getAuthHeader(),
            },
          });
        }
        throw error;
      } catch (e) {
        log.error('Error during retry request', {
          error: e,
        });
        throw error;
      }
    }
    throw error;
  }
);

export default ChatAxiosInstance;
