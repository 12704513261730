import {TAppState} from '~/types/appTypes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rechargeBudgetOptionByIdSelector = (state: TAppState, id: string) =>
  state.payments.rechargeBudgetOptions[id];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getMembershipByIdSelector = (state: TAppState, membershipId: string) => {
  const {availableMemberships} = state.payments;
  if (availableMemberships) {
    return availableMemberships[membershipId];
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRechardgeBudgetBackRoute = (state: TAppState) =>
  state.payments.rechargeBudgetFromRoute;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPageGoldRequested = (state: TAppState) => state.payments.pageFromGoldRequested;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getUserCardsSelector = (state: TAppState) => state.payments.cards;

export default {
  getMembershipByIdSelector,
  rechargeBudgetOptionByIdSelector,
  getRechardgeBudgetBackRoute,
  getPageGoldRequested,
};
