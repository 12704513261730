import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {MouseEventHandler} from 'react';
import {Link} from 'react-router-dom';

import SpriteIcon, {TSpriteIconName} from '~/components/ui/icons/SpriteIcon';

const useStyles = makeStyles(() => ({
  listItem: {
    padding: '11px',
    backgroundColor: '#F6F7F8',
    '&.noBackground': {
      backgroundColor: 'transparent',
    },
  },
  listItemSprite: {
    marginRight: 22,
  },
  nextIcon: {
    width: 7,
    height: 12,
    color: '#808080',
    opacity: 0.4,
    marginRight: 6,
  },
}));

interface Props {
  background?: boolean;
  className?: string;
  color?: string;
  divider?: boolean;
  icon?: React.ReactNode | TSpriteIconName;
  label: React.ReactNode | string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  route?: string;
  next?: boolean;
}

const SettingsListItem: React.FC<Props> = ({
  route,
  label,
  color = '#000',
  icon,
  onClick,
  divider,
  background = true,
  className,
  next = true,
}) => {
  let listItemProps = {};
  const s = useStyles();

  if (route) {
    listItemProps = {
      to: route,
      component: Link,
    };
  }

  return (
    <ListItem
      classes={{root: clsx(s.listItem, className)}}
      className={clsx({noBackground: !background})}
      button
      divider={Boolean(divider)}
      onClick={onClick as MouseEventHandler<HTMLDivElement>}
      {...listItemProps}
    >
      {typeof icon === 'string' && (
        <SpriteIcon name={icon as TSpriteIconName} color={color} className={s.listItemSprite} />
      )}
      <ListItemText>{label}</ListItemText>
      {next && <SpriteIcon name="next-bold" fontSize="custom" className={s.nextIcon} />}
    </ListItem>
  );
};

export default SettingsListItem;
