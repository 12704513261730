import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BaseTypography from '@material-ui/core/Typography';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: (props) => props.heading && 600,
    color: (props) => {
      const {inverted, muted} = props;
      const {palette, utils} = theme;

      if (inverted && muted) {
        return utils.alpha(palette.common.white, 0.5);
      }

      if (inverted) {
        return palette.common.white;
      }

      if (muted) {
        return palette.text.secondary;
      }

      return false;
    },
  },
}));

const Typography = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {heading, inverted, muted, className, ...other} = props;
  const classes = useStyles(props);

  return <BaseTypography className={clsx(className, classes.root)} {...other} />;
};

Typography.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.bool,
  inverted: PropTypes.bool,
  muted: PropTypes.bool,
  ...(BaseTypography.propTypes || {}),
};

Typography.defaultProps = {
  className: '',
  heading: false,
  inverted: false,
  muted: false,
};

export default Typography;
