import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Box, Typography} from '@material-ui/core';

import benefitsList from './benefitsList';
import BenefitItem from './components/BenefitItem';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: 'linear-gradient(180deg, #BE30DA 0%, #993DF8 100%)',
      color: theme.palette.common.white,
      textAlign: 'center',
      padding: theme.spacing(2.5, 2, 4),
    },
    wrapper: {
      maxWidth: 1312,
      margin: '0 auto',
    },
    heading: {
      marginBottom: theme.spacing(2),
      fontWeight: 700,
    },
    benefitsWrapper: {
      '@media (min-width: 768px)': {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    benefitsItem: {
      '@media (min-width: 768px)': {
        width: '50%',
        flexBasis: '50%',
        minWidth: 237,
      },
      '@media (min-width: 1023px)': {
        width: '25%',
        flexBasis: '25%',
        minWidth: 237,
      },
    },
  }),
  {name: 'BenefitsSection'}
);

const BenefitsSection = () => {
  const s = useStyles();

  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <Typography variant="h1" className={s.heading}>
          TS Date & TS Dating Experience at Bendr
        </Typography>
        <p>
          Every detail of Bendr was engineered to make trans dating simpler than ever. From our
          attitude to our interface, everything about Bendr exists to eliminate the friction between
          you and finding someone you really click with. Here are just some of the ways we help you
          get there:
        </p>
        <p>
          Meaningful Connections: Bendr isn’t an open chat room, thread, or forum. We believe in the
          power of intimate one-on-one connections, and that’s exactly what you get here.
        </p>
        <div className={s.benefitsWrapper}>
          {benefitsList.map((item) => (
            <Box key={item.title} mt={4} px={4} className={s.benefitsItem}>
              <BenefitItem icon={item.icon} title={item.title} description={item.description} />
            </Box>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;
