import {useMemo} from 'react';

import useQuery from '~/customHooks/useQuery';

type TPromocodeParams = {
  promocode?: string;
};

const getPromoCode = (query: TPromocodeParams) => {
  if (typeof query.promocode === 'string') {
    return query.promocode;
  }

  return null;
};

const useQueryPromoCode = (): string | null => {
  const query = useQuery();

  return useMemo(() => getPromoCode(query), [query]);
};

export default useQueryPromoCode;
