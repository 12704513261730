import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';

import {navigateAction} from '~/actions/navigateActions';
import routeByName from '~/constants/routes';
import useModalById from '~/customHooks/useModalById';
import {MODAL_SEARCH_CITY_BENDR} from '~/rootStore/modals/modalsIds';
import {isAuthSelector} from '~/modules/CurrentUser/store/selectors';
import Typography from '~/components/ui/Typography';

import {ReactComponent as LocationsEmptyStateIcon} from './img/locations-empty-state.svg';

const SearchCityEmptyState: React.FC = () => {
  const dispatch = useDispatch();
  const isUserAuth = useSelector(isAuthSelector);
  const {close: closeSearchCityModal} = useModalById(MODAL_SEARCH_CITY_BENDR);

  const handleSignUp = useCallback(() => {
    dispatch(navigateAction(routeByName.signUp));
    closeSearchCityModal();
  }, [closeSearchCityModal, dispatch]);

  return (
    <>
      <div className="mt-3 mb-3 d-flex justify-center">
        <LocationsEmptyStateIcon />
      </div>
      <div className="mb-1">
        <Typography variant="h1" align="center" heading>
          No Results found
        </Typography>
      </div>
      <div className="mb-2">
        <Typography variant="body1" align="center">
          Sorry we don`t have any profiles for this location.{' '}
          {!isUserAuth && 'Sign up and be the first one in your city!'}
        </Typography>
      </div>
      {!isUserAuth && (
        <Button fullWidth onClick={handleSignUp}>
          Sign Up
        </Button>
      )}
    </>
  );
};

export default SearchCityEmptyState;
