import React, {useCallback, useEffect, useMemo} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import {MODAL_PWA_BANNER_MEDIUM} from '~/rootStore/modals/modalsIds';
import {TAppState} from '~/types/appTypes';
import {TCurrentUserUser} from '~/types/CurrentUserState';
import useModalById from '~/customHooks/useModalById';
import ModalVerifyProfileLock from '~/components/Modals/ModalVerifyProfileLock';
import ModalCompleteChatSettings from '~/components/Modals/ModalCompleteChatSettings';
import useChatSettings from '~/customHooks/useChatSettings';
import {logoutAction} from '~/modules/Auth/store/logoutAction';
import {
  isProfileClientSelector,
  isProfileCompletedSelector,
  isUserPhoneVerifiedSelector,
} from '~/modules/CurrentUser/store/selectors';
import {openFeedbackModalAction} from '~/modules/Feedback/store/actions';
import {isStandaloneModeSelector} from '~/modules/App/store/selectors';
import {loadSettingsPageAction} from '~/modules/CurrentUser/Settings/store/actions';

import {layoutCloseDrawerAction, layoutOpenDrawerAction} from '../../store/actions';
import {TSettingsItem} from '../types';
import {getLinksList} from '../helpers';
import RightDrawerComponent from './RightDrawerComponent';

interface Props {
  isDrawerOpened: boolean;
  isProfileCompleted: boolean;
  isUserPhoneVerified: boolean;
  layoutCloseDrawer: () => void;
  layoutOpenDrawer: () => void;
  openFeedbackModal: () => void;
  user: TCurrentUserUser | null;
}

export const RightDrawerContainer: React.FC<Props> = ({
  isDrawerOpened,
  isProfileCompleted,
  isUserPhoneVerified,
  layoutCloseDrawer,
  layoutOpenDrawer,
  openFeedbackModal,
  user,
}) => {
  const {open: openMediumPWAModal} = useModalById(MODAL_PWA_BANNER_MEDIUM);
  const dispatch = useDispatch();
  const isStandalone = useSelector(isStandaloneModeSelector);
  const isProfileClient = useSelector(isProfileClientSelector);

  const handleClickLogout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadSettingsPageAction());
  }, [dispatch]);

  useChatSettings();

  const linksList = useMemo(() => {
    return getLinksList({
      handleClickLogout,
      isProfileClient,
      isProfileCompleted,
      isStandalone,
      user,
    });
  }, [
    handleClickLogout,
    isProfileCompleted,
    isProfileClient,
    isStandalone,
    user,
  ]) as TSettingsItem[];

  const handleOpenPWAModal = () => {
    openMediumPWAModal();
    layoutCloseDrawer();
  };

  return (
    <>
      <SwipeableDrawer
        className="test-burger-menu-drawer"
        onClose={layoutCloseDrawer}
        onOpen={layoutOpenDrawer}
        open={isDrawerOpened}
        anchor="right"
        ModalProps={{
          keepMounted: true,
        }}
      >
        <RightDrawerComponent
          linksList={linksList}
          layoutCloseDrawer={layoutCloseDrawer}
          handleOpenPWAModal={handleOpenPWAModal}
          isUserPhoneVerified={isUserPhoneVerified}
          openFeedbackModal={openFeedbackModal}
          user={user}
        />
      </SwipeableDrawer>
      <ModalVerifyProfileLock />
      <ModalCompleteChatSettings />
    </>
  );
};

export default connect(
  (state: TAppState) => {
    const {
      currentUser: {user, profile},
      app: {shemalePages},
      layout: {isDrawerOpened},
    } = state;
    return {
      isProfileCompleted: isProfileCompletedSelector(state),
      isUserPhoneVerified: isUserPhoneVerifiedSelector(state),
      user,
      profile,
      shemalePages,
      isDrawerOpened,
    };
  },
  {
    layoutOpenDrawer: layoutOpenDrawerAction,
    layoutCloseDrawer: layoutCloseDrawerAction,
    openFeedbackModal: openFeedbackModalAction,
  }
)(RightDrawerContainer);
