"use strict";
/**
 * @packageDocumentation @module Contacts
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAll = void 0;
var effector_react_1 = require("effector-react");
var chat_1 = require("@yeobill/chat");
/**
 * To get all user's contacts with additional data (like online status, etc) as the react hook.
 */
function useAll() {
    return effector_react_1.useStore(chat_1.Contacts.All$);
}
exports.useAll = useAll;
