// @ts-expect-error update finger print
import Fingerprint2 from '@fingerprintjs/fingerprintjs';
import Cookie from 'js-cookie';

import {COOKIE_FINGERPRINT} from '~/constants/cookies';
import {ONE_YEAR} from '~/constants/dateTime';
import Sentry from '~/utils/Sentry';
import logger from '~/utils/logger';

const log = logger.module('fingerprint');
// eslint-disable-next-line import/prefer-default-export
export const getFingerprint = (): string => {
  return Fingerprint2.getPromise().then((components: Record<string, unknown>[]) => {
    const values = components.map((c: Record<string, unknown>) => c.value);
    return Fingerprint2.x64hash128(values.join(''), 31);
  });
};

export const testFingerPrint = async (): Promise<void> => {
  const oldfp = Cookie.get(COOKIE_FINGERPRINT);
  const newfp = await getFingerprint();

  if (!oldfp) {
    const expires = new Date(Date.now() + ONE_YEAR);
    Cookie.set(COOKIE_FINGERPRINT, newfp, {expires});
    return;
  }

  log.debug('fingerprint', {oldfp, newfp});
  if (oldfp !== newfp) {
    Sentry.captureMessage('Gen new fingerprint for current device');
  } else {
    Sentry.captureMessage('Fingerprint is equal');
  }
};
