import * as React from 'react';
import {useDispatch} from 'react-redux';

import routeByName from '~/constants/routes';
import {setPageFromRequested} from '~/modules/Payments/store/actions';

import WarningButton from '../ui/buttons/WarningButton';

const PurchaseGoldBtn: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setPageFromRequested('settings'));
  };

  return (
    <WarningButton
      backgroundVariant="gold"
      to={routeByName.goldMembershipChoose}
      title="Upgrade to Gold Member"
      subtitle="Send non-stop messages to anyone around the globe."
      onClick={handleClick}
    />
  );
};

export default PurchaseGoldBtn;
