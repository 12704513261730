import {memo, useEffect, useMemo, useState} from 'react';
import * as PropTypes from 'prop-types';

import AccordionPanel from '~/components/ui/accordion/AccordionPanel';

import ExpansionContent from './ExpansionContent';

const LocationsList = ({title, locations, expanded, onChange}) => {
  const [mounted, setMounted] = useState(false);
  const content = useMemo(() => <ExpansionContent locations={locations} />, [locations]);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <AccordionPanel
      title={<span>{title}</span>}
      expanded={expanded === title}
      onClick={() => onChange(title)}
    >
      {mounted && content}
    </AccordionPanel>
  );
};

LocationsList.propTypes = {
  title: PropTypes.string.isRequired,
  locations: PropTypes.shape({}).isRequired,
  expanded: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

LocationsList.defaultProps = {};

export default memo(LocationsList);
