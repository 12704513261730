import {TThunkAsyncAction} from '~/types/appTypes';
import Sentry from '~/utils/Sentry';
import {navigateReplaceAction} from '~/actions/navigateActions';

import AuthService from '../AuthService';
import {AUTH_LOGOUT} from './constants';
import {jivoDestroyAction} from '../../JivoChat/store/actions';
import {chatDestroyAction} from '../../Chats/store/actions';
import ChatsService from '../../Chats/ChatsService';
import {chatSessionSelector} from '../../Chats/store/selectors';
import setFilterProfileTypeAction from '../../Profiles/store/actions/setFilterProfileTypeAction';

// eslint-disable-next-line import/prefer-default-export
export const logoutAction: TThunkAsyncAction = () => async (dispatch, getState) => {
  const session = chatSessionSelector(getState());
  Sentry.configureScope((scope) => {
    scope.setUser(null);
  });
  if (session) {
    ChatsService.logout(session.user_id);
  }
  AuthService.logout();
  dispatch(navigateReplaceAction('/'));
  dispatch(setFilterProfileTypeAction(null));
  dispatch(jivoDestroyAction());
  dispatch(chatDestroyAction());
  dispatch(chatDestroyAction());
  dispatch({
    type: AUTH_LOGOUT,
  });
};
