import AppService from '../AppService';
import {APP_SET_LANGUAGES} from './constants';

const syncAppLanguagesAction = () => async (dispatch) => {
  const languages = await AppService.getAppLanguages();

  dispatch({
    type: APP_SET_LANGUAGES,
    payload: languages,
  });
};

export default syncAppLanguagesAction;
