import {TThunkAction} from '~/types/appTypes';

type TClearPushNotificationsAction = () => TThunkAction;

const clearPushNotificationsAction: TClearPushNotificationsAction = () => async () => {
  if (!navigator?.serviceWorker) {
    return;
  }

  const registration = await navigator.serviceWorker.ready;
  const notifications = await registration.getNotifications();

  notifications.forEach((notification) => {
    notification.close();
  });
};

export default clearPushNotificationsAction;
