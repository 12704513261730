"use strict";
/**
 * @packageDocumentation @module Messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.onNewMessage = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To react to a new incoming message.
 *
 * The passed callback function will be invoked every time on received new incoming messages with that message as an argument.
 */
function onNewMessage(cb) {
    return chat_1.Messages.onNewMessage.watch(cb);
}
exports.onNewMessage = onNewMessage;
