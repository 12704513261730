import {Action} from 'redux';

import {UPDATE_APP_ONLINE_STATUS} from '../constants';

type TUpdateAppOnlineStatusAction = (isOnline: boolean) => Action;
// eslint-disable-next-line import/prefer-default-export
export const updateAppOnlineStatusAction: TUpdateAppOnlineStatusAction = (isOnline) => ({
  type: UPDATE_APP_ONLINE_STATUS,
  payload: {isOnline},
});
