"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LoggerModule = /** @class */ (function () {
    function LoggerModule(name, logFn) {
        this.name = name;
        this.__originLog = logFn;
    }
    LoggerModule.prototype.log = function (level, message, extendedData) {
        this.__originLog(this.name, level, message, extendedData);
    };
    LoggerModule.prototype.verbose = function (message, extendedData) {
        this.log('verbose', message, extendedData);
    };
    LoggerModule.prototype.debug = function (message, extendedData) {
        this.log('debug', message, extendedData);
    };
    LoggerModule.prototype.info = function (message, extendedData) {
        this.log('info', message, extendedData);
    };
    LoggerModule.prototype.warn = function (message, extendedData) {
        this.log('warn', message, extendedData);
    };
    LoggerModule.prototype.error = function (message, extendedData) {
        this.log('error', message, extendedData);
    };
    LoggerModule.prototype.metric = function (message, extendedData) {
        this.log('metric', message, extendedData);
    };
    LoggerModule.prototype.event = function (message, extendedData) {
        this.log('event', message, extendedData);
    };
    return LoggerModule;
}());
exports.default = LoggerModule;
