import {lazy, Suspense, useEffect} from 'react';
import {Switch, Route} from 'react-router-dom';
import {useDispatch, useSelector, batch} from 'react-redux';

import './styles/global.css';
import './styles/fonts.css';
import {initAppAction} from './modules/App/store/actions';
import {appStatusSelector} from './modules/App/store/selectors';
import GuestRoute from './helpers/routes/GuestRoute';
import routeByName from './constants/routes';
import PrivateRoute from './helpers/routes/PrivateRoute';
import WelcomeScreen from './components/preloaders/WelcomeScreen';
import {BaseLayout} from './components/Layout';
import Home from './pages/Home';
import {
  setUpInitInterceptor as setUpInitInterceptorAction,
  setUpLogoutInterceptor as setUpLogoutInterceptorAction,
} from './utils/axios';
import {AppStatus} from './constants/app';
import {ErrorPage} from './components/Errors';
import componentLoader from './helpers/componentLoader';

// region chunks block
const ChangeUserEmailConfirmPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/ChangeUserEmailConfirm/ChangeUserEmailConfirmPage' /* webpackChunkName: "ChangeUserEmailConfirmPage" */
      )
  )
);
const PinCodeSettings = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/PinCode/PinCodeSettings' /* webpackChunkName: "PinCodeSettings" */
      )
  )
);
const UserPhoneVerification = lazy(() =>
  componentLoader(
    () =>
      import('./modules/Auth/UserPhoneVerification' /* webpackChunkName: "UserPhoneVerification" */)
  )
);
const AddCreditCardContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/AddCreditCard/AddCreditCardContainer' /* webpackChunkName: "Payments" */
      )
  )
);
const ChangeEmailContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/ChangeEmail/ChangeEmailContainer' /* webpackChunkName: "ChangeEmailContainer" */
      )
  )
);
const SignUpContainer = lazy(() =>
  componentLoader(
    () => import('./modules/Auth/SignUp/SignUpPage' /* webpackChunkName: "SignUpPage" */)
  )
);
const SignUpLandingPage = lazy(() =>
  componentLoader(
    () =>
      import('./modules/Auth/SignUp/SignUpLandingPage' /* webpackChunkName: "SignUpLandingPage" */)
  )
);
const ChangeUserPhoneContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/ChangeUserPhone/ChangeUserPhoneContainer' /* webpackChunkName: "ChangeUserPhoneContainer" */
      )
  )
);
const PasswordResetPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Auth/PasswordReset/PasswordResetPage' /* webpackChunkName: "PasswordResetPage" */
      )
  )
);
const ChangePasswordContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/ChangePassword/ChangePasswordContainer' /* webpackChunkName: "ChangePasswordContainer" */
      )
  )
);
const CloseAccountContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/CloseAccount/CloseAccountContainer' /* webpackChunkName: "CloseAccountContainer" */
      )
  )
);
const PaymentCreatePage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/PaymentCreate/PaymentCreatePage' /* webpackChunkName: "Payments" */
      )
  )
);
const BillingAndPaymentsContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/BillingAndPayments/BillingAndPaymentsContainer' /* webpackChunkName: "Payments" */
      )
  )
);
const ProfileDetailsPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Profiles/ProfileDetailsPage/index' /* webpackChunkName: "ProfileDetailsPageContainerBendr" */
      )
  )
);
const ChooseMembershipContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/Memberships/ChooseMembership/ChooseMembershipContainer' /* webpackChunkName: "Payments" */
      )
  )
);
const ChooseRechargeBudgetContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/RechargeBudget/ChooseRechargeBudget/ChooseRechargeBudgetContainer' /* webpackChunkName: "Payments" */
      )
  )
);
const PurchaseVipContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/PurchaseVip/PurchaseVipContainer' /* webpackChunkName: "Payments" */
      )
  )
);
const ComplimentaryActivationPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/ComplimentaryActivation/ComplimentaryActivationPage' /* webpackChunkName: "Payments" */
      )
  )
);
const MoveProfileContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/MoveProfile/MoveProfileContainer' /* webpackChunkName: "MoveProfileContainer" */
      )
  )
);
const BuyTopUpsContainer = lazy(() =>
  componentLoader(
    () =>
      import('./modules/Payments/BuyTopUps/BuyTopUpsContainer' /* webpackChunkName: "Payments" */)
  )
);
const PrivacyPolicy = lazy(() =>
  componentLoader(
    () => import('~/pages/PrivacyPolicy/index' /* webpackChunkName: "PrivacyPolicy" */)
  )
);
const TermsAndConditions = lazy(() =>
  componentLoader(
    () => import('~/pages/TermsAndConditions/index' /* webpackChunkName: "TermsAndConditions" */)
  )
);
const ContactUs = lazy(() =>
  componentLoader(() => import('~/pages/ContactUs/index' /* webpackChunkName: "ContactUs" */))
);
const LocationProfilesContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Profiles/ProfileListPage/components/ProfileListPage' /* webpackChunkName: "Bootstrap" */
      )
  )
);
const ProfileListUserLocationPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Profiles/ProfileListPage/components/ProfileListUserLocationPage' /* webpackChunkName: "Bootstrap" */
      )
  )
);
const SignInContainer = lazy(() =>
  componentLoader(
    () => import('./modules/Auth/SignIn/SignInContainer' /* webpackChunkName: "SignInContainer" */)
  )
);
const TwoFactorAuthPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Auth/TwoFactorAuth/TwoFactorAuthPage' /* webpackChunkName: "SignInContainer" */
      )
  )
);
const FavoritesContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/Favorites/FavoritesContainer' /* webpackChunkName: "FavoritesContainer" */
      )
  )
);
const ChangeLanguageContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/ChangeLanguage/ChangeLanguageContainer' /* webpackChunkName: "ChangeLanguageContainer" */
      )
  )
);
const VerificationContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/Verification/VerificationContainer' /* webpackChunkName: "VerificationContainer" */
      )
  )
);
const VerificationIdContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/VerificationId/VerificationIdPage' /* webpackChunkName: "VerificationIdPage" */
      )
  )
);
const ChooseProfileTypePage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/ChooseProfileType/ChooseProfileTypePage' /* webpackChunkName: "ChooseProfileTypePage" */
      )
  )
);
const CreateProfileWrapper = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/ProfileForm/CreateForm/CreateProfileForm' /* webpackChunkName: "CreateProfileForm" */
      )
  )
);
const EditProfileWrapper = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/ProfileForm/EditForm/EditProfileWrapper' /* webpackChunkName: "EditProfileWrapper" */
      )
  )
);
const SingleChatContainer = lazy(() =>
  componentLoader(
    () => import('./modules/Chats/SingleChat/SingleChatPage' /* webpackChunkName: "Chat" */)
  )
);
const ChatsListContainer = lazy(() =>
  componentLoader(
    () => import('./modules/Chats/ChatsList/ChatsListPage' /* webpackChunkName: "Chat" */)
  )
);
const ContactsListContainer = lazy(() =>
  componentLoader(
    () => import('./modules/Contacts/ContactsList/ContactsListPage' /* webpackChunkName: "Chat" */)
  )
);
const CreateSingleChatContainer = lazy(() =>
  componentLoader(
    () =>
      import('./modules/Chats/CreateSingleChat/ChatSearchUserPage' /* webpackChunkName: "Chat" */)
  )
);
const ChangeTextSettingsContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/ChangeTextSettings/ChangeTextSettingsContainer' /* webpackChunkName: "ChangeTextSettingsContainer" */
      )
  )
);
const ImpersonatePage = lazy(() =>
  componentLoader(
    () => import('./modules/Auth/Impersonate/ImpersonatePage' /* webpackChunkName: "Others" */)
  )
);
// const AboutUs = lazy(() =>
//   componentLoader(
//     () =>
//       import(
//         './components/pages/static/AboutUs' /* webpackChunkName: "Others" */
//       )
//   )
// );
const ShemaleReferences = lazy(() =>
  componentLoader(() => import('./pages/ShemaleReferences/index' /* webpackChunkName: "Others" */))
);
const ShemaleSingle = lazy(() =>
  componentLoader(() => import('./pages/ShemaleSingle/index' /* webpackChunkName: "Others" */))
);
const PurchaseRechargeBudgetContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/RechargeBudget/PurchaseRechargeBudget/PurchaseRechargeBudgetContainer' /* webpackChunkName: "Payments" */
      )
  )
);
const GoldMembershipChoosePage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/GoldMembershipChoose/GoldMembershipChoosePage' /* webpackChunkName: "Payments" */
      )
  )
);
const BlockedUserListPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/CurrentUser/settingSections/BlockedUserList/BlockedUserListPage' /* webpackChunkName: "Others" */
      )
  )
);
const PaymentRefundPage = lazy(() =>
  componentLoader(
    () => import('./modules/Payments/Refund/RefundPage' /* webpackChunkName: "Payments" */)
  )
);
const PasswordResetConfirmPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Auth/PasswordResetConfirm/PasswordResetConfirmPage' /* webpackChunkName: "Others" */
      )
  )
);
const PaymentRedirectPage = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/PaymentRedirect/PaymentRedirectPage' /* webpackChunkName: "Payments" */
      )
  )
);
const PurchaseMembershipContainer = lazy(() =>
  componentLoader(
    () =>
      import(
        './modules/Payments/Memberships/PurchaseMembership/PurchaseMembershipPage' /* webpackChunkName: "Payments" */
      )
  )
);
const Page404 = lazy(() =>
  componentLoader(() => import('./pages/Page404/index' /* webpackChunkName: "Others" */))
);
const FaqDetailPage = lazy(() =>
  componentLoader(
    () => import('./modules/CurrentUser/Faq/FaqDetailPage' /* webpackChunkName: "Faq" */)
  )
);
const ProfileReportPage = lazy(() =>
  componentLoader(
    () =>
      import('./modules/CurrentUser/ProfileReport/ProfileReportPage' /* webpackChunkName: "Faq" */)
  )
);
const AllowCookieGuidesPage = lazy(() =>
  componentLoader(
    () => import('~/pages/AllowCookie/Guides/index' /* webpackChunkName: "AllowCookieGuides" */)
  )
);
const AllowCookieGuideDetails = lazy(() =>
  componentLoader(
    () => import('~/pages/AllowCookie/Details/index' /* webpackChunkName: "AllowCookieGuides" */)
  )
); // end region chunks

const App: React.FC = () => {
  const appStatus = useSelector(appStatusSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    batch(() => {
      dispatch(setUpLogoutInterceptorAction());
      dispatch(setUpInitInterceptorAction());
      dispatch(initAppAction());
    });
  }, [dispatch]);
  if (AppStatus.wait === appStatus) {
    return (
      <BaseLayout>
        <WelcomeScreen />
      </BaseLayout>
    );
  }
  if (AppStatus.error === appStatus) {
    return <ErrorPage />;
  }
  return (
    <BaseLayout>
      <Suspense fallback={<WelcomeScreen />}>
        <Switch>
          <Route exact path={routeByName.home} component={Home} />
          <Route exact path={routeByName.privacyPolicy} component={PrivacyPolicy} />
          {/* <Route exact path={routeByName.aboutUs} component={AboutUs} /> */}
          <Route exact path={routeByName.terms} component={TermsAndConditions} />
          <Route exact path={routeByName.contact} component={ContactUs} />
          <Route exact path={routeByName.shemaleReferences} component={ShemaleReferences} />
          <Route exact path={routeByName.shemaleSingle()} component={ShemaleSingle} />
          <Route path={routeByName.profileDetailsPage} component={ProfileDetailsPage} />
          <GuestRoute exact path={routeByName.signIn} component={SignInContainer} />
          <GuestRoute exact path={routeByName.twoFactorAuth} component={TwoFactorAuthPage} />
          <GuestRoute exact path={routeByName.signUp} component={SignUpContainer} />
          <Route path={routeByName.userImpersonate} component={ImpersonatePage} />
          <GuestRoute
            exact
            path={[routeByName.passwordResetRequest, routeByName.passwordResetRequestFull]}
            component={PasswordResetPage}
          />
          <GuestRoute exact path={routeByName.passwordReset} component={PasswordResetConfirmPage} />
          <GuestRoute exact path={routeByName.postProfile} component={SignUpLandingPage} />
          <GuestRoute
            exact
            path={routeByName.allowCookieGuides}
            component={AllowCookieGuidesPage}
          />
          <GuestRoute
            exact
            path={routeByName.allowCookieGuideDetails()}
            component={AllowCookieGuideDetails}
          />
          <PrivateRoute
            allowUnverifiedUser
            exact
            path={routeByName.userPhoneVerification}
            component={UserPhoneVerification}
          />
          <PrivateRoute
            allowUnverifiedUser
            exact
            path={routeByName.userPhoneChange}
            component={ChangeUserPhoneContainer}
          />
          <PrivateRoute exact path={routeByName.changePhone} component={ChangeUserPhoneContainer} />
          <PrivateRoute exact path={routeByName.verifyPhone} component={UserPhoneVerification} />
          <PrivateRoute
            exact
            path={routeByName.settingsAddCard}
            component={AddCreditCardContainer}
          />
          <PrivateRoute exact path={routeByName.paymentCreate} component={PaymentCreatePage} />
          <PrivateRoute exact path={routeByName.paymentRedirect} component={PaymentRedirectPage} />
          <PrivateRoute
            path={routeByName.billingAndPayments}
            component={BillingAndPaymentsContainer}
          />
          <PrivateRoute path={routeByName.refund} component={PaymentRefundPage} />
          <PrivateRoute path={routeByName.textSettings} component={ChangeTextSettingsContainer} />
          <PrivateRoute path={routeByName.blockedList} component={BlockedUserListPage} />
          <PrivateRoute path={routeByName.changeEmail} component={ChangeEmailContainer} />
          <Route path={routeByName.changeEmailConfirm} component={ChangeUserEmailConfirmPage} />
          <PrivateRoute path={routeByName.changeLanguage} component={ChangeLanguageContainer} />
          <PrivateRoute path={routeByName.changePassword} component={ChangePasswordContainer} />
          <PrivateRoute path={routeByName.setPinCode} component={PinCodeSettings} />
          <PrivateRoute path={routeByName.closeAccount} component={CloseAccountContainer} />
          <PrivateRoute path={routeByName.chooseProfileType} component={ChooseProfileTypePage} />
          <PrivateRoute path={routeByName.createProfile} component={CreateProfileWrapper} />
          <PrivateRoute path={routeByName.profileForm} component={EditProfileWrapper} />
          <PrivateRoute
            path={routeByName.goldMembershipChoose}
            component={GoldMembershipChoosePage}
          />
          <PrivateRoute
            exact
            path={routeByName.chooseMembership}
            component={ChooseMembershipContainer}
          />
          <PrivateRoute
            path={routeByName.purchaseMembership()}
            component={PurchaseMembershipContainer}
          />
          <PrivateRoute
            exact
            path={routeByName.chooseRechargeBudget}
            component={ChooseRechargeBudgetContainer}
          />
          <PrivateRoute
            path={routeByName.purchaseRechargeBudget()}
            component={PurchaseRechargeBudgetContainer}
          />
          <PrivateRoute exact path={routeByName.purchaseVip} component={PurchaseVipContainer} />
          <PrivateRoute
            exact
            path={routeByName.complimentaryActivation}
            component={ComplimentaryActivationPage}
          />
          <PrivateRoute exact path={routeByName.moveAd} component={MoveProfileContainer} />
          <PrivateRoute exact path={routeByName.buyTopUps} component={BuyTopUpsContainer} />
          <PrivateRoute exact path={routeByName.favorites} component={FavoritesContainer} />
          <PrivateRoute
            exact
            path={routeByName.identityVerification}
            component={VerificationContainer}
          />
          <PrivateRoute
            exact
            path={routeByName.identityVerificationId}
            component={VerificationIdContainer}
          />
          <PrivateRoute exact path={routeByName.faqGuides} component={FaqDetailPage} />
          {/* Chat */}
          <PrivateRoute exact path={routeByName.chats.index} component={ChatsListContainer} />
          <PrivateRoute
            exact
            path={routeByName.chats.create}
            component={CreateSingleChatContainer}
          />
          <PrivateRoute exact path={routeByName.chats.single()} component={SingleChatContainer} />
          <PrivateRoute exact path={routeByName.contacts.index} component={ContactsListContainer} />
          <PrivateRoute exact path={routeByName.profileReport} component={ProfileReportPage} />
          <Route
            exact
            path={routeByName.profileListUserLocation}
            component={ProfileListUserLocationPage}
          />
          <Route
            path={[routeByName.locationPage, routeByName.locationPageAlias]}
            component={LocationProfilesContainer}
          />

          <Route exact path={routeByName.notFound} component={Page404} />
          <Route path="*" component={Page404} />
        </Switch>
      </Suspense>
    </BaseLayout>
  );
};

export default App;
