import {useEffect, FC} from 'react';
import {useSnackbar} from 'notistack';
import {Messages, Users} from '@yeobill/react-chat';
import {TOnMessageErrorParams} from '@yeobill/chat/lib/types';

import errorSnackbar from '~/helpers/snackbar/errorSnackbar';
import logger from '~/utils/logger';

const log = logger.module('ChatErrorWatcher');

const isUserBlocked = (error: TOnMessageErrorParams['error']) => {
  return error.code === 503 && error.detail === 'Service not available.';
};

const ChatErrorWatcher: FC = () => {
  const snackbar = useSnackbar();

  useEffect(() => {
    const watch = Messages.onMessageError(({error, message}) => {
      log.error('onMessageError', {
        error: `status: ${error.code} code: ${error.status} details: ${error.detail}`,
        message,
      });

      if (isUserBlocked(error)) {
        // TODO: cast type - problem in @yeobill/chat - mb fixed in future ;/
        const user = message && Users.getUser(message.recipient_id as number);
        const username = user ? user.full_name : 'this user';
        errorSnackbar(snackbar.enqueueSnackbar, `You can't text to ${username}.`);
      }
    });

    return () => {
      watch.unsubscribe();
    };
  }, [snackbar.enqueueSnackbar]);

  return null;
};

export default ChatErrorWatcher;
