import * as React from 'react';

import ChatStatusTimer from './ChatStatusTimer';
import RefreshGeoTimer from './RefreshGeoTimer';

const TimerManager: React.FC = () => {
  return (
    <>
      <RefreshGeoTimer />
      <ChatStatusTimer />
    </>
  );
};

export default TimerManager;
