import {makeStyles} from '@material-ui/core';

const profileListSortStyles = makeStyles(
  (theme) => ({
    sortButton: {
      fontSize: 14,
      height: '100%',
      minWidth: 0,
      padding: theme.spacing(0.375, 1),
    },
    popoverWrap: {
      paddingTop: 11,
      overflow: 'visible',
      boxShadow: '0 0 8px 3px rgba(0, 0, 0, 0.2)',
      '&:after': {
        content: '""',
        width: 0,
        height: 0,
        borderLeft: '7px solid transparent',
        borderRight: '7px solid transparent',
        borderBottom: '9px solid #fff',
        position: 'absolute',
        top: -9,
        left: 14,
      },
    },
    popoverTitle: {
      color: '#000',
      paddingBottom: 6,
      paddingLeft: 8,
      paddingRight: 10,
      fontSize: 10,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    sortIcon: {
      width: 17,
      height: 14,
      marginRight: 2,
    },
    sortItemButton: {
      borderRadius: 0,
      justifyContent: 'flex-start',
      paddingBottom: 2,
      paddingTop: 2,
      '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    sortItemButtonLabel: {
      fontWeight: 400,
    },
  }),
  {name: 'FilterByProfileType'}
);

export default profileListSortStyles;
