/**
 * - Use underscore in identifiers and filenames then it's easier to work with
 * - Make sure viewbox is not stripped when optimizing
 * - Change fill to `fill="currentColor"`
 * - Default size of the box is 24x24
 */

import clsx from 'clsx';
import * as React from 'react';
import {MouseEventHandler} from 'react';

import {ReactComponent as add_circle} from './add-circle.svg';
import {ReactComponent as add_image_icon} from './add-image-icon.svg';
import {ReactComponent as add_to_contact} from './add-to-contact.svg';
import {ReactComponent as addPerson} from './addPerson.svg';
import {ReactComponent as arrow_up} from './arrow-up.svg';
import {ReactComponent as arrow_back} from './arrow_back.svg';
import {ReactComponent as arrow_forward} from './arrow_forward.svg';
import {ReactComponent as back_keyboard} from './back-keyboard.svg';
import {ReactComponent as back} from './back.svg';
import {ReactComponent as balance_icon} from './balance-icon.svg';
import {ReactComponent as bell} from './bell.svg';
import {ReactComponent as billing} from './billing.svg';
import {ReactComponent as block_user_colorful} from './block-user-colorful.svg';
import {ReactComponent as block_user} from './block-user.svg';
import {ReactComponent as btc_icon} from './btc-icon.svg';
import {ReactComponent as cameraDisabled} from './camera-disabled.svg';
import {ReactComponent as cameraSwitch} from './camera-switch.svg';
import {ReactComponent as camera} from './camera.svg';
import {ReactComponent as cams} from './cams.svg';
import {ReactComponent as cancel_red} from './cancel-red.svg';
import {ReactComponent as card_icon} from './card-icon.svg';
import {ReactComponent as chat_add} from './chat-add.svg';
import {ReactComponent as chat} from './chat.svg';
import {ReactComponent as check_bold} from './check-bold.svg';
import {ReactComponent as check_round_off} from './check-round-off.svg';
import {ReactComponent as check_round_on} from './check-round-on.svg';
import {ReactComponent as circle_close} from './circle-close.svg';
import {ReactComponent as circle_exclamation} from './circle-exclamation.svg';
import {ReactComponent as close} from './close.svg';
import {ReactComponent as cogwheel} from './cogwheel.svg';
import {ReactComponent as cross} from './cross.svg';
import {ReactComponent as cross_thin} from './cross-thin.svg';
import {ReactComponent as crossed_eye} from './crossed-eye.svg';
import {ReactComponent as dialog} from './dialog.svg';
import {ReactComponent as dollar} from './dollar.svg';
import {ReactComponent as done_round} from './done-round.svg';
import {ReactComponent as drop_down} from './drop-down.svg';
import {ReactComponent as drop_down_thin} from './drop-down-thin.svg';
import {ReactComponent as drop} from './drop.svg';
import {ReactComponent as edit} from './edit.svg';
import {ReactComponent as email_support} from './email-support.svg';
import {ReactComponent as email} from './email.svg';
import {ReactComponent as error_404} from './error-404.svg';
import {ReactComponent as expand_arrow_down} from './expand-arrow-down.svg';
import {ReactComponent as expand_arrow_down_sm} from './expand-arrow-down-sm.svg';
import {ReactComponent as eye} from './eye.svg';
import {ReactComponent as filter} from './filter.svg';
import {ReactComponent as gallery_colorful} from './gallery-colorful.svg';
import {ReactComponent as gallery} from './gallery.svg';
import {ReactComponent as gallery_close} from './gallery_close.svg';
import {ReactComponent as group} from './group.svg';
import {ReactComponent as hamburger} from './hamburger.svg';
import {ReactComponent as heart_outlined} from './heart-outlined.svg';
import {ReactComponent as heart} from './heart.svg';
import {ReactComponent as home} from './home.svg';
import {ReactComponent as icon_business} from './icon-business.svg';
import {ReactComponent as icon_female} from './icon-female.svg';
import {ReactComponent as icon_gay} from './icon-gay.svg';
import {ReactComponent as icon_male} from './icon-male.svg';
import {ReactComponent as icon_sort_newest} from './icon-sort-newest.svg';
import {ReactComponent as icon_sort_oldest} from './icon-sort-oldest.svg';
import {ReactComponent as icon_sort} from './icon-sort.svg';
import {ReactComponent as icon_trans} from './icon-trans.svg';
import {ReactComponent as info} from './info.svg';
import {ReactComponent as input_check} from './input-check.svg';
import {ReactComponent as input_error} from './input-error.svg';
import {ReactComponent as jivo} from './jivo.svg';
import {ReactComponent as job} from './job.svg';
import {ReactComponent as keyboard_attach} from './keyboard_attach.svg';
import {ReactComponent as keyboard_voice} from './keyboard_voice.svg';
import {ReactComponent as lamp} from './lamp.svg';
import {ReactComponent as language_dialog} from './language-dialog.svg';
import {ReactComponent as like} from './like.svg';
import {ReactComponent as location_colorful} from './location-colorful.svg';
import {ReactComponent as location_marker_outlined} from './location-marker-outlined.svg';
import {ReactComponent as location_marker_settings} from './location-marker-settings.svg';
import {ReactComponent as location_marker} from './location-marker.svg';
import {ReactComponent as lock_update} from './lock-update.svg';
import {ReactComponent as lock_user} from './lock-user.svg';
import {ReactComponent as logo_bendr} from './logo-bendr.svg';
import {ReactComponent as logout} from './logout.svg';
import {ReactComponent as mail} from './mail.svg';
import {ReactComponent as media_video_upload} from './media-video-upload.svg';
import {ReactComponent as message_read} from './message-read.svg';
import {ReactComponent as message_received} from './message-received.svg';
import {ReactComponent as microphone} from './microphone.svg';
import {ReactComponent as microphone_disabled} from './microphone_disabled.svg';
import {ReactComponent as minus} from './minus.svg';
import {ReactComponent as next_bold} from './next-bold.svg';
import {ReactComponent as next} from './next.svg';
import {ReactComponent as pause} from './pause.svg';
import {ReactComponent as pen} from './pen.svg';
import {ReactComponent as people} from './people.svg';
import {ReactComponent as person_icon} from './person_icon.svg';
import {ReactComponent as phone_change} from './phone-change.svg';
import {ReactComponent as phone_decline} from './phone-decline.svg';
import {ReactComponent as phone_square} from './phone-square.svg';
import {ReactComponent as phone} from './phone.svg';
import {ReactComponent as phone2} from './phone2.svg';
import {ReactComponent as photo_icon} from './photo-icon.svg';
import {ReactComponent as photos} from './photos.svg';
import {ReactComponent as pin_lock} from './pin-lock.svg';
import {ReactComponent as play} from './play.svg';
import {ReactComponent as plus_card} from './plus-card.svg';
import {ReactComponent as plus} from './plus.svg';
import {ReactComponent as post_profile_communication} from './post-profile-communication.svg';
import {ReactComponent as post_profile_privacy} from './post-profile-privacy.svg';
import {ReactComponent as post_profile_swiss} from './post-profile-swiss.svg';
import {ReactComponent as post_profile_verification} from './post-profile-verification.svg';
import {ReactComponent as power} from './power.svg';
import {ReactComponent as preloader} from './preloader.svg';
import {ReactComponent as profile} from './profile.svg';
import {ReactComponent as question_circle_icon} from './question-circle-icon.svg';
import {ReactComponent as RC_radio_round_on} from './radio-round-on.svg';
import {ReactComponent as RC_radio_round_off} from './radio-round-off.svg';
import {ReactComponent as reload} from './reload.svg';
import {ReactComponent as remove_card_icon} from './remove-card-icon.svg';
import {ReactComponent as remove_from_contact} from './remove-from-contact.svg';
import {ReactComponent as search_bendr} from './search-bendr.svg';
import {ReactComponent as search} from './search.svg';
import {ReactComponent as send_arrow} from './send-arrow.svg';
import {ReactComponent as sort_white} from './sort-white.svg';
import {ReactComponent as speaker} from './speaker.svg';
import {ReactComponent as star_filled} from './star-filled.svg';
import {ReactComponent as star_warning} from './star-warning.svg';
import {ReactComponent as stop_sign} from './stop-sign.svg';
import {ReactComponent as subscription_icon} from './subscription-icon.svg';
import {ReactComponent as swiper_like_disabled} from './swiper-like-disabled.svg';
import {ReactComponent as swiper_like} from './swiper-like.svg';
import {ReactComponent as swiper_reject} from './swiper-reject.svg';
import {ReactComponent as swiper_rewind} from './swiper-rewind.svg';
import {ReactComponent as tab_location_pin} from './tab-location-pin.svg';
import {ReactComponent as tab_people} from './tab-people.svg';
import {ReactComponent as text_sms_icon} from './text-sms-icon.svg';
import {ReactComponent as three_dots_options} from './three-dots-options.svg';
import {ReactComponent as trash} from './trash.svg';
import {ReactComponent as unblock_user} from './unblock-user.svg';
import {ReactComponent as video_icon} from './video-icon.svg';
import {ReactComponent as video} from './video.svg';
import {ReactComponent as warning_sign} from './warning-sign.svg';
import {ReactComponent as warning_sign_triangle} from './warning-sign-triangle.svg';

const icons = {
  '404': error_404,
  'add-circle': add_circle,
  'add-image-icon': add_image_icon,
  'add-to-contact': add_to_contact,
  arrow_back,
  arrow_forward,
  'arrow-up': arrow_up,
  'back-keyboard': back_keyboard,
  'balance-icon': balance_icon,
  'block-user-colorful': block_user_colorful,
  'block-user': block_user,
  'btc-icon': btc_icon,
  'cancel-red': cancel_red,
  'card-icon': card_icon,
  'chat-add': chat_add,
  'check-bold': check_bold,
  'check-round-off': check_round_off,
  'check-round-on': check_round_on,
  'circle-close': circle_close,
  'circle-exclamation': circle_exclamation,
  'crossed-eye': crossed_eye,
  'done-round': done_round,
  'drop-down': drop_down,
  'drop-down-thin': drop_down_thin,
  'email-support': email_support,
  'expand-arrow-down': expand_arrow_down,
  'expand-arrow-down-sm': expand_arrow_down_sm,
  'gallery-colorful': gallery_colorful,
  'heart-outlined': heart_outlined,
  'icon-business': icon_business,
  'icon-female': icon_female,
  'icon-gay': icon_gay,
  'icon-male': icon_male,
  'icon-sort-newest': icon_sort_newest,
  'icon-sort-oldest': icon_sort_oldest,
  'icon-sort': icon_sort,
  'icon-trans': icon_trans,
  'input-check': input_check,
  'input-error': input_error,
  'language-dialog': language_dialog,
  'location-colorful': location_colorful,
  'location-marker-outlined': location_marker_outlined,
  'location-marker-settings': location_marker_settings,
  'location-marker': location_marker,
  'lock-update': lock_update,
  'lock-user': lock_user,
  'logo-bendr': logo_bendr,
  'media-video-upload': media_video_upload,
  'message-read': message_read,
  'message-received': message_received,
  'next-bold': next_bold,
  'phone-change': phone_change,
  'phone-square': phone_square,
  'photo-icon': photo_icon,
  'pin-lock': pin_lock,
  'plus-card': plus_card,
  'post-profile-communication': post_profile_communication,
  'post-profile-privacy': post_profile_privacy,
  'post-profile-swiss': post_profile_swiss,
  'post-profile-verification': post_profile_verification,
  'question-circle-icon': question_circle_icon,
  'radio-round-off': RC_radio_round_off,
  'radio-round-on': RC_radio_round_on,
  'remove-card-icon': remove_card_icon,
  'remove-from-contact': remove_from_contact,
  'search-bendr': search_bendr,
  'send-arrow': send_arrow,
  'sort-white': sort_white,
  'star-filled': star_filled,
  'star-warning': star_warning,
  'stop-sign': stop_sign,
  'subscription-icon': subscription_icon,
  'swiper-like-disabled': swiper_like_disabled,
  'swiper-like': swiper_like,
  'swiper-reject': swiper_reject,
  'swiper-rewind': swiper_rewind,
  'tab-location-pin': tab_location_pin,
  'tab-people': tab_people,
  'text-sms-icon': text_sms_icon,
  'three-dots-options': three_dots_options,
  'unblock-user': unblock_user,
  'video-icon': video_icon,
  'warning-sign': warning_sign,
  'warning-sign-triangle': warning_sign_triangle,
  addPerson,
  back,
  bell,
  billing,
  camera,
  cameraDisabled,
  cameraSwitch,
  cams,
  chat,
  gallery_close,
  close,
  cogwheel,
  cross,
  cross_thin,
  dialog,
  dollar,
  drop,
  edit,
  email,
  eye,
  filter,
  gallery,
  group,
  hamburger,
  heart,
  home,
  info,
  jivo,
  job,
  keyboard_attach,
  keyboard_voice,
  lamp,
  like,
  logout,
  mail,
  microphone_disabled,
  microphone,
  minus,
  next,
  pause,
  pen,
  people,
  person_icon,
  phone_decline,
  phone,
  phone2,
  photos,
  play,
  plus,
  power,
  preloader,
  profile,
  reload,
  search,
  speaker,
  trash,
  video,
} as const;

export type TSpriteIconName = keyof typeof icons;

export enum fontSizes {
  extraSmall = 'extra-small',
  small = 'small', // 16x16 px
  normal = 'medium', // 24x24 px
  mediumLarge = 'medium-large',
  large = 'large',
  extraLarge = 'extra-large',
  custom = '',
}

interface Props {
  className?: string;
  fontSize?: keyof typeof fontSizes;
  name: TSpriteIconName;
  onClick?: MouseEventHandler<SVGSVGElement>;
  color?: string;
  style?: Record<string, string | number>;
}

const SpriteIcon: React.FC<Props> = ({
  className = '',
  color = '',
  fontSize = 'normal',
  name,
  onClick = () => undefined,
  style = {},
}) => {
  const svgClassNames = clsx('sprite-svg', fontSizes[fontSize], className);
  const Component =
    icons[name] || // eslint-disable-next-line no-console
    console.error(`Icon ${name} is missing`);

  return Component ? (
    <Component color={color} style={style} onClick={onClick} className={svgClassNames} />
  ) : null;
};

export default SpriteIcon;
