import React, {useCallback} from 'react';
import {Form} from 'react-final-form';
import {useDispatch, useSelector, batch} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {navigateAction} from '~/actions/navigateActions';
import routeByName from '~/constants/routes';
import {modalHideAction} from '~/rootStore/modals/modalsActions';
import {MODAL_CHOOSE_LOCATION} from '~/rootStore/modals/modalsIds';
import {SearchCityContainerBendr} from '~/components/SearchCity';
import useQuery from '~/customHooks/useQuery';
import {TProfileType} from '~/constants/profiles';
import {TLeafLocation} from '~/types/LeafLocation';
import {isProfileTransSelector} from '~/modules/CurrentUser/store/selectors';
import {getLocationLink, getHeaderLocationSelector} from '~/modules/Locations/store/selectors';
import {updateHeaderLocationAction} from '~/modules/App/store/actions/updateHeaderLocationAction';
import {updateHeaderNearbyLocation} from '~/modules/App/store/actions';

import {
  resetNavigationFiltersAction,
  resetProfileFiltersAction,
  setProfileLocationFiltersAction,
} from '../../../store/actions';
import {
  setProfileLocationIdActionCreator,
  resetLocationProfilesActionCreator,
} from '../../../store/actionCreators';
import setFilterProfileTypeAction from '../../../store/actions/setFilterProfileTypeAction';
import {filterProfileTypeSelector} from '../../../store/selectors';
import {TRouteParams, TSearchType} from '../../types';
import ProfileTypeControl from './ProfileTypeControl';
import SearchTypeControl from './SearchTypeControl';
import MapControl from './MapControl';
import DistanceControl from './DistanceControl';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
  },
  Button: {
    fontSize: 20,
    borderRadius: 50,
  },
}));

type TFormValues = {
  searchType: TSearchType;
  profileType: TProfileType;
  headerLocation: TLeafLocation;
  distance: number;
  locationId: number;
};

const ProfileChooseLocationBendr: React.FC = () => {
  const s = useStyles();

  const dispatch = useDispatch();
  const userLocationRouteMatch = useRouteMatch<TRouteParams>(routeByName.profileListUserLocation);
  const query = useQuery();
  const profileType = useSelector(filterProfileTypeSelector);
  const isProfileTrans = useSelector(isProfileTransSelector);
  const headerLocation = useSelector(getHeaderLocationSelector);

  const handleFormSubmit = useCallback(
    (values: TFormValues) => {
      batch(() => {
        const locationId = values?.headerLocation?.id;
        const location = values?.headerLocation;

        dispatch(resetNavigationFiltersAction());
        dispatch(resetLocationProfilesActionCreator());
        dispatch(
          setProfileLocationFiltersAction({
            searchType: values.searchType,
            profileType: values.profileType,
            distance: values.distance,
          })
        );
        // make sure profiles.filters.profileType is equal profiles.filters.locationFilters.profileType
        dispatch(setFilterProfileTypeAction(values.profileType));

        if (values.searchType === TSearchType.CurrentLocation) {
          const queryParams = {
            ...query,
            distance: values.distance.toString(),
            profileType: values.profileType.toString(),
          };

          dispatch(
            navigateAction(routeByName.profileListUserLocation, {
              query: queryParams,
            })
          );

          dispatch(updateHeaderLocationAction(null));
        }

        if (values.searchType === TSearchType.Global) {
          const locationUrl = getLocationLink(location, values.profileType);

          // TODO: merge updateHeaderNearbyLocation and updateHeaderLocationAction
          dispatch(setProfileLocationIdActionCreator(locationId));
          dispatch(resetProfileFiltersAction());
          dispatch(updateHeaderNearbyLocation(locationId));
          dispatch(updateHeaderLocationAction(location));
          dispatch(navigateAction(locationUrl));
        }

        dispatch(modalHideAction(MODAL_CHOOSE_LOCATION));
      });
    },
    [dispatch, query]
  );

  const searchType = userLocationRouteMatch ? TSearchType.CurrentLocation : TSearchType.Global;

  const initialValues = {
    searchType,
    profileType,
    distance: 15,
    headerLocation,
  };

  return (
    <Box className={s.wrapper}>
      <Form
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        render={({values, handleSubmit, hasValidationErrors}) => (
          <form onSubmit={handleSubmit}>
            <SearchTypeControl />
            {values.searchType === TSearchType.Global && <SearchCityContainerBendr />}
            {values.searchType === TSearchType.CurrentLocation && <MapControl />}
            {isProfileTrans && <ProfileTypeControl />}
            {values.searchType === TSearchType.CurrentLocation && <DistanceControl />}
            <Box px={3} py={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={s.Button}
                disabled={hasValidationErrors}
              >
                Search
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default ProfileChooseLocationBendr;
