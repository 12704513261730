import {PureComponent, ReactNode} from 'react';

import logger from '~/utils/logger';

import ErrorPage from './ErrorPage';

const log = logger.module('ErrorBoundary');

const initialState = {
  error: null,
  eventId: null,
  count: 0,
};

class ErrorBoundary extends PureComponent {
  state = {...initialState};

  static getDerivedStateFromError(error: Error): {error: Error} {
    return {error};
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    const exceptionErrors = ['ChunkLoadError'];
    if (exceptionErrors.indexOf(error.name) === -1) {
      log.error('Catch error', {error, errorInfo});
    }
    const {count} = this.state;
    this.setState({error, count: count + 1});
  }

  render(): ReactNode {
    const {count, error} = this.state;

    if (error) {
      return count > 2 ? (
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <a className="p-2 bg-white" href="/">
            Error loop. :(
          </a>
        </div>
      ) : (
        <ErrorPage />
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
