import {
  LAYOUT_RESET,
  LAYOUT_SET_DRAWER_STATE,
  LAYOUT_SET_FULLSCREEN_LOADER_STATE,
  LAYOUT_SET_PROPS,
} from './constants';

const initialState = {
  title: undefined,
  headerContent: null,
  headerBottomContent: null,
  isDrawerOpened: false,
  hideHeader: false,
  hideFooter: false,
  loading: false,
  contentClass: undefined,
  paperBody: false,
  imageBody: false,
  showFullScreenLoader: false,
  hideTopBarTitle: false,
};

const layoutReducer = (state = {...initialState}, {type, payload}) => {
  switch (type) {
    case LAYOUT_SET_PROPS:
      return {
        ...state,
        ...payload,
      };
    case LAYOUT_SET_DRAWER_STATE:
      return {
        ...state,
        isDrawerOpened: payload,
      };
    case LAYOUT_SET_FULLSCREEN_LOADER_STATE:
      return {
        ...state,
        showFullScreenLoader: payload,
      };
    case LAYOUT_RESET:
      return {...initialState, jivoAvailable: state.jivoAvailable};
    default:
      return state;
  }
};

export default layoutReducer;
