import {useEffect} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {isIOS} from '~/helpers/navigator';
import {addClassToElement, removeClassFromElement} from '~/helpers/handleHTMLClass';

export const keyboardOpenedClass = 'keyboardOpened';
const minKeyboardHeight = 300;

const updateVhValue = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const useKeyboardOpenedWatcher = () => {
  const onResize = useDebouncedCallback(() => {
    updateVhValue();

    const rootDiv: HTMLElement | null = document.getElementById('root');

    if (window.screen.height - minKeyboardHeight > window.visualViewport.height) {
      addClassToElement(rootDiv, keyboardOpenedClass);
    } else {
      removeClassFromElement(rootDiv, keyboardOpenedClass);
    }
  }, 100);

  useEffect(() => {
    updateVhValue();

    if (!isIOS) {
      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);
};

export default useKeyboardOpenedWatcher;
