"use strict";
/**
 * @packageDocumentation @module Chat
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.initChat = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To login to chat you need pass valid session and QuickBlox credentials.
 * The session you can get from our node service (Red).
 *
 * You can use another method from this lib only after that method resolve.
 */
function initChat(options) {
    var session = options.session, redEndpoint = options.redEndpoint, appId = options.appId, authKey = options.authKey, authSecret = options.authSecret, qbConfig = options.qbConfig;
    return chat_1.startChat({ appId: appId, authKey: authKey, authSecret: authSecret }, session, redEndpoint, qbConfig);
}
exports.initChat = initChat;
