import logger from '~/utils/logger';
import config from '~/constants/config';
import {GeolocationPositionErrors} from '~/modules/App/store/constants';
import {logEvent} from '~/modules/App/store/gaActions';

const log = logger.module('checkGeolocationPermission');

export interface PositionResponse {
  position: GeolocationPosition | false;
  hasError?: boolean;
  errorCode?: GeolocationPositionErrors;
}
// eslint-disable-next-line import/prefer-default-export
export const checkGeolocationPermission = (): Promise<PositionResponse> => {
  return new Promise((resolve) => {
    if (!window.navigator.geolocation) {
      log.error('Geolocation API is not supported!');
      resolve({
        position: false,
        hasError: true,
        errorCode: GeolocationPositionErrors.API_UNAVAILABLE,
      });
    }

    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        log.info('Received user location', {position});

        resolve({position, hasError: false});
      },
      (err) => {
        log.info('Geolocation API: error during get position', {error: err});
        logEvent({
          category: 'location-modal',
          action: 'reject',
        });
        resolve({position: false, hasError: true, errorCode: err.code});
      },
      {timeout: config.requestGeoTimeout as number}
    );
  });
};
