export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE';
export const SET_CURRENT_USER_PROFILE_STATS = 'SET_CURRENT_USER_PROFILE_STATS';
export const SET_CURRENT_USER_AND_PROFILE = 'SET_CURRENT_USER_AND_PROFILE';
export const SET_CURRENT_USER_PROFILE_LOCATION = 'SET_CURRENT_USER_PROFILE_LOCATION';
export const SET_CURRENT_USER_PROFILE_THUMBNAIL = 'SET_CURRENT_USER_PROFILE_THUMBNAIL';
export const SET_CURRENT_USER_PROFILE_IMAGES = 'SET_CURRENT_USER_PROFILE_IMAGES';
export const SYNC_CURRENT_USER_PROFILE_CHAT_SETTINGS = 'SYNC_CURRENT_USER_PROFILE_CHAT_SETTINGS';
export const SET_CURRENT_USER_FAVORITES = 'SET_CURRENT_USER_FAVORITES';
export const SET_CURRENT_USER_CREATE_PROFILE_STEP = 'SET_CURRENT_USER_CREATE_PROFILE_STEP';
export const CURRENT_USER_REMOVE_FROM_FAVORITES = 'CURRENT_USER_REMOVE_FROM_FAVORITES';
export const USER_LOCATION_UPDATE = 'USER_LOCATION_UPDATE';
export const SHOW_UPGRADE_PAID_POPUP = 'SHOW_UPGRADE_PAID_POPUP';
export const HIDE_UPGRADE_PAID_POPUP = 'HIDE_UPGRADE_PAID_POPUP';
export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE';
export const CLEAR_PROMOCODE = 'CLEAR_PROMOCODE';
export const SYNC_PROFILE_REPORT_OPTIONS = 'SYNC_PROFILE_REPORT_OPTIONS';
export const SYNC_PROFILE_VERIFICATION = 'SYNC_PROFILE_VERIFICATION';
export const SET_TWO_FACTOR_DATA = 'SET_TWO_FACTOR_DATA';
export const RESET_TWO_FACTOR_DATA = 'RESET_TWO_FACTOR_DATA';
export const SET_ATTEMPS_TWO_FACTOR = 'SET_ATTEMPS_TWO_FACTOR';
export const SET_VERIFICATION_METHOD_TWO_FACTOR = 'SET_VERIFICATION_METHOD_TWO_FACTOR';
export const SET_USER_IP_GEO_LOCATION = 'SET_USER_IP_GEO_LOCATION';
export const SET_CURRENT_USER_PROFILE_VERIFICATION_PENDING =
  'SET_CURRENT_USER_PROFILE_VERIFICATION_PENDING';
