import {useEffect, useState, useCallback} from 'react';
import * as React from 'react';
import {useRouteMatch} from 'react-router-dom';
import {TNewMessage} from '@yeobill/chat/lib/types';
import {Users} from '@yeobill/react-chat';
import {makeStyles, Grid, Typography, Avatar} from '@material-ui/core';
import {useDispatch} from 'react-redux';

import routeByName from '~/constants/routes';
import config from '~/constants/config';
import {navigateAction} from '~/actions/navigateActions';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal,
    backgroundColor: '#fff',
    margin: 5,
    padding: 10,
    borderRadius: 20,
    boxShadow: '0 4px 15px rgba(163, 163, 163, 0.25)',
  },
  login: {
    fontWeight: 600,
    fontSize: 16,
  },
  action: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    lineHeight: 2.5,
  },
  fallbackAvatarIcon: {
    width: 24,
    height: 24,
  },
}));

interface Props {
  newMessage: TNewMessage;
  closeMessage: () => void;
}

const MessageNotificationBanner: React.FC<Props> = ({newMessage, closeMessage}) => {
  const s = useStyles();
  const dispatch = useDispatch();
  Users.useLoadChatUsers([newMessage.userId]);
  const user = Users.useChatUser(newMessage.userId);
  const [show, setShow] = useState(false);
  const isInChat = useRouteMatch(routeByName.chats.single(newMessage.message.chat_dialog_id));

  useEffect(() => {
    if (user) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
        closeMessage();
      }, config.chat.chatNotifyDelay);
    }
  }, [user, closeMessage]);

  const handleClick = useCallback(() => {
    setShow(false);
    closeMessage();
    dispatch(navigateAction(routeByName.chats.single(newMessage.message.chat_dialog_id)));
  }, [dispatch, closeMessage, newMessage.message.chat_dialog_id]);

  return (
    <>
      {!isInChat && show && user && (
        <div tabIndex={-1} role="button" onClick={handleClick} className={s.wrapper}>
          <Grid container>
            <Grid item xs={2}>
              <Avatar
                classes={{fallback: s.fallbackAvatarIcon}}
                src={user.extendedData.avatar?.url}
                variant="circular"
              />
            </Grid>
            <Grid item xs={8}>
              <Typography className={s.login}>{user.full_name}</Typography>
              New Message
            </Grid>
            <Grid item xs={2} className={s.action}>
              Reply
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default MessageNotificationBanner;
