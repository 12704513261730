import SVGClockIcon from './images/clock.svg';
import SVGCursorIcon from './images/cursor.svg';
import SVGMapIcon from './images/map.svg';
import SVGShieldIcon from './images/shield.svg';

export interface TBenefit {
  icon: string;
  title: string;
  description: string;
}
const benefitsList: TBenefit[] = [
  {
    icon: SVGShieldIcon,
    title: 'Safe Environment',
    description:
      'This is a 100% anti-troll, non-judgemental community. Everyone on Bendr is like-minded, so you can feel comfortable knowing that you’re in good company. We also prioritize the use of respectful communication and language for the best TS Dating experience.',
  },
  {
    icon: SVGClockIcon,
    title: 'Quick Setup',
    description:
      'We want you to get out there, so we’ve made it a breeze for you to create your Bendr profile and start meeting people as soon as possible.',
  },
  {
    icon: SVGCursorIcon,
    title: 'Easy-To-Use',
    description:
      'Our platform was developed to be as simple and accessible as possible. Inclusivity is at the core of Bendr’s mission, so we’ve done everything in our power to ensure a user-friendly and intuitive interface that’s fun to use.',
  },
  {
    icon: SVGMapIcon,
    title: 'Location-Based',
    description:
      'You never have to worry about falling for someone hundreds of miles away. We find people for you to chat with that are always nearby. That way, it’s easier to let a relationship blossom from online to offline.',
  },
];

export default benefitsList;
