import React, {memo, useCallback, useState, useMemo} from 'react';
import {Button, Popover, PopoverOrigin} from '@material-ui/core';
import clsx from 'clsx';
import {useSelector, useDispatch} from 'react-redux';

import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import {SortingMethodsType} from '~/types/appTypes';

import {setProfilesSortingMethodAction} from '../../../store/actions';
import {profilesSortingMethodSelector} from '../../../store/selectors';
import profileListSortStyles from './profileListSortStyles';
import {SortingMethod} from '../../types';

const anchorOrigin: PopoverOrigin = {vertical: 50, horizontal: 'center'};

const sortMethods: SortingMethod[] = [
  {
    key: 'newest',
    title: 'Newest',
    icon: 'icon-sort-newest',
  },
  {
    key: 'oldest',
    title: 'Oldest',
    icon: 'icon-sort-oldest',
  },
];

const ProfileListSort = memo(() => {
  const styles = profileListSortStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const selectedSortKey = useSelector(profilesSortingMethodSelector);

  const currentSorting: SortingMethod = useMemo(() => {
    if (!selectedSortKey) {
      return {
        key: null,
        title: 'Sort',
        icon: 'icon-sort',
      };
    }

    const foundSort = sortMethods.find(({key}) => key === selectedSortKey);

    if (!foundSort) {
      throw new Error(`Sorting "${selectedSortKey}" not found`);
    }

    return foundSort;
  }, [selectedSortKey]);

  const handleOpen = useCallback(({currentTarget}) => setAnchorEl(currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleButtonClick = (newSortKey: SortingMethodsType) => {
    if (newSortKey === selectedSortKey) {
      return;
    }

    dispatch(setProfilesSortingMethodAction(newSortKey));
  };

  return (
    <>
      <Button
        className={styles.sortButton}
        variant="contained"
        color="primary"
        onClick={handleOpen}
      >
        <SpriteIcon name={currentSorting.icon} className={styles.sortIcon} fontSize="custom" />
        <span>{currentSorting.title}</span>
      </Button>
      <Popover
        id="profile-type-popover"
        classes={{paper: styles.popoverWrap}}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClick={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <div className={styles.popoverTitle}>Sort by</div>
        {sortMethods.map(({title, key}) => (
          <Button
            className={clsx(styles.sortItemButton, {
              active: key === selectedSortKey,
            })}
            key={key}
            classes={{label: styles.sortItemButtonLabel}}
            onClick={() => handleButtonClick(key)}
            color="default"
            variant="text"
          >
            {title}
          </Button>
        ))}
      </Popover>
    </>
  );
});

ProfileListSort.displayName = 'ProfileListSort';
export default ProfileListSort;
