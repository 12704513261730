import {v4 as uuidv4} from 'uuid';
import {merge} from 'lodash-es';

import ensureSlash from '~/utils/ensureSlash';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore package.json is not under root dir
import packageJson from '../../package.json';
import {ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_SECOND} from './dateTime';

const env = {...process.env};
const isProd = env.NODE_ENV === 'production';
const isDev = env.NODE_ENV === 'development';

// used in production only
if (window?.__ENV) {
  merge(env, window.__ENV);
}

let build = {
  version: '',
  commit: '',
  buildNumber: '',
  buildDate: '',
  branch: '',
};

try {
  // eslint-disable-next-line import/no-unresolved,global-require
  build = require('../build.json');
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Build info not found');
}

const parseEnvToBoolean = (value?: string): boolean => Boolean(parseInt(value || '', 10));

const parseNumber = (value?: string, defaultValue = 0): number =>
  parseInt(value || '', 10) || defaultValue;

const config = {
  app: {
    humanDomainName: 'Bendr.com',
    domainName: 'bendr.com',
    url: 'https://bendr.com',
    title: 'Bendr — Free, secure calls and messages to anyone, anywhere',
    name: 'Bendr',
    description: 'Find Local TS Near You!',
    keywords:
      'trans dating, ts dating, transgender dating, trans attracted, trans dating site, transgender dating site, trans date, ts dating site, transgender dating website, trans friendly dating apps, best transgender dating site, best trans dating apps',
  },
  transactionId: uuidv4(),
  build,
  version: packageJson.version,
  isDev,
  isProd,
  isCypressEnv: typeof window.Cypress !== 'undefined',
  refreshAppUrl: env.REACT_APP_REFRESH_APP_URL || '/?REFRESH_APP=1',
  httpFileTimeout: parseNumber(env.REACT_APP_HTTP_FILE_TIMEOUT, 0),
  api: {
    url: env.REACT_APP_API_URL,
    clientId: env.REACT_APP_API_CLIENT_ID,
    clientSecret: env.REACT_APP_API_CLIENT_SECRET,
    httpTimeout: parseNumber(env.REACT_APP_HTTP_TIMEOUT, ONE_SECOND * 60),
  },
  prodUrl: 'bendr.com',
  configUrl: env.REACT_APP_CONFIG_URL,
  desktopDomain: ensureSlash(env.REACT_APP_DESKTOP_DOMAIN) || 'https://bendr.com/',
  chunkRetryTimeout: parseNumber(env.REACT_APP_CHUNK_RETRY_TIMEOUT, 1) * ONE_SECOND,
  maxChunkRetryAttempts: parseNumber(env.REACT_APP_MAX_CHUNK_RETRY_ATTEMPTS, 3),
  imgPlaceholder: '/img/stub.png',
  imgPlaceholderEscort: '/img/stub-escort.svg',
  imgPlaceholderMale: '/img/stub-male.svg',
  rechargeBudget: {
    maxAmount: 200,
    minRecurringAmount: 50,
  },
  svgSpritePath: '/img/icons_old.svg',
  sentryDSN: env.REACT_APP_sentryDSN,
  sentryEnv: env.REACT_APP_sentryEnv || 'unspecified',
  sentryEnable: parseEnvToBoolean(env.REACT_APP_sentryEnable) || false,
  support: {
    mail: 'support@bendr.com',
    phone: '',
  },
  googleAnalyticsId: env.REACT_APP_googleAnalyticsId,
  googleApiKey: env.REACT_APP_googleApiKey || '',

  maxFileSize: {
    number: 10 * 1000 * 1000,
    string: '10MB',
  },
  maxPhotosQuantity: 20,
  jivoId: env.REACT_APP_jivoId,
  chat: {
    debug: parseEnvToBoolean(env.REACT_APP_CHAT_DEBUG) || false,
    debugQB: parseEnvToBoolean(env.REACT_APP_CHAT_DEBUG_QB) || false,
    appId: env.REACT_APP_CHAT_APP_ID || '',
    key: env.REACT_APP_CHAT_KEY || '',
    secret: env.REACT_APP_CHAT_SECRET || '',
    serviceUrl: ensureSlash(env.REACT_APP_CHAT_SERVICE_URL),
    qbChatHost: env.REACT_APP_CHAT_QB_CHATHOST || '',
    qbApiHost: env.REACT_APP_CHAT_QB_APIHOST || '',
    qbWsUrl: env.REACT_APP_CHAT_QB_WS_URL || '',
    chatNotifyDelay: parseNumber(env.REACT_APP_CHAT_NOTIFY_DELAY, ONE_SECOND * 5),
    callsEnabled: parseEnvToBoolean(env.REACT_APP_CHAT_CALLS_ENABLED),
  },
  publicVAPID: env.REACT_APP_PUBLIC_VAPID || '',
  refreshGeoLocationDelay: parseNumber(env.REACT_APP_REFRESH_GEO_DELAY, ONE_MINUTE * 5),
  requestGeoTimeout: parseNumber(env.REACT_REQUEST_GEO_TIMEOUT, ONE_SECOND * 30),
  acquiringLocationPopupDelay: parseNumber(env.REACT_APP_ACQUIRING_GEO_POPUP_DELAY, ONE_SECOND * 3),
  upToDateGeoCriteria: parseNumber(env.REACT_APP_UP_TO_DATE_GEO_CRITERIA, ONE_HOUR),
  forbiddenToMoveCountries: ['USA'],
  utmTTL: env.REACT_APP_UTM_TTL || ONE_DAY,
  pinCheckTime: env.REACT_APP_PIN_CHECK_TIME || ONE_MINUTE * 5,
  updateAppConfigInterval: ONE_HOUR / 2,
  features: {
    payment: parseEnvToBoolean(env.REACT_APP_FF_PAYMENT || '0'),
    chat: parseEnvToBoolean(env.REACT_APP_FF_CHAT || '1'),
    translate: parseEnvToBoolean(env.REACT_APP_TRANSLATE || '0'),
    paymentForEscorts: parseEnvToBoolean(env.REACT_APP_FF_PAYMENT_ESCORTS || '0'),
    paymentForClients: parseEnvToBoolean(env.REACT_APP_FF_PAYMENT_CLIENTS || '0'),
  },
  refershIntervalPaymentStatus: parseNumber(env.REACT_APP_REFRESH_PAYMENT_STATUS, ONE_SECOND * 10),
  easyAuthRetryCount: parseNumber(env.REACT_APP_EASY_AUTH_RETRY, 3),
  easyAuthCompletePopupMessages: parseNumber(env.REACT_APP_EASY_AUTH_COMPLETE_POPUP_COUNT, 10),
  recaptchaKey: env.REACT_APP_RECAPTCHA3_SITEKEY,
  cdnImageBaseUrl: env.REACT_APP_CDN_IMAGE_BASE_URL,
  cdnVideoBaseUrl: env.REACT_APP_CDN_VIDEO_BASE_URL,
};

export default config;
