"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultAudioMediaParams = void 0;
exports.defaultAudioMediaParams = {
    audio: true,
    video: false,
    options: {
        muted: false,
        mirror: true,
    },
    elemId: null,
};
