import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import * as React from 'react';

import routeByName from '~/constants/routes';
import {navigateRefreshAction} from '~/actions/navigateActions';
import useModalById from '~/customHooks/useModalById';
import {MODAL_COMPLETE_CHAT_SETTINGS} from '~/rootStore/modals/modalsIds';

import imageUpgradeToPaid from './img/icon-checked-green.svg';
import ModalBase from '../ModalBase';
import ModalBaseContent from '../ModalBaseContent';

const ModalCompleteChatSettings = React.memo(() => {
  const {isOpen, close} = useModalById(MODAL_COMPLETE_CHAT_SETTINGS);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => close(), [close]);

  const handleActionClick = useCallback(() => {
    handleClose();
    dispatch(navigateRefreshAction(routeByName.textSettings));
  }, [dispatch, handleClose]);

  return (
    <ModalBase open={isOpen} onClose={close} showSkipButton>
      <ModalBaseContent
        image={imageUpgradeToPaid}
        onClickActionButton={handleActionClick}
        title="See your chat settings"
        actionButtonText="Set up now"
      />
    </ModalBase>
  );
});
ModalCompleteChatSettings.displayName = 'ModalCompleteChatSettings';

export default ModalCompleteChatSettings;
