import makeStyles from '@material-ui/core/styles/makeStyles';
import Button, {ButtonProps} from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  btn: {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
}));

type ActionButtonProps = ButtonProps & {
  to?: string;
  component?: React.ReactNode;
};

const ActionButton: React.FC<ActionButtonProps> = ({children, ...rest}) => {
  const s = useStyles();

  return (
    <Button className={s.btn} variant="outlined" {...rest}>
      {children}
    </Button>
  );
};

export default ActionButton;
