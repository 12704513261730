"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var effector_1 = require("effector");
var QBAdapter_1 = require("../QBAdapter");
exports.default = (function (Dialogs$) {
    var unreadedMessages = effector_1.createEffect().use(QBAdapter_1.loadDialogsUnreadMessagesCount);
    Dialogs$.on(unreadedMessages.done, function (state, _a) {
        var result = _a.result, dialogIds = _a.params;
        var total = result.total, dialogsCounters = __rest(result, ["total"]);
        return dialogIds.reduce(function (accumStateObject, dialogId) {
            if (!accumStateObject[dialogId]) {
                return accumStateObject;
            }
            var updatedState = __assign({}, accumStateObject);
            updatedState[dialogId].unread_messages_count =
                dialogsCounters[dialogId] || 0;
            return updatedState;
        }, state);
    });
    return unreadedMessages;
});
