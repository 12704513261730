import {FINAL_FORM_UPDATE_FORM_STATE, FINAL_FORM_RESET_FORM_STATE} from './constants';
import {AUTH_LOGOUT} from '../../Auth/store/constants';

const initialState = {};

const currentUserReducer = (state = {...initialState}, {type, payload}) => {
  switch (type) {
    case FINAL_FORM_UPDATE_FORM_STATE: {
      const {form, values} = payload;

      if (JSON.stringify(state[form]) === JSON.stringify(values)) {
        return state;
      }

      return {
        ...state,
        [form]: values,
      };
    }
    case FINAL_FORM_RESET_FORM_STATE: {
      const {formName} = payload;
      return {
        ...state,
        [formName]: undefined,
      };
    }
    case AUTH_LOGOUT:
      return {...initialState};
    default:
      return state;
  }
};

export default currentUserReducer;
