import {TThunkAction} from '~/types/appTypes';

import CurrentUserService from '../../CurrentUserService';
import {SET_CURRENT_USER} from '../constants';

type TLoadCurrentUserAction = () => TThunkAction;
const loadCurrentUserAction: TLoadCurrentUserAction = () => async (dispatch) => {
  const currentUser = await CurrentUserService.getMe();
  dispatch({
    type: SET_CURRENT_USER,
    payload: currentUser,
  });

  return currentUser;
};

export default loadCurrentUserAction;
