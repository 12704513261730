import {useCallback} from 'react';
import {useField} from 'react-final-form';
import makeStyles from '@material-ui/core/styles/makeStyles';

import useModalById from '~/customHooks/useModalById';
import BaseControl from '~/modules/Profiles/ProfileListPage/components/ProfileFilters/BaseControl';
import {ExpandIcon} from '~/modules/Profiles/ProfileListPage/components/ProfileFilters/BaseControlSelect';
import {MODAL_SEARCH_CITY_BENDR} from '~/rootStore/modals/modalsIds';

const useStyles = makeStyles(
  () => ({
    button: {
      display: 'flex',
      width: '100%',
      fontSize: 18,
      letterSpacing: 0.2,
      fontWeight: 400,
      textAlign: 'left',
      padding: '11px 0',
    },
  }),
  {name: 'SearchCityContainerBendr'}
);

const SearchCityContainerBendr: React.FC = () => {
  const s = useStyles();
  const {
    input: {onChange, value},
  } = useField('headerLocation', {
    validate: (val) => (val ? undefined : 'Error'),
  });
  const {open: openSearchCityModal} = useModalById(MODAL_SEARCH_CITY_BENDR, (result) => {
    if (result?.location) {
      onChange(result?.location);
    }
  });

  const handleOpenModal = useCallback(() => {
    openSearchCityModal();
  }, [openSearchCityModal]);

  /**
   * onFocus bind to onClick because onFocus event not cross platform for buttons
   */

  return (
    <BaseControl title="City">
      {/*  eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={s.button} onClick={handleOpenModal}>
        <div className="flex-1">{value?.name || 'Select your City'}</div>
        <ExpandIcon />
      </div>
    </BaseControl>
  );
};

export default SearchCityContainerBendr;
