import {WithSnackbarProps} from 'notistack';
import {ReactNode} from 'react';

import {DEFAULT_ERROR_MESSAGE} from '~/rootStore/notifications/notificationsReducer';

/**
 * @deprecated Use `dispatch(addErrorNotificationAction)`
 */
const errorSnackbar = (
  enqueueFunction: WithSnackbarProps['enqueueSnackbar'],
  message: ReactNode | string = DEFAULT_ERROR_MESSAGE,
  options: Record<string, unknown> = {}
): void => {
  if (!enqueueFunction) {
    return;
  }

  enqueueFunction(message, {variant: 'error', ...options});
};

export default errorSnackbar;
