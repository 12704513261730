"use strict";
/**
 * @packageDocumentation @module Contacts
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To remove a user from current user's contact list.
 */
function remove(userId) {
    return chat_1.Contacts.remove(userId);
}
exports.remove = remove;
