import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

const useQuery = () => {
  const location = useLocation();

  return useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location]);
};

export default useQuery;
