import spacing from '../spacing';

export default {
  root: {
    textTransform: 'none',
    padding: spacing(0, 1),
  },
  textColorInherit: {
    opacity: 1,
  },
  labelIcon: {
    minHeight: 44,
  },
};
