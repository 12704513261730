import {TThunkAction} from '~/types/appTypes';

import {LOCATIONS_SET_USER_LOCATION} from './constants';

/**
 * Moved as separate file for prevent cycle dependency
 * @returns {Function}
 */

// eslint-disable-next-line import/prefer-default-export
export const setUserLocationAction =
  (location: string): TThunkAction =>
  (dispatch) => {
    dispatch({
      type: LOCATIONS_SET_USER_LOCATION,
      payload: location,
    });
  };
