import {PictureConfig} from './types';

const pictureConfig: PictureConfig = {
  profileCard: {
    sources: [
      {
        selector: '(min-width: 800px), (min-width: 400px) and (-webkit-min-device-pixel-ratio: 2)',
        type: 'image/webp',
        options: {
          format: 'webp',
          width: 1000,
          height: 1400,
          crop: 'lfill',
          gravity: 'auto',
        },
      },
      {
        selector: '(min-width: 400px), (min-width: 200px) and (-webkit-min-device-pixel-ratio: 2)',
        type: 'image/webp',
        options: {
          format: 'webp',
          width: 600,
          height: 800,
          crop: 'lfill',
          gravity: 'auto',
        },
      },
      {
        selector: '(min-width: 200px)',
        type: 'image/webp',
        options: {
          format: 'webp',
          width: 400,
          height: 600,
          crop: 'lfill',
          gravity: 'auto',
        },
      },
    ],
    fallback: {
      type: '',
      options: {
        format: 'jpeg',
        width: 800,
        height: 1000,
        crop: 'fill',
        gravity: 'auto',
      },
    },
  },
  profileThumb: {
    sources: [
      {
        type: 'image/webp',
        options: {
          format: 'webp',
          height: 150,
          width: 150,
          crop: 'thumb',
          gravity: 'face',
        },
      },
    ],
    fallback: {
      type: '',
      options: {
        format: 'jpeg',
        height: 150,
        width: 150,
        crop: 'thumb',
        gravity: 'auto',
      },
    },
  },
};

export default pictureConfig;
