"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effector_1 = require("effector");
exports.default = (function (Contacts$, ContactsOnlineStatus$, ContactsDialogs$) {
    return effector_1.combine(Contacts$, ContactsOnlineStatus$, ContactsDialogs$, function (all, withOnlineStatus, dialogs) {
        return Object.values(all).reduce(function (acc, user) {
            acc[user.id] = __assign(__assign({}, user), { isOnline: withOnlineStatus[user.id] || false, dialogId: dialogs[user.id] });
            return acc;
        }, {});
    });
});
