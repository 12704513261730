import {TCurrentUserProfile} from '~/types/CurrentUserState';
import loaderSelector from '~/rootStore/loaders/loaderSelector';
import {TAppState} from '~/types/appTypes';
import {TChatSession} from '~/types/Chat';

import {isAuthSelector} from '../../CurrentUser/store/selectors';
import {LOADER_INIT_CHAT} from '../../Payments/constants/loadersIds';

export const isChatActiveSelector: (state: TAppState) => boolean = (state) =>
  Boolean(state.chats.session);

export const currentChatUserIdSelector: (state: TAppState) => number | null = (state) =>
  state.chats.session && state.chats.session.user_id;

export const chatAvailableSelector: (state: TAppState) => boolean = (state) =>
  Boolean(state.chats.session);

/**
 * Chat loaded but failed to initialize
 */
export const chatInitFailureSelector: (state: TAppState) => boolean = (state) =>
  loaderSelector(state, LOADER_INIT_CHAT) === false && !chatAvailableSelector(state);

export const chatSessionSelector: (state: TAppState) => TChatSession | null = (state) =>
  state.chats.session;

export const isAnonymousChatUserSelector: (state: TAppState) => boolean = (state) => {
  const isUserAuth = isAuthSelector(state);
  return Boolean(state.chats.session) && !isUserAuth;
};

export const getCurrentChatOpponentProfileSelector: (
  state: TAppState
) => TCurrentUserProfile | null = (state) => state.chats.chatOpponentProfile;
