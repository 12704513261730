import routeByName from '~/constants/routes';
import logger from '~/utils/logger';
import localStorageWrapper from '~/utils/localStorageWrapper';
import {checkNotificationsPermission} from '~/helpers/navigator/checkNotificationsPermission';
import {modalShowAction} from '~/rootStore/modals/modalsActions';
import {allProfileTypesRegexp, profiles} from '~/constants/profiles';
import {MODAL_ALLOW_LOCATION, MODAL_NOTIFICATION_PERMISSION} from '~/rootStore/modals/modalsIds';
import {NavigatorPermissions, NotificationPermissions} from '~/constants/navigator';
import {navigateAction, navigateReplaceAction} from '~/actions/navigateActions';
import {formSubmitFullScreenControl} from '~/components/Layout';
import {addErrorNotificationAction} from '~/rootStore/notifications/notificationsReducer';

import CurrentUserService from '../CurrentUserService';
import {
  CURRENT_USER_REMOVE_FROM_FAVORITES,
  HIDE_UPGRADE_PAID_POPUP,
  SET_CURRENT_USER_AND_PROFILE,
  SET_CURRENT_USER_CREATE_PROFILE_STEP,
  SET_CURRENT_USER_FAVORITES,
  SET_CURRENT_USER_PROFILE,
  SET_CURRENT_USER_PROFILE_IMAGES,
  SET_CURRENT_USER_PROFILE_LOCATION,
  SET_CURRENT_USER_PROFILE_STATS,
  SET_CURRENT_USER_PROFILE_THUMBNAIL,
  SET_CURRENT_USER_PROFILE_VERIFICATION_PENDING,
  SET_TWO_FACTOR_DATA,
  RESET_TWO_FACTOR_DATA,
  SET_ATTEMPS_TWO_FACTOR,
  SHOW_UPGRADE_PAID_POPUP,
  SYNC_CURRENT_USER_PROFILE_CHAT_SETTINGS,
  SYNC_PROFILE_REPORT_OPTIONS,
  SYNC_PROFILE_VERIFICATION,
  SET_VERIFICATION_METHOD_TWO_FACTOR,
  SET_USER_IP_GEO_LOCATION,
} from './constants';
import ProfileService from '../../Profiles/ProfileService';
import {
  currentProfileSelector,
  currentUserSelector,
  isUserPhoneVerifiedSelector,
  userProfileChatSettingsSelector,
} from './selectors';
import {
  getLocationBreadcrumbsById,
  getLocationLink,
  leafLocationsSelector,
  userLocationSelector,
} from '../../Locations/store/selectors';
import {setUserLocationAction} from '../../Locations/store/setUserLocationAction';
import AuthService from '../../Auth/AuthService';
import {jivoInitializeAction} from '../../JivoChat/store/actions';
import {locationProfilesResponseTransformer} from '../../Profiles/transformers';
import {syncPriceRangeAction} from '../../App/store/priceRangeActions';
import {chatsInitAction} from '../../Chats/store/actions';
import updateChatProfile from './updateChatProfileAction';
import {refreshProfileGeoLocationAction} from '../../App/store/geoActions';
import ChatsService from '../../Chats/ChatsService';
import {currentChatUserIdSelector} from '../../Chats/store/selectors';
import {
  updateNotificationsPermission,
  updateNotificationsToken,
} from '../../App/store/notificationActions';
import loadCurrentUserAction from './actions/loadCurrentUserAction';
import {reportProfileIdRouteParam} from '../ProfileReport/constants';
import {filterProfileTypeSelector} from '../../Profiles/store/selectors';

const log = logger.module('CurrentUser actions');

export const setProfileLocation = () => (dispatch, getState) => {
  const {
    currentUser: {profile},
  } = getState();
  if (!profile) {
    return;
  }

  const leafLocations = leafLocationsSelector(getState());
  const profileType = filterProfileTypeSelector(getState());

  const locations = getLocationBreadcrumbsById(leafLocations, profile.location_id);

  if (!locations.city) {
    return;
  }

  dispatch({
    type: SET_CURRENT_USER_PROFILE_LOCATION,
    payload: locations,
  });

  dispatch(
    setUserLocationAction(getLocationLink(locations.borough || locations.city, profileType))
  );
};

export const setProfileTextSettings = (chatSettings) => async (dispatch) => {
  dispatch({
    type: SYNC_CURRENT_USER_PROFILE_CHAT_SETTINGS,
    payload: chatSettings,
  });
};

export const loadProfileTextSettings = () => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());
  if (!currentUser.profileId) {
    return;
  }

  const chatSettings = await ProfileService.getChatSettings(currentUser.profileId);

  if (chatSettings) {
    dispatch(setProfileTextSettings(chatSettings));
  }
};

export const showUpgradePaidPopup = () => async (dispatch) => {
  const showPaidPopup = localStorageWrapper.getItem(SHOW_UPGRADE_PAID_POPUP);

  if (showPaidPopup === 'true') {
    dispatch({
      type: SHOW_UPGRADE_PAID_POPUP,
      payload: true,
    });
  }
};

export const hideUpgradePaidPopup = () => (dispatch) => {
  localStorageWrapper.setItem(SHOW_UPGRADE_PAID_POPUP, 'false');

  dispatch({
    type: HIDE_UPGRADE_PAID_POPUP,
    payload: false,
  });
};

export const getFavoritesAction = () => async (dispatch, getState) => {
  const {
    app: {
      profileFields: {role},
    },
  } = getState();

  const user = currentUserSelector(getState());
  const leafLocations = leafLocationsSelector(getState());

  if (!user) {
    return;
  }

  const payload = await CurrentUserService.getFavorites().then(({profiles: favoriteProfiles}) =>
    locationProfilesResponseTransformer(favoriteProfiles, leafLocations, role)
  );

  dispatch({
    type: SET_CURRENT_USER_FAVORITES,
    payload,
  });
};

export const syncUserAndProfileDataAction = () => async (dispatch) => {
  const payload = {};
  payload.user = await dispatch(loadCurrentUserAction());

  if (payload.user.profileId) {
    payload.profile = await ProfileService.getProfileById(payload.user.profileId);
  }

  dispatch({
    type: SET_CURRENT_USER_AND_PROFILE,
    payload,
  });

  dispatch(jivoInitializeAction());
  dispatch(setProfileLocation());

  if (payload.user.isPhoneVerified) {
    dispatch(getFavoritesAction());
  }

  return payload;
};

export const updateCurrentUserDefaultLocationFilters = () => (dispatch, getState) => {
  const userLocationFilter = userLocationSelector(getState());
  if (!userLocationFilter) {
    return;
  }
  const profile = currentProfileSelector(getState());
  if (!profile.looking_for) {
    return;
  }
  const newUserLocationFilter = userLocationFilter.replace(
    new RegExp(`(${allProfileTypesRegexp})`),
    profiles[profile.looking_for].url
  );
  dispatch(setUserLocationAction(newUserLocationFilter));
};

export const syncProfileAction = () => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());

  if (!currentUser.profileId) {
    return undefined;
  }

  const profile = await ProfileService.getProfileById(currentUser.profileId);

  dispatch({
    type: SET_CURRENT_USER_PROFILE,
    payload: profile,
  });
  dispatch(updateCurrentUserDefaultLocationFilters());
  dispatch(jivoInitializeAction());
  dispatch(setProfileLocation());
  dispatch(loadProfileTextSettings());
  await dispatch(showUpgradePaidPopup());
  try {
    await dispatch(syncPriceRangeAction());
    // eslint-disable-next-line no-empty
  } catch (error) {
    log.error('Error during syncProfileAction', {
      error,
    });
  }

  return profile;
};
export const syncProfileStatsAction = () => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());

  if (!currentUser.profileId) {
    return undefined;
  }

  const profileStats = await ProfileService.getProfileStatsById(currentUser.profileId);

  dispatch({
    type: SET_CURRENT_USER_PROFILE_STATS,
    payload: profileStats,
  });

  return profileStats;
};

export const sendUserPhoneVerificationSmsAction = (newPhone) => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());

  const result = await CurrentUserService.sendUserPhoneVerificationSms({
    user_id: currentUser.id,
    phone: newPhone || currentUser.phone,
    remember_me: Boolean(AuthService.getRefreshToken()),
  });

  AuthService.setToken(result.token);

  return result;
};

export const checkUserPhoneVerificationCodeAction = (code) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());

    await CurrentUserService.checkUserPhoneVerificationCode({
      userId: currentUser.id,
      code,
    });

    await dispatch(syncUserAndProfileDataAction());
    dispatch(navigateAction(routeByName.chooseProfileType));
    // Route will be replaced to "my ts" by PrivateRoute component
  });

export const changeEmailAction = (newEmail) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());
    await CurrentUserService.changeEmail(currentUser.id, newEmail);
  });

export const changeLanguageAction = (language) =>
  formSubmitFullScreenControl(async (dispatch) => {
    await CurrentUserService.changeLanguage(language);
    await dispatch(loadCurrentUserAction());
  });

export const changePasswordAction = (data) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());

    await CurrentUserService.changePassword(currentUser.id, data);
  });

export const changeChatSettingsAction = (data) => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());
  const chatSettings = userProfileChatSettingsSelector(getState());
  dispatch(
    setProfileTextSettings({
      ...chatSettings,
      ...data,
    })
  );
  ProfileService.setChatSettings(currentUser.profileId, {
    ...chatSettings,
    ...data,
  });
};

export const closeAccountAction = (data) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());
    await CurrentUserService.closeAccount(currentUser.id, data);
  });

export const createProfileAction = (type) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    if (!isUserPhoneVerifiedSelector(getState())) {
      return;
    }
    await CurrentUserService.createProfile(type);
    await dispatch(loadCurrentUserAction());
    await dispatch(syncProfileAction());
    dispatch(chatsInitAction());
    dispatch(navigateReplaceAction(routeByName.createProfile));
  });

export const preUpdateProfileAction = (formValues) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const {profileId} = currentUserSelector(getState());
    await ProfileService.updateProfile(profileId, formValues);
    await dispatch(syncProfileAction());
  });

export const updateProfileAction = (formValues) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const user = currentUserSelector(getState());
    await ProfileService.updateProfile(user.profileId, formValues);
    await dispatch(loadCurrentUserAction());
    await dispatch(syncProfileAction());
    await dispatch(updateChatProfile());
    await dispatch(showUpgradePaidPopup());
  });

export const enableProfileAction = () =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());
    await ProfileService.enableProfile(currentUser.profileId);
    await dispatch(syncProfileAction());
  });

export const disableProfileAction = () =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const currentUser = currentUserSelector(getState());
    await ProfileService.disableProfile(currentUser.profileId);
    await dispatch(syncProfileAction());
  });

export const setProfileThumbnailAction = (profileId, imageId) => async (dispatch) => {
  try {
    const thumbnailUrl = await ProfileService.setThumbnail(profileId, imageId);
    if (!thumbnailUrl) {
      return;
    }

    dispatch({
      type: SET_CURRENT_USER_PROFILE_THUMBNAIL,
      payload: thumbnailUrl,
    });
  } catch (err) {
    if (err.response?.status === 400) {
      dispatch(
        addErrorNotificationAction(
          'Unable to set this image as a thumbnail. Please wait until it passes moderation and try again.'
        )
      );
    }
  }
};

export const setProfileImagesAction = (images) => (dispatch) => {
  if (!Array.isArray(images)) {
    return;
  }

  dispatch({
    type: SET_CURRENT_USER_PROFILE_IMAGES,
    payload: images,
  });
};

export const setProfileVerificationPendingAction = (state) => ({
  type: SET_CURRENT_USER_PROFILE_VERIFICATION_PENDING,
  payload: state,
});

export const addToFavoritesPureAction = (profileId) => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());

  if (!profileId || !currentUser) {
    return;
  }

  await CurrentUserService.addToFavorites(profileId);

  await dispatch(getFavoritesAction());
};

export const removeFromFavoritesPureAction = (profileId) => async (dispatch, getState) => {
  const currentUser = currentUserSelector(getState());

  if (!profileId || !currentUser) {
    return;
  }

  await CurrentUserService.removeFromFavorites(profileId);

  dispatch({
    type: CURRENT_USER_REMOVE_FROM_FAVORITES,
    payload: profileId,
  });
};

export const addToFavoritesWithLoaderAction = (profileId) =>
  formSubmitFullScreenControl(async (dispatch) => {
    await dispatch(addToFavoritesPureAction(profileId));
  });

export const removeFromFavoritesWithLoaderAction = (profileId) =>
  formSubmitFullScreenControl(async (dispatch) => {
    await dispatch(removeFromFavoritesPureAction(profileId));
  });

export const changePhoneAction = (newPhone, code) =>
  formSubmitFullScreenControl(async (dispatch) => {
    const {token} = await CurrentUserService.changePhone(newPhone, code);
    AuthService.setToken(token);
    CurrentUserService.setNewPhone();
    await dispatch(loadCurrentUserAction());
  });

export const setCreateProfileStepAction = (step) => ({
  type: SET_CURRENT_USER_CREATE_PROFILE_STEP,
  payload: step,
});

export const refreshCurrentGeoLocationAction = (profileId) => async (dispatch, getState) => {
  if (!profileId) {
    return;
  }
  const {
    app: {navigator: permissions = ''},
  } = getState();
  const isAllowLocationModalShown = localStorageWrapper.getItem(MODAL_ALLOW_LOCATION) === 'true';
  const locationAskPermissions = permissions.geolocation === NavigatorPermissions.ASK;
  if (locationAskPermissions && !isAllowLocationModalShown) {
    dispatch(modalShowAction(MODAL_ALLOW_LOCATION, {profileId}));
  } else {
    await dispatch(refreshProfileGeoLocationAction(profileId));
  }
};

export const requestNotificationsPermissionsAction = () => async (dispatch, getState) => {
  const state = getState();
  const chatUserId = currentChatUserIdSelector(state);
  const profile = currentProfileSelector(state);

  log.info('Start refresh token notifications');
  const {permission, endpoint: subscription} = await checkNotificationsPermission();
  if (chatUserId) {
    ChatsService.updatePushSubscription(chatUserId, profile.phone, subscription);

    dispatch(updateNotificationsToken(subscription));
    dispatch(updateNotificationsPermission(permission));
  }
};

export const refreshNotificationsPermission = () => async (dispatch) => {
  const isBrowserPermissionsDefault =
    'Notification' in window && window.Notification.permission === NotificationPermissions.DEFAULT;

  const isPermissionModalShown =
    localStorageWrapper.getItem(MODAL_NOTIFICATION_PERMISSION) === 'true';

  if (isBrowserPermissionsDefault && !isPermissionModalShown) {
    dispatch(modalShowAction(MODAL_NOTIFICATION_PERMISSION));
  } else {
    dispatch(requestNotificationsPermissionsAction());
  }
};

export const syncProfileReportOptionsAction = () => async (dispatch) => {
  const profileReportOptions = await ProfileService.getReportOptions();
  dispatch({
    type: SYNC_PROFILE_REPORT_OPTIONS,
    payload: profileReportOptions,
  });
};

export const sendProfileReportAction = (profileId, formValues) =>
  formSubmitFullScreenControl(async () => {
    if (!profileId && !formValues) {
      return;
    }

    await ProfileService.sendReportToProfile({
      [reportProfileIdRouteParam]: profileId,
      ...formValues,
    });
  });

export const syncProfileVerificationDataAction = () => async (dispatch, getState) => {
  const state = getState();
  const profile = currentProfileSelector(state);
  if (!profile || !profile.id) {
    return;
  }

  try {
    const verificationData = await ProfileService.getVerification(profile.id);
    dispatch({
      type: SYNC_PROFILE_VERIFICATION,
      payload: verificationData,
    });
  } catch (error) {
    log.error('Error during getVerification', {error});
  }
};

export const setupTwoFADataAction = (data) => async (dispatch) => {
  if (!data) {
    return;
  }

  dispatch({
    type: SET_TWO_FACTOR_DATA,
    payload: data,
  });
};

export const setTwoFAAttempsCountAction = (attemptsCount) => async (dispatch) => {
  if (!attemptsCount) {
    return;
  }

  dispatch({
    type: SET_ATTEMPS_TWO_FACTOR,
    payload: attemptsCount,
  });
};

export const setTwoFAVerificationMethodAction = (verificationMethod) => async (dispatch) => {
  dispatch({
    type: SET_VERIFICATION_METHOD_TWO_FACTOR,
    payload: verificationMethod,
  });
};

export const resetTwoFADataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_TWO_FACTOR_DATA,
  });
};

export const setIPGeoLocationByAction = (geolocation) => async (dispatch) => {
  dispatch({
    type: SET_USER_IP_GEO_LOCATION,
    payload: geolocation,
  });
};
