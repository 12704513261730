import config from '~/constants/config';
import {AppStatus} from '~/constants/app';
import {NotificationPermissions} from '~/constants/navigator';

import {
  APP_UPDATE_STATUS,
  APP_SET_LANGUAGES,
  APP_SET_PROFILE_FIELDS,
  APP_SET_SHEMALE_PAGES_LIST,
  APP_SET_PRICE_RANGE,
  APP_SERVICE_WORKER_HAS_UPDATE,
  APP_HEADER_LOCATION_UPDATE,
  APP_NAVIGATOR_PERMISSIONS_UPDATE,
  APP_SET_STANDALONE_MODE,
  APP_SET_PLAY_VIDEO,
  APP_SET_OPEN_FILE_UPLOAD,
  APP_UPDATE_CONFIG,
  APP_HEADER_NEARBY_LOCATION_UPDATE,
  APP_NOTIFICATIONS_PERMISSION,
  APP_NOTIFICATIONS_TOKEN_UPDATE,
  APP_SET_CHAT_EASY_START_FROM,
  APP_CLEAR_CHAT_EASY_START_FROM,
  SET_CURRENT_USER_COUNTRY_CODE,
  SET_USER_FINGERPRINT,
  UPDATE_APP_ONLINE_STATUS,
} from './constants';

const initialState = {
  status: AppStatus.wait,
  isOnline: true,
  shemalePages: [],
  profileFields: {},
  languages: [],
  priceRanges: {},
  isServiceWorkerInitialized: false,
  serviceWorkerRegistration: null,
  serviceWorker: {
    hasUpdate: false,
  },
  headerLocation: null,
  navigator: {
    geolocation: 'ask',
  },
  notifications: {
    permissions: NotificationPermissions.DEFAULT,
    token: null,
  },
  isStandalone: false,
  playVideo: false,
  isFileUploadOpen: false,
  config: {...config},
  chatEasyStartFrom: null,
  fingerprint: null,
};

const appReducer = (state = {...initialState}, {type, payload}) => {
  switch (type) {
    case APP_UPDATE_STATUS: {
      const {status} = payload;
      return {...state, status};
    }
    case APP_SET_PROFILE_FIELDS:
      if (JSON.stringify(state.profileFields) === JSON.stringify(payload.profileFields)) {
        return state;
      }
      return {
        ...state,
        profileFields: payload.profileFields,
      };
    case APP_SET_LANGUAGES:
      return {
        ...state,
        languages: payload,
      };
    case APP_SET_SHEMALE_PAGES_LIST:
      if (JSON.stringify(state.shemalePages) === JSON.stringify(payload)) {
        return state;
      }
      return {
        ...state,
        shemalePages: payload,
      };
    case APP_SET_PRICE_RANGE: {
      const {currencyId, priceRange} = payload;
      if (
        state.priceRanges[currencyId] &&
        JSON.stringify(state.priceRanges[currencyId]) === JSON.stringify(priceRange)
      ) {
        return state;
      }
      return {
        ...state,
        priceRanges: {
          ...state.priceRanges,
          [currencyId]: priceRange,
        },
      };
    }
    case APP_SET_STANDALONE_MODE: {
      return {
        ...state,
        isStandalone: payload,
      };
    }
    case APP_SERVICE_WORKER_HAS_UPDATE: {
      return {
        ...state,
        serviceWorker: {
          ...state.serviceWorker,
          hasUpdate: true,
        },
      };
    }
    case APP_HEADER_LOCATION_UPDATE: {
      return {
        ...state,
        headerLocation: payload.location,
      };
    }
    case APP_HEADER_NEARBY_LOCATION_UPDATE: {
      return {
        ...state,
        headerLocation: {
          ...state.headerLocation,
          nearbyLocations: payload,
        },
      };
    }
    case APP_NAVIGATOR_PERMISSIONS_UPDATE: {
      return {
        ...state,
        navigator: {
          ...state.navigator,
          ...payload,
        },
      };
    }
    case APP_SET_PLAY_VIDEO: {
      return {
        ...state,
        playVideo: payload,
      };
    }
    case APP_SET_OPEN_FILE_UPLOAD: {
      return {
        ...state,
        isFileUploadOpen: payload,
      };
    }
    case APP_UPDATE_CONFIG: {
      const {config: newConfig} = payload;
      const prevConfig = {...state.config};
      return {
        ...state,
        config: {
          ...prevConfig,
          ...newConfig,
        },
      };
    }
    case APP_NOTIFICATIONS_PERMISSION: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          permissions: payload,
        },
      };
    }
    case APP_NOTIFICATIONS_TOKEN_UPDATE: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          token: payload,
        },
      };
    }
    case APP_SET_CHAT_EASY_START_FROM: {
      return {
        ...state,
        chatEasyStartFrom: payload || state.chatEasyStartFrom,
      };
    }

    case APP_CLEAR_CHAT_EASY_START_FROM: {
      return {
        ...state,
        chatEasyStartFrom: null,
      };
    }
    case SET_CURRENT_USER_COUNTRY_CODE: {
      const {countryCode} = payload;
      return {...state, countryCode};
    }

    case SET_USER_FINGERPRINT: {
      const {fingerprint} = payload;
      return {...state, fingerprint};
    }
    case UPDATE_APP_ONLINE_STATUS: {
      const {isOnline} = payload;
      return {...state, isOnline};
    }
    default:
      return state;
  }
};

export default appReducer;
