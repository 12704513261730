import routeByName from '~/constants/routes';
import logger from '~/utils/logger';
import {navigateReplaceAction} from '~/actions/navigateActions';

import LocationsService from '../LocationsService';
import {LOCATIONS_SET_LOCATIONS_LIST} from './constants';
import {setUserLocationAction} from './setUserLocationAction';
import {setProfileLocation} from '../../CurrentUser/store/actions';
import {
  getLocationByRoute,
  getLocationLink,
  leafLocationsSelector,
  userLocationSelector,
} from './selectors';
import {
  currentUserLocationSelector,
  userIpGeoLocationSelector,
} from '../../CurrentUser/store/selectors';
import setCountryCodeAction from '../../CurrentUser/store/setCountryCodeAction';
import {updateHeaderLocationAction} from '../../App/store/actions/updateHeaderLocationAction';
import {filterProfileTypeSelector} from '../../Profiles/store/selectors';

const log = logger.module('getGeoLocationAction');

export const getLocationsAction = () => async (dispatch) => {
  const locations = await LocationsService.getLocations();

  dispatch({
    type: LOCATIONS_SET_LOCATIONS_LIST,
    payload: locations,
  });

  dispatch(setProfileLocation());
};

export const getGeoLocationAction = () => async (dispatch, getState) => {
  const leafLocations = leafLocationsSelector(getState());
  const userLocation = userLocationSelector(getState());
  const currentUserLocation = currentUserLocationSelector(getState());
  const userIpGeoLocation = userIpGeoLocationSelector(getState());
  const profileType = filterProfileTypeSelector(getState());

  if (userLocation || currentUserLocation) {
    let currentLocationPath;

    if (userLocation) {
      currentLocationPath = userLocation;
    } else {
      currentLocationPath = getLocationLink(
        currentUserLocation.borough || currentUserLocation.city || currentUserLocation.state,
        profileType
      );
    }
    const headerLocation = getLocationByRoute(leafLocations, currentLocationPath, profileType);
    dispatch(updateHeaderLocationAction(headerLocation));

    return;
  }

  if (!userIpGeoLocation) {
    return;
  }

  const location = getLocationByRoute(leafLocations, userIpGeoLocation.path, profileType);
  if (!location) {
    log.warn('Location not found', {url: userIpGeoLocation.path});
    return;
  }

  dispatch(setCountryCodeAction(userIpGeoLocation.countryCode));
  dispatch(setUserLocationAction(userIpGeoLocation.path));
  dispatch(updateHeaderLocationAction(location));

  if (window.location.pathname === routeByName.home) {
    dispatch(navigateReplaceAction(userIpGeoLocation.path));
  }
};
