import {TThunkAsyncAction} from '~/types/appTypes';
import {CHAT_USAGE_INFO_UPDATE} from '~/rootStore/chatUsageInfo/actionTypes';
import {chatUsageInfoSelector} from '~/rootStore/chatUsageInfo/selectors';

export const resetChatUsageInfo: TThunkAsyncAction = () => (dispatch) => {
  const payload = {
    messageSendCount: 0,
    lastMessageSendTime: null,
  };

  dispatch({type: CHAT_USAGE_INFO_UPDATE, payload});
};

export const updateChatUsageInfo: TThunkAsyncAction = () => (dispatch, getState) => {
  const chatUsageInfo = chatUsageInfoSelector(getState());
  const payload = {
    messageSendCount: chatUsageInfo.messageSendCount + 1,
    lastMessageSendTime: Date.now(),
  };
  dispatch({type: CHAT_USAGE_INFO_UPDATE, payload});
};
