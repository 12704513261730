"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteMessageFromStore = void 0;
var QBAdapter_1 = require("../QBAdapter");
var effector_1 = require("effector");
var types_1 = require("../types");
exports.deleteMessageFromStore = function (state, _a) {
    var messageId = _a.params._id;
    var _b = state, _c = messageId, deletedMessage = _b[_c], withoutDeletedMessage = __rest(_b, [typeof _c === "symbol" ? _c : _c + ""]);
    return withoutDeletedMessage;
};
var deleteMessage = effector_1.createEffect().use(QBAdapter_1.deleteMessage);
exports.default = (function (Messages$, MessagesOrder$, Dialogs$, remove, onRemoveMessageReceived, getTalker) {
    effector_1.forward({
        from: remove,
        to: deleteMessage,
    });
    Messages$.on(deleteMessage.done, exports.deleteMessageFromStore);
    Messages$.on(onRemoveMessageReceived, function (state, messageId) {
        return exports.deleteMessageFromStore(state, { params: { _id: messageId } });
    });
    effector_1.sample({
        clock: deleteMessage.done,
        source: Dialogs$,
        fn: function (state, props) {
            var _a = props.params, messageId = _a._id, dialogId = _a.chat_dialog_id;
            var dialog = state[dialogId];
            if (!dialog) {
                return state;
            }
            var message = {
                body: types_1.TSystemEvents.DELETE_MESSAGE,
                extension: {
                    _id: messageId,
                },
            };
            QBAdapter_1.sendDeleteMessageEvent(getTalker(dialog), message);
        },
    });
});
