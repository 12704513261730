import {useState} from 'react';
import * as PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SpriteIcon from '../icons/SpriteIcon';

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      borderTop: `1px solid ${theme.palette.common.white}`,
      '&.expanded': {
        '& > $content': {
          display: 'block',
        },
        '& > div > $arrow': {
          transform: 'rotate(180deg)',
        },
      },
    },
    arrow: {
      transform: 'rotate(0deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    content: {
      display: 'none',
    },
    arrowIcon: {
      width: 19,
      height: 19,
    },
    title: {color: '#222728'},
  }),
  {name: 'DropDownComponent'}
);

const DropDownComponent = ({title, link, children}) => {
  const s = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={clsx('px-2', s.wrapper, {expanded})}>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div className="d-flex" onClick={() => setExpanded(!expanded)} role="button">
        <div className="title d-flex align-center py-2 flex-1">
          <RouterLink className={clsx('no-underline', s.title)} to={link}>
            {title}
          </RouterLink>
        </div>
        <div className={clsx('p-1-5 d-flex align-center', s.arrow)}>
          <SpriteIcon name="drop-down-thin" fontSize="custom" className={s.arrowIcon} />
        </div>
      </div>
      <div className={s.content}>{children}</div>
    </div>
  );
};

DropDownComponent.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

DropDownComponent.defaultProps = {};

export default DropDownComponent;
