import localStorageWrapper from '~/utils/localStorageWrapper';

import {LOCATIONS_SET_LOCATIONS_LIST, LOCATIONS_SET_USER_LOCATION} from './constants';
import {storageLocationKey} from '../constants/localStorage';

const initialState = {
  locations: {},
  homeLists: {},
  userLocation: null,
};

const createHomePageLists = (locations) => {
  const values = Object.values(locations);
  const newUsa = values.find((entry) => entry.url === 'united-states');
  const newCanada = values.find((entry) => entry.url === 'canada');

  return {
    usa: newUsa ? newUsa.children : {},
    canada: newCanada ? newCanada.children : {},
    worldwide: {
      ...values.filter((entry) => entry.url !== 'united-states' && entry.url !== 'canada'),
    },
  };
};

const locationsReducer = (
  state = {
    ...initialState,
    userLocation: localStorageWrapper.getItem(storageLocationKey) || initialState.userLocation,
  },
  {type, payload}
) => {
  switch (type) {
    case LOCATIONS_SET_LOCATIONS_LIST:
      if (JSON.stringify(state.locations) === JSON.stringify(payload)) {
        return state;
      }
      return {
        ...state,
        locations: payload,
        homeLists: createHomePageLists(payload),
      };
    case LOCATIONS_SET_USER_LOCATION:
      if (state.userLocation === payload) {
        return state;
      }

      localStorageWrapper.setItem(storageLocationKey, payload);

      return {
        ...state,
        userLocation: payload,
      };
    default:
      return state;
  }
};

export default locationsReducer;
