"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.withAttachmentLink = exports.MessagesOrder$ = exports.Messages$ = exports.fillNewMessage = exports.getUNIXTime = exports.getIsoString = void 0;
var effector_1 = require("effector");
var QBAdapter_1 = require("../QBAdapter");
exports.getIsoString = function () { return new Date().toISOString(); };
exports.getUNIXTime = function () { return Math.round(new Date().getTime() / 1000); };
var emptyMessage = {
    _id: '',
    message: '',
    imageSize: '',
    recipient_id: 0,
    created_at: '',
    updated_at: '',
    chat_dialog_id: '',
    date_sent: 0,
    sender_id: 0,
    read_ids: [],
    delivered_ids: [],
    attachments: [],
};
exports.fillNewMessage = function (message) { return (__assign(__assign(__assign({}, emptyMessage), message), { date_sent: exports.getUNIXTime(), created_at: exports.getIsoString(), updated_at: exports.getIsoString() })); };
exports.Messages$ = effector_1.createStore({});
exports.MessagesOrder$ = effector_1.createStore({});
exports.withAttachmentLink = function (token) { return function (_a) {
    var type = _a.type, id = _a.id;
    return ({
        type: type,
        link: QBAdapter_1.getFileLink(token, id),
    });
}; };
