import {Route, Redirect, RouteProps} from 'react-router-dom';
import {useSelector} from 'react-redux';

import routeByName from '~/constants/routes';
import {layoutFullScreenLoaderSelector} from '~/components/Layout';
import {currentUserSelector} from '~/modules/CurrentUser/store/selectors';

interface Props extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FunctionComponent<any>;
}

const GuestRoute: React.FC<Props> = ({component: Component, ...rest}) => {
  const user = useSelector(currentUserSelector);
  const loading = useSelector(layoutFullScreenLoaderSelector);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user || loading) {
          return <Component {...props} />;
        }

        return <Redirect to={routeByName.home} />;
      }}
    />
  );
};

export default GuestRoute;
