import {useCallback, useEffect, useRef} from 'react';

import useVisibilityChange from './useVisibilityChange';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useInterval = (callback: () => void, interval: number) => {
  const callbackRef = useRef(callback);
  const intervalRef = useRef<number>();
  const documentVisible = useVisibilityChange();

  const stopInterval = useCallback(() => {
    window.clearInterval(intervalRef.current);
  }, []);

  const startInterval = useCallback(() => {
    intervalRef.current = window.setInterval(() => {
      callbackRef.current();
    }, interval);

    callbackRef.current();
  }, [interval]);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!documentVisible) {
      stopInterval();
      return undefined;
    }

    startInterval();

    return () => {
      stopInterval();
    };
  }, [documentVisible, startInterval, stopInterval]);

  return [stopInterval, startInterval];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTimeout = (callback: () => void, timeout: number) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<number>();

  const stopTimeout = useCallback(() => {
    window.clearTimeout(timeoutRef.current);
  }, []);

  const startTimeout = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      callbackRef.current();
    }, timeout);
  }, [timeout]);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    startTimeout();

    return () => {
      stopTimeout();
    };
  }, [startTimeout, stopTimeout]);

  return [stopTimeout, startTimeout];
};

export default useInterval;
