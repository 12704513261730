"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findUsersByIds = void 0;
var effector_1 = require("effector");
var QBAdapter_1 = require("../QBAdapter");
exports.findUsersByIds = effector_1.createEffect().use(function (ids) {
    if (!ids || (ids && !ids.length)) {
        return [];
    }
    return QBAdapter_1.findUsersByFilter({
        per_page: 100,
        filters: { field: 'id', param: 'in', value: ids },
    });
});
exports.default = exports.findUsersByIds;
