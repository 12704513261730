import isEmail from 'validator/es/lib/isEmail';

import {formFields} from '../constants/feedback';
import {errorMessages} from '../constants/messages';

const validateForm = ({
  [formFields.rate]: rate,
  [formFields.type]: type,
  [formFields.content]: content,
  [formFields.email]: email,
  [formFields.year]: year,
}) => {
  const errors = {};

  if (!rate) {
    errors[formFields.rate] = [errorMessages.required[formFields.rate]];
  }

  if (!type) {
    errors[formFields.type] = [errorMessages.required[formFields.type]];
  }

  if (!content) {
    errors[formFields.content] = [errorMessages.required[formFields.content]];
  }

  if (!email) {
    errors[formFields.email] = [errorMessages.required[formFields.email]];
  }

  if (!year) {
    errors[formFields.year] = [errorMessages.required[formFields.year]];
  } else if (Number.parseInt(year, 10) !== new Date().getFullYear()) {
    errors[formFields.year] = [errorMessages.invalid[formFields.year]];
  }

  if (!errors[formFields.content] && content.length < 15) {
    errors[formFields.content] = [errorMessages.invalid[formFields.content]];
  }

  if (!errors[formFields.email] && !isEmail(email)) {
    errors[formFields.email] = [errorMessages.invalid[formFields.email]];
  }

  return errors;
};

export default validateForm;
