import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: '100%',
      boxShadow: theme.shadows[4],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 107,
      height: 107,
      margin: '0 auto 16px',
    },
  }),
  {name: 'BenefitIcon'}
);
interface Props {
  icon: string;
}
const BenefitIcon: React.FC<Props> = ({icon}) => {
  const s = useStyles();

  return (
    <div className={s.root}>
      <img src={icon} alt={icon} />
    </div>
  );
};

export default BenefitIcon;
