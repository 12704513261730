import {Grid, makeStyles} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    background: 'white',
    borderBottom: '1px solid #C8C7CC',
    fontSize: 18,
  },
  label: {
    padding: '11px 10px',
  },
  control: {
    color: '#898989',
  },
});

interface Props {
  title: string;
  rootClassName?: string;
}

const BaseControl: React.FC<Props> = ({title, rootClassName, children}) => {
  const s = useStyles();
  return (
    <div className={clsx(s.root, rootClassName)}>
      <Grid container alignItems="center">
        <Grid item xs={4} className={s.label}>
          {title}
        </Grid>
        <Grid item xs={8} className={s.control}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default BaseControl;
