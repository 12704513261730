import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';

import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import CheckboxButton from '~/components/ui/inputs/CheckboxButton';
import {setProfileFiltersAction} from '~/modules/Profiles/store/actions';

const useStyles = makeStyles(
  (theme) => ({
    label: {
      padding: theme.spacing(1.5),
    },
    sectionTitle: {
      fontSize: 18,
    },
    arrow: {
      width: 12,
      height: 20,
      transition: 'transform 0.2s linear',
    },
    openArrow: {
      transform: 'rotate(180deg)',
    },
    checkboxWrapper: {
      marginLeft: theme.spacing(-1.25),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
    },
  }),
  {name: 'ProfileFilterControl'}
);

interface TOption {
  value: number;
  name: string;
  disabled: boolean;
}

interface Props {
  name: string;
  value: string;
  options: {
    [key: string]: TOption;
  };
  currentValues: {
    [key: string]: string;
  };
}

const ProfileFilterControl: React.FC<Props> = ({
  name: filterName,
  options,
  currentValues = {},
  value,
}) => {
  const s = useStyles();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const expandDivRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (!expandDivRef.current) {
      return;
    }

    const expandDivPositionInfo = expandDivRef.current.getBoundingClientRect();
    if (
      expandDivRef.current &&
      (expandDivPositionInfo.top < 0 ||
        expandDivPositionInfo.bottom + expandDivPositionInfo.height > window.innerHeight)
    ) {
      expandDivRef.current.scrollIntoView(false);
    }
  }, [collapsed]);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value;
    const currentName = event.target.name;
    dispatch(
      setProfileFiltersAction({
        fieldName: value,
        value: currentValue,
        valueName: currentName,
      })
    );
  };

  const toggleSection = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  const selectedLabels = useMemo(() => Object.keys(currentValues), [currentValues]);

  // uncheck checkbox even if it's disabled
  const handleClick = (currentValue: number, currentName: string, disabled: boolean) => () => {
    if (!disabled || !currentValues[currentName]) {
      return;
    }

    dispatch(
      setProfileFiltersAction({
        fieldName: value,
        value: String(currentValue),
        valueName: currentName,
      })
    );
  };

  if (!filterName) {
    return null;
  }

  return (
    <>
      <div className={clsx('divider-bottom', s.label)} onClick={toggleSection} role="presentation">
        <Grid container alignItems="center" spacing={2} wrap="nowrap">
          <Grid xs item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item className={clsx('text-capitalize', s.sectionTitle)}>
                {filterName}
              </Grid>
              {Boolean(selectedLabels.length) && collapsed && (
                <Grid item className="text-primary">
                  ({selectedLabels.join(', ')})
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <SpriteIcon
              className={clsx(s.arrow, {[s.openArrow]: !collapsed})}
              name="expand-arrow-down-sm"
              fontSize="custom"
            />
          </Grid>
        </Grid>
      </div>
      <div
        ref={expandDivRef}
        className={clsx(s.checkboxWrapper, 'd-flex wrap divider-bottom', {
          'd-none': collapsed,
        })}
      >
        {Object.values(options).map(({value: optionValue, name, disabled}) => {
          return (
            <CheckboxButton
              key={`${filterName}-${name}-${value}`}
              value={optionValue}
              label={name}
              disabled={disabled}
              name={name}
              checked={!!currentValues[name]}
              onChange={handleChange}
              onClick={handleClick(optionValue, name, disabled)}
            />
          );
        })}
      </div>
    </>
  );
};

export default ProfileFilterControl;
