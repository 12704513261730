export const USER_SETTINGS = 'bendr_userSettings';
export const APP_LATEST_PATH = 'bendr_appLatestPath';
export const TOTAL_PROFILE_PAGE_VIEWS = 'bendr_totalProfilePageViews';
export const TOTAL_WEBSITE_VIEWS = 'bendr_totalWebsiteViews';
export const PWA_BANNER_CANCEL_COUNT = 'bendr_pwaBannerCancelCount';
export const LOCATION_PROFILE_FILTERS = 'bendr_profileFilters_v2';
export const LOCATION_PROFILE_FILTERS_OPTIONS = 'bendr_profileFiltersOptions';
export const LOCATION_FILTERS = 'bendr_locationFilters';
export const NAVIGATION_PROFILE_FILTERS = 'bendr_navigationFilters';
export const LOCATION_PROFILE_TYPE_FILTER = 'bendr_profileTypeFilter';
export const SWIPE_TUTORIAL_STATE = 'bendr_swipeTutorial';
