"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CALL_MESSAGES = exports.TSystemEvents = exports.DialogType = exports.TRoxySessionState = exports.TSessionState = exports.TWebRTCMediaType = void 0;
var TWebRTCMediaType;
(function (TWebRTCMediaType) {
    TWebRTCMediaType[TWebRTCMediaType["VIDEO"] = 1] = "VIDEO";
    TWebRTCMediaType[TWebRTCMediaType["AUDIO"] = 2] = "AUDIO";
})(TWebRTCMediaType = exports.TWebRTCMediaType || (exports.TWebRTCMediaType = {}));
var TSessionState;
(function (TSessionState) {
    TSessionState[TSessionState["NEW"] = 1] = "NEW";
    TSessionState[TSessionState["ACTIVE"] = 2] = "ACTIVE";
    TSessionState[TSessionState["HUNGUP"] = 3] = "HUNGUP";
    TSessionState[TSessionState["REJECTED"] = 4] = "REJECTED";
    TSessionState[TSessionState["CLOSED"] = 5] = "CLOSED";
})(TSessionState = exports.TSessionState || (exports.TSessionState = {}));
var TRoxySessionState;
(function (TRoxySessionState) {
    TRoxySessionState["INACTIVE"] = "inactive";
    TRoxySessionState["ACTIVE"] = "active";
    TRoxySessionState["INCOMING"] = "incoming";
    TRoxySessionState["OUTGOING"] = "outgoing";
})(TRoxySessionState = exports.TRoxySessionState || (exports.TRoxySessionState = {}));
var DialogType;
(function (DialogType) {
    DialogType[DialogType["PUBLIC_GROUP"] = 1] = "PUBLIC_GROUP";
    DialogType[DialogType["GROUP"] = 2] = "GROUP";
    DialogType[DialogType["PRIVATE"] = 3] = "PRIVATE";
})(DialogType = exports.DialogType || (exports.DialogType = {}));
var TSystemEvents;
(function (TSystemEvents) {
    TSystemEvents["DELETE_MESSAGE"] = "DELETE_MESSAGE";
})(TSystemEvents = exports.TSystemEvents || (exports.TSystemEvents = {}));
var CALL_MESSAGES;
(function (CALL_MESSAGES) {
    CALL_MESSAGES["INCOMING_AUDIO"] = "Incoming Call...";
    CALL_MESSAGES["INCOMING_VIDEO"] = "Incoming Video Call...";
})(CALL_MESSAGES = exports.CALL_MESSAGES || (exports.CALL_MESSAGES = {}));
