import {NavigatorPermissions} from '~/constants/navigator';
import {checkGeolocationPermission} from '~/helpers/navigator/checkGeolocationPermission';
import {TGeoLocation} from '~/types';
import store from '~/rootStore/store';
import {TThunkAction} from '~/types/appTypes';
import logger from '~/utils/logger';
import {startLoader, stopLoader} from '~/rootStore/loaders/loadersReducer';

import {APP_NAVIGATOR_PERMISSIONS_UPDATE, GeolocationPositionErrors} from './constants';
import {USER_LOCATION_UPDATE} from '../../CurrentUser/store/constants';
import CurrentUserService from '../../CurrentUser/CurrentUserService';
import {LOADER_REQUEST_GEOLOCATION} from '../../CurrentUser/Settings/constants/loadersIds';

const log = logger.module('geoActions');

interface TPermissions {
  geolocation: NavigatorPermissions;
  [key: string]: NavigatorPermissions;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateNavigatorPermissions = (permissions: TPermissions) => {
  return {
    type: APP_NAVIGATOR_PERMISSIONS_UPDATE,
    payload: {...permissions},
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateUserLocation = ({lat, lng}: TGeoLocation) => ({
  type: USER_LOCATION_UPDATE,
  payload: {
    lat,
    lng,
    timestamp: Date.now(),
  },
});

export const requestGeolocation = async (): Promise<{
  position?: TGeoLocation;
  hasError?: boolean;
  errorCode?: GeolocationPositionErrors;
}> => {
  store.dispatch(startLoader(LOADER_REQUEST_GEOLOCATION));
  const {position, hasError, errorCode} = await checkGeolocationPermission();
  log.info('Geolocation', {position, errorCode});
  if (!position) {
    store.dispatch(updateNavigatorPermissions({geolocation: NavigatorPermissions.BLOCK}));
    log.warn('User reject access to his geolocation', {position, errorCode});
    store.dispatch(stopLoader(LOADER_REQUEST_GEOLOCATION));
    return {position: undefined, hasError, errorCode};
  }

  store.dispatch(updateNavigatorPermissions({geolocation: NavigatorPermissions.ALLOW}));

  const {latitude: lat, longitude: lng} = position.coords;
  const pos: TGeoLocation = {lat, lng};
  store.dispatch(updateUserLocation(pos));
  store.dispatch(stopLoader(LOADER_REQUEST_GEOLOCATION));
  return {position: pos, hasError, errorCode: undefined};
};

type TRefreshProfileGeo = (profileId: number) => TThunkAction;

export const refreshProfileGeoLocationAction: TRefreshProfileGeo = (profileId) => async () => {
  const {position} = await requestGeolocation();

  if (position) {
    const {lat, lng} = position;
    await CurrentUserService.refreshCurrentGeoLocation(profileId, lat, lng);
  }
};
