import {useCallback, useMemo, useState} from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useModal = () => {
  const [isOpen, setOpen] = useState(false);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const openModal = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return useMemo(
    () => ({
      isOpen,
      closeModal,
      openModal,
    }),
    [isOpen, closeModal, openModal]
  );
};

export default useModal;
