import * as React from 'react';
import {Reducer} from 'redux';
import {OptionsObject} from 'notistack';

import {TAction} from '~/types/appTypes';

const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export interface TNotification {
  options: OptionsObject;
  message: string | React.ReactNode;
  key: number;
}

export interface TNotificationsState {
  notifications: TNotification[];
  nextNotification: number;
}

const initialState = {
  nextNotification: -1, // used for notifications keys
  notifications: [], // contains the list of notifications
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addNotification = (notification: Omit<TNotification, 'key'>) => ({
  type: ADD_NOTIFICATION,
  notification,
});

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, Please try again later';

export const addErrorNotificationAction = (
  message: string = DEFAULT_ERROR_MESSAGE,
  options?: Record<string, unknown>
): TAction => ({
  type: ADD_NOTIFICATION,
  notification: {message, options: {variant: 'error', ...options}},
});

export const addSuccessNotificationAction = (
  message: string,
  options?: Record<string, unknown>
): TAction => ({
  type: ADD_NOTIFICATION,
  notification: {message, options: {variant: 'success', ...options}},
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeNotificationAction = (key: number) => ({
  type: REMOVE_NOTIFICATION,
  key,
});

export const notificationsReducer: Reducer<TNotificationsState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      if (
        /* do not display same message twice */
        state.notifications.find((n) => n.message === action.notification.message)
      ) {
        return state;
      }
      const key = state.nextNotification + 1;
      return {
        ...state,
        nextNotification: key,
        notifications: [{...action.notification, key}, ...state.notifications],
      };
    }

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };

    default:
      return state;
  }
};
