import logger from '~/utils/logger';
import config from '~/constants/config';
import {NotificationPermissions} from '~/constants/navigator';

import {isIOS} from './detect';
import {urlB64ToUint8Array} from '../converters';

const log = logger.module('checkNotificationsPermission');

export interface NotificationResponse {
  permission: NotificationPermission;
  endpoint?: string | void;
}

const subscribeUser = (): Promise<string | void> => {
  if (!(config.publicVAPID && config.publicVAPID.length)) {
    log.error(`Public VAPID key doesn't exist`);
    return Promise.resolve('');
  }

  return window.navigator.serviceWorker
    .getRegistration()
    .then(async (reg): Promise<string | void> => {
      if (!reg) {
        return new Promise((resolve) => {
          resolve('');
        });
      }

      const currentSubcription = await reg.pushManager.getSubscription();
      const currentPermission = await reg.pushManager.permissionState({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(config.publicVAPID),
      });
      if (currentSubcription && currentPermission === NotificationPermissions.GRANTED) {
        return new Promise((resolve) => {
          resolve(currentSubcription.toJSON() as string);
        });
      }

      return reg.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(config.publicVAPID),
        })
        .then((sub: PushSubscription) => {
          return sub ? (sub.toJSON() as string) : '';
        })
        .catch((e) => {
          if (window.Notification.permission === NotificationPermissions.DENIED) {
            log.info('Permission for notifications was denied');
            return '';
          }

          if (!isIOS) {
            log.info('Unable to subscribe to push', e);
          }
          return '';
        });
    });
};

export const checkNotificationsPermission = (): Promise<NotificationResponse> => {
  return new Promise((resolve) => {
    (async () => {
      const handlePermissionRequest = (permission: NotificationPermission) => {
        if (window.navigator.serviceWorker) {
          subscribeUser().then((endpoint) => {
            resolve({permission, endpoint});
          });
        } else {
          resolve({permission, endpoint: ''});
        }
      };

      if (!('Notification' in window)) {
        log.info('Notification API is not supported!');
        resolve({permission: NotificationPermissions.DENIED});
        return;
      }
      if (window.Notification.permission === NotificationPermissions.GRANTED) {
        handlePermissionRequest(NotificationPermissions.GRANTED);
      }
      if (window.Notification.permission !== NotificationPermissions.DENIED) {
        if (isIOS) {
          window.Notification.requestPermission(handlePermissionRequest);
        } else {
          window.Notification.requestPermission().then(handlePermissionRequest);
        }
        return;
      }

      resolve({permission: NotificationPermissions.DENIED});
    })();
  });
};
