import React from 'react';
import {useSelector} from 'react-redux';
import * as PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import clsx from 'clsx';

import {filterProfileTypeSelector} from '~/modules/Profiles/store/selectors';
import {getLocationLink} from '~/modules/Locations/store/selectors';
import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import DropDown from '~/components/ui/dropDowns/DropDown';
import DropDownComponent from '~/components/ui/dropDowns/DropDownComponent';

const locationMarker = (
  <SpriteIcon name="location-marker-outlined" className=" text-primary mr-1" />
);

const useStyles = makeStyles(
  (theme) => ({
    borderTop: {
      borderTop: `1px solid ${theme.palette.common.white}`,
    },
  }),
  {name: 'ExpansionContent'}
);

// eslint-disable-next-line react/prop-types
const BoroughWrapper = ({title, link, locations}) => {
  const filterProfileType = useSelector(filterProfileTypeSelector);
  const s = useStyles();

  return (
    <DropDown title={title} link={link}>
      {Object.values(locations).map((loc) => (
        <RouterLink
          key={loc.id}
          to={getLocationLink(loc, filterProfileType)}
          className={clsx('py-2 d-flex align-center no-underline text-default px-2', s.borderTop)}
        >
          {locationMarker}
          {loc.name}
        </RouterLink>
      ))}
    </DropDown>
  );
};

const CityWrapper = ({locations}) => {
  const s = useStyles();
  const filterProfileType = useSelector(filterProfileTypeSelector);

  return Object.values(locations).map((loc) => {
    if (loc.children && loc.active_profile) {
      return (
        <BoroughWrapper
          key={loc.id}
          title={loc.name}
          link={getLocationLink(loc, filterProfileType)}
          locations={loc.children}
        />
      );
    }

    const hasContent = true;
    // isEscort
    //   ? loc.active_provider || loc.active_dating
    //   : loc.active_profile;
    // const showShemalesOnly =
    //   isEscort &&
    //   !loc.active_dating &&
    //   filterProfileType === profileTypes.client;
    // const showMalesOnly =
    //   isEscort &&
    //   !loc.active_provider &&
    //   filterProfileType === profileTypes.escort;

    return (
      hasContent && (
        <RouterLink
          key={loc.id}
          to={getLocationLink(loc, filterProfileType)}
          className={clsx('py-2 d-flex align-center no-underline text-default px-1', s.borderTop)}
        >
          {locationMarker}
          {loc.name}
          {/* {showShemalesOnly && ' (Shemales only)'}
          {showMalesOnly && ' (Males only)'} */}
        </RouterLink>
      )
    );
  });
};

const ExpansionContent = ({locations}) => {
  const filterProfileType = useSelector(filterProfileTypeSelector);

  return Object.values(locations).map((loc) => {
    const hasChildAnyActiveProfiles = Object.values(loc.children).some(
      ({active_profile: activeProfile}) => activeProfile === true
    );

    return (
      hasChildAnyActiveProfiles && (
        <DropDownComponent
          key={loc.id}
          title={loc.name}
          link={getLocationLink(loc, filterProfileType)}
        >
          <CityWrapper locations={loc.children} />
        </DropDownComponent>
      )
    );
  });
};

ExpansionContent.propTypes = {
  locations: PropTypes.shape({}).isRequired,
};

ExpansionContent.defaultProps = {};

export default ExpansionContent;
