const languagesList = {
  af: {
    name: 'Afrikaans',
    native: 'Afrikaans',
    country: 'ZA',
  },
  am: {
    name: 'Amharic',
    native: 'አማርኛ',
    country: 'ET',
  },
  ar: {
    name: 'Arabic',
    native: 'العربية',
    rtl: 1,
    country: 'SA',
  },
  ay: {
    name: 'Aymara',
    native: 'Aymar',
    country: 'BO',
  },
  az: {
    name: 'Azerbaijani',
    native: 'Azərbaycanca / آذربايجان',
    country: 'AZ',
  },
  be: {
    name: 'Belarusian',
    native: 'Беларуская',
    country: 'BY',
  },
  bg: {
    name: 'Bulgarian',
    native: 'Български',
    country: 'BG',
  },
  bi: {
    name: 'Bislama',
    native: 'Bislama',
    country: 'VU',
  },
  bn: {
    name: 'Bengali',
    native: 'বাংলা',
    country: 'BD',
  },
  bs: {
    name: 'Bosnian',
    native: 'Bosanski',
    country: 'BA',
  },
  ca: {
    name: 'Catalan',
    native: 'Català',
    country: 'AD',
  },
  ch: {
    name: 'Chamorro',
    native: 'Chamoru',
    country: 'GU',
  },
  cs: {
    name: 'Czech',
    native: 'Česky',
    country: 'CZ',
  },
  da: {
    name: 'Danish',
    native: 'Dansk',
    country: 'DK',
  },
  de: {
    name: 'German',
    native: 'Deutsch',
    country: 'DE',
  },
  dv: {
    name: 'Divehi',
    native: 'ދިވެހިބަސް',
    rtl: 1,
    country: 'MV',
  },
  dz: {
    name: 'Dzongkha',
    native: 'ཇོང་ཁ',
    country: 'BT',
  },
  el: {
    name: 'Greek',
    native: 'Ελληνικά',
    country: 'GR',
  },
  en: {
    name: 'English',
    native: 'English',
    country: 'GB',
  },
  es: {
    name: 'Spanish',
    native: 'Español',
    country: 'ES',
  },
  et: {
    name: 'Estonian',
    native: 'Eesti',
    country: 'EE',
  },
  eu: {
    name: 'Basque',
    native: 'Euskara',
    country: 'ES',
  },
  fa: {
    name: 'Persian',
    native: 'فارسی',
    rtl: 1,
    country: 'IR',
  },
  ff: {
    name: 'Peul',
    native: 'Fulfulde',
    country: 'GN',
  },
  fi: {
    name: 'Finnish',
    native: 'Suomi',
    country: 'FI',
  },
  fj: {
    name: 'Fijian',
    native: 'Na Vosa Vakaviti',
    country: 'FJ',
  },
  fo: {
    name: 'Faroese',
    native: 'Føroyskt',
    country: 'FO',
  },
  fr: {
    name: 'French',
    native: 'Français',
    country: 'FR',
  },
  ga: {
    name: 'Irish',
    native: 'Gaeilge',
    country: 'IE',
  },
  gl: {
    name: 'Galician',
    native: 'Galego',
    country: 'ES',
  },
  gn: {
    name: 'Guarani',
    native: "Avañe'ẽ",
    country: 'PY',
  },
  gv: {
    name: 'Manx',
    native: 'Gaelg',
    country: 'IM',
  },
  he: {
    name: 'Hebrew',
    native: 'עברית',
    rtl: 1,
    country: 'IL',
  },
  hi: {
    name: 'Hindi',
    native: 'हिन्दी',
    country: 'IN',
  },
  hr: {
    name: 'Croatian',
    native: 'Hrvatski',
    country: 'HR',
  },
  ht: {
    name: 'Haitian',
    native: 'Krèyol ayisyen',
    country: 'HT',
  },
  hu: {
    name: 'Hungarian',
    native: 'Magyar',
    country: 'HU',
  },
  hy: {
    name: 'Armenian',
    native: 'Հայերեն',
    country: 'AM',
  },
  id: {
    name: 'Indonesian',
    native: 'Bahasa Indonesia',
    country: 'ID',
  },
  is: {
    name: 'Icelandic',
    native: 'Íslenska',
    country: 'IS',
  },
  it: {
    name: 'Italian',
    native: 'Italiano',
    country: 'IT',
  },
  ja: {
    name: 'Japanese',
    native: '日本語',
    country: 'JP',
  },
  ka: {
    name: 'Georgian',
    native: 'ქართული',
    country: 'GE',
  },
  kg: {
    name: 'Kongo',
    native: 'KiKongo',
    country: 'CD',
  },
  kk: {
    name: 'Kazakh',
    native: 'Қазақша',
    country: 'KZ',
  },
  kl: {
    name: 'Greenlandic',
    native: 'Kalaallisut',
    country: 'GL',
  },
  km: {
    name: 'Cambodian',
    native: 'ភាសាខ្មែរ',
    country: 'KH',
  },
  ko: {
    name: 'Korean',
    native: '한국어',
    country: 'KR',
  },
  ku: {
    name: 'Kurdish',
    native: 'Kurdî / كوردی',
    rtl: 1,
    country: 'IQ',
  },
  ky: {
    name: 'Kirghiz',
    native: 'Kırgızca / Кыргызча',
    country: 'KG',
  },
  la: {
    name: 'Latin',
    native: 'Latina',
    country: 'VA',
  },
  lb: {
    name: 'Luxembourgish',
    native: 'Lëtzebuergesch',
    country: 'LU',
  },
  ln: {
    name: 'Lingala',
    native: 'Lingála',
    country: 'CD',
  },
  lo: {
    name: 'Laotian',
    native: 'ລາວ / Pha xa lao',
    country: 'LA',
  },
  lt: {
    name: 'Lithuanian',
    native: 'Lietuvių',
    country: 'LT',
  },
  lu: {},
  lv: {
    name: 'Latvian',
    native: 'Latviešu',
    country: 'LV',
  },
  mg: {
    name: 'Malagasy',
    native: 'Malagasy',
    country: 'MG',
  },
  mh: {
    name: 'Marshallese',
    native: 'Kajin Majel / Ebon',
    country: 'MH',
  },
  mi: {
    name: 'Maori',
    native: 'Māori',
    country: 'NZ',
  },
  mk: {
    name: 'Macedonian',
    native: 'Македонски',
    country: 'MK',
  },
  mn: {
    name: 'Mongolian',
    native: 'Монгол',
    country: 'MN',
  },
  ms: {
    name: 'Malay',
    native: 'Bahasa Melayu',
    country: 'MY',
  },
  mt: {
    name: 'Maltese',
    native: 'bil-Malti',
    country: 'MT',
  },
  my: {
    name: 'Burmese',
    native: 'Myanmasa',
    country: 'MM',
  },
  na: {
    name: 'Nauruan',
    native: 'Dorerin Naoero',
    country: 'NR',
  },
  nb: {},
  nd: {
    name: 'North Ndebele',
    native: 'Sindebele',
    country: 'ZW',
  },
  ne: {
    name: 'Nepali',
    native: 'नेपाली',
    country: 'NP',
  },
  nl: {
    name: 'Dutch',
    native: 'Nederlands',
    country: 'NL',
  },
  nn: {
    name: 'Norwegian Nynorsk',
    native: 'Norsk (nynorsk)',
    country: 'NO',
  },
  no: {
    name: 'Norwegian',
    native: 'Norsk (bokmål / riksmål)',
    country: 'NO',
  },
  nr: {
    name: 'South Ndebele',
    native: 'isiNdebele',
    country: 'ZA',
  },
  ny: {
    name: 'Chichewa',
    native: 'Chi-Chewa',
    country: 'MW',
  },
  oc: {
    name: 'Occitan',
    native: 'Occitan',
    country: 'ES',
  },
  pa: {
    name: 'Panjabi / Punjabi',
    native: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
    country: 'CW',
  },
  pl: {
    name: 'Polish',
    native: 'Polski',
    country: 'PL',
  },
  ps: {
    name: 'Pashto',
    native: 'پښتو',
    rtl: 1,
    country: 'AF',
  },
  pt: {
    name: 'Portuguese',
    native: 'Português',
    country: 'PT',
  },
  qu: {
    name: 'Quechua',
    native: 'Runa Simi',
    country: 'BO',
  },
  rn: {
    name: 'Kirundi',
    native: 'Kirundi',
    country: 'BI',
  },
  ro: {
    name: 'Romanian',
    native: 'Română',
    country: 'RO',
  },
  ru: {
    name: 'Russian',
    native: 'Русский',
    country: 'RU',
  },
  rw: {
    name: 'Rwandi',
    native: 'Kinyarwandi',
    country: 'RW',
  },
  sg: {
    name: 'Sango',
    native: 'Sängö',
    country: 'CF',
  },
  si: {
    name: 'Sinhalese',
    native: 'සිංහල',
    country: 'LK',
  },
  sk: {
    name: 'Slovak',
    native: 'Slovenčina',
    country: 'SK',
  },
  sl: {
    name: 'Slovenian',
    native: 'Slovenščina',
    country: 'SI',
  },
  sm: {
    name: 'Samoan',
    native: 'Gagana Samoa',
    country: 'WS',
  },
  sn: {
    name: 'Shona',
    native: 'chiShona',
    country: 'ZW',
  },
  so: {
    name: 'Somalia',
    native: 'Soomaaliga',
    country: 'SO',
  },
  sq: {
    name: 'Albanian',
    native: 'Shqip',
    country: 'AL',
  },
  sr: {
    name: 'Serbian',
    native: 'Српски',
    country: 'RS',
  },
  ss: {
    name: 'Swati',
    native: 'SiSwati',
    country: 'SZ',
  },
  st: {
    name: 'Southern Sotho',
    native: 'Sesotho',
    country: 'LS',
  },
  sv: {
    name: 'Swedish',
    native: 'Svenska',
    country: 'SE',
  },
  sw: {
    name: 'Swahili',
    native: 'Kiswahili',
    country: 'TZ',
  },
  ta: {
    name: 'Tamil',
    native: 'தமிழ்',
    country: 'LK',
  },
  tg: {
    name: 'Tajik',
    native: 'Тоҷикӣ',
    country: 'TJ',
  },
  th: {
    name: 'Thai',
    native: 'ไทย / Phasa Thai',
    country: 'TH',
  },
  ti: {
    name: 'Tigrinya',
    native: 'ትግርኛ',
    country: 'ER',
  },
  tk: {
    name: 'Turkmen',
    native: 'Туркмен / تركمن',
    country: 'TM',
  },
  tn: {
    name: 'Tswana',
    native: 'Setswana',
    country: 'BW',
  },
  to: {
    name: 'Tonga',
    native: 'Lea Faka-Tonga',
    country: 'TO',
  },
  tr: {
    name: 'Turkish',
    native: 'Türkçe',
    country: 'TR',
  },
  ts: {
    name: 'Tsonga',
    native: 'Xitsonga',
    country: 'ZA',
  },
  uk: {
    name: 'Ukrainian',
    native: 'Українська',
    country: 'UA',
  },
  ur: {
    name: 'Urdu',
    native: 'اردو',
    rtl: 1,
    country: 'PK',
  },
  uz: {
    name: 'Uzbek',
    native: 'Ўзбек',
    country: 'UZ',
  },
  ve: {
    name: 'Venda',
    native: 'Tshivenḓa',
    country: 'ZA',
  },
  vi: {
    name: 'Vietnamese',
    native: 'Tiếng Việt',
    country: 'VN',
  },
  xh: {
    name: 'Xhosa',
    native: 'isiXhosa',
    country: 'ZA',
  },
  zh: {
    name: 'Chinese',
    native: '中文',
    country: 'CN',
  },
  zu: {
    name: 'Zulu',
    native: 'isiZulu',
    country: 'ZA',
  },
};

export default languagesList;
