import {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';

import {topUpProfileAction} from '~/modules/Profiles/store/actions';
import loadCurrentUserAction from '~/modules/CurrentUser/store/actions/loadCurrentUserAction';
import logger from '~/utils/logger';
import {
  addErrorNotificationAction,
  addSuccessNotificationAction,
} from '~/rootStore/notifications/notificationsReducer';
import IosStyleConfirm from '~/components/ui/Dialogs/IosStyleConfirm';

import {successMessages} from '../constants';
import {useTopUpProfile} from '../helpers';
import ActionButton from './ActionButton';

const log = logger.module('ProfileOwnerActions');

const DrawerProfileTopUpPorfile: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const {canTopUpProfile, timeToNextTopup} = useTopUpProfile();

  const topUp = useCallback(async () => {
    setModalOpen(false);
    try {
      await dispatch(topUpProfileAction());
      await dispatch(loadCurrentUserAction());
      dispatch(addSuccessNotificationAction(successMessages.profileTopUp));
    } catch (error) {
      log.info('Error during topUpProfile', {error});
      dispatch(addErrorNotificationAction(error.message));
    }
  }, [dispatch]);

  return (
    <>
      <ActionButton onClick={() => setModalOpen(true)} disabled={!canTopUpProfile}>
        {canTopUpProfile ? 'Top Up Profile' : `Please wait ${timeToNextTopup} to top up again`}
      </ActionButton>
      <IosStyleConfirm
        title="Top Up Profile?"
        content={
          <>
            Are you sure you want to top up your profile? This will consume <b>1 top up credit</b>
          </>
        }
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        actionBtnHandler={topUp}
        actionBtnText="Top Up Profile"
      />
    </>
  );
};

export default DrawerProfileTopUpPorfile;
