import {StyleRules} from '@material-ui/core';
import {Palette} from '@material-ui/core/styles/createPalette';
import {Typography} from '@material-ui/core/styles/createTypography';
import shadows from '@material-ui/core/styles/shadows';
import shape from '@material-ui/core/styles/shape';

import spacing from '../spacing';

export default ({typography, palette}: {typography: Typography; palette: Palette}): StyleRules => ({
  '@global': {
    body: {
      // because google translate adds top 40px to body
      top: '0 !important',
      fontSize: typography.body1.fontSize,
      overflowX: 'hidden',
      backgroundColor: 'inherit',
    },
    '.m-1': {
      margin: spacing(1),
    },
    '.my-1': {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
    '.my-2': {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
    '.mb-0-5': {
      marginBottom: spacing(0.5),
    },
    '.mt-1': {
      marginTop: spacing(1),
    },
    '.mt-2': {
      marginTop: spacing(2),
    },
    '.mt-3': {
      marginTop: spacing(3),
    },
    '.mb-1': {
      marginBottom: spacing(1),
    },
    '.mb-2': {
      marginBottom: spacing(2),
    },
    '.mb-3': {
      marginBottom: spacing(3),
    },
    '.mb-4': {
      marginBottom: spacing(4),
    },
    '.mb-5': {
      marginBottom: spacing(5),
    },
    '.mr-1': {
      marginRight: spacing(1),
    },
    '.mr-2': {
      marginRight: spacing(2),
    },
    '.mr-3': {
      marginRight: spacing(3),
    },
    '.ml-1': {
      marginLeft: spacing(1),
    },
    '.ml-2': {
      marginLeft: spacing(2),
    },
    '.ml-3': {
      marginLeft: spacing(3),
    },
    '.p-0': {
      padding: '0!important;',
    },
    '.p-1': {
      padding: spacing(1),
    },
    '.p-1-5': {
      padding: spacing(1.5),
    },
    '.p-2': {
      padding: spacing(2),
    },
    '.p-2-5': {
      padding: spacing(2.5),
    },
    '.px-0': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '.px-0-5': {
      paddingLeft: spacing(0.5),
      paddingRight: spacing(0.5),
    },
    '.px-1': {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
    '.px-2': {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    '.px-3': {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
    '.py-1': {
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
    },
    '.py-2': {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    },
    '.py-3': {
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
    },
    '.pr-1': {
      paddingRight: spacing(1),
    },
    '.pr-3': {
      paddingRight: spacing(3),
    },
    '.pl-1': {
      paddingLeft: spacing(1),
    },
    '.pl-2': {
      paddingLeft: spacing(2),
    },
    '.pb-0': {
      paddingBottom: '0!important',
    },
    '.pb-1': {
      paddingBottom: spacing(1),
    },
    '.pb-2': {
      paddingBottom: spacing(2),
    },
    '.pt-0': {
      paddingTop: '0',
    },
    '.pt-1': {
      paddingTop: spacing(1),
    },
    '.pt-2': {
      paddingTop: spacing(2),
    },
    '.pt-3': {
      paddingTop: spacing(3),
    },
    '.pt-4': {
      paddingTop: spacing(4),
    },
    '.pr-2': {
      paddingRight: spacing(2),
    },

    '.paper': {
      background: palette.background.paper,
      boxShadow: shadows[1],
      borderRadius: shape.borderRadius,
      overflow: 'hidden',
    },
    '.border-round': {borderRadius: 999},
    '.bg-primary': {
      background: palette.primary.main,
    },

    '.bg-white': {
      background: palette.background.paper,
    },
    '.bg-success': {
      background: palette.success.light,
    },
    '.text-primary': {
      color: palette.primary.main,
    },
    '.text-success': {
      color: palette.success.main,
    },
    '.text-success-dark': {
      color: palette.success.main,
    },
    '.text-default': {
      color: palette.text.primary,
    },
    '.text-secondary': {
      color: palette.text.secondary,
    },
    '.text-white': {
      color: palette.common.white,
    },
    '.text-link': {
      color: '#156DD1',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    'h1, .h1': {
      ...typography.h1,
    },
    'h2, .h2': {
      ...typography.h2,
    },
    'h3, .h3': {
      ...typography.h3,
    },
    'h4, .h4': {
      ...typography.h4,
    },
    '.body1': {
      ...typography.body1,
    },
    '.body2': {
      ...typography.body2,
    },
    '.divider-top': {
      borderTop: `1px solid ${palette.divider}`,
    },
    '.divider-bottom': {
      borderBottom: `1px solid ${palette.divider}`,
    },
    '.divider-bold-bottom': {
      borderBottom: `5px solid ${palette.divider}`,
    },
    '.divider-right': {
      borderRight: `1px solid ${palette.divider}`,
    },
    '.list-item': {
      padding: spacing(1.25, 1, 1.25, 2),

      '&.selectable': {
        '& * ': {
          pointerEvents: 'none',
        },
      },

      '&.primary': {
        '&.selected': {},
      },
    },
  },
});
