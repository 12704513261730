import {TAppState} from '~/types/appTypes';
import {AppStatus} from '~/constants/app';
import config from '~/constants/config';

import {isProfileClientSelector, isProfileEscortSelector} from '../../CurrentUser/store/selectors';

export const appInitedSelector = (state: TAppState) => state.app.status === AppStatus.initialized;

export const appStatusSelector = (state: TAppState) => state.app.status;

export const getReferenceByUrlSelector = (state: TAppState, url: string) => {
  const {shemalePages} = state.app;
  if (!shemalePages) {
    return null;
  }
  return shemalePages.find((page) => page.url === url);
};

export const isStandaloneModeSelector = (state: TAppState) => state.app.isStandalone;

export const isVideoPlaySelector = (state: TAppState) => state.app.playVideo;

export const isFileUploadOpenSelector = (state: TAppState) => state.app.isFileUploadOpen;

export const languagesListSelector = (state: TAppState) => state.app.languages;

export const serviceWorkerSelector = (state: TAppState) => state.app.serviceWorker;

// TODO: move to feature flag service in github
export const isChatEnabledSelector = () => {
  return Boolean(config.features.chat);
};

export const isPaymentByCardAvailableSelector = (state: TAppState) => {
  const isEscort = isProfileEscortSelector(state);
  const isClient = isProfileClientSelector(state);

  const isCCPaymentOn = Boolean(config.features.payment);
  const isCCPaymentForEscortsOn = Boolean(config.features.paymentForEscorts);
  const isCCPaymentForClientsOn = Boolean(config.features.paymentForClients);

  return (
    ((isClient && isCCPaymentForClientsOn) || (isEscort && isCCPaymentForEscortsOn)) &&
    isCCPaymentOn
  );
};

export const isGoogleTranslateAvailableSelector = () => {
  return Boolean(config.features.translate);
};

export const isNotificationToken = (state: TAppState) => Boolean(state.app.notifications.token);

export const getChatEasyFromSource = (state: TAppState) => state.app.chatEasyStartFrom;

export const userFingerprintSelector = (state: TAppState) => state.app.fingerprint;

export const appStatusIsOnlineSelector = (state: TAppState) => state.app.isOnline;

export const profileFieldsSelector = (state: TAppState) => state.app.profileFields;

export const navigatorPermissionsSelector = (state: TAppState) => state.app.navigator;
