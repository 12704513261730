import {useEffect} from 'react';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {userIpGeoLocationSelector} from '~/modules/CurrentUser/store/selectors';
import {navigateAction} from '~/actions/navigateActions';
import useQuery from '~/customHooks/useQuery';
import {filterProfileTypeSelector} from '~/modules/Profiles/store/selectors';
import {
  getLocationLink,
  leafLocationsSelector,
  userLocationSelector,
} from '~/modules/Locations/store/selectors';

import AllLocations from './components/AllLocations';
import TopBarHome from './components/TopBarHome';

const Home: React.FC = () => {
  const userLocation = useSelector(userLocationSelector);
  const {id} = useSelector(userIpGeoLocationSelector);
  const leafLocations = useSelector(leafLocationsSelector);
  const detectedLocationByIp = leafLocations[id] || null;
  const filterProfileType = useSelector(filterProfileTypeSelector);

  const {testNoLocation}: {testNoLocation?: boolean} = useQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if ((userLocation || detectedLocationByIp?.active_provider) && !testNoLocation) {
      dispatch(
        navigateAction(userLocation || getLocationLink(detectedLocationByIp, filterProfileType))
      );
    }
  });

  return (
    <>
      <TopBarHome />
      <AllLocations />
    </>
  );
};

export default Home;
