import {TThunkAction} from '~/types/appTypes';
import {modalShowAction} from '~/rootStore/modals/modalsActions';
import {MODAL_PWA_BANNER_SMALL} from '~/rootStore/modals/modalsIds';
import localStorageWrapper from '~/utils/localStorageWrapper';
import {PWA_BANNER_CANCEL_COUNT} from '~/constants/localStorage';

type TOpenPwaModalAction = () => TThunkAction;
// eslint-disable-next-line import/prefer-default-export
export const openSmallPwaModalAction: TOpenPwaModalAction = () => async (dispatch) => {
  const totalModalsCancelCount = parseInt(
    localStorageWrapper.getItem(PWA_BANNER_CANCEL_COUNT) || '0',
    10
  );

  if (totalModalsCancelCount < 2) {
    dispatch(modalShowAction(MODAL_PWA_BANNER_SMALL));
  }
};
