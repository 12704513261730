import {AxiosPromise} from 'axios';
import {
  Session,
  TConfig,
  TDialog,
  TGetDialogHistoryParams,
  TRawPayload,
  DialogId,
  TUser,
} from '@yeobill/chat/lib/types';
import {Chat, Messages, Dialogs, Users} from '@yeobill/react-chat';
import {Dialogs as ChatDialogs} from '@yeobill/chat';

import {ChatAxiosInstance} from '~/utils/axios';
import RequestError from '~/utils/RequestError';
import config from '~/constants/config';
import {chatTypes, externalChatTypeToInternal} from '~/constants/chats';
import logger, {sentryHandler} from '~/utils/logger';
import localStorageWrapper from '~/utils/localStorageWrapper';
import {NotificationKeys} from '~/constants/app';
import {TChatUser, TChatSession} from '~/types/Chat';

import * as guestCookieSession from './guestCookieSession';
import chatUserTransformer from './transformers/chatUserTransformer';
import AuthService from '../Auth/AuthService';

// @ts-expect-error Type '"metric"' is not assignable to type 'TLogLevel'.
Chat.addErrorListener(sentryHandler);

const log = logger.module('ChatService');

const ChatsService = {
  findUserByLogin(login: string | number): Promise<TChatUser[]> {
    return Users.findByLogin(String(login)).then((user) => {
      if (!user) {
        return [];
      }

      return [chatUserTransformer(user)];
    });
  },
  findUserByName(name: string): Promise<TChatUser[]> {
    return Users.findByName(name).then((user) => {
      if (!user) {
        return [];
      }

      return [chatUserTransformer(user)];
    });
  },
  findUsers(searchString: string): Promise<TChatUser[]> {
    return Users.searchUsersByQuery(searchString).then((users) => {
      // @ts-expect-error TS2339: Property 'map' does not exist on type 'withList '.
      return users.map((user) => chatUserTransformer(user));
    });
  },
  findUsersByName(name: string): Promise<TChatUser[]> {
    return Users.findUsersByFilter({
      // @ts-expect-error TODO: `field:` not in params
      field: 'full_name',
      param: 'eq',
      value: name,
    }).then((users) => {
      if (!users || !users.length) {
        return [];
      }

      return users.map((user) => chatUserTransformer(user));
    });
  },
  findUsersByIds(dialogIds: number[]): Promise<TUser[]> {
    return Users.findUsersByIds(dialogIds);
  },

  async loadGuestSession({fingerprint}: {fingerprint: string}): Promise<Session> {
    const guestCookie = guestCookieSession.get();

    const data = {} as {fingerprint?: string; guestCookie?: string};

    if (guestCookie) {
      data.guestCookie = guestCookie;
    }

    if (fingerprint) {
      data.fingerprint = fingerprint;
    }

    log.info('Chat: Auth as guest in chat', {data});
    return ChatAxiosInstance.post('/authByGuestCookie', data)
      .then(guestCookieSession.handleFromFetch)
      .then(({session}) => session)
      .catch((error) => {
        log.error('Chat Error: Fail auth as guest in chat ', {error});
        if (
          error.response &&
          error.response.status === 403 &&
          error.response.data.errorCode === 'INVALID_SESSION'
        ) {
          guestCookieSession.remove();
          // return this.getSession({guest: true});
        }

        throw error;
      });
  },

  async loadUserSession({fingerprint}: {fingerprint: string}): Promise<Session> {
    const token = AuthService.getClearToken();
    const guestCookie = guestCookieSession.get();

    const data = {
      token,
    } as {fingerprint?: string; guestCookie?: string; token: string};

    if (guestCookie) {
      data.guestCookie = guestCookie;
    }

    if (fingerprint) {
      data.fingerprint = fingerprint;
    }

    return ChatAxiosInstance.post('/', data)
      .then(({session}) => session)
      .then((session) => {
        // remove cookie after merging account
        if (data.guestCookie) {
          guestCookieSession.remove();
        }

        return session;
      });
  },

  async getSession({
    guest = false,
    fingerprint = '',
  }: {
    guest?: boolean;
    fingerprint: string;
  }): Promise<Session> {
    if (!guest && !AuthService.hasToken()) {
      throw new RequestError({message: 'Auth is required'});
    }

    if (guest) {
      return this.loadGuestSession({fingerprint});
    }

    return this.loadUserSession({fingerprint});
  },

  async initChat({
    guest = false,
    fingerprint = '',
  }: {
    guest?: boolean;
    fingerprint?: string;
  }): Promise<unknown> {
    // TODO: add check for current state
    if (!guest && !AuthService.hasToken()) {
      log.error('user has no token');
      return false;
    }

    log.info('Init chat', {guest, fingerprint});
    const session = await this.getSession({guest, fingerprint});

    log.info('Session received', {session});
    const qbConfig: TConfig = {
      debug: config.chat.debug,
      qbDebug: config.chat.debugQB,
      sessionExpiredHandler: () => {
        log.info('Session expired', {guest, fingerprint});
        return this.getSession({guest, fingerprint});
      },
    };

    if (config.chat.qbApiHost && config.chat.qbChatHost) {
      qbConfig.endpoints = {
        api: config.chat.qbApiHost,
        chat: config.chat.qbChatHost,
      };
    }

    if (config.chat.qbWsUrl) {
      qbConfig.chatProtocol = {
        websocket: config.chat.qbWsUrl,
      };
    }

    log.info('Start init QB chat', {qbConfig, session});
    await Chat.initChat({
      appId: config.chat.appId,
      authKey: config.chat.key,
      authSecret: config.chat.secret,
      session,
      redEndpoint: config.chat.serviceUrl,
      qbConfig: {...qbConfig},
    });

    this.loadChatList();
    return session;
  },

  loadChatList(): Promise<TDialog[]> {
    return Dialogs.get().then(({items}) => items);
  },

  createChat(chatUserId: number): Promise<TDialog> {
    return Dialogs.create(chatUserId);
  },

  getLoadedChat(id: DialogId): TDialog | undefined {
    return Dialogs.getLoadedDialog(id);
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getChatMessages(params: TGetDialogHistoryParams) {
    try {
      return Dialogs.getHistory(params).then(({items}) => items);
    } catch (error) {
      log.error('getChatMessages error', error);
    }
    return [];
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sendMessage(chatId: string, messageData: TRawPayload) {
    return Messages.sendMessage(chatId, messageData);
  },

  async getChatByUserId(chatUserId: number): Promise<TDialog | void> {
    if (!chatUserId) {
      return undefined;
    }

    const chatsArray = Object.values(ChatDialogs.All$.getState());

    return chatsArray.find(({type, occupants_ids: occupantsIds}) =>
      Boolean(
        externalChatTypeToInternal[type] === chatTypes.private && occupantsIds.includes(chatUserId)
      )
    );
  },

  async removeDialog(dialogId: string): Promise<void> {
    const result = await Dialogs.remove([dialogId]).then();
    log.info('remove dialog', {result});
    return result;
  },

  updatePushSubscription(userId: string, phone: string, subscription: string | void): void {
    const currentSubscription = localStorageWrapper.getItem(NotificationKeys.STORAGE_SUBSCRIPTION);
    if (subscription && JSON.stringify(subscription) !== currentSubscription) {
      this.deletePushSubscription(userId);
      localStorageWrapper.setItem(
        NotificationKeys.STORAGE_SUBSCRIPTION,
        JSON.stringify(subscription)
      );
    }
    ChatAxiosInstance.post('/notifications/subscription', {
      userId,
      phone,
      subscription,
    }).catch((error) => {
      log.error('Error update FCM subscription ', error);
    });
  },

  deletePushSubscription(userId: string): void {
    const subscriptionSerialized = localStorageWrapper.getItem(
      NotificationKeys.STORAGE_SUBSCRIPTION
    );
    if (!subscriptionSerialized && subscriptionSerialized !== 'undefined') {
      return;
    }

    let subscription = null;
    try {
      subscription = JSON.parse(subscriptionSerialized);
    } catch (err) {
      log.warn('Subscription parse error', {subscriptionSerialized});
    }

    if (subscription) {
      localStorageWrapper.removeItem(NotificationKeys.STORAGE_SUBSCRIPTION);
      ChatAxiosInstance.post('/notifications/subscription-delete', {
        userId,
        subscription,
      }).catch((error) => {
        log.error('Error delete FCM subscription ', error);
      });
    }
  },

  deleteAllPushSubscription(userId: string): void {
    localStorageWrapper.removeItem(NotificationKeys.STORAGE_SUBSCRIPTION);
    ChatAxiosInstance.post('/notifications/subscription-delete', {
      userId,
    }).catch((error) => {
      log.error('Error delete FCM subscription ', error);
    });
  },

  notificationEventSend(message: Record<string, unknown>): void {
    ChatAxiosInstance.post('/notifications/message-send', {
      message,
      messageId: message.messageId,
    }).catch((error) => {
      log.error('Error notification message send event ', error);
    });
  },

  notificationEventRead(message: Record<string, unknown>, userId: number): void {
    ChatAxiosInstance.post('/notifications/message-read/', {
      message,
      messageId: message.id,
      userId, // TODO: remove after deploy red with message received event
    }).catch((error) => {
      log.error('Error notification message read event ', error);
    });
  },

  notificationEventReceived(message: Record<string, unknown>, userId: number): void {
    ChatAxiosInstance.post('/notifications/message-recieved/', {
      message,
      messageId: message.id,
      userId, // TODO: remove after deploy red with message received event
    }).catch((error) => {
      log.error('Error notification message received event ', error);
    });
  },

  sendStatus(payload: {
    role: 'escort' | 'client' | string;
    userId: number;
    session: TChatSession;
  }): AxiosPromise<unknown> {
    return ChatAxiosInstance.post('/me/onlineStatus', payload);
  },

  logout(qbUserId: number): void {
    this.deletePushSubscription(String(qbUserId));
    Chat.logout();
  },
};

export default ChatsService;
