import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {TAppState} from '~/types/appTypes';
import {removeNotificationAction} from '~/rootStore/notifications/notificationsReducer';

// eslint-disable-next-line import/prefer-default-export
export const Notifier: React.FC = () => {
  const [displayed, setDisplayed] = useState<number[]>([]);

  const {notifications} = useSelector((state: TAppState) => state.notifications);

  const dispatch = useDispatch();

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    notifications.map((notification) => {
      setTimeout(() => {
        // If notification already displayed, abort
        if (displayed.filter((key) => key === notification.key).length > 0) {
          return;
        }
        enqueueSnackbar(notification.message, notification.options);
        setDisplayed([...displayed, notification.key]);
        dispatch(removeNotificationAction(notification.key));
      }, 300);
    });
  }, [notifications, displayed, enqueueSnackbar, dispatch]);

  return null;
};
