"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBlackList = void 0;
var react_1 = require("react");
var chat_1 = require("@yeobill/chat");
exports.useBlackList = function () {
    var _a = react_1.useState(false), loading = _a[0], setLoading = _a[1];
    var addUsers = react_1.useCallback(function (items) {
        setLoading(true);
        chat_1.BlackList.addUsers(items).finally(function () {
            setLoading(false);
        });
    }, []);
    var removeUsers = react_1.useCallback(function (items) {
        setLoading(true);
        chat_1.BlackList.removeUsers(items).finally(function () {
            setLoading(false);
        });
    }, []);
    return [addUsers, removeUsers, loading];
};
