"use strict";
/**
 * @packageDocumentation @module Dialogs
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadDialogsUnreadMessages = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To get unread messages count by dialogs.
 */
function loadDialogsUnreadMessages(dialogIds) {
    return chat_1.Dialogs.loadDialogsUnreadMessages(dialogIds);
}
exports.loadDialogsUnreadMessages = loadDialogsUnreadMessages;
