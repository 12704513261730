import * as React from 'react';
import {useDispatch} from 'react-redux';

import {updateAppOnlineStatusAction} from '~/modules/App/store/actions/updateAppOnlineStatusAction';

// eslint-disable-next-line import/prefer-default-export
export const AppOnlineStatusWatcher: React.FC = () => {
  const dispatch = useDispatch();
  const updateAppStatus = React.useCallback(() => {
    dispatch(updateAppOnlineStatusAction(window.navigator.onLine));
  }, [dispatch]);

  React.useEffect(() => {
    window.addEventListener('online', updateAppStatus);
    window.addEventListener('offline', updateAppStatus);

    return () => {
      window.removeEventListener('online', updateAppStatus);
      window.removeEventListener('offline', updateAppStatus);
    };
  }, [updateAppStatus]);

  return null;
};
