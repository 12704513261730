import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Typography} from '@material-ui/core';

import phonesImages from './images/phones.png';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.grey[100],
      textAlign: 'center',
      padding: theme.spacing(2.5, 2, 2),
      '@media (min-width: 1023px)': {
        padding: '34px 52px 0',
        textAlign: 'left',
        display: 'flex',
      },
    },
    heading: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
      fontWeight: 700,
    },
    subheading: {fontWeight: 600},
    phones: {
      flexShrink: 0,
      width: '100%',
      maxWidth: 507,
      margin: '0 auto',
    },
    phonesImage: {
      width: '100%',
    },
    content: {
      '@media (min-width: 1023px)': {
        paddingRight: 40,
      },
    },
  }),
  {name: 'AboutSection'}
);

const AboutSection = () => {
  const s = useStyles();

  return (
    <div className={s.root}>
      <div className={s.content}>
        <Typography variant="h1" className={s.heading}>
          Bendr Trans Dating
        </Typography>
        <p>
          Welcome to Bendr, an online community of transgender woman and trans-interested men
          looking to connect. We bring amazing people together in a safe and inclusive environment
          that lets you be your authentic self. It’s easy to use and simple to get started, so if
          you’re ready to meet interesting people nearby, sign up today.
        </p>
        <Typography variant="h2" className={s.subheading}>
          Find Transgender Women & Trans-Attracted Men
        </Typography>
        <p>
          For too long, the trans community has lacked accessible ways of seeking out a soulmate.
          Traditional dating apps are created too binarily and don’t foster an attitude of respect
          or understanding. Similarly, meeting people IRL comes with its own challenges that just
          make it daunting to put yourself out there. Where do you even begin to find the one you’re
          looking for?
        </p>
        <p>
          Bendr was designed to break down all of these social barriers. By bringing together
          transgender woman and trans-attracted men in a platform designed around inclusivity, we’re
          creating a community of likeminded individuals who are open to meeting new people. It’s
          fun, effective, and made just for you.
        </p>
      </div>
      <div className={s.phones}>
        <img className={s.phonesImage} src={phonesImages} alt="phones" />
      </div>
    </div>
  );
};

export default AboutSection;
