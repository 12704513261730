import {profileTypeNames} from '~/constants/profiles';
import logger from '~/utils/logger';
import {TThunkAction} from '~/types/appTypes';

import {currentProfileSelector} from '../../../CurrentUser/store/selectors';
import ChatsService from '../../ChatsService';
import {chatSessionSelector} from '../selectors';

const log = logger.module('updateChatOnlineStatus');

type TUpdateChatOnlineStatus = () => TThunkAction;
// eslint-disable-next-line import/prefer-default-export
export const updateChatOnlineStatus: TUpdateChatOnlineStatus = () => async (dispatch, getState) => {
  const profile = currentProfileSelector(getState());
  const chatSession = chatSessionSelector(getState());
  const role =
    // TODO: could there be no profile here?
    profile ? profileTypeNames[profile.type] : profileTypeNames.ANONYMOUS;
  if (!chatSession) {
    log.info('chat session not exist', {role, chatSession});
    return false;
  }

  const payload = {
    role,
    userId: chatSession.user_id,
    session: chatSession,
  };

  try {
    await ChatsService.sendStatus(payload);
    return true;
  } catch (error) {
    log.info('Error during update online status', {role, chatSession});
    return false;
  }
};
