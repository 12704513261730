import React from 'react';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';

import {TAppState} from '~/types/appTypes';
import config from '~/constants/config';

const Seo: React.FC = () => {
  const {title, description, keywords} = useSelector((state: TAppState) => state.layout) || {
    title: config.app.name,
    description: config.app.description,
    keywords: config.app.keywords,
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="keywords" content={keywords} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${window.location.origin}/img/pwa/icon-400x400.png`} />
      <meta property="og:url" content={window.location.origin} />
    </Helmet>
  );
};

export default Seo;
