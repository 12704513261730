import {CHATS_SET_SESSION, CHATS_SET_LIST, CHATS_SET_OPPONENT_PROFILE} from './constants';

const initialState = {
  session: null,
  list: [],
  chatToMessages: {},
  chatOpponentProfile: null,
};

const chatsReducer = (state = {...initialState}, {type, payload}) => {
  switch (type) {
    case CHATS_SET_SESSION:
      return {...state, session: payload};
    case CHATS_SET_LIST:
      return {...state, list: payload};
    case CHATS_SET_OPPONENT_PROFILE:
      return {...state, chatOpponentProfile: payload};
    default:
      return state;
  }
};

export default chatsReducer;
