"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var effector_1 = require("effector");
var QBAdapter_1 = require("../QBAdapter");
var messages_1 = require("../messages");
var pick_1 = require("../utils/pick");
var array2map_1 = require("../utils/array2map");
var addAttachmentLink = function (token) { return function (_a) {
    var attachments = _a.attachments, rest = __rest(_a, ["attachments"]);
    return (__assign(__assign({}, rest), { attachments: attachments.map(messages_1.withAttachmentLink(token)) }));
}; };
exports.default = (function (Messages$, MessagesOrder$, getSession) {
    var getHistory = effector_1.createEffect().use(QBAdapter_1.getDialogHistory);
    MessagesOrder$.on(getHistory.done, function (state, _a) {
        var _b;
        var _c = _a.params, dialogId = _c.dialogId, append = _c.append, items = _a.result.items;
        var newItems = items.map(pick_1.pick('_id'));
        if (append && state[dialogId]) {
            newItems = state[dialogId]
                .filter(function (item) { return !newItems.includes(item); })
                .concat(newItems);
        }
        return __assign(__assign({}, state), (_b = {}, _b[dialogId] = newItems, _b));
    });
    Messages$.on(getHistory.done, function (state, payload) {
        var _a = getSession().token, token = _a === void 0 ? '' : _a;
        return __assign(__assign({}, state), array2map_1.array2MapWithTransform(payload.result.items, '_id', addAttachmentLink(token)));
    });
    return getHistory;
});
