import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';

import {
  isProfileCompletedSelector,
  isProfileExistsSelector,
  isProfilePhoneVerifiedSelector,
} from '../../store/selectors';
import CompleteProfile from './ProfileActions/CompleteProfile';
import CreateProfile from './ProfileActions/CreateProfile';

const useStyles = makeStyles((theme) => ({
  Button: {
    '&, &:hover': {
      color: theme.palette.primary.main,
      padding: theme.spacing(1.25),
      backgroundColor: theme.palette.common.white,
      borderWidth: 0,
      borderRadius: 99,
      fontSize: 18,
      fontWeight: 600,
      boxShadow: theme.shadows[1],
      letterSpacing: 0.2,
      width: '100%',
    },
  },
}));

const ProfileActions: React.FC = () => {
  const s = useStyles();
  const isProfileExists = useSelector(isProfileExistsSelector);
  const isProfileVerified = useSelector(isProfilePhoneVerifiedSelector);
  const isProfileCompleted = useSelector(isProfileCompletedSelector);
  const profileTotallyCompleted = isProfileExists && isProfileCompleted && isProfileVerified;
  if (profileTotallyCompleted) return null;
  return (
    <Box width="100%">
      {!isProfileExists ? (
        <CreateProfile className={s.Button} />
      ) : (
        <CompleteProfile className={s.Button} />
      )}
    </Box>
  );
};

export default ProfileActions;
