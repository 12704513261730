import {sortBy} from 'lodash-es';

const isProfileDummyRegExp = new RegExp(/^dummy-[0-9a-zA-Z]*$/);
const booleanProfileValues = [
  'bdsm',
  'gfe',
  'massage',
  'incall',
  'outcall',
  'men',
  'women',
  'couples',
  'tstvtg',
  'groups',
];

const videoTransformer = (videos) => {
  if (!videos.length) {
    return undefined;
  }

  const {video_id: videoId, thumbnail_href: thumbnailHref, ...video} = videos[0];
  return {
    ...video,
    id: videoId,
    thumbnailHref,
  };
};

const videosTransformer = (videos) => {
  if (!videos) {
    return [];
  }
  if (Array.isArray(videos)) {
    return videos;
  }
  return videos.split(',');
};

const roleTransformer = (id, roles) => {
  if (!id || !roles) {
    return '';
  }
  const role = roles.find((entry) => parseInt(entry.value, 10) === id);
  return role ? role.label : '';
};

const toInt = ['hips_cm', 'hips_inch', 'bust_cm', 'bust_inch', 'waist_cm', 'waist_inch'];

const measurementsTransformer = (profile) =>
  toInt.reduce(
    (accum, field) => ({
      ...accum,
      [field]: Number.parseInt(profile[field], 10) || 0,
    }),
    {}
  );

export const profileFirstImageTransformer = (profile) => {
  if (!profile) {
    return null;
  }

  function getImageWeight(image) {
    if (image.is_thumbnail) {
      return 0;
    }
    if (image.naked === 'nakedFace') {
      return 1;
    }
    if (image.naked === 'nakedNo') {
      return 2;
    }

    return 3;
  }
  const thumbnail = profile.images.find((img) => img.is_thumbnail);
  const assignWeights = (image) => ({...image, weight: getImageWeight(image)});
  const resultImages = sortBy(profile.images.map(assignWeights), ['weight', 'image_id']);
  return {...profile, images: resultImages, thumbnail};
};

const shuffleVipProfiles = (profiles) => {
  const vipProfiles = profiles.filter(({isVip}) => isVip);
  const simpleProfiles = profiles.filter(({isVip}) => !isVip);
  for (let i = vipProfiles.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * i);
    const temp = vipProfiles[i];
    vipProfiles[i] = vipProfiles[j];
    vipProfiles[j] = temp;
  }

  return [...vipProfiles, ...simpleProfiles];
};

export const profileResponseTransformer = (profile) => {
  const {
    id,
    profile_id,
    name,
    languages,
    videos,
    phone,
    type,
    is_verified: isVerified,
    is_verification_pending: isVerificationPending,
    is_verification_id_require: isVerificationIdRequire,
    profile_url: profileUrl,
    lat,
    lng,
    address,
    ...restProfileProps
  } = profile;
  const transformedProfile = {
    ...restProfileProps,
    ...measurementsTransformer(restProfileProps),
    lat: Number.parseFloat(lat) || null,
    lng: Number.parseFloat(lng) || null,
    id: id ?? profile_id,
    phone: isProfileDummyRegExp.test(phone) ? '' : phone,
    aboutme: restProfileProps.aboutme || '', // For prevent warn from reach editor
    name: isProfileDummyRegExp.test(name) ? '' : name,
    languages: languages
      ? languages.split(',').map((value) => {
          if (typeof value !== 'string') {
            return value;
          }
          return value.trim();
        })
      : [],
    profileUrl,
    isCompleted: profile.completed,
    isPhoneVerified: Boolean(phone && !isProfileDummyRegExp.test(phone)),
    video: videoTransformer(videos),
    isVerified,
    isVerificationPending,
    isVerificationIdRequire,
    type,
  };

  let parsedAddress;

  try {
    parsedAddress = JSON.parse(address);
  } catch (error) {
    // Handle incorrect json format
  }

  transformedProfile.location = {
    ...(parsedAddress || {}),
    lat: Number.parseFloat(lat) || null,
    lng: Number.parseFloat(lng) || null,
  };

  booleanProfileValues.forEach((objectKey) => {
    transformedProfile[objectKey] = !!transformedProfile[objectKey];
  });

  return profileFirstImageTransformer(transformedProfile);
};

export const singleLocationProfileTransformer = (
  roles,
  {
    profile_id: id,
    role,
    circumcised,
    location_label: locationLabel,
    videos,
    videos_thumbnails,
    featured_in_location: featuredInLocation,
    ...profile
  }
) => ({
  ...profile,
  id,
  locationLabel,
  videos: videosTransformer(videos),
  videos_thumbnails: videosTransformer(videos_thumbnails),
  role,
  circumcised,
  circumcisedLabel: circumcised ? 'Yes' : 'No',
  roleLabel: roleTransformer(parseInt(role, 10), roles), // role could be string for some reason
  isVip: Boolean(featuredInLocation),
  distance: profile.distance,
});

export const profileListResponseTransformer = (profileList, roles) => {
  return profileList.map((profile) => singleLocationProfileTransformer(roles, profile));
};

export const locationProfilesResponseTransformer = (
  profiles,
  leafLocations,
  roles,
  currentLocationId
) => {
  if (!Array.isArray(profiles)) {
    return null;
  }
  const preparedProfiles = profileListResponseTransformer(profiles, roles);
  if (currentLocationId) {
    const currentLocation = Object.values(leafLocations).find(
      (location) => location.id === currentLocationId
    );
    if (currentLocation && !currentLocation.children) {
      return shuffleVipProfiles(
        preparedProfiles.sort(({isVip: isFirstVip}, {isVip: isSecondVip}) => {
          if (isFirstVip && isSecondVip) {
            return 0;
          }

          if (isFirstVip) {
            return -1;
          }

          return 1;
        })
      );
    }
  }
  return preparedProfiles;
};

export const verificationTransformer = ({
  id,
  video_path: videoPath,
  video_thumbnail_path: videoThumb,
}) => ({
  video: videoPath
    ? {
        id,
        href: videoPath,
        thumbnailHref: videoThumb,
      }
    : undefined,
});

export const reportProfileOptionsTransformer = (reportOptions) => {
  return Object.keys(reportOptions).reduce(
    (accum, option) => [...accum, {value: option, label: reportOptions[option]}],
    []
  );
};

export const fullProfileListResponseTransformer = (profileList, roles, offset) => {
  return profileList.map((profile, idx) => {
    return {
      ...profileResponseTransformer(singleLocationProfileTransformer(roles, profile)),
      realIndex: offset + idx,
    };
  });
};
