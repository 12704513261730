"use strict";
/**
 * @packageDocumentation @module Messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendDeliveredStatus = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To send delivered status message into the dialog.
 *
 */
var sendDeliveredStatus = function (params) {
    return chat_1.Messages.sendDeliveredStatus(params);
};
exports.sendDeliveredStatus = sendDeliveredStatus;
