import {ReactNode} from 'react';
import * as React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import ModalBase from '../../ModalBase';
import ModalBaseContent from '../../ModalBaseContent';

interface IosStyleConfirmProps {
  title: string | ReactNode;
  content: string | ReactNode;
  icon?: ReactNode;
  actionBtnText?: string;
  open: boolean;
  onClose: () => void;
  actionBtnHandler: () => void;
}

const IosStyleConfirm: React.FC<IosStyleConfirmProps> = ({
  title,
  content,
  onClose,
  open,
  actionBtnHandler,
  actionBtnText = 'OK',
  icon,
}) => (
  <ModalBase
    open={open}
    onClose={onClose}
    onButtonClick={onClose}
    showOkButton={false}
    buttons={[
      <Button key="cancel" variant="text" color="default" onClick={onClose}>
        <Box fontWeight="normal">Cancel</Box>
      </Button>,
      <Button key="action" variant="text" color="primary" onClick={actionBtnHandler}>
        <Box fontWeight="normal">{actionBtnText}</Box>
      </Button>,
    ]}
  >
    <ModalBaseContent
      // TODO: icon is actually img.src
      image={icon}
      title={title}
      description={content}
    />
  </ModalBase>
);

export default IosStyleConfirm;
