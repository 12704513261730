"use strict";
/**
 * @packageDocumentation @module Messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendReadStatus = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To send read status message into the dialog.
 *
 */
var sendReadStatus = function (params) {
    return chat_1.Messages.sendReadStatus(params);
};
exports.sendReadStatus = sendReadStatus;
