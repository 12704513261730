import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Typography} from '@material-ui/core';

import TypographyCustom from '~/components/ui/Typography';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.grey[100],
      textAlign: 'center',
      padding: theme.spacing(2.5, 2, 4),
    },
    wrapper: {
      maxWidth: 900,
      margin: '0 auto',
    },
    heading: {
      marginBottom: theme.spacing(2),
      fontSize: 32,
      fontWeight: 600,
    },
  }),
  {name: 'WhatDoesMeanSection'}
);

const WhatDoesMeanSection = () => {
  const s = useStyles();

  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <Typography variant="h1" className={s.heading}>
          What Does it Mean To Be Trans-Attracted?
        </Typography>
        <p>
          Trans-attracted refers to people who themselves do not identify as transgender, but are
          attracted to people that do. Being trans-attracted in no way affects your sexuality. For
          example, if you identify as a straight male, being attracted to a transgender woman
          doesn’t necessarily make you any less straight. On Bendr, we welcome trans-attracted men
          into our community. So long as everyone treats each other with mutual respect, everyone is
          entitled to meet their soulmate
        </p>
        <TypographyCustom heading variant="h2">
          Dating a Transgender Woman
        </TypographyCustom>
        <p>
          If you’re a trans attracted man looking to date a transgender woman, it’s important to
          treat the relationship just like you would any other. Trans women don’t want to feel like
          they’re outsiders or be treated any differently.
        </p>
        <p>
          Be respectful if you really want to establish a meaningful connection with someone
          quickly. Be transparent about your intentions and what you’re looking for. Be supportive
          of your partner and their journey. But most importantly, just be yourself! Let loose and
          let yourself have some fun.
        </p>
      </div>
    </div>
  );
};

export default WhatDoesMeanSection;
