import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Box, Typography} from '@material-ui/core';

import advicesList from './advicesList';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      textAlign: 'center',
      padding: theme.spacing(2.5, 2, 4),
    },
    wrapper: {
      maxWidth: 1110,
      margin: '0 auto',
    },
    heading: {
      marginBottom: theme.spacing(2),
      fontSize: 32,
      fontWeight: 600,
    },
    adviceNumber: {
      borderRadius: '100%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto 12px',
      fontWeight: 800,
      fontSize: 24,
      width: 47,
      height: 47,
    },
    adviceTitle: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: theme.spacing(1.75),
    },
    adviceDescription: {
      fontSize: 16,
    },
  }),
  {name: 'AdviceSection'}
);

const AdviceSection = () => {
  const s = useStyles();

  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <Typography variant="h1" className={s.heading}>
          Transgender Dating Tips & Transgender Dating Advice
        </Typography>
        <p>Here are some tips to help you get off to a good start.</p>
        {advicesList.map((advice, index) => (
          <Box key={advice.title} mt={4.5} display="flex" flexDirection="column">
            <div className={s.adviceNumber}>{index + 1}</div>
            <div className={s.adviceTitle}>{advice.title}</div>
            <div className={s.adviceDescription}>{advice.description}</div>
          </Box>
        ))}
      </div>
    </div>
  );
};

export default AdviceSection;
