import {useMemo} from 'react';

import useQuery from '~/customHooks/useQuery';

type TUtmParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
};

const getUtm = (query: TUtmParams) => {
  if (
    typeof query.utm_source === 'string' ||
    typeof query.utm_medium === 'string' ||
    typeof query.utm_campaign === 'string'
  ) {
    return {
      utm_source: document.referrer.includes('google') ? 'google' : query.utm_source,
      utm_medium: query.utm_medium,
      utm_campaign: query.utm_campaign,
    };
  }

  return null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUtmFromQuery = () => {
  const query = useQuery();

  return useMemo(() => getUtm(query), [query]);
};

export default useUtmFromQuery;
