import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import {Button} from '@material-ui/core';

import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import Typography from '~/components/ui/Typography';
import {TCurrentUserUser} from '~/types/CurrentUserState';
import CreateProfile from '~/modules/CurrentUser/Settings/components/ProfileActions/CreateProfile';
import SettingsListItem from '~/modules/CurrentUser/Settings/components/SettingsListItem';
import ViewProfileCardDrawer from '~/modules/CurrentUser/Settings/components/ViewProfileCard/ViewProfileCardDrawer';
import ButtonWarnings from '~/modules/CurrentUser/Settings/components/ButtonWarnings';

import {drawerInformationSection} from '../constants';
import {TSettingsItem} from '../types';
import BuildInfo from './BuildInfo';
import DrawerProfileLinks from './DrawerProfileLinks';
import DrawerProfileActions from './DrawerProfileActions';

const useStyles = makeStyles((theme) => ({
  typographyRoot: {
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(3),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(3),
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '22px 19px 9px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  divider: {
    margin: theme.spacing(1, 0),
    backgroundColor: '#F2F2F2',
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  ListItem: {
    padding: theme.spacing(1.5, 1, 1.5, 1.5),
  },
  drawerProfileLinks: {
    marginTop: theme.spacing(-1),
  },
}));

interface Props {
  linksList: TSettingsItem[];
  handleOpenPWAModal: () => void;
  isUserPhoneVerified: boolean;
  layoutCloseDrawer: () => void;
  openFeedbackModal: () => void;
  user: TCurrentUserUser | null;
}

const RightDrawerComponent: React.FC<Props> = ({
  handleOpenPWAModal,
  isUserPhoneVerified,
  layoutCloseDrawer,
  openFeedbackModal,
  user,
  linksList,
}) => {
  const s = useStyles();
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={layoutCloseDrawer}>
        <Box color="white" className={s.drawerHeader}>
          <Box flex={1}>
            <ViewProfileCardDrawer />
          </Box>
          <Button variant="text" fullWidth={false}>
            <SpriteIcon color="white" name="cross_thin" />
          </Button>
        </Box>
        <DrawerProfileActions />
        <ButtonWarnings />
        <List dense component="nav">
          <List disablePadding>
            {isUserPhoneVerified && user && !user.profileId && (
              <CreateProfile
                component={
                  <SettingsListItem
                    label="Create profile"
                    icon="profile"
                    color="black"
                    onClick={layoutCloseDrawer}
                  />
                }
              />
            )}
            {linksList.map((item) => {
              const {component: Component} = item;
              if (Component) {
                return (
                  <React.Fragment key={item.label}>
                    <Component
                      DefaultComponent={SettingsListItem}
                      item={item}
                      background={false}
                      divider={false}
                    />
                    <Divider className={s.marginLeft} />
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={item.label}>
                  <SettingsListItem background={false} {...item} color="black" />
                  <Divider className={s.marginLeft} />
                </React.Fragment>
              );
            })}
          </List>
        </List>

        <List dense component="nav">
          {drawerInformationSection.map((listItem) => (
            <SettingsListItem
              {...listItem}
              key={listItem.to}
              route={listItem.to}
              className={s.paddingLeft}
              background={false}
              next={false}
            />
          ))}
          <SettingsListItem
            label="Add to home screen"
            className={s.paddingLeft}
            background={false}
            onClick={handleOpenPWAModal}
            next={false}
          />
        </List>
        <Typography
          classes={{
            root: s.typographyRoot,
          }}
          variant="h4"
          color="primary"
        >
          Care to help?
        </Typography>
        <List dense component="nav">
          <SettingsListItem
            next={false}
            className={s.paddingLeft}
            background={false}
            label="Send Feedback"
            onClick={() => {
              openFeedbackModal();
            }}
          />
        </List>
        <Divider className={s.divider} />
      </div>
      <BuildInfo />
      {user?.profileId && <DrawerProfileLinks />}
    </>
  );
};

export default RightDrawerComponent;
