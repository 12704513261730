import {TLeafLocation} from '~/types/LeafLocation';

import {TLocationType} from './constants/LocationTypes';

export const isState = (location: TLeafLocation) => {
  return location.type === TLocationType.State;
};

export const isCity = (location: TLeafLocation) => {
  return location.type === TLocationType.City;
};

export const isBorough = (location: TLeafLocation) => {
  return location.type === TLocationType.Borough;
};

export const isCountry = (location: TLeafLocation) => {
  return location.type === TLocationType.Country;
};
