export enum TProfileType {
  TRANS = 0,
  MALE = 1,
}

export enum TProfileTypeUrl {
  MALE_URL = 'dates',
  TRANS_URL = 'trans-dating',
}

export const DEFAULT_PROFILE_TYPE = TProfileType.TRANS;

export type TProfileMeta = {
  label: string;
  type: number;
  describe?: string;
  url: TProfileTypeUrl;
  icon: 'icon-male' | 'icon-trans'; // | 'icon-female' |'icon-gay';
};

export const profiles = {
  [TProfileType.MALE]: {
    label: 'Male',
    type: TProfileType.MALE,
    url: TProfileTypeUrl.MALE_URL,
    icon: 'icon-male',
    describe: '',
    background: 'linear-gradient(90deg, #009EF7, #59D6FD)',
  } as const,
  [TProfileType.TRANS]: {
    icon: 'icon-trans',
    label: 'Trans',
    type: TProfileType.TRANS,
    url: TProfileTypeUrl.TRANS_URL,
    describe: '',
    background: 'linear-gradient(90deg, #FE6A97, #FD8359)',
  } as const,
};

export const profileTypes: Record<'TRANS' | 'MALE' | 'client' | 'escort', TProfileType> = {
  TRANS: TProfileType.TRANS,
  MALE: TProfileType.MALE,
  client: TProfileType.MALE,
  escort: TProfileType.TRANS,
};

export const profileTypesFilter = [profiles[TProfileType.TRANS], profiles[TProfileType.MALE]];

export const profileTypeNames = {
  ANONYMOUS: 'anonymous',
  MALE: 'male',
  TRANS: 'trans',
  [`${TProfileType.TRANS}`]: 'trans',
  [`${TProfileType.MALE}`]: 'male',
};

export const profileTypeUrlToId: Record<TProfileTypeUrl, TProfileType> = {
  [TProfileTypeUrl.TRANS_URL]: TProfileType.TRANS,
  [TProfileTypeUrl.MALE_URL]: TProfileType.MALE,
};

// if u trans default search by male, because trans is not another trans looking for
const defaultFiltersForRole: Record<TProfileType, TProfileType> = {
  [TProfileType.TRANS]: TProfileType.MALE,
  [TProfileType.MALE]: TProfileType.TRANS,
};

export const defaultProfileTypeFilter = (needle: TProfileType | undefined): TProfileType => {
  const dFilter = needle !== undefined ? defaultFiltersForRole[needle] : undefined;
  return dFilter === undefined ? DEFAULT_PROFILE_TYPE : dFilter;
};

export const signUpProfiles = [profiles[TProfileType.TRANS], profiles[TProfileType.MALE]];

export const allProfileTypesRegexp = 'females|dates|gays|trans-dating|shemale-escorts';
