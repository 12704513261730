"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsUserBlocked = void 0;
var chat_1 = require("@yeobill/chat");
var effector_react_1 = require("effector-react");
var react_1 = require("react");
exports.useIsUserBlocked = function (userId) {
    var store = effector_react_1.useStore(chat_1.BlackList.BlackList$);
    return react_1.useMemo(function () {
        return chat_1.BlackList.isUserBlocked(userId);
    }, [store, userId]);
};
