import {useSelector} from 'react-redux';

import {TAppState} from '~/types/appTypes';
import loaderSelector from '~/rootStore/loaders/loaderSelector';

const LoaderState = {
  LOADING: true,
  LOADED: false,
};

interface TLoaderState {
  isLoading: boolean;
  isLoaded: boolean;
}

const useLoader = (loaderName: string): TLoaderState => {
  const loaderState = useSelector((state: TAppState) => {
    return loaderSelector(state, loaderName);
  });

  return {
    isLoading: loaderState === LoaderState.LOADING,
    isLoaded: loaderState === LoaderState.LOADED,
  };
};

export default useLoader;
