import {UPDATE_UTMS} from '~/modules/utms';
import {UPDATE_PROMOCODE} from '~/modules/promocode';

import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_AND_PROFILE,
  SET_CURRENT_USER_PROFILE,
  SET_CURRENT_USER_PROFILE_LOCATION,
  SET_CURRENT_USER_PROFILE_THUMBNAIL,
  SET_CURRENT_USER_PROFILE_IMAGES,
  SYNC_CURRENT_USER_PROFILE_CHAT_SETTINGS,
  SET_CURRENT_USER_FAVORITES,
  CURRENT_USER_REMOVE_FROM_FAVORITES,
  SET_CURRENT_USER_CREATE_PROFILE_STEP,
  SET_CURRENT_USER_PROFILE_STATS,
  USER_LOCATION_UPDATE,
  SHOW_UPGRADE_PAID_POPUP,
  HIDE_UPGRADE_PAID_POPUP,
  CLEAR_PROMOCODE,
  SYNC_PROFILE_REPORT_OPTIONS,
  SYNC_PROFILE_VERIFICATION,
  SET_TWO_FACTOR_DATA,
  RESET_TWO_FACTOR_DATA,
  SET_ATTEMPS_TWO_FACTOR,
  SET_VERIFICATION_METHOD_TWO_FACTOR,
  SET_USER_IP_GEO_LOCATION,
  SET_CURRENT_USER_PROFILE_VERIFICATION_PENDING,
} from './constants';
import {AUTH_LOGOUT} from '../../Auth/store/constants';
import {defaultChatSettings} from '../constants/createProfileConst';
import {SET_PENDING_PAYMENTS} from '../Settings/constants/payments';

const initialState = {
  user: null,
  profile: null,
  profileStats: null,
  profileVerification: null,
  location: null,
  favorites: [],
  createProfileStep: null,
  currentLocation: null,
  currentIpGeoLocation: null,
  showPaidPopup: null,
  profileChatSettings: defaultChatSettings,
  promocode: null,
  utms: null,
  profileReportOptions: null,
  twoFA: null,
  pendingPayments: null,
};
const currentUserReducer = (state = {...initialState}, {type, payload}) => {
  switch (type) {
    case SET_CURRENT_USER:
      if (JSON.stringify(state.user) === JSON.stringify(payload)) {
        return state;
      }
      return {...state, user: payload};
    case SET_CURRENT_USER_PROFILE:
      if (JSON.stringify(state.profile) === JSON.stringify(payload)) {
        return state;
      }
      return {...state, profile: payload};
    case SET_CURRENT_USER_PROFILE_STATS:
      if (JSON.stringify(state.profileStats) === JSON.stringify(payload)) {
        return state;
      }
      return {...state, profileStats: payload};
    case SET_CURRENT_USER_PROFILE_LOCATION:
      if (JSON.stringify(state.location) === JSON.stringify(payload)) {
        return state;
      }
      return {
        ...state,
        location: payload,
      };
    case SET_CURRENT_USER_AND_PROFILE:
      return {
        ...state,
        ...payload,
      };
    case SET_CURRENT_USER_PROFILE_THUMBNAIL:
      return {
        ...state,
        profile: {...state.profile, thumbnail: payload},
      };
    case SET_CURRENT_USER_PROFILE_IMAGES:
      if (JSON.stringify(state.profile.images) === JSON.stringify(payload)) {
        return state;
      }
      return {
        ...state,
        profile: {...state.profile, images: payload},
      };
    case SET_CURRENT_USER_PROFILE_VERIFICATION_PENDING:
      return {
        ...state,
        profile: {...state.profile, isVerificationPending: payload},
      };
    case SYNC_CURRENT_USER_PROFILE_CHAT_SETTINGS:
      if (JSON.stringify(state.profileChatSettings) === JSON.stringify(payload)) {
        return state;
      }
      return {
        ...state,
        profileChatSettings: payload,
      };
    case SET_CURRENT_USER_FAVORITES:
      if (JSON.stringify(state.favorites) === JSON.stringify(payload)) {
        return state;
      }
      return {
        ...state,
        favorites: payload,
      };
    case SET_CURRENT_USER_CREATE_PROFILE_STEP:
      return {
        ...state,
        createProfileStep: payload,
      };
    case CURRENT_USER_REMOVE_FROM_FAVORITES:
      return {
        ...state,
        favorites: state.favorites.filter(({id}) => id !== payload),
      };
    case SHOW_UPGRADE_PAID_POPUP:
      return {
        ...state,
        showPaidPopup: payload,
      };
    case HIDE_UPGRADE_PAID_POPUP:
      return {
        ...state,
        showPaidPopup: payload,
      };
    case AUTH_LOGOUT:
      return {...initialState};
    case USER_LOCATION_UPDATE:
      return {
        ...state,
        currentLocation: payload,
        profile: {
          ...state.profile,
          current_lat: payload.lat,
          current_lng: payload.lng,
          lat: payload.lat,
          lng: payload.lng,
        },
      };
    case UPDATE_PROMOCODE: {
      const {promocode} = payload;
      return {...state, promocode};
    }
    case CLEAR_PROMOCODE: {
      return {...state, promocode: null};
    }
    case UPDATE_UTMS: {
      const {utms} = payload;
      return {...state, utms};
    }
    case SYNC_PROFILE_REPORT_OPTIONS: {
      return {...state, profileReportOptions: payload};
    }
    case SYNC_PROFILE_VERIFICATION:
      return {...state, profileVerification: payload};
    case SET_TWO_FACTOR_DATA:
      return {...state, twoFA: {...state.twoFA, ...payload}};
    case RESET_TWO_FACTOR_DATA:
      return {...state, twoFA: initialState.twoFA};
    case SET_ATTEMPS_TWO_FACTOR:
      return {...state, twoFA: {...state.twoFA, attemptsCount: payload}};
    case SET_VERIFICATION_METHOD_TWO_FACTOR:
      return {...state, twoFA: {...state.twoFA, verificationMethod: payload}};
    case SET_USER_IP_GEO_LOCATION:
      return {...state, currentIpGeoLocation: payload};
    case SET_PENDING_PAYMENTS:
      return {
        ...state,
        pendingPayments: payload,
      };
    default:
      return state;
  }
};

export default currentUserReducer;
