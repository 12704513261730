import {Divider} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {navigateAction} from '~/actions/navigateActions';
import {modalHideAction} from '~/rootStore/modals/modalsActions';
import {MODAL_PROFILE_FILTERS} from '~/rootStore/modals/modalsIds';
import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import useModalById from '~/customHooks/useModalById';
import {TProfileFiltersListParams} from '~/types/appTypes';

import {
  profileFiltersAsListSelector,
  profileFiltersOptionsSelector,
  profileFiltersSelector,
} from '../../../store/selectors';
import {
  resetNavigationFiltersAction,
  resetProfileFiltersAction,
  updateProfileFiltersAction,
} from '../../../store/actions';
import {resetLocationProfilesActionCreator} from '../../../store/actionCreators';
import ProfileFilterControl from './ProfileFilterControl';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    backgroundColor: theme.palette.common.white,
    overflow: 'auto',
    paddingBottom: 83,
  },
  submitButtonWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1.5, 2.5),
  },
  submitButton: {
    fontSize: 20,
    borderRadius: 50,
  },
  btnWrapper: {
    background: '#FDFDFD',
    display: 'flex',
    padding: 11,
  },
  discoverySettings: {
    backgroundColor: '#F8F8F8',
    borderTop: `2px solid ${theme.palette.divider}`,
    color: '#898989',
    fontSize: 15,
    fontWeight: 'bold',
    padding: '7px 10px',
  },
}));

const ProfileFiltersBendr: React.FC = () => {
  const s = useStyles();
  const initialValues = useRef<TProfileFiltersListParams | null>(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const profileFilters = useSelector(profileFiltersSelector);
  const activeFilters = useSelector(profileFiltersAsListSelector);
  const filterOptions = useSelector(profileFiltersOptionsSelector);
  const modal = useModalById(MODAL_PROFILE_FILTERS);

  const handleSearch = useCallback(() => {
    dispatch(resetNavigationFiltersAction());
    dispatch(resetLocationProfilesActionCreator());
    dispatch(
      navigateAction(location.pathname, {
        query: {...activeFilters},
      })
    );
    dispatch(modalHideAction(MODAL_PROFILE_FILTERS));
  }, [activeFilters, dispatch, location.pathname]);

  const handleClearForm = () => {
    initialValues.current = null;
    dispatch(resetProfileFiltersAction());
  };

  const handleClose = () => {
    modal.close();
    if (initialValues.current) {
      dispatch(updateProfileFiltersAction(initialValues.current));
    }
  };

  useEffect(() => {
    initialValues.current = profileFilters;
    // need back up for filters when modal is opening only on cdm
    // see handleClose methood
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.btnWrapper}>
        <Button variant="text" className="text-default h2" fullWidth={false} onClick={handleClose}>
          <SpriteIcon name="back" className="mr-1" fontSize="normal" color="black" />
          <span>Filters</span>
        </Button>
        <div className="flex-1" />
        <Button fullWidth={false} variant="text" className="h2" onClick={handleClearForm}>
          Clear all
        </Button>
        <Divider />
      </div>
      <div className={s.discoverySettings}>DISCOVERY SETTINGS</div>
      <Divider />

      {Object.values(filterOptions).map(
        ({value, options, name}) =>
          options && (
            <ProfileFilterControl
              key={value}
              name={name}
              value={value}
              options={options}
              currentValues={profileFilters[value]}
            />
          )
      )}
      <div className={s.submitButtonWrapper}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSearch}
          className={s.submitButton}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default ProfileFiltersBendr;
