import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import * as React from 'react';
import {useSelector} from 'react-redux';

import Picture from '~/components/Picture/Picture';

import {currentProfileSelector} from '../../../store/selectors';
import placeholder from './img/placeholder.svg';

const useStyles = makeStyles(() => ({
  avatar: {
    width: 73,
    height: 73,
    borderRadius: 12,
    objectFit: 'cover',
    objectPosition: 'center',
    '&.isBorder': {
      border: '3px solid white',
    },
  },
  avatarRoot: {
    display: 'flex',
  },
}));

const ProfileCardAvatar: React.FC<{isBorder?: boolean}> = ({isBorder = true}) => {
  const profile = useSelector(currentProfileSelector);
  const s = useStyles();
  const imgClass = clsx(s.avatar, {
    isBorder,
  });
  return (
    <Picture
      image={profile?.thumbnail}
      type="profileCard"
      imgClassName={imgClass}
      rootClassName={s.avatarRoot}
      placeholder={placeholder}
    />
  );
};

export default ProfileCardAvatar;
