import {useMemo, useEffect, useState, useCallback} from 'react';
import {throttle} from 'lodash-es';
import {useSelector} from 'react-redux';

import {appInitedSelector} from '~/modules/App/store/selectors';

type TUseActivityTimer = (timeExp: number) => {
  isSpentTime: boolean;
  restartTimer: () => void;
  stopTimer: () => void;
};

const useActivityTimer: TUseActivityTimer = (timeExp) => {
  const [isSpentTime, setIsSpentTime] = useState(false);
  const [timer, setTimer] = useState<number>();
  const appInited = useSelector(appInitedSelector);

  const timeoutAction = () => {
    setIsSpentTime(true);
  };

  const handleActivity = throttle(() => {
    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }

    setIsSpentTime(false);

    const timerId = window.setTimeout(timeoutAction, timeExp);
    setTimer(timerId);
  }, 100);

  useEffect(() => {
    window.addEventListener('touchstart', handleActivity, false);
    return () => {
      window.removeEventListener('touchstart', handleActivity);
    };
  }, [appInited, handleActivity, timer]);

  const restartTimer = useCallback(() => {
    clearTimeout(timer);
    handleActivity();

    return () => {
      handleActivity.cancel();
    };
  }, [handleActivity, timer]);

  const stopTimer = useCallback(() => {
    clearTimeout(timer);
  }, [timer]);

  return useMemo(
    () => ({
      isSpentTime,
      restartTimer,
      stopTimer,
    }),
    [isSpentTime, restartTimer, stopTimer]
  );
};

export default useActivityTimer;
