import {StyleRules} from '@material-ui/core';
import {Palette} from '@material-ui/core/styles/createPalette';

export default (palette: Palette): StyleRules => ({
  outlined: {
    fontSize: 16,
    color: palette.text.secondary,
    '&.Mui-valid': {
      color: palette.success.main,
    },
    '&.Mui-focused:not(.Mui-error), &.MuiInputLabel-shrink:not(.Mui-error)': {
      color: palette.text.primary,
    },
    '&.MuiFormLabel-filled ~ .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.text.primary,
    },
    '&$marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
  },
});
