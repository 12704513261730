import React from 'react';
import {useSelector} from 'react-redux';
import {Divider} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GoogleMap from 'google-map-react';

import {currentLocationSelector} from '~/modules/CurrentUser/store/selectors';
import MapMarkerSimple from '~/components/ui/MapMarkerSimple';
import config from '~/constants/config';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '235px',
    background: 'white',
  },
}));

const MapControl: React.FC = () => {
  const s = useStyles();
  const {lat, lng} = useSelector(currentLocationSelector) || {};

  return (
    <>
      <div className={s.wrapper}>
        <GoogleMap
          bootstrapURLKeys={{key: config.googleApiKey}}
          defaultCenter={{lat, lng}}
          defaultZoom={11}
          options={{
            panControl: false,
            mapTypeControl: false,
            zoomControl: false,
            fullscreenControl: false,
          }}
        >
          <MapMarkerSimple lat={lat} lng={lng} />
        </GoogleMap>
      </div>
      <Divider />
    </>
  );
};

export default MapControl;
