import config from '~/constants/config';
import {CDNParams} from '~/types/Media';
import {TThunkAction} from '~/types/appTypes';
import logger from '~/utils/logger';
import {ChatAxiosInstance} from '~/utils/axios';
import {getImageUrl} from '~/helpers/images';

import {currentProfileSelector, currentUserSelector} from './selectors';

const log = logger.module('updateChatProfile');

const CDN_AVATAR_OPTIONS: CDNParams = {
  format: 'jpeg',
  width: 300,
  height: 300,
  crop: 'thumb',
  gravity: 'center',
};

type TUpdateChatProfile = () => TThunkAction;
const updateChatProfile: TUpdateChatProfile = () => (dispatch, getState) => {
  const profile = currentProfileSelector(getState());
  const user = currentUserSelector(getState());

  log.info('Update chat profile', {profile, user});
  if (!user || !profile) {
    log.info('Has no profile or user', {profile, user});
    return false;
  }

  const fullName = profile.name;

  const {thumbnail} = profile;

  const avatarUrl = thumbnail ? getImageUrl(thumbnail.cdn_slug, CDN_AVATAR_OPTIONS) : null;
  const userData = {
    username: user.username,
    full_name: fullName,
    extendedData: {
      username: user.username,
      avatar: {
        url: avatarUrl,
      },
    },
  };

  log.info('Full name for chat', {fullName});

  return ChatAxiosInstance.put(`${config.chat.serviceUrl}users/${profile.id}`, {
    data: userData,
  }).catch((error) => {
    log.error('Error during update chat profile', {error});
  });
};

export default updateChatProfile;
