"use strict";
/**
 * @packageDocumentation @module Messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useByDialog = void 0;
var effector_react_1 = require("effector-react");
var chat_1 = require("@yeobill/chat");
/**
 * To get all messages in the specific dialog as the react hook.
 *
 * **Remember before you can use this hook you needed get dialog history for that dialog by [[getHistory]].**
 */
function useByDialog(dialogId) {
    return effector_react_1.useStoreMap({
        store: chat_1.Messages.ByDialogs$,
        keys: [dialogId],
        fn: function (messages, _a) {
            var dialogId = _a[0];
            return messages[dialogId] || [];
        },
    });
}
exports.useByDialog = useByDialog;
