import {createSelector} from 'reselect';

import {TAppState} from '~/types/appTypes';

import {TModalIds} from './types';

const EMPTY_OBJECT = {};

export const makeModalByIdSelector = (modalId: TModalIds) => {
  return createSelector(
    (state: TAppState) => state.modals,
    (modals) => {
      const currentModal = modals.find((modal) => modal?.id === modalId);
      const {isOpen = false, props = EMPTY_OBJECT} = currentModal || {};

      return {isOpen, props} as Record<string, any>;
    }
  );
};

export default {
  makeModalByIdSelector,
};
