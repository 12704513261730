const translate = {
  defaultLanguage: 'en',
  cookieName: 'googtrans',
  cookieOptions: {
    path: '/',
    SameSite: 'none',
  },
};

export default translate;
