import makeStyles from '@material-ui/core/styles/makeStyles';
import * as React from 'react';
import {CircularProgress} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    minHeight: 45,
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1.5),
  },
}));

interface Props {
  loading: boolean;
}

const LoadableBlock: React.FC<Props> = ({loading, children}) => {
  const s = useStyles();
  return (
    <div className={s.wrapper}>
      {children}
      {loading && <CircularProgress size={24} className={s.loader} />}
    </div>
  );
};

export default LoadableBlock;
