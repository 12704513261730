import React from 'react';
import {useSelector} from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';

import bgImage from '~/styles/body-bg.svg';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      flex: '1 1 auto',
      padding: theme.spacing(1),
      backgroundColor: ({paperBody}) => (paperBody ? theme.palette.background.paper : 'inherit'),
      background: ({imageBody}) =>
        imageBody ? `#fff url("${bgImage}") center center / contain` : 'inherit',
    },
    loader: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(128,128,128,0.4)',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
    },
  }),
  {name: 'ContentWrapper'}
);

const ContentWrapper = ({children}) => {
  const layout = useSelector((state) => state.layout);

  const s = useStyles({
    paperBody: layout.paperBody,
    imageBody: layout.imageBody,
  });
  return (
    <Container
      classes={{
        root: clsx(s.root, layout.contentClass, 'test-content'),
      }}
      maxWidth={false}
    >
      {layout.loading && (
        <div className={s.loader}>
          <CircularProgress />
        </div>
      )}
      {children}
    </Container>
  );
};

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentWrapper;
