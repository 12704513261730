import {useMemo} from 'react';
import {useRouteMatch} from 'react-router';
import {useSelector} from 'react-redux';

import {TProfile} from '~/types/Profile';
import {TLeafLocation} from '~/types/LeafLocation';
import {TProfileType, profileTypeUrlToId} from '~/constants/profiles';
import config from '~/constants/config';
import {TLocationType} from '~/modules/Locations/constants/LocationTypes';

import {currentSwiperProfileSelector, profileListLocationSelector} from '../store/selectors';
import {isBorough, isCity, isState} from '../../Locations/helpers';
import {TRouteParams} from './types';

export function getProfileTypeFromRouteParams(params: TRouteParams): TProfileType | void {
  return params.profileTypeUrl && profileTypeUrlToId[params.profileTypeUrl];
}

export const buildPageMetaProfileList = (
  profile: TProfile,
  leafLocation?: TLeafLocation,
  isMyLocation?: boolean
) => {
  if ((!leafLocation && !isMyLocation) || !profile) {
    return {title: config.app.title, description: config.app.description};
  }

  const isTrans = Boolean(profile?.type === TProfileType.TRANS);
  const lookingForTrans = isTrans;

  const parent = leafLocation?.parent;
  const postfix = (parent && isState(parent) && `, ${parent?.name}`) || '';
  const locationName = isMyLocation ? 'my current location' : leafLocation?.name;

  if (isMyLocation) {
    return {
      title: `${lookingForTrans ? 'Trans' : 'Male'} Dating in ${locationName} - Date a ${
        lookingForTrans ? 'TS' : 'Male'
      } Near ${locationName} | Bendr`,
      description: `Browse & Find ${
        lookingForTrans ? 'Transgender' : 'Male'
      } Members & Profiles in ${locationName}! Bendr is a TS Dating Website for Serious and Safe Transgender Dating & Forming Trans Relationships.`,
      keywords: `trans dating, ts dating, transgender dating, trans attracted, trans dating site, transgender dating site, transgender dating ${locationName}, ts dating ${locationName}, trans date, ts dating site, transgender dating website, trans friendly dating apps, best transgender dating sites, best trans dating apps`,
    };
  }
  if (leafLocation && isBorough(leafLocation)) {
    return {
      title: `${lookingForTrans ? 'Trans' : 'Male'} Dating in ${locationName}${postfix} - Date a ${
        lookingForTrans ? 'TS' : 'Male'
      } Near ${locationName} | Bendr`,
      description: `Browse & Find ${
        lookingForTrans ? 'Transgender' : 'Male'
      } Members & Profiles in ${locationName}${postfix}! Bendr is a TS Dating Website for Serious and Safe Transgender Dating & Forming Trans Relationships.`,
      keywords: `trans dating, ts dating, transgender dating, trans attracted, trans dating site, transgender dating site, transgender dating ${locationName}, ts dating ${locationName}, trans date, ts dating site, transgender dating website, trans friendly dating apps, best transgender dating sites, best trans dating apps`,
    };
  }

  if (leafLocation && isCity(leafLocation)) {
    return {
      title: `${isTrans ? 'Trans' : 'Male'} Dating in ${locationName}${postfix} - Date a ${
        isTrans ? 'TS' : 'Male'
      } Near ${locationName} | Bendr`,
      description: `Browse & Find ${
        isTrans ? 'Transgender' : 'Male'
      } Members & Profiles in ${locationName}${postfix}! Bendr is a TS Dating Website for Serious and Safe Transgender Dating & Forming Trans Relationships.`,
      keywords: `trans dating, ts dating, transgender dating, trans attracted, trans dating site, transgender dating site, transgender dating ${locationName}, ts dating ${locationName}, trans date, ts dating site, transgender dating website, trans friendly dating apps, best transgender dating sites, best trans dating apps`,
    };
  }

  if (leafLocation && isState(leafLocation)) {
    return {
      title: `${isTrans ? 'Trans' : 'Male'} Dating in ${locationName} - Date a ${
        isTrans ? 'TS' : 'Male'
      } in ${leafLocation.code} | Bendr`,
      description: `Looking for a ${
        isTrans ? 'TS' : 'Male'
      } Date in ${locationName}? Join Bendr Today to Find and Meet Thousands of Transgender & Trans Attracted Individuals in ${locationName} or Near You!`,
      keywords: `trans dating, ts dating, transgender dating, trans attracted, trans dating site, transgender dating site, transgender dating ${locationName}, ts dating ${locationName}, trans date, ts dating site, transgender dating website, trans friendly dating apps, best transgender dating sites, best trans dating apps`,
    };
  }

  return {
    title: config.app.title,
    description: config.app.title,
    keywords: config.app.keywords,
  };
};

export const parseLocations = (leafLocations: TLeafLocation[]) => {
  const result: TLeafLocation[] = [];
  leafLocations.forEach((country) => {
    if (country.type === TLocationType.Country) {
      const parentCountry = country;
      const states: TLeafLocation[] = Object.values(country.children ?? {});
      if (states.length) {
        states.forEach((state) => {
          const parentState = {...state, parent: parentCountry};
          const cities: TLeafLocation[] = Object.values(state.children ?? {});
          if (cities.length) {
            cities.forEach((city) => {
              const cityLocation = {...city, parent: parentState};
              result.push(cityLocation);
              const boroughs: TLeafLocation[] = Object.values(city.children ?? {});
              if (boroughs.length) {
                boroughs.forEach((borough) => {
                  result.push({...borough, parent: cityLocation});
                });
              }
            });
          }
          result.push(parentState);
        });
      }
      result.push(parentCountry);
    }
  });
  return result;
};

export const usePageMeta = () => {
  const {path} = useRouteMatch();
  const currentProfile = useSelector(currentSwiperProfileSelector);
  const leafLocation = useSelector(profileListLocationSelector);

  const pageMeta = useMemo(() => {
    const isMyLocation = path?.includes('my-location');

    return buildPageMetaProfileList(currentProfile, leafLocation, isMyLocation);
  }, [currentProfile, leafLocation, path]);

  return pageMeta;
};
