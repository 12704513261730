import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Link} from 'react-router-dom';

import routeByName from '~/constants/routes';
import palette from '~/styles/palette';
import Logo from '~/components/Logo';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: `linear-gradient(${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
      textAlign: 'center',
      padding: theme.spacing(1.6, 4.25, 2.5),
      fontSize: 12,
      fontWeight: 600,
    },
    footerItem: {
      borderRight: `1px solid ${theme.palette.common.white}`,
      color: theme.palette.common.white,
      display: 'inline-block',
      lineHeight: 1,
      padding: '0 10px',
      textTransform: 'uppercase',
      '&:last-child': {
        borderRight: 'none',
      },
    },
  }),
  {name: 'AllLocationsFooter'}
);

const footerLinks = [
  // {name: 'affiliates', link: ''},
  {name: 'privacy policy', link: routeByName.privacyPolicy},
  // {name: 'law enforcement', link: ''},
  {name: 'contact us', link: routeByName.contact},
  // {name: 'business owners', link: ''},
  // {name: 'grab our banner', link: ''},
  // {name: 'rca', link: ''},
];

const AllLocationsFooter = () => {
  const s = useStyles();

  return (
    <div className={s.root}>
      <Logo className="mb-3" color={palette.common.white} />
      <div>
        {footerLinks.map((link) => (
          <Link key={link.link} to={link.link} className={s.footerItem}>
            <span>{link.name}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AllLocationsFooter;
