import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SpriteIcon from '../icons/SpriteIcon';

const plusIcon = <SpriteIcon name="plus" fontSize="small" />;

const minusIcon = <SpriteIcon name="minus" fontSize="small" />;

const useStyles = makeStyles(
  (theme) => ({
    accordion: {
      backgroundColor: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.divider}`,
      color: '#3B5999',
      fontSize: 20,
      '&.expanded': {
        borderBottom: `1px solid ${theme.palette.common.white}`,
        backgroundColor: theme.palette.grey[200],
        color: '#222728',
        '& > $accordionContent': {
          display: 'block',
        },
      },
    },
    accordionContent: {
      display: 'none',
    },
  }),
  {name: 'AccordionPanel'}
);

const AccordionPanel = ({title, expanded, onClick, children}) => {
  const s = useStyles();
  return (
    <div className={clsx('divider-bottom', s.accordion, {expanded})}>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div className="d-flex px-2" onClick={onClick} role="button">
        <div className="semibold d-flex align-center py-3 flex-1">{title}</div>
        <div className="arrow p-1-5 d-flex align-center">{expanded ? minusIcon : plusIcon}</div>
      </div>
      <div className={s.accordionContent}>{children}</div>
    </div>
  );
};

AccordionPanel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

AccordionPanel.defaultProps = {};

export default AccordionPanel;
