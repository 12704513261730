import Cookie from 'js-cookie';

import {TThunkAsyncAction} from '~/types/appTypes';
import {ONE_YEAR} from '~/constants/dateTime';
import {COOKIE_PROMOCODE} from '~/constants/cookies';

import {UPDATE_PROMOCODE} from './constants';

export const savePromocodeAction: TThunkAsyncAction<string> = (promocode) => (dispatch) => {
  const expires = new Date(Date.now() + ONE_YEAR);
  Cookie.set(COOKIE_PROMOCODE, promocode, {expires});
  dispatch({
    type: UPDATE_PROMOCODE,
    payload: {promocode},
  });
};

export const clearPromocodeAction: TThunkAsyncAction = () => (dispatch) => {
  const expires = new Date(0);
  Cookie.set(COOKIE_PROMOCODE, '', {expires});
  dispatch({
    type: UPDATE_PROMOCODE,
    payload: {promocode: ''},
  });
};

export const restorePromocodeAction: TThunkAsyncAction = () => (dispatch) => {
  const promocode = Cookie.get(COOKIE_PROMOCODE);
  if (promocode) {
    dispatch({
      type: UPDATE_PROMOCODE,
      payload: {
        promocode,
      },
    });
  }
};
