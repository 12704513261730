import axios, {AxiosInstance} from 'axios';

export default function extendAxiosInstance(
  baseInstance: AxiosInstance,
  newConfig: Record<string, unknown>
): AxiosInstance {
  const newInstance = axios.create({
    ...baseInstance.defaults,
    ...newConfig,
  });
  // @ts-expect-error Property 'handlers' does not exist on type 'AxiosInterceptorManager<AxiosRequestConfig>'
  baseInstance.interceptors.request.handlers.forEach((interceptor) => {
    // @ts-expect-error Argument of type 'unknown' is not assignable to parameter of type
    newInstance.interceptors.request.use(...Object.values(interceptor));
  });

  // @ts-expect-error Property 'handlers' does not exist on type 'AxiosInterceptorManager<AxiosRequestConfig>'
  baseInstance.interceptors.response.handlers.forEach((interceptor) => {
    // @ts-expect-error Argument of type 'unknown' is not assignable to parameter of type
    newInstance.interceptors.response.use(...Object.values(interceptor));
  });

  return newInstance;
}
