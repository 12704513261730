import makeStyles from '@material-ui/core/styles/makeStyles';

import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import {profiles} from '~/constants/profiles';
import {TProfile} from '~/types/Profile';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 99,
    fontSize: 12,
    letterSpacing: 0.23,
    paddingLeft: 3,
    paddingRight: 3,
    color: '#fff',
    fontWeight: 600,
    height: 14,
    minWidth: 14,
    lineHeight: 1,
  },
  age: {},
}));

const GenderBadge: React.FC<{profile: TProfile}> = ({profile}) => {
  const s = useStyles();
  return (
    <div className={s.root} style={{background: profiles[profile.type].background}}>
      {' '}
      <SpriteIcon
        name={profiles[profile.type].icon}
        fontSize="custom"
        style={{width: 8, height: 8}}
      />
      {profile.age > 0 && <span className={s.age}>{profile.age}</span>}
    </div>
  );
};

export default GenderBadge;
