import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useDispatch, useSelector} from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';

import PrimaryDialog from '~/components/ui/Dialogs/PrimaryDialog';
import Typography from '~/components/ui/Typography';
import StarRating from '~/components/ui/StarRating';
import logger from '~/utils/logger';
import {
  addErrorNotificationAction,
  addSuccessNotificationAction,
} from '~/rootStore/notifications/notificationsReducer';

import TabsRadios from './TabsRadios';
import FeedbackTextarea from './FeedbackTextarea';
import {closeFeedbackModalAction, sendFeedbackAction} from '../store/actions';
import {feedbackTypes, formFields, feedbackTabsConfig} from '../constants/feedback';
import {successMessages} from '../constants/messages';
import validateForm from './validateForm';
import {userEmailSelector} from '../../CurrentUser/store/selectors';
import isFeedbackModalOpenSelector from '../store/selectors';

const log = logger.module('FeedbackModal');

const initialState = {
  [formFields.rate]: undefined,
  [formFields.type]: feedbackTypes.improvements,
  [formFields.content]: '',
  [formFields.email]: '',
  [formFields.year]: '',
};

const FeedbackModal = () => {
  const [modalState, setModalState] = useState(initialState);
  const [errors, setErrors] = useState({});
  const open = useSelector(isFeedbackModalOpenSelector);
  const userEmail = useSelector(userEmailSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const {[formFields.email]: currentEmail} = modalState;

    if (open && userEmail && userEmail !== currentEmail) {
      setModalState((prevState) => ({
        ...prevState,
        [formFields.email]: userEmail,
      }));
    }
  }, [modalState, open, userEmail]);

  const onInputChange = ({target: {name, value}}) => {
    const newErrors = {...errors};
    delete newErrors[name];

    setModalState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors(newErrors);
  };

  const onClose = () => {
    setModalState({...initialState});
    dispatch(closeFeedbackModalAction());
  };

  const onSubmit = async () => {
    const validateErrors = validateForm(modalState);

    if (Object.keys(validateErrors).length) {
      setErrors(validateErrors);
      return;
    }

    try {
      await dispatch(sendFeedbackAction(modalState));
      dispatch(addSuccessNotificationAction(successMessages.feedbackSent));
      onClose();
    } catch (error) {
      log.error('Error during sendFeedback in FeedbackModal component', {
        error,
      });
      dispatch(addErrorNotificationAction(error.generalError));
    }
  };

  const {
    [formFields.rate]: rate = null,
    [formFields.type]: type = '',
    [formFields.content]: content = '',
    [formFields.email]: email = '',
    [formFields.year]: year = '',
  } = modalState;

  return (
    <PrimaryDialog
      open={open}
      onClose={onClose}
      title={
        <div className="text-white">
          <div className="mb-1">
            <Typography variant="h2" heading>
              Send us some feedback!
            </Typography>
          </div>
          <Typography variant="body2">
            Do you have a suggestion or found some bug? Let us know in the field below.
          </Typography>
        </div>
      }
      handleOk={onSubmit}
      submitTitle="Send Feedback"
      closeTitle="Cancel"
      disableSubmit={Boolean(errors && errors.options)}
      fullScreen={false}
    >
      <div className="px-1 pb-2">
        <div className="pb-1">
          <b>How was your experience?</b>
        </div>
        <StarRating name={formFields.rate} value={rate} onChange={onInputChange} />
        {errors[formFields.rate] && (
          <FormHelperText error>{errors[formFields.rate].map((error) => error)}</FormHelperText>
        )}
      </div>
      <div className="pb-1">
        <TabsRadios
          name={formFields.type}
          options={Object.values(feedbackTabsConfig)}
          value={type}
          onChange={onInputChange}
        />
        <FeedbackTextarea
          name={formFields.content}
          placeholder={
            feedbackTabsConfig[type] ? feedbackTabsConfig[type].placeholder : 'Type here'
          }
          value={content}
          onChange={onInputChange}
        />
        {errors[formFields.content] && (
          <FormHelperText className="px-2" error>
            {errors[formFields.content]}
          </FormHelperText>
        )}
      </div>
      <div className="px-1 mb-1">
        <TextField
          name={formFields.email}
          placeholder="Enter your email here"
          value={email}
          onChange={onInputChange}
          helperText={errors[formFields.email] && errors[formFields.email]}
          error={Boolean(errors[formFields.email])}
        />
      </div>
      <div className="px-1">
        <InputLabel htmlFor={`feedback-${formFields.year}`} className="mb-1">
          To verify you are human, please enter current year
        </InputLabel>
        <TextField
          id={`feedback-${formFields.year}`}
          name={formFields.year}
          value={year}
          onChange={onInputChange}
          helperText={errors[formFields.year] && errors[formFields.year]}
          error={Boolean(errors[formFields.year])}
        />
      </div>
      {errors && errors.options && (
        <FormHelperText className="px-2" error>
          {errors.options}
        </FormHelperText>
      )}
    </PrimaryDialog>
  );
};

export default FeedbackModal;
