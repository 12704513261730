import {TAction, TThunkDispatch} from '~/types/appTypes';

import {
  LAYOUT_RESET,
  LAYOUT_SET_DRAWER_STATE,
  LAYOUT_SET_FULLSCREEN_LOADER_STATE,
  LAYOUT_SET_PROPS,
} from './constants';
import {TLayoutControlProps} from '../BaseLayout/types';

export const layoutSetPropsAction = (props: TLayoutControlProps): TAction => ({
  type: LAYOUT_SET_PROPS,
  payload: props,
});

export const layoutOpenDrawerAction = (): TAction => ({
  type: LAYOUT_SET_DRAWER_STATE,
  payload: true,
});
export const layoutCloseDrawerAction = (): TAction => ({
  type: LAYOUT_SET_DRAWER_STATE,
  payload: false,
});
export const layoutOpenFullScreenLoaderAction = (): TAction => ({
  type: LAYOUT_SET_FULLSCREEN_LOADER_STATE,
  payload: true,
});
export const layoutCloseFullScreenLoaderAction = (): TAction => ({
  type: LAYOUT_SET_FULLSCREEN_LOADER_STATE,
  payload: false,
});

export const formSubmitFullScreenControl =
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fn: any
  ) =>
  async (
    dispatch: TThunkDispatch,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...rest: any[]
  ) => {
    try {
      dispatch(layoutOpenFullScreenLoaderAction());
      const result = await fn(dispatch, ...rest);
      return result;
    } finally {
      dispatch(layoutCloseFullScreenLoaderAction());
    }
  };

export const layoutResetAction = (props: unknown): TAction => ({
  type: LAYOUT_RESET,
  payload: props,
});
