import Button from '@material-ui/core/Button';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {profileEditUrlSelector} from '../../../store/selectors';

const CompleteProfile: React.FC<{className?: string}> = ({className}) => {
  const profileEditUrl = useSelector(profileEditUrlSelector);
  return (
    <Button className={className} component={Link} to={profileEditUrl}>
      Complete Profile
    </Button>
  );
};

export default CompleteProfile;
