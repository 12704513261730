import RequestError from '~/utils/RequestError';
import {addSuccessNotificationAction} from '~/rootStore/notifications/notificationsReducer';
import {formSubmitFullScreenControl} from '~/components/Layout';

import PaymentsService from '../PaymentsService';
import {
  COMPLIMENTARY_ACTIVATION_PAGE_LOADING,
  PAYMENTS_SET_CARDS_LIST,
  PAYMENTS_SET_MEMBERSHIPS_LIST,
  PAYMENTS_SET_RECHARGE_BUDGET_OPTIONS,
  PAYMENTS_SET_TOP_UP_PRICE,
  PAYMENTS_SET_VIP_OPTIONS,
  PAYMENTS_SET_RECHARGE_BACK_ROUTE,
  PAYMENTS_SET_FROM_PAGE_GOLD,
} from './constants';
import {rechargeBudgetOptionByIdSelector} from './selectors';
import {syncProfileAction} from '../../CurrentUser/store/actions';
import ProfileService from '../../Profiles/ProfileService';
import {
  currentProfileSelector,
  isProfileCompletedSelector,
  utmsSelector,
} from '../../CurrentUser/store/selectors';
import loadCurrentUserAction from '../../CurrentUser/store/actions/loadCurrentUserAction';
import {successMessages} from '../constants/messages';

export const syncCardsListAction = () => async (dispatch) => {
  const cards = await PaymentsService.getCardsList();

  dispatch({
    type: PAYMENTS_SET_CARDS_LIST,
    payload: cards,
  });

  return cards;
};

export const createCreditCardAction = (cardData) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    await PaymentsService.createCreditCard(cardData);
    const {
      currentUser: {
        user: {email},
      },
    } = getState();

    if (!email) {
      dispatch(loadCurrentUserAction());
    }
  });

export const deleteCreditCardAction = (cardId) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    if (!cardId) {
      return;
    }
    const {
      currentUser: {
        user: {subscriptions},
      },
    } = getState();
    if (
      subscriptions.length &&
      subscriptions.find(({card_id: subscrCardId}) => Number.parseInt(cardId, 10) === subscrCardId)
    ) {
      throw new RequestError({
        message: 'Error: Cannot delete card, it has assigned active subscription.',
      });
    }

    const result = await PaymentsService.deleteCreditCard(cardId);
    await dispatch(syncCardsListAction());
    // eslint-disable-next-line consistent-return
    return result;
  });

export const syncRechargeBudgetOptionsAction = () => async (dispatch) => {
  const rechargeOptions = {
    50: {
      id: 50,
      label: '$50',
      amount: 50,
    },
    80: {
      id: 80,
      label: '$80',
      amount: 80,
    },
    100: {
      id: 100,
      label: '$100',
      amount: 100,
    },
    200: {
      id: 200,
      label: '$200',
      amount: 200,
    },
  };

  dispatch({
    type: PAYMENTS_SET_RECHARGE_BUDGET_OPTIONS,
    payload: rechargeOptions,
  });

  return rechargeOptions;
};

export const syncAvailableMembershipsAction = () => async (dispatch, getState) => {
  const isProfileCompleted = isProfileCompletedSelector(getState());

  if (!isProfileCompleted) {
    throw new RequestError({
      message: 'Profile is not completed. Action Forbidden',
    });
  }

  const profile = currentProfileSelector(getState());

  // if (profile.isClient) {
  ProfileService.getMembershipOptions(profile.location_id);
  const memberships = await ProfileService.getGoldMembershipOptions();
  // }

  dispatch({
    type: PAYMENTS_SET_MEMBERSHIPS_LIST,
    payload: memberships,
  });
  return memberships;
};

// type: membership | dating-membership
export const createMembershipPaymentAction = ({membershipId, type}) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const profile = currentProfileSelector(getState());
    const utms = utmsSelector(getState());

    let params = {
      type,
      profile_id: profile.id,
      membership_id: membershipId,
    };

    if (utms) {
      params = {...params, ...utms};
    }

    return PaymentsService.createPayment(params);
  });

export const purchaseMembershipAction = ({cardId, paymentId, processor}) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const {
      currentUser: {
        user: {email},
        location: {country},
      },
    } = getState();

    await PaymentsService.payPayment(paymentId, {
      email,
      country: country.id,
      card_id: cardId,
      processor,
    });

    await dispatch(loadCurrentUserAction());
    await dispatch(syncProfileAction());
    dispatch(addSuccessNotificationAction(successMessages.paymentSucceeded));
  });

export const createBudgetPaymentAction = (rechargeBudgetId, isRecurring) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const state = getState();
    const {
      currentUser: {
        profile: {id},
      },
    } = state;

    const rechargeBudgetOption = rechargeBudgetOptionByIdSelector(state, rechargeBudgetId);

    const utms = utmsSelector(state);

    let params = {
      type: 'budget',
      profile_id: id,
      amount: rechargeBudgetOption.amount,
      recurring: isRecurring,
    };

    if (utms) {
      params = {...params, ...utms};
    }

    return PaymentsService.createPayment(params);
  });

export const purchaseRechargeBudgetAction = ({paymentId, cardId}) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const state = getState();
    const {
      currentUser: {
        user: {email},
        location: {country},
      },
    } = state;

    await PaymentsService.payPayment(paymentId, {
      email,
      country: country.id,
      card_id: cardId,
    });

    await dispatch(loadCurrentUserAction());
  });

export const applyPromoCodeAction = (paymentId, promocode) =>
  formSubmitFullScreenControl(() => PaymentsService.applyPromoCode(paymentId, promocode));

export const deletePromoCodeAction = (paymentId) =>
  formSubmitFullScreenControl(() => PaymentsService.deletePromoCode(paymentId));

export const syncVipOptionsAction = () => async (dispatch, getState) => {
  const {
    currentUser: {
      user: {profileId},
    },
  } = getState();

  const options = await PaymentsService.getVipOptions({profile_id: profileId});

  dispatch({
    type: PAYMENTS_SET_VIP_OPTIONS,
    payload: options,
  });
};

export const purchaseVipAction = (days, recurring) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const {
      currentUser: {
        user: {profileId},
      },
    } = getState();

    const response = await PaymentsService.purchaseVip(
      {
        days,
        recurring,
        profile_id: profileId,
      },
      {needMessage: true}
    );

    await dispatch(loadCurrentUserAction());
    return response;
  });

/**
 *
 * @param subscriptionId
 * @param formData Object {reason: '', reason_other: ''}
 * @returns {Function}
 */
export const cancelSubscriptionAction = (subscriptionId, formData) =>
  formSubmitFullScreenControl(async (dispatch) => {
    const response = await PaymentsService.cancelSubscription(subscriptionId, formData);
    await dispatch(loadCurrentUserAction());
    await dispatch(syncProfileAction());

    return response;
  });

export const changeSubscriptionCardAction = (subscriptionId, cardId) =>
  formSubmitFullScreenControl(async (dispatch) => {
    await PaymentsService.changeSubscriptionCard(subscriptionId, cardId);
    await dispatch(loadCurrentUserAction());
    await dispatch(syncProfileAction());
  });

// TODO: move to current user actions
export const changeProfileLocationAction = (locationId) =>
  formSubmitFullScreenControl(async (dispatch, getState) => {
    const {
      currentUser: {profile},
    } = getState();

    if (!profile) {
      return;
    }

    await ProfileService.changeLocation(profile.id, locationId);
    await dispatch(loadCurrentUserAction());
    await dispatch(syncProfileAction());
  });

export const syncTopUpPriceAction = () => async (dispatch) => {
  const price = await PaymentsService.getTopUpPrice();
  dispatch({
    type: PAYMENTS_SET_TOP_UP_PRICE,
    payload: price,
  });
};

export const purchaseTopUpAction = (quantity) =>
  formSubmitFullScreenControl(async (dispatch) => {
    if (!quantity) {
      return;
    }

    await PaymentsService.purchaseTopUp(quantity);

    await dispatch(loadCurrentUserAction());
  });

export const complimentaryActivationPageLoading = (loading) => ({
  type: COMPLIMENTARY_ACTIVATION_PAGE_LOADING,
  payload: {loading},
});

export const setBackRechargeBudgetRouteAction = (route) => ({
  type: PAYMENTS_SET_RECHARGE_BACK_ROUTE,
  payload: route,
});

export const setPageFromRequested = (from) => ({
  type: PAYMENTS_SET_FROM_PAGE_GOLD,
  payload: from,
});

export default {
  syncCardsListAction,
  createCreditCardAction,
  syncRechargeBudgetOptionsAction,
  purchaseRechargeBudgetAction,
  setBackRechargeBudgetRouteAction,
  syncVipOptionsAction,
  purchaseVipAction,
  cancelSubscriptionAction,
  changeSubscriptionCardAction,
  syncTopUpPriceAction,
  purchaseTopUpAction,
  setPageFromRequested,
};
