import makeStyles from '@material-ui/core/styles/makeStyles';
import {CSSProperties} from 'react';

const useStyles = makeStyles((theme) => ({
  inner: {
    borderRadius: '50%',
    width: '9px',
    height: '9px',
    backgroundColor: theme.palette.success.light,
  },
  outer: {
    padding: '2px',
    display: 'block',
    borderRadius: '50%',
    backgroundColor: 'white',
  },
}));

const DotStatus: React.FC<{style?: CSSProperties}> = ({style}) => {
  const s = useStyles();

  return (
    <div className={s.outer} style={style}>
      <div className={s.inner} />
    </div>
  );
};

export default DotStatus;
