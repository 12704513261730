import * as React from 'react';
import Button from '@material-ui/core/Button';
import {
  TWebRTCSession,
  //  TSessionState,
  // TRoxySessionState,
  TWebRTCMediaType,
} from '@yeobill/chat/lib/types';
import {Users} from '@yeobill/react-chat';
import Modal from '@material-ui/core/Modal';

// import Text from '~/components/ui/Text';
import SpriteIcon from '~/components/ui/icons/SpriteIcon';

import useStyles from './chatCallsStyles';

interface Props {
  onClose: () => void;
  onAccept?: () => void;
  session: TWebRTCSession;
  direction: 'incoming' | 'outgoing';
}

const ModalDialing: React.FC<Props> = ({onAccept, session, onClose, direction}) => {
  const s = useStyles();

  const isVideo = session.callType === TWebRTCMediaType.VIDEO;
  const opponentId = [session.opponentsIDs[0], session.initiatorID].filter(
    (id) => id !== session.currentUserID
  )[0];

  const opponent = Users.useChatUser(opponentId);
  const currentUser = Users.useChatUser(session.currentUserID);

  let imageUrl = '';
  let name = '';

  try {
    // eslint-disable-next-line
    // @ts-ignore
    imageUrl = opponent.extendedData.avatar.url;
    // eslint-disable-next-line
    // @ts-ignore
    name = opponent.full_name;
  } catch (e) {
    try {
      // eslint-disable-next-line
      // @ts-ignore
      imageUrl = currentUser.extendedData.avatar.url;
    } catch (e2) {
      imageUrl = '';
    }
  }

  // eslint-disable-next-line
  console.log('ModalOutgoingCall', {session, opponent});
  return (
    <Modal open onClose={onClose}>
      <>
        <div
          className={`${s.wrapper} ${s.blurred}`}
          style={{backgroundImage: `url(${imageUrl})`}}
        />
        <div className={s.paper}>
          <div>
            <div className={s.avatarWrapper}>
              <img alt="" className={s.avatar} src={imageUrl} />
            </div>
            <div className={s.userName}>
              {name}
              <div className={s.stateText}>{isVideo ? 'Video Calling...' : 'Calling...'}</div>
            </div>
          </div>

          <div className={s.buttons}>
            {direction === 'incoming' && (
              <Button type="button" className={`${s.button} ${s.success}`} onClick={onAccept}>
                <SpriteIcon fontSize="large" name={isVideo ? 'camera' : 'phone2'} />
              </Button>
            )}
            <Button
              type="button"
              color="inherit"
              className={`${s.button} ${s.danger}`}
              onClick={onClose}
            >
              <SpriteIcon fontSize="large" name="phone_decline" />
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ModalDialing;
