import * as React from 'react';
import {SnackbarProvider, SnackbarProviderProps, useSnackbar} from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SpriteIcon from './ui/icons/SpriteIcon';

interface StackActionProps {
  id?: number | string;
}

const SnackAction: React.FC<StackActionProps> = ({id}) => {
  const {closeSnackbar} = useSnackbar();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className="p-1" onClick={() => closeSnackbar(id)}>
      OK
    </div>
  );
};

const defaultIconVariant = SnackbarProvider.defaultProps?.iconVariant || {};

const useStyles = makeStyles(
  (theme) => ({
    snackBarItem: {
      fontSize: theme.typography.button.fontSize,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
    },
    snackbarError: {
      backgroundColor: theme.palette.error.main,
    },
  }),
  {name: 'AppSnackbarProvider'}
);

const AppSnackbarProvider: React.FC<SnackbarProviderProps> = ({children}) => {
  const s = useStyles();

  return (
    <SnackbarProvider
      action={(key) => <SnackAction id={key} />}
      classes={{
        variantSuccess: s.snackbarSuccess,
        variantError: s.snackbarError,
      }}
      ContentProps={{
        classes: {
          root: s.snackBarItem,
        },
      }}
      maxSnack={3}
      iconVariant={{
        ...defaultIconVariant,
        success: <SpriteIcon name="check-bold" className="mr-1" />,
        error: '',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default AppSnackbarProvider;
