import {useState, useEffect, useCallback} from 'react';
import * as React from 'react';
import {useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';

import useLocalStorage from '~/customHooks/useLocalStorage';
import {encrypt, check} from '~/helpers/pinCode';
import errorSnackbar from '~/helpers/snackbar/errorSnackbar';
import useActivityTimer from '~/customHooks/useActivityTimer';
import config from '~/constants/config';
import useModalById from '~/customHooks/useModalById';
import useExpiredPin from '~/customHooks/useExpiredPin';
import {MODAL_PIN_CODE} from '~/rootStore/modals/modalsIds';

import {PinCode, pinCodeSkippedKey, tsPinCodeKey} from '../constants/pinCode';
import {
  isStandaloneModeSelector,
  isVideoPlaySelector,
  isFileUploadOpenSelector,
  appInitedSelector,
} from '../../App/store/selectors';
import PinCodeComponent from './PinCodeComponent';

const PinCodeWrapper: React.FC = () => {
  const [pinCheckOk, setPinCheckOk] = useState(false);
  const isStandalone = useSelector(isStandaloneModeSelector);
  const {enqueueSnackbar} = useSnackbar();
  const isPlayVideo = useSelector(isVideoPlaySelector);
  const isFileUploadOpen = useSelector(isFileUploadOpenSelector);
  const appInited = useSelector(appInitedSelector);
  const [isVisible, setIsVisible] = useState(true);
  const {
    isOpen: isPinCodeModalOpen,
    open: openPinCodeModal,
    close: closePinCodeModal,
  } = useModalById(MODAL_PIN_CODE);

  const [skippedPin, setSkippedPin, refreshSkippedPin] = useLocalStorage(pinCodeSkippedKey, false); // skip | none
  const [tsPin, setTsPin] = useLocalStorage(tsPinCodeKey, false);
  const isPinExpired = useExpiredPin(pinCheckOk);

  const {isSpentTime, restartTimer} = useActivityTimer(
    parseInt(config.pinCheckTime.toString(), 10)
  );

  // Clear skip user choice on start
  useEffect(() => {
    setSkippedPin(PinCode.NONE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVisibilityChange = useCallback(() => {
    refreshSkippedPin();
    setIsVisible(!document.hidden);
  }, [refreshSkippedPin]);

  useEffect(() => {
    if (isSpentTime || !isVisible) {
      if (isPlayVideo) {
        restartTimer();
      } else {
        setPinCheckOk(false);
      }
    }
  }, [isSpentTime, isPlayVideo, isVisible, restartTimer]);

  useEffect(() => {
    if (isPinCodeModalOpen && !pinCheckOk && isFileUploadOpen) {
      setPinCheckOk(true);
      closePinCodeModal();
    }
  }, [isPinCodeModalOpen, closePinCodeModal, pinCheckOk, isFileUploadOpen]);

  // TODO: code for visibility handler has bug with duplicate of init for activityTimer
  useEffect(() => {
    window.document.addEventListener('visibilitychange', handleVisibilityChange, false);
    return () => {
      window.document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    };
  }, [appInited, handleVisibilityChange]);

  useEffect(() => {
    refreshSkippedPin();

    if (
      isStandalone &&
      // check user choice about pin skip
      (!skippedPin || skippedPin === PinCode.NONE) &&
      // check entered pin is correct and NOT exipired by timer
      !pinCheckOk &&
      // what current show pin modal
      !isPinCodeModalOpen &&
      // what current show file open modal
      !isFileUploadOpen &&
      // do not show pin screen if safe time not expired
      isPinExpired
    ) {
      openPinCodeModal();
    }
  }, [
    isPinCodeModalOpen,
    openPinCodeModal,
    refreshSkippedPin,
    pinCheckOk,
    skippedPin,
    isStandalone,
    isFileUploadOpen,
    isPinExpired,
  ]);

  const setPinMode = !(skippedPin && tsPin);

  const handlePinCode = (pinCode: string) => {
    if (!setPinMode) {
      if (check(pinCode, tsPin)) {
        setPinCheckOk(true);
        closePinCodeModal();
      } else {
        errorSnackbar(enqueueSnackbar, 'Wrong PIN Code');
      }
    } else {
      setPinCheckOk(true);
      closePinCodeModal();
      setSkippedPin(PinCode.NONE);
      setTsPin(encrypt(pinCode));
    }
  };

  const handleSkip = () => {
    setSkippedPin(PinCode.SKIP);
    closePinCodeModal();
  };

  return isPinCodeModalOpen ? (
    <PinCodeComponent
      showForgot={!setPinMode}
      showSkip={setPinMode}
      needConfirm={setPinMode}
      handleSkip={handleSkip}
      handlePinCode={handlePinCode}
    />
  ) : null;
};

export default PinCodeWrapper;
