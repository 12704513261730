import config from './config';

const pageTitles = {
  AllLocations: 'Trans Dating | Bendr',
  BuyTopUps: 'Payment - Top ups',
  ChooseMembership: 'Payment - Choose Membership',
  PurchaseVip: 'Payment - Choose Membership',
  AddCreditCard: 'Add/Edit Card',
  ContactUs: 'Contact Us',
  UnderConstruction: 'Under Construction',
  ErrorPage: 'Oooops',
  PaymentRedirectPage: 'Payment',
  Refund: 'Refund',
  PasswordResetConfirmPage: 'Reset Password',
  UserPhoneVerification: 'Verification',
  SignUpPage: 'Create an Account',
  SignInComponent: 'Login',
  PasswordResetPage: 'Reset Password',
  ImpersonatePage: "User's impersonate",
  AllowCookieGuidesPage: 'How to allow cookies',
  AllowCookieGuideDetails: 'How to allow cookies',
  VerificationContainer: 'Verification',
  BlockedUserListPage: 'Blocked Users',
  ChangeUserEmailConfirmPage: 'Change email confirm',
  PinCodeSettings: 'PIN Code',
  ChangeTextSettingsComponent: 'Change Text Settings',
  BillingAndPaymentsComponent: 'Billing & Payment',
  GoldMembershipChoosePage: 'Gold membership',
  FaqListPage: 'FAQ',
  MoveProfileComponent: 'Change location',
  CloseAccountComponent: 'Close Account',
  ChangePasswordComponent: 'Change Password',
  ChangeEmailComponent: 'Change Email',
  ChangeLanguageComponent: 'Change Language',
  CreateProfileForm: 'New Profile',
  EditBusinessProfileForm: 'Edit Profile',
  EditProfileForm: 'Edit Profile',
  ChatListPage: 'Chats',
  ChatSearchUserPage: 'Search',
  PaymentCreationCreditCard: 'Billing Details',
  ChooseProfileTypePage: 'Create an Account',
  BitcoinPaymentCreation: 'Billing Details',
  ComplimentaryActivationPage: 'Choose Membership',
  ShemaleReferences: 'Shemale References',
  SettingsComponent: 'My Account',
  ChooseRechargeBudgetComponent: 'Recharge budget',
  FavoritesContainer: 'Favorites',
  SignUpLandingPage: 'Sign up',
  TermsAndConditions: `${config.app.name} Terms of Use`,
  FaqDetailPage: 'FAQ',
  PrivacyPolicy: 'Bendr Privacy Policy',
  AboutUs: `About ${config.app.title}`,
  ProfileReportPage: 'Send Report',
  TwoFactorAuthPage: 'Security Verification Required',
  Page404: 'Not Found',
};

export default pageTitles;
