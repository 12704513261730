import palette from '~/styles/palette';

import SpriteIcon, {fontSizes, TSpriteIconName} from './ui/icons/SpriteIcon';

interface Props {
  name?: TSpriteIconName & 'logo-bendr';
  className?: string;
  size?: keyof typeof fontSizes;
  color?: string;
}

const Logo: React.FC<Props> = ({
  className,
  name = 'logo-bendr',
  size = 'custom',
  color = palette.primary.main,
}) => {
  return name && <SpriteIcon name={name} color={color} className={className} fontSize={size} />;
};

export default Logo;
