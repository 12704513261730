import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import DotStatus from '~/components/ui/DotStatus';
import GenderBadge from '~/components/GenderBadge';
import palette from '~/styles/palette';
import {TProfile} from '~/types/Profile';

import {
  currentProfileSelector,
  currentUserLocationSelector,
  currentUserSelector,
  isProfileCompletedSelector,
} from '../../../store/selectors';
import ProfileCardAvatar from './ProfileCardAvatar';

const useStyles = makeStyles((theme) => ({
  Button: {
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  GenderBadge: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  avatarBox: {
    display: 'flex',
    position: 'relative',
  },
  name: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: 1.2,
  },
  locationTitle: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
    marginBottom: 7,
  },
}));

const ViewProfileCardDrawer = () => {
  const s = useStyles();
  const location = useSelector(currentUserLocationSelector);
  const profile = useSelector(currentProfileSelector);
  const user = useSelector(currentUserSelector);
  const isProfileCompleted = useSelector(isProfileCompletedSelector);
  const isReady = user && profile && isProfileCompleted;
  const {name, profileUrl} = profile || {};
  const {borough, city, country, state} = location ?? {};

  const locationTitle = useMemo(() => {
    const cityName = city?.name;
    if (state) {
      return `${cityName}, ${state?.code}`;
    }
    if (borough) {
      return `${cityName}, ${borough?.name}`;
    }
    return `${cityName}, ${country?.name}`;
  }, [borough, city?.name, country?.name, state]);

  const profileContent = (
    <Box display="flex">
      <Box className={s.avatarBox}>
        <ProfileCardAvatar isBorder={false} />
        <Box position="absolute" bottom={0} right={0}>
          <DotStatus style={{backgroundColor: palette.primary.dark}} />
        </Box>
      </Box>
      {isReady && (
        <Box ml={1} display="flex" flexDirection="column" alignItems="flex-start">
          <div className={s.name}>{name}</div>
          {city && <div className={s.locationTitle}>{locationTitle}</div>}
          <GenderBadge profile={profile as TProfile} />
        </Box>
      )}
    </Box>
  );

  return (
    <Box position="relative">
      {isReady ? (
        <Button
          to={profileUrl ?? ''}
          variant="text"
          component={Link}
          color="inherit"
          disabled={!profileUrl}
          className={s.Button}
        >
          {profileContent}
        </Button>
      ) : (
        <div className={s.Button}>{profileContent}</div>
      )}
    </Box>
  );
};

export default ViewProfileCardDrawer;
