import {TProfileTypeUrl} from '~/constants/profiles';
import {SortingMethodsType} from '~/types/appTypes';
import {TSpriteIconName} from '~/components/ui/icons/SpriteIcon';

export interface TRouteParams {
  profileTypeUrl: TProfileTypeUrl;
}

export enum TSearchType {
  CurrentLocation = 'current-location',
  Global = 'global',
}

export interface SortingMethod {
  key: SortingMethodsType;
  title: string;
  icon: TSpriteIconName;
}
