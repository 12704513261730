import {APP_NOTIFICATIONS_PERMISSION, APP_NOTIFICATIONS_TOKEN_UPDATE} from './constants';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateNotificationsPermission = (permission: Record<string, string>) => ({
  type: APP_NOTIFICATIONS_PERMISSION,
  payload: permission,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateNotificationsToken = (token: string) => ({
  type: APP_NOTIFICATIONS_TOKEN_UPDATE,
  payload: token,
});
