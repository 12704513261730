import {useEffect, useMemo, useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';

import ToggleProfile from '~/modules/CurrentUser/Settings/components/ToggleProfile';
import routeByName from '~/constants/routes';
import {TThunkAction} from '~/types/appTypes';
import {TCurrentUserUser} from '~/types/CurrentUserState';
import {currentUserSelector} from '~/modules/CurrentUser/store/selectors';

export const useTopUpProfile = () => {
  const user = useSelector(currentUserSelector);
  const [time, setTime] = useState(0);
  const interval = useRef<NodeJS.Timeout | null>();
  const topupValidityInterval = (user?.topup_validity_interval ?? 0) * 1000;
  const timeSinceTopUp = useMemo(() => +new Date() - (user?.last_topup ?? 0) * 1000, [user]);

  useEffect(() => {
    if (timeSinceTopUp < topupValidityInterval) {
      setTime(topupValidityInterval - timeSinceTopUp);

      interval.current = setInterval(() => {
        setTime((_time) => _time - 1000);
      }, 1000);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [topupValidityInterval, timeSinceTopUp]);

  useEffect(() => {
    if (time < 0 && interval.current) {
      clearInterval(interval.current);
    }
  }, [time]);

  return {
    canTopUpProfile: time <= 0,
    timeToNextTopup: moment(time).format('m:ss'),
  };
};

export const getLinksList = ({
  isProfileClient,
  isProfileCompleted,
  isStandalone,
  handleClickLogout,
  user,
}: {
  isProfileClient: boolean;
  isProfileCompleted: boolean;
  isStandalone: boolean;
  handleClickLogout: TThunkAction;
  user: TCurrentUserUser | null;
}) =>
  [
    user?.profileId && {
      route: routeByName.profileForm,
      label: 'Edit profile',
      icon: 'edit',
    },
    {
      route: routeByName.billingAndPayments,
      label: 'Billing & Payment',
      icon: 'billing',
    },
    !isProfileClient && {
      // Except Straight Males
      route: routeByName.textSettings,
      label: 'Text Settings',
      icon: 'cogwheel',
    },
    {
      route: routeByName.blockedList,
      label: 'Blocked Users',
      icon: 'lock-user',
      color: '#808080',
    },
    isProfileCompleted && {
      label: 'Change location',
      icon: 'location-marker-settings',
      route: routeByName.moveAd,
      // component: ChangeLocation,
    },
    isStandalone && {
      route: routeByName.setPinCode,
      label: 'PIN Code',
      icon: 'pin-lock',
    },
    {
      route: routeByName.changePassword,
      label: 'Change Password',
      icon: 'lock-update',
      color: '#808080',
    },
    {
      route: routeByName.changePhone,
      label: 'Change Phone Number',
      icon: 'phone-change',
      color: '#808080',
    },
    {
      route: routeByName.changeEmail,
      label: 'Change Email',
      icon: 'email',
      color: '#808080',
    },

    {
      label: 'Disable profile',
      icon: 'power',
      component: ToggleProfile, // TODO: move to PAGE instead of MODAL
    },
    {
      route: routeByName.closeAccount,
      label: 'Close Account',
      icon: 'circle-close',
    },
    {
      label: 'Logout',
      icon: 'logout',
      color: '#808080',
      onClick: handleClickLogout,
    },
  ].filter(Boolean);
