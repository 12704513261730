export const feedbackTypes = {
  improvements: 2,
  problems: 3,
};

export const feedbackTabsConfig = {
  [feedbackTypes.improvements]: {
    value: feedbackTypes.improvements,
    icon: 'lamp',
    label: 'Improvements',
    placeholder: 'Tell us how we can improve this app!',
  },
  [feedbackTypes.problems]: {
    value: feedbackTypes.problems,
    icon: 'circle-exclamation',
    label: 'Problems / Errors',
    placeholder:
      'Please tell us what kind of problems or errors you had during your stay on our website.' +
      ' We apologize for any inconvenience and we will do our best to fix it',
  },
};

export const formFields = {
  rate: 'rate',
  type: 'type',
  content: 'content',
  email: 'email',
  year: 'year',
};
