import {addErrorNotificationAction} from '~/rootStore/notifications/notificationsReducer';
import {TThunkAction} from '~/types/appTypes';
import logger from '~/utils/logger';
import {startLoader, stopLoader} from '~/rootStore/loaders/loadersReducer';

import {syncProfileStatsAction, syncProfileVerificationDataAction} from '../../store/actions';
import loadCurrentUserAction from '../../store/actions/loadCurrentUserAction';
import {LOADER_SETTINGS_PAGE} from '../constants/loadersIds';
import {SET_PENDING_PAYMENTS} from '../constants/payments';
import PaymentsService from '../../../Payments/PaymentsService';

const log = logger.module('SettingsPageActions');

type TLoadSettingsPageAction = () => TThunkAction;

export const loadSettingsPageAction: TLoadSettingsPageAction = () => async (dispatch) => {
  dispatch(startLoader(LOADER_SETTINGS_PAGE));
  try {
    await dispatch(syncProfileStatsAction());
    await Promise.all([loadCurrentUserAction(), syncProfileVerificationDataAction()].map(dispatch));
    dispatch(stopLoader(LOADER_SETTINGS_PAGE));
  } catch (error) {
    log.error('Error: load settings page', {error});
    dispatch(addErrorNotificationAction());
  }
};

type TLoadPaymentsInProgressAction = (profileId: number) => TThunkAction;

export const loadPaymentsInProgressAction: TLoadPaymentsInProgressAction =
  (profileId) => async (dispatch) => {
    try {
      const pendingPayments = await PaymentsService.getProfilePendingPayments(profileId);

      dispatch({
        type: SET_PENDING_PAYMENTS,
        payload: pendingPayments,
      });
    } catch (error) {
      log.error('Error during load pending payments', {error});
    }
  };
