"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var effector_1 = require("effector");
var QBAdapter_1 = require("../QBAdapter");
var voidStream_1 = require("../utils/voidStream");
exports.default = (function (get) {
    var editGroup = effector_1.createEffect().use(QBAdapter_1.editGroupDialog);
    effector_1.forward({
        from: editGroup.done.map(voidStream_1.voidStream),
        to: get,
    });
    return editGroup;
});
