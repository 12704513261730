import {StyleRules} from '@material-ui/core';
import {Palette} from '@material-ui/core/styles/createPalette';

export default (palette: Palette): StyleRules => ({
  root: {
    color: palette.text.primary,
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0.2,
    '&.Mui-valid': {
      color: palette.success.main,
    },
  },
});
