"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var QBAdapter_1 = require("../QBAdapter");
var effector_1 = require("effector");
var array2map_1 = require("../utils/array2map");
var pick_1 = require("../utils/pick");
exports.default = (function (Contacts$) {
    var get = effector_1.createEffect().use(QBAdapter_1.getContacts);
    Contacts$.on(get.done, function (_state, _a) {
        var result = _a.result;
        return array2map_1.array2Map(result.items.map(pick_1.pick('user')), 'id');
    });
    return get;
});
