"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUserFromQBData = void 0;
exports.getUserFromQBData = function (userData) {
    var extendedData = {};
    if (userData.extendedData) {
        extendedData = __assign({}, userData.extendedData);
    }
    try {
        var customData = userData.custom_data
            ? JSON.parse(userData.custom_data)
            : {};
        extendedData = __assign(__assign({}, extendedData), customData);
    }
    catch (e) {
        console.error('Error in parse user custom_data for id: $1, login: $2', userData.id, userData.login);
    }
    return {
        id: userData.id,
        login: userData.login,
        full_name: userData.full_name || extendedData.username || 'No name',
        created_at: userData.created_at,
        last_request_at: userData.last_request_at,
        updated_at: userData.updated_at,
        external_user_id: userData.external_user_id,
        extendedData: extendedData,
    };
};
