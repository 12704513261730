"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var effector_1 = require("effector");
var QBAdapter_1 = require("../QBAdapter");
exports.default = (function (getContacts) {
    var add = effector_1.createEffect().use(QBAdapter_1.addUser);
    effector_1.forward({
        from: add.done,
        to: getContacts,
    });
    return add;
});
