import {useCallback, useState} from 'react';

import DropDownComponent from './DropDownComponent';

const DropDown = (props) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return <DropDownComponent {...props} expanded={expanded} onClick={toggle} />;
};

export default DropDown;
