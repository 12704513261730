import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {LayoutControl} from '~/components/Layout';
import routeByName from '~/constants/routes';
import pageTitles from '~/constants/pageTitles';

import LocationsSection from './LocationsSection';
import headerBgDesktop from './images/bg_desktop.jpg';
import headerBgMobile from './images/bg_mobile.jpg';
import AboutSection from './AboutSection';
import BenefitsSection from './BenefitsSection/BenefitsSection';
import WhatDoesMeanSection from './WhatDoesMeanSection';
import AdviceSection from './AdviceSection/AdviceSection';
import AllLocationsFooter from './AllLocationsFooter';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: `url(${headerBgMobile})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    maxHeight: '667px',
    '@media (min-width: 768px)': {
      backgroundImage: `url(${headerBgDesktop})`,
      height: '100%',
      maxHeight: '864px',
    },
  },
  headerTitle: {
    fontSize: 48,
    fontWeight: 700,
    '@media (min-width: 768px)': {
      fontSize: 100,
    },
  },
  btn: {
    background: 'linear-gradient(180deg, #BE2FDA 11.77%, #943EFC 100%)',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontSize: 20,
    padding: '18px 54px',
  },
}));

const AllLocations = () => {
  const s = useStyles();

  return (
    <>
      <LayoutControl
        title={pageTitles.AllLocations}
        description="Bendr is the Ultimate Safe Space for People to Meet Transgender Women & Trans Attracted Men! Join Today to Browse, Find & Meet Like Minded People Near You."
        keywords="trans dating, ts dating, transgender dating, trans attracted, trans dating site, transgender dating site, trans date, ts dating site, transgender dating website, trans friendly dating apps, best transgender dating site, best trans dating apps"
        contentClass="bg-white p-0"
        hideHeader
      />
      <div className={s.header}>
        <div className={s.headerTitle}>Date Trans</div>
        <Button
          component={Link}
          to={routeByName.signUp}
          color="default"
          className={s.btn}
          fullWidth={false}
        >
          Create account
        </Button>
      </div>
      <AboutSection />
      <BenefitsSection />
      <AdviceSection />
      <WhatDoesMeanSection />
      <AllLocationsFooter />
      <LocationsSection />
    </>
  );
};

export default AllLocations;
