import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import {useRouteMatch} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import routeByName from '~/constants/routes';
import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import {MODAL_CHOOSE_LOCATION} from '~/rootStore/modals/modalsIds';
import useModalById from '~/customHooks/useModalById';
import {getHeaderLocationSelector} from '~/modules/Locations/store/selectors';

import {TSearchType} from '../../types';
import ProfileChooseLocationModal from './ProfileChooseLocationModal';

const useStyles = makeStyles((theme) => ({
  locationMarkerBtn: {
    background: theme.palette.background.green1,
    border: 'none',
    height: '100%',
    maxWidth: 125,
  },
  locationMarkerIcon: {
    color: theme.palette.common.white,
    width: 14,
    height: 19,
    flexShrink: 0,
  },
  locationLabel: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 600,
  },
}));

const ProfileChooseLocationButton = () => {
  const s = useStyles();
  const userLocationRouteMatch = useRouteMatch(routeByName.profileListUserLocation);
  const headerLocation = useSelector(getHeaderLocationSelector);
  const {open: openProfileChooseLocationModal} = useModalById(MODAL_CHOOSE_LOCATION);

  const locationLabel = useMemo(() => {
    const searchType = userLocationRouteMatch ? TSearchType.CurrentLocation : TSearchType.Global;

    return searchType === TSearchType.CurrentLocation ? 'My location' : headerLocation?.name;
  }, [userLocationRouteMatch, headerLocation]);

  return (
    <>
      <Button
        size="small"
        className={s.locationMarkerBtn}
        onClick={() => {
          openProfileChooseLocationModal();
        }}
      >
        <SpriteIcon name="location-marker" className={s.locationMarkerIcon} fontSize="custom" />
        <span className={clsx(s.locationLabel, 'text-overflow-ellipsis')}>{locationLabel}</span>
      </Button>
      <ProfileChooseLocationModal />
    </>
  );
};

export default ProfileChooseLocationButton;
