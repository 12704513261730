import {TThunkAction} from '~/types/appTypes';

import {SET_CURRENT_USER_COUNTRY_CODE} from '../../App/store/constants';

type TSetCountryCodeAction = (countryCode: string) => TThunkAction;

const setCountryCodeAction: TSetCountryCodeAction = (countryCode) => (dispatch) => {
  dispatch({type: SET_CURRENT_USER_COUNTRY_CODE, payload: {countryCode}});
};

export default setCountryCodeAction;
