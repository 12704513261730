import Modal from '@material-ui/core/Modal';
import React from 'react';

import useModalById from '~/customHooks/useModalById';
import {MODAL_PROFILE_FILTERS} from '~/rootStore/modals/modalsIds';

import ProfileFiltersBendr from '../ProfileFilters/ProfileFiltersBendr';

const ProfileListFiltersModal: React.FC = () => {
  const modal = useModalById(MODAL_PROFILE_FILTERS);
  return (
    <Modal disableScrollLock open={modal.isOpen} onClose={modal.close}>
      <>
        <ProfileFiltersBendr />
      </>
    </Modal>
  );
};

export default ProfileListFiltersModal;
