import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Box, Button, Typography} from '@material-ui/core';

import routeByName from '~/constants/routes';
import {navigateRefreshAction} from '~/actions/navigateActions';
import useModalById from '~/customHooks/useModalById';
import {MODAL_VERIFY_PROFILE_LOCK} from '~/rootStore/modals/modalsIds';

import imageUpgradeToPaid from './img/icon-checked-green.svg';
import ModalBase from '../ModalBase';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  roundedButton: {
    borderRadius: 10,
  },
}));

const ModalVerifyProfileLock: React.FC = () => {
  const s = useStyles();
  const {isOpen, close: closeVerifyProfileLockModal} = useModalById(MODAL_VERIFY_PROFILE_LOCK);
  const dispatch = useDispatch();

  const handleActionClick = useCallback(() => {
    closeVerifyProfileLockModal();
    dispatch(navigateRefreshAction(routeByName.identityVerification));
  }, [closeVerifyProfileLockModal, dispatch]);

  return (
    <ModalBase open={isOpen} onClose={() => undefined}>
      <Box textAlign="center" p={2}>
        <Box mb={2}>
          <img src={imageUpgradeToPaid} alt="Verify profile" />
        </Box>
        <Box mb={1}>
          <Typography variant="h6">
            <b>Verify your Profile</b>
          </Typography>
        </Box>
        <Box mb={3} textAlign="center">
          <Typography variant="body2">
            Get more profile views, be on top, get verified now!
          </Typography>
        </Box>
        <Box alignSelf="stretch">
          <Button className={s.roundedButton} onClick={handleActionClick}>
            <b>Verify Now</b>
          </Button>
        </Box>
      </Box>
    </ModalBase>
  );
};

export default ModalVerifyProfileLock;
