import {omit} from 'lodash-es';

import logger from '~/utils/logger';
import {TProfile} from '~/types/Profile';
import {TAction} from '~/types/appTypes';

import {
  PROFILES_CACHE_CHAT_PROFILE,
  PROFILE_CACHE_CHAT_DATA,
  PROFILES_CACHE_PROFILE,
  PROFILES_CACHE_CLEAR,
  PROFILES_CACHE_PROFILE_BATCH,
  RESET_PROFILE_FILTERS,
  SET_PROFILE_FILTERS,
  SET_FILTER_PROFILE_TYPE,
  SET_PROFILES_LOCATION_ID,
  SET_LOCATION_FILTERS,
  UPDATE_NAVIGATION_FILTERS,
  SET_LAST_FILTERS_QUERY,
  SET_SORTING_METHOD,
  SET_LOCATION_PROFILES,
  RESET_LOCATION_PROFILES,
  SET_TUTORIAL_PROPS,
  RESET_NAVIGATION_FILTERS,
  SET_CHAT_PROFILE_IS_LOADING,
  PROFILE_CACHE_CLEAR,
  SET_PROFILE_FILTERS_OPTIONS,
  UPDATE_PROFILES_LAST_ACTIVITY,
} from './constants';
import {TProfilesState, TTabs} from './types';
import {
  getStoredTutorial,
  getStoredNavigationFilters,
  getStoredProfileFilters,
} from './localStorage';

const log = logger.module('profiles/store');

const navigationFiltersDefaults = {
  currentProfileIndex: 0,
  currentProfileId: undefined,
  totalProfilesCount: 0,
  prevProfileIndex: 0,
  activeTab: TTabs.CHAT,
};

const tutorialPropsDefaults = {
  show: true,
  started: false,
};

const initialState: TProfilesState = {
  routeToProfileId: {},
  profiles: {},
  profileIdToOpponent: {},
  profileIdToChat: {},
  profileIdToChatLoading: {},
  locationProfiles: [],
  sortingMethod: null,
  filters: {
    profileType: null,
    profileFiltersOptions: {},
    profileFilters: getStoredProfileFilters(),
    locationFilters: {},
    navigationFilters: getStoredNavigationFilters(navigationFiltersDefaults),
  },
  lastFiltersQuery: undefined,
  locationId: null,
  tutorial: getStoredTutorial(tutorialPropsDefaults),
};

const profilesReducer = (state = {...initialState}, action: TAction): TProfilesState => {
  switch (action.type) {
    case PROFILES_CACHE_PROFILE_BATCH: {
      const {profiles} = action.payload;
      const profileUrls = profiles.reduce(
        (accum: Record<number, string>, item: TProfile) => ({
          ...accum,
          [item.id]: item.profileUrl,
        }),
        {}
      );
      const profilesById = profiles.reduce(
        (accum: Record<number, TProfile>, item: TProfile) => ({
          ...accum,
          [item.id]: item,
        }),
        {}
      );

      return {
        ...state,
        routeToProfileId: {
          ...state.routeToProfileId,
          ...profileUrls,
        },
        profiles: {
          ...state.profiles,
          ...profilesById,
        },
      };
    }
    case PROFILES_CACHE_PROFILE: {
      const {profile} = action.payload;
      const {profileUrl: routeString} = profile;
      if (!routeString) {
        log.error('profileURL is missing', {profile});
        return {
          ...state,
          profiles: {
            ...state.profiles,
            [profile.id]: profile,
          },
        };
      }
      return {
        ...state,
        routeToProfileId: {
          ...state.routeToProfileId,
          [routeString as string]: profile.id,
        },
        profiles: {
          ...state.profiles,
          [profile.id]: profile,
        },
      };
    }
    case SET_FILTER_PROFILE_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          profileType: action.payload,
        },
      };
    case SET_PROFILE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          profileFilters: action.payload,
        },
      };
    case RESET_PROFILE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          profileFilters: {},
        },
      };
    case SET_LOCATION_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          locationFilters: action.payload,
        },
      };
    case UPDATE_NAVIGATION_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          navigationFilters: {
            ...state.filters.navigationFilters,
            ...action.payload,
          },
        },
      };
    case SET_LAST_FILTERS_QUERY:
      return {
        ...state,
        lastFiltersQuery: {
          ...state.lastFiltersQuery,
          ...action.payload,
        },
      };
    case PROFILES_CACHE_CHAT_PROFILE:
      return {
        ...state,
        profileIdToOpponent: {
          ...state.profileIdToOpponent,
          ...action.payload,
        },
      };
    case PROFILE_CACHE_CHAT_DATA:
      return {
        ...state,
        profileIdToChat: {
          ...state.profileIdToChat,
          ...action.payload,
        },
      };
    case SET_CHAT_PROFILE_IS_LOADING:
      return {
        ...state,
        profileIdToChatLoading: {
          ...state.profileIdToChatLoading,
          ...action.payload,
        },
      };
    case PROFILES_CACHE_CLEAR:
      return {
        ...state,
        profileIdToOpponent: {},
        profileIdToChat: {},
        profileIdToChatLoading: {},
      };
    case PROFILE_CACHE_CLEAR:
      return {
        ...state,
        profileIdToOpponent: omit(state.profileIdToOpponent, action.payload.profileId),
        profileIdToChat: omit(state.profileIdToChat, action.payload.profileId),
        profileIdToChatLoading: omit(state.profileIdToChatLoading, action.payload.profileId),
      };
    case SET_PROFILES_LOCATION_ID: {
      return {
        ...state,
        locationId: action.payload.locationId,
      };
    }
    case SET_LOCATION_PROFILES: {
      const tmpList = new Set(
        action.payload.direction === 'backward'
          ? [...action.payload.profileIds, ...state.locationProfiles]
          : [...state.locationProfiles, ...action.payload.profileIds]
      );

      return {
        ...state,
        locationProfiles: Array.from(tmpList),
      };
    }
    case SET_SORTING_METHOD: {
      return {
        ...state,
        sortingMethod: action.payload,
      };
    }
    case RESET_LOCATION_PROFILES: {
      return {
        ...state,
        routeToProfileId: {},
        profiles: {},
        locationProfiles: [],
      };
    }
    case SET_TUTORIAL_PROPS: {
      return {
        ...state,
        tutorial: {
          ...state.tutorial,
          ...action.payload,
        },
      };
    }
    case RESET_NAVIGATION_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          navigationFilters: navigationFiltersDefaults,
        },
      };
    }
    case SET_PROFILE_FILTERS_OPTIONS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          profileFiltersOptions: action.payload,
        },
      };
    }
    case UPDATE_PROFILES_LAST_ACTIVITY: {
      const {profiles} = state;
      const {lastActivities} = action.payload;

      lastActivities.forEach(({profile_id, last_activity_stamp}: any) => {
        if (profiles[profile_id] && last_activity_stamp) {
          profiles[profile_id].last_activity_stamp = parseInt(last_activity_stamp, 10);
        }
      });

      return {
        ...state,
        profiles: {...profiles},
      };
    }
    default:
      return state;
  }
};

export default profilesReducer;
