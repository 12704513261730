"use strict";
/**
 * @packageDocumentation @module Users
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.findByLogin = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To find the user by the login.
 */
function findByLogin(login) {
    return chat_1.Users.getByLogin(login);
}
exports.findByLogin = findByLogin;
