import React, {PureComponent} from 'react';
import {Theme} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import {connect} from 'react-redux';

import {navigateBackAction} from '~/actions/navigateActions';
import {TAppState} from '~/types/appTypes';
import {appStatusIsOnlineSelector} from '~/modules/App/store/selectors';
import {isAuthSelector} from '~/modules/CurrentUser/store/selectors';
import ProfileListTopBar from '~/modules/Profiles/ProfileListPage/components/ProfileListTopBar/ProfileListTopBar';

import {layoutOpenDrawerAction} from '../store/actions';
import {layoutSelector} from '../store/selectors';
import {LayoutContext} from '../BaseLayout';

export const topBarHeight = 52;
const scrollOffset = 50;

let scrollDirectionSwitchPoint: number | undefined;
let currentScrollPosition = 0;

interface Props {
  classes: {
    blockWithBackWidthExcluded: string;
    appBar: string;
    topBarHidden: string;
    topBarInvisible: string;
    toolbar: string;
    backBtn: string;
    rightSection: string;
    signUpBtn: string;
    backIcon: string;
    topBarCenterActions: string;
    onlineStatus: string;
  };
  content: React.ReactNode;
  headerClass: string;
  hideHeader: boolean;
  hideHeaderOnScroll: boolean;
  hideTopBarTitle: boolean;
  isAppOnline: boolean;
  isAuth: boolean;
  layoutOpenDrawer: () => void;
  dispatchNavigateBackAction: () => void;
  title: string;
}

class TopBarBendr extends PureComponent<Props> {
  barRef?: HTMLElement | undefined; // eslint-disable-line react/sort-comp

  state = {
    height: topBarHeight,
    hide: false,
  };

  componentDidMount() {
    this.checkHeight();
    window.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate() {
    this.updateHideOnScroll();
    this.checkHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    const {hideHeaderOnScroll} = this.props;
    if (!hideHeaderOnScroll) {
      return;
    }
    const {height} = this.state;
    // Set switch direction point
    if (
      // switch direction from down to up
      (!scrollDirectionSwitchPoint && currentScrollPosition > window.scrollY) ||
      // switch direction from up to down
      (scrollDirectionSwitchPoint &&
        scrollDirectionSwitchPoint > currentScrollPosition &&
        currentScrollPosition < window.scrollY)
    ) {
      scrollDirectionSwitchPoint = currentScrollPosition;
    }

    currentScrollPosition = window.scrollY;

    // If current scroll less than header height
    if (currentScrollPosition <= height) {
      scrollDirectionSwitchPoint = undefined;
      this.setState({hide: false});
      return;
    }

    // on scroll down hide header if scroll position higher than header height
    if (!scrollDirectionSwitchPoint && currentScrollPosition > height) {
      this.setState({hide: true});
      return;
    }

    // if we have switch direction point
    if (scrollDirectionSwitchPoint) {
      // switch direction from down to up, check offset boundary
      if (
        currentScrollPosition < scrollDirectionSwitchPoint &&
        scrollDirectionSwitchPoint - currentScrollPosition > scrollOffset
      ) {
        this.setState({hide: false});
        return;
      }

      // switch direction from up to down, check offset boundary and reset switch point
      if (
        currentScrollPosition > scrollDirectionSwitchPoint &&
        currentScrollPosition - scrollDirectionSwitchPoint > scrollOffset
      ) {
        scrollDirectionSwitchPoint = undefined;
        this.setState({hide: true});
      }
    }
  };

  setRef = (node: HTMLElement) => {
    if (node) {
      this.barRef = node;
    }
  };

  updateHideOnScroll() {
    const {hideHeaderOnScroll} = this.props;
    const {hide} = this.state;
    if (!hideHeaderOnScroll && hide) {
      this.setState({hide: false});
    }
  }

  checkHeight() {
    if (this.barRef) {
      this.setState({
        height: this.barRef.clientHeight,
      });
    }
  }

  render() {
    const {classes, headerClass, hideHeader, isAppOnline, content, title, hideTopBarTitle} =
      this.props;
    const {hide} = this.state;

    return (
      <LayoutContext.Consumer>
        {({onBack}) => (
          <AppBar
            position="static"
            color="inherit"
            className={clsx(classes.appBar, headerClass, {
              [classes.topBarHidden]: hide,
              [classes.topBarInvisible]: hideHeader,
            })}
            ref={this.setRef}
            elevation={0}
          >
            {!isAppOnline && <div className={classes.onlineStatus}>offline</div>}
            <ProfileListTopBar
              content={content}
              onBack={onBack}
              title={hideTopBarTitle ? undefined : title}
            />
          </AppBar>
        )}
      </LayoutContext.Consumer>
    );
  }
}
// @ts-expect-error something
TopBarBendr.defaultProps = {
  hideHeaderOnScroll: true,
};

const styles = (theme: Theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    zIndex: 100,
    top: 0,
    transition: 'all .2s linear',
  },
  topBarHidden: {
    transform: 'translateY(-110%)',
  },
  topBarInvisible: {
    display: 'none',
  },
  toolbar: {
    padding: '0 12px',
    minHeight: topBarHeight,
    svg: {fill: 'black'},
  },
  blockWithBackWidthExcluded: {
    width: 'calc(100% - 40px)',
  },
  rightSection: {
    marginRight: '8px',
    flexShrink: 0,
  },
  signUpBtn: {
    marginLeft: 5,
    padding: '2px 5px',
    borderColor: 'transparent',
    boxShadow: 'none',
  },
  topBarCenterActions: {
    minWidth: 0,
  },
  onlineStatus: {
    textAlign: 'center',
    fontSize: '12px',
    backgroundColor: theme.palette.common.black,
  },
});

// @ts-expect-error something
const TopBarBendrStyled = withStyles(styles)(TopBarBendr);

const mapStateToProps = (state: TAppState) => {
  const {
    title,
    hideHeader,
    headerClass,
    hideHeaderOnScroll,
    headerContent,
    headerBottomContent,
    hideTopBarTitle,
  } = layoutSelector(state);
  const isAuth = isAuthSelector(state);

  const isAppOnline = appStatusIsOnlineSelector(state);

  return {
    title,
    content: headerContent,
    bottomContent: headerBottomContent,
    hideHeader,
    headerClass,
    hideHeaderOnScroll,
    hideTopBarTitle,
    isAuth,
    isAppOnline,
  };
};

export default connect(mapStateToProps, {
  dispatchNavigateBackAction: navigateBackAction,
  layoutOpenDrawer: layoutOpenDrawerAction,
  // @ts-expect-error something
})(TopBarBendrStyled);
