import {FC, useCallback, ReactNode} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {Button, Grid} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Location} from 'history';
import clsx from 'clsx';

import routeByName from '~/constants/routes';
import {isAuthSelector} from '~/modules/CurrentUser/store/selectors';
import ButtonBlack from '~/components/ui/buttons/ButtonBlack';
import SpriteIcon, {TSpriteIconName} from '~/components/ui/icons/SpriteIcon';
import {MODAL_PROFILE_FILTERS} from '~/rootStore/modals/modalsIds';
import {navigateHomeAction} from '~/actions/navigateActions';
import useModalById from '~/customHooks/useModalById';
import {isRouteEqual} from '~/helpers/routes/routes';
import Typography from '~/components/ui/Typography';
import {layoutOpenDrawerAction, ChooseLanguageContainer} from '~/components/Layout';

import {profileFiltersCount} from '../../../store/selectors';
import ProfileListSort from './ProfileListSort';
import ProfileListFiltersModal from './ProfileListFiltersModal';
import ProfileChooseLocationButton from './ProfileChooseLocationButton';

// TODO: move top bar to Layout

const useStyles = makeStyles((theme) => ({
  withBg: {},
  root: {
    padding: theme.spacing(1),
    alignItems: 'center',
    '&$withBg': {
      background: 'linear-gradient(180deg, #C22ED7 0%, #9040FF 100%)',
    },
    height: '52px', // temporary hack, until move this to Layout, where
  },
  rightButtons: {
    flexShrink: 0,
    marginLeft: 'auto',
  },
  icon: {
    width: 18,
    height: 18,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    color: 'white',
    fontSize: '18px',
    paddingRight: '4px',
  },
  mainColor: {
    color: theme.palette.primary.main,
  },
  locationMarkerBtn: {
    background: theme.palette.background.green1,
    border: 'none',
    height: '100%',
  },
  locationMarkerIcon: {
    color: theme.palette.common.white,
    width: 14,
    height: 19,
  },
  signInBtn: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  signUpBtn: {
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
  },
  filtersCount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 18,
    height: 18,
    marginLeft: theme.spacing(1),
    background: theme.palette.background.green1,
    borderRadius: '50%',
  },
}));

export const Hamburger = ({onClick}: {onClick: () => void}) => {
  const s = useStyles();
  return (
    <ButtonBlack aria-label="Menu" onClick={onClick}>
      <SpriteIcon name="hamburger" className={clsx(s.icon)} fontSize="custom" />
    </ButtonBlack>
  );
};

const NavigationButton: FC<{
  iconName: TSpriteIconName;
  className?: string;
  onClick?: () => void;
}> = ({className, onClick, iconName}) => {
  const s = useStyles();
  return (
    <Grid item className={className}>
      <ButtonBlack aria-label="Menu" onClick={onClick}>
        <SpriteIcon className={clsx(s.icon)} name={iconName} fontSize="custom" />
      </ButtonBlack>
    </Grid>
  );
};

const checkAllowedRoutesForProfileFilters = (location: Location): boolean => {
  const allowedRoutes = [routeByName.profileListUserLocation, routeByName.locationPage];
  for (let i = 0; i < allowedRoutes.length; i += 1) {
    const allowedRoute = allowedRoutes[i];
    const isEqual = isRouteEqual(location.pathname, allowedRoute);
    if (isEqual) {
      return true;
    }
  }
  return false;
};

interface Props {
  onBack?: () => void;
  content: ReactNode;
  title?: string;
}

const ProfileListTopBar: FC<Props> = ({onBack, content, title}) => {
  const s = useStyles();
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthSelector);
  const selectedFiltersCount = useSelector(profileFiltersCount);
  const location = useLocation();
  const isShowProfileFilters = checkAllowedRoutesForProfileFilters(location);
  const {open: openProfileListFiltersModal} = useModalById(MODAL_PROFILE_FILTERS);
  const handleOpenFilters = useCallback(() => {
    openProfileListFiltersModal();
  }, [openProfileListFiltersModal]);

  const layoutOpenDrawer = () => dispatch(layoutOpenDrawerAction());
  const handleGoHome = () => dispatch(navigateHomeAction());

  return (
    <>
      <Grid container wrap="nowrap" className={clsx(s.root, s.withBg)}>
        <div className="mr-1">
          {onBack ? (
            <NavigationButton iconName="back" onClick={onBack} />
          ) : (
            <NavigationButton iconName="logo-bendr" onClick={handleGoHome} />
          )}
        </div>
        {!content && title && (
          <Typography variant="h1" className={s.title}>
            {title || ''}
          </Typography>
        )}
        {content || (
          <>
            <ChooseLanguageContainer />
            <Grid item className={s.rightButtons}>
              {isAuth && <Hamburger onClick={layoutOpenDrawer} />}
              {!isAuth && (
                <>
                  <Button
                    className={s.signInBtn}
                    fullWidth={false}
                    size="small"
                    to={routeByName.signIn}
                    component={Link}
                    color="default"
                  >
                    Log in
                  </Button>
                  <Button
                    className={s.signUpBtn}
                    fullWidth={false}
                    size="small"
                    to={routeByName.signUp}
                    component={Link}
                    color="default"
                    variant="outlined"
                  >
                    Sign up
                  </Button>
                </>
              )}
            </Grid>
          </>
        )}
      </Grid>
      {isShowProfileFilters && (
        <Grid container wrap="nowrap" className={s.root}>
          <Grid item className="mr-1">
            <ProfileChooseLocationButton />
          </Grid>
          <div className="d-flex flex-1" />
          <Grid item className="mr-1">
            <ProfileListSort />
          </Grid>
          <Grid item>
            <Button size="small" onClick={handleOpenFilters}>
              <SpriteIcon name="filter" className={clsx(s.icon, 'mr-1')} fontSize="custom" />
              Filters
              {selectedFiltersCount > 0 && (
                <div className={s.filtersCount}>{selectedFiltersCount}</div>
              )}
            </Button>
            <ProfileListFiltersModal />
          </Grid>
        </Grid>
      )}
    </>
  );
};
ProfileListTopBar.displayName = 'ProfileListTopBar';
export default ProfileListTopBar;
