import React, {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';

import {homeListLocationsSelector} from '~/modules/Locations/store/selectors';

import LocationsList from './LocationsList/LocationsList';

const LocationsSection = () => {
  const [expanded, setExpanded] = useState('');
  const locations = useSelector(homeListLocationsSelector);

  const onChange = useCallback(
    (panel) => {
      setExpanded(panel !== expanded ? panel : '');
    },
    [expanded]
  );

  return (
    <>
      <LocationsList
        title="USA"
        locations={locations.usa}
        expanded={expanded}
        onChange={onChange}
      />
      <LocationsList
        title="Canada"
        locations={locations.canada}
        expanded={expanded}
        onChange={onChange}
      />
      <LocationsList
        title="Worldwide"
        locations={locations.worldwide}
        expanded={expanded}
        onChange={onChange}
      />
    </>
  );
};

export default LocationsSection;
