export const MODAL_ALLOW_LOCATION = 'MODAL_ALLOW_LOCATION';
export const MODAL_ALLOW_TEXT_MESSAGES = 'MODAL_ALLOW_TEXT_MESSAGES';
export const MODAL_ANDROID_PWA = 'MODAL_ANDROID_PWA';
export const MODAL_AVAILABILITY_VIP_SLOTS = 'MODAL_AVAILABILITY_VIP_SLOTS';
export const MODAL_CHAT_HEADER_PROFILE = 'MODAL_CHAT_HEADER_PROFILE';
export const MODAL_CHAT_INIT_PROGRESS = 'MODAL_CHAT_INIT_PROGRESS';
export const MODAL_CHAT_UNAVAILABLE = 'MODAL_CHAT_UNAVAILABLE';
export const MODAL_COMPLETE_EASY_PROFILE = 'MODAL_COMPLETE_EASY_PROFILE';
export const MODAL_COMPLETE_PROFILE = 'MODAL_COMPLETE_PROFILE';
export const MODAL_ERROR = 'MODAL_ERROR';
export const MODAL_GOLD_MEMBERSHIP = 'MODAL_GOLD_MEMBERSHIP';
export const MODAL_LOCATION_DISABLED_FLASH = 'MODAL_LOCATION_DISABLED_FLASH';
export const MODAL_NOTIFICATION_PERMISSION = 'MODAL_NOTIFICATION_PERMISSION';
export const MODAL_ORIENTATION_CHANGE = 'MODAL_ORIENTATION_CHANGE';
export const MODAL_PIN_CODE = 'MODAL_PIN_CODE';
export const MODAL_PROFILE_FILTERS = 'MODAL_PROFILE_FILTERS';
export const MODAL_CHOOSE_LOCATION = 'MODAL_CHOOSE_LOCATION';
export const MODAL_PWA_BANNER_MEDIUM = 'MODAL_PWA_BANNER_MEDIUM';
export const MODAL_PWA_BANNER_SMALL = 'MODAL_PWA_BANNER_SMALL';
export const MODAL_REGISTER_ANONYMOUSLY = 'MODAL_REGISTER_ANONYMOUSLY';
export const MODAL_REQUEST_GEO_LOCATION = 'MODAL_REQUEST_GEO_LOCATION';
export const MODAL_SEARCH_CITY = 'MODAL_SEARCH_CITY';
export const MODAL_SEARCH_CITY_BENDR = 'MODAL_SEARCH_CITY_BENDR';
export const MODAL_SENDING_IMAGES_UNAVAILABLE = 'MODAL_SENDING_IMAGES_UNAVAILABLE';
export const MODAL_SIGN_UP_REQUEST = 'MODAL_SIGN_UP_REQUEST';
export const MODAL_TWO_FA_RESEND_NOTICE = 'MODAL_TWO_FA_RESEND_NOTICE';
export const MODAL_UNAUTHORIZED = 'MODAL_UNAUTHORIZED';
export const MODAL_UPGRADE_ESCORT_TO_PAID = 'MODAL_UPGRADE_ESCORT_TO_PAID';
export const MODAL_UPGRADE_TO_PAID = 'MODAL_UPGRADE_TO_PAID';
export const MODAL_VERIFICATION_CODE = 'MODAL_VERIFICATION_CODE';
export const MODAL_VERIFY_PROFILE = 'MODAL_VERIFY_PROFILE';
export const MODAL_VERIFY_PROFILE_LOCK = 'MODAL_VERIFY_PROFILE_LOCK';
export const MODAL_COMPLETE_CHAT_SETTINGS = 'MODAL_COMPLETE_CHAT_SETTINGS';
export const MODAL_CHAT_LIMIT = 'MODAL_CHAT_LIMIT';
