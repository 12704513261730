export const PROFILES_CACHE_PROFILE = 'PROFILES_CACHE_PROFILE';
export const PROFILES_CACHE_CHAT_PROFILE = 'PROFILES_CACHE_CHAT_PROFILE';
export const PROFILE_CACHE_CHAT_DATA = 'PROFILE_CACHE_CHAT_DATA';
export const PROFILES_CACHE_CLEAR = 'PROFILES_CACHE_CLEAR';
export const PROFILE_CACHE_CLEAR = 'PROFILE_CACHE_CLEAR';
export const PROFILES_CACHE_BY_LOCATION_ID = 'PROFILES_CACHE_BY_LOCATION_ID';
export const PROFILES_CACHE_OPTIONS = 'PROFILES_CACHE_OPTIONS';
export const SET_FILTER_PROFILE_TYPE = 'SET_FILTER_PROFILE_TYPE';
export const SET_PROFILE_FILTERS_OPTIONS = 'SET_PROFILE_FILTERS_OPTIONS';
export const SET_PROFILE_FILTERS = 'SET_PROFILE_FILTERS';
export const RESET_PROFILE_FILTERS = 'RESET_PROFILE_FILTERS';
export const SET_LOCATION_FILTERS = 'SET_LOCATION_FILTERS';
export const UPDATE_NAVIGATION_FILTERS = 'UPDATE_NAVIGATION_FILTERS';
export const SET_PROFILES_LOCATION_ID = 'SET_PROFILES_LOCATION_ID';
export const RESET_PROFILE_SWIPER = 'RESET_PROFILE_SWIPER';
export const NEXT_PROFILE = 'NEXT_PROFILE';
export const PREV_PROFILE = 'PREV_PROFILE';
export const SET_LAST_FILTERS_QUERY = 'SET_LAST_FILTERS_QUERY';
export const SET_SORTING_METHOD = 'SET_SORTING_METHOD';
export const SET_CHAT_PROFILE_IS_LOADING = 'SET_CHAT_PROFILE_IS_LOADING';
export const UPDATE_PROFILES_LAST_ACTIVITY = 'UPDATE_PROFILES_LAST_ACTIVITY';

export const LOADER_LOAD_MORE_PROFILES = 'LOADER_LOAD_MORE_PROFILES';
export const PROFILES_CACHE_PROFILE_BATCH = 'PROFILES_CACHE_PROFILE_BATCH';

export const RESET_NAVIGATION_FILTERS = 'RESET_NAVIGATION_FILTERS';
export const SET_LOCATION_PROFILES = 'SET_LOCATION_PROFILES';
export const RESET_LOCATION_PROFILES = 'RESET_LOCATION_PROFILES';
export const SET_TUTORIAL_PROPS = 'SET_TUTORIAL_PROPS';
