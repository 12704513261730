class RequestError extends Error {
  response?: {
    data: {
      validation?: Record<string, unknown>;
      message?: string;
      error?: {
        code?: number;
        [key: string]: string | number | undefined;
      };
    };
  };

  stack?: string;

  validation: Record<string, string[]> = {};

  constructor({
    message,
    stack,
    response,
    ...rest
  }: {
    message?: string;
    stack?: string;
    response?: {
      data: {
        validation?: Record<string, unknown>;
        message?: string;
        error?: Record<string, string>;
      };
    };
  }) {
    super(message);
    const messageLines = (this.message.match(/\n/g) || []).length + 1;
    this.stack =
      stack &&
      `${stack
        .split('\n')
        .slice(0, messageLines + 1)
        .join('\n')}\n${stack}`;
    this.response = response;

    Object.keys(rest).forEach((key) => {
      // @ts-expect-error No index found on Request Error
      this[key] = rest[key];
    });

    if (response && response.data) {
      if (response.data.message) {
        this.message = response.data.message;
      }

      if (response.data.error && response.data.error.message) {
        this.message = response.data.error.message;
      }

      if (response && response.data && response.data.validation) {
        this.validation = Object.keys(response.data.validation).reduce(
          (accum: Record<string, string[]>, fieldKey) => {
            const fieldErrors = response.data.validation && response.data.validation[fieldKey];
            // eslint-disable-next-line no-param-reassign
            accum[fieldKey] = Array.isArray(fieldErrors) ? fieldErrors : [fieldErrors];
            return accum;
          },
          {}
        );
      }
    }
  }

  get generalError(): string {
    if (this.validation && Object.values(this.validation).length) {
      return Object.values(this.validation).join(', ');
      // return this.messages.map(({ text }) => text);
    }

    return this.message;
  }

  get validationErrors(): Record<string, string[]> {
    return this.validation;
  }
}

export default RequestError;
