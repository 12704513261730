import {formSubmitFullScreenControl} from '~/components/Layout';

import {FEEDBACK_SET_MODAL_OPEN} from './constants';
import FeedbackService from '../FeedbackService';

export const openFeedbackModalAction = () => ({
  type: FEEDBACK_SET_MODAL_OPEN,
  payload: true,
});

export const closeFeedbackModalAction = () => ({
  type: FEEDBACK_SET_MODAL_OPEN,
  payload: false,
});

export const sendFeedbackAction = (params) =>
  formSubmitFullScreenControl(async () => {
    if (!params) {
      return;
    }

    await FeedbackService.sendFeedback(params);
  });
