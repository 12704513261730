import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

import config from '~/constants/config';
import RequestError from '~/utils/RequestError';
import logger from '~/utils/logger';

interface BaseAxiosRequestConfig extends AxiosRequestConfig {
  needMessage?: boolean;
}

interface BaseAxiosInstance extends AxiosInstance {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<T = any>(url: string, config?: BaseAxiosRequestConfig): Promise<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T = any>(
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    config?: BaseAxiosRequestConfig
  ): Promise<T>;
}

const log = logger.module('BaseRequester');

const BaseAxiosInstance: BaseAxiosInstance = axios.create({
  baseURL: config.api.url,
  timeout: config.api.httpTimeout,
}) as BaseAxiosInstance;

BaseAxiosInstance.interceptors.response.use(
  // @ts-expect-error needMessage does not exists on Axios
  ({data: rootData, data: {data, message}, config: {needMessage}}) => {
    const response = data || rootData || {};

    if (needMessage) {
      response.message = message;
    }

    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      throw error;
    }

    const requestError = new RequestError({
      message: error.message,
      stack: error.stack,
      ...error,
    });

    const extendedData = {...error};

    if (error.code === 'ECONNABORTED' && error.message.includes('timeout of')) {
      log.error('Timeout API error', extendedData);
      throw requestError;
    }
    if (
      error.response &&
      error.response.status &&
      error.response.status >= 400 &&
      error.response.status !== 401
    ) {
      const {message = ''} = error.response.data || {};
      log.warn(`Request Error ${error.response.status} ${message}`, extendedData);
      throw requestError;
    }
    if (error.response && error.response.status !== 401) {
      log.error(
        `Error in Base Request: ${error && error.config && error.config.url}`,
        extendedData
      );
      throw requestError;
    }
    throw requestError;
  }
);

export default BaseAxiosInstance;
