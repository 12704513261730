"use strict";
/**
 * @packageDocumentation @module Chat
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.logout = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To login to chat you need pass valid session and QuickBlox credentials.
 * The session you can get from our node service (Red).
 *
 * You can use another method from this lib only after that method resolve.
 */
function logout() {
    chat_1.Chat.logout();
}
exports.logout = logout;
