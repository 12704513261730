import {Dialogs} from '@yeobill/react-chat';
import Box from '@material-ui/core/Box';
import React from 'react';
import {Badge, Button, Divider} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDispatch} from 'react-redux';

import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import routeByName from '~/constants/routes';

import {layoutCloseDrawerAction} from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    left: 0,
    bottom: -1,
    backgroundColor: theme.palette.common.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  rect: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 14,
    width: 41,
    height: 41,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  favoriteIcon: {
    color: '#F55B76',
  },
  chatIcon: {
    color: '#0EA400',
  },
}));

const DrawerProfileLinks: React.FC = () => {
  const s = useStyles();
  const dispatch = useDispatch();
  const unreadDialogCounter = Dialogs.useUnreadDialogCounter();
  const handleCloseDrawer = () => {
    dispatch(layoutCloseDrawerAction());
  };
  return (
    <Box className={s.root}>
      <Button
        component={RouterLink}
        onClick={handleCloseDrawer}
        to={routeByName.chats.index}
        variant="text"
        color="default"
      >
        <div className={s.rect}>
          <Badge color="primary" badgeContent={unreadDialogCounter} showZero={false}>
            <SpriteIcon name="chat" className={s.chatIcon} />
          </Badge>
        </div>
        <span className="body1">Chats</span>
      </Button>
      <Divider orientation="vertical" style={{height: '38px'}} />
      <Button
        component={RouterLink}
        onClick={handleCloseDrawer}
        to={routeByName.favorites}
        variant="text"
        color="default"
      >
        <div className={s.rect}>
          <SpriteIcon name="swiper-like-disabled" className={s.favoriteIcon} />
        </div>
        <span className="body1">Favorites</span>
      </Button>
    </Box>
  );
};

export default DrawerProfileLinks;
