import {Fragment, useCallback, useMemo, useState} from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ButtonBase from '@material-ui/core/ButtonBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import CommonDialog from '../../Dialogs/CommonDialog';
import SearchInput from '../SearchInput';
import ModalSelectListItem from './ModalSelectListItem';
import ModalInput from './ModalInput';
import SpriteIcon from '../../icons/SpriteIcon';

const useStyles = makeStyles(
  (theme) => ({
    modalContentEmpty: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchWrapper: {
      flex: '0 0 auto',
      padding: theme.spacing(0, 1),
    },
    confirmButtonGap: {
      paddingBottom: 68,
    },
    cancelButton: {
      padding: theme.spacing(1),
    },
    // empty disabled object required by material UI
    // https://material-ui.com/customization/components/#use-rulename-to-reference-a-local-rule-within-the-same-style-sheet
    confirmButton: {
      '&$disabled': {
        border: 'none',
        color: '#474747',
      },
    },
    disabled: {},
  }),
  {name: 'ModalSelect'}
);

const equalCompare = (first, second) => String(first) === String(second);

const multipleResolveChecked = (selected, value) =>
  selected &&
  Array.isArray(selected) &&
  selected.some((selectedVal) => equalCompare(selectedVal, value));

const ModalSelect = ({
  label: inputLabel,
  name,
  renderValue,
  renderListItem,
  value: currentValue,
  onChange,
  options,
  filter,
  minSearchLength,
  error,
  helperText,
  modalTitle,
  valueKey,
  multiple,
  customInput,
  noTranslate,
  placeholder,
  classes,
  featuredValues,
  manualControl,
  handleCloseManual,
  isManualOpen,
  emptyResultText,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [search, setSearchValue] = useState('');
  const s = useStyles();

  const onSearchChange = useCallback(
    ({target: {value}}) => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  /**
   * Filter options by provided search query
   * @type {any}
   */

  const filteredOptions = useMemo(() => {
    /**
     * If filter function is not provided then return full list
     */
    if (!filter || typeof filter !== 'function') {
      return options;
    }
    /**
     * Return empty result is search query is required and less than minimal length
     */
    if (minSearchLength && (!search || search.length < minSearchLength)) {
      return [];
    }

    if (!search) {
      return options;
    }

    return options.filter((entry) => Boolean(filter(search.toLowerCase(), entry)));
  }, [search, options, minSearchLength, filter]);

  let featuredList = [];
  if (featuredValues) {
    featuredList = filteredOptions.filter(({value}) => featuredValues.indexOf(value) > -1);
  }
  /**
   * Focus handler for always visible control trigger
   * Notice: by default it is input,
   * but for button or any block component should be attached as onClick handler
   * @type {Function}
   */
  const onControlFocus = useCallback(() => {
    if (!manualControl) {
      setModalOpen(true);
    }
  }, [setModalOpen, manualControl]);

  const onClose = useCallback(() => {
    if (!manualControl) {
      setModalOpen(false);
    } else {
      handleCloseManual();
    }

    setSearchValue('');
  }, [setModalOpen, setSearchValue, manualControl, handleCloseManual]);

  const onSelect = useCallback(
    ({target}) => {
      /**
       * Function works with html attribute for prevent redundant on click handlers creations
       * onClose event triggers on any value change, it is business requirements
       * @type {string}
       */
      let newValue = target.getAttribute('data-value');

      if (!newValue) {
        return;
      }

      if (equalCompare(newValue, currentValue) && !multiple) {
        onClose();
        return;
      }

      /**
       * If modal accepts multiple select
       */
      if (multiple) {
        newValue = multipleResolveChecked(currentValue, newValue)
          ? currentValue.filter((id) => !equalCompare(id, newValue))
          : [...currentValue, newValue].sort();
      }

      onChange(newValue);
      if (!multiple) {
        onClose();
      }
    },
    [currentValue, multiple, onChange, onClose]
  );

  const isResultEmpty = Boolean(
    ((minSearchLength && search.length >= minSearchLength) ||
      (!minSearchLength && search.length)) &&
      !filteredOptions.length
  );

  /**
   * Check is modal has initial state
   * Can be true only for modals where search query is required
   * @type {boolean}
   */

  const isInitialState = Boolean(
    ((minSearchLength && search.length < minSearchLength) ||
      (!minSearchLength && !search.length)) &&
      !filteredOptions?.length
  );

  const Placeholder = placeholder || (
    <FormHelperText component="div">
      <div className="text-center">
        Please start typing for search relevant results
        <br />
        {Boolean(minSearchLength) && `(min ${minSearchLength} symbols)`}
      </div>
    </FormHelperText>
  );

  const contentIsEmpty = isResultEmpty || isInitialState;
  const contentClasses = clsx({
    [s.modalContentEmpty]: contentIsEmpty,
    [classes.emptyContent]: contentIsEmpty,
  });

  // fake options to prevent MUI Select warning
  const controlSelectOptions = [{value: currentValue, label: ''}];

  return (
    <>
      <ModalInput
        label={inputLabel}
        name={name}
        value={currentValue}
        options={controlSelectOptions}
        renderValue={renderValue}
        error={error}
        helperText={helperText}
        onFocus={onControlFocus}
        auto
        component={customInput}
      />

      {(isManualOpen || modalOpen) && (
        <CommonDialog
          fullScreen
          open={manualControl ? isManualOpen : modalOpen}
          title={modalTitle}
          onClose={onClose}
          disableRestoreFocus
          contentClass={contentClasses}
          subTitle={
            <Grid container className={`p-1 ${s.searchWrapper}`} alignItems="center">
              <Grid item xs>
                <SearchInput
                  autoFocus={Boolean(minSearchLength)}
                  value={search}
                  onChange={onSearchChange}
                  placeholder={modalTitle}
                />
              </Grid>
              <Grid item className="pl-2">
                <ButtonBase className={s.cancelButton} onClick={onClose}>
                  <SpriteIcon name="cancel-red" fontSize="small" />
                </ButtonBase>
              </Grid>
            </Grid>
          }
          hideActions
        >
          {Boolean(filteredOptions.length) && (
            <div
              className={clsx({
                notranslate: noTranslate,
                [s.confirmButtonGap]: multiple,
              })}
              onClick={onSelect}
              role="presentation"
            >
              {featuredList &&
                featuredList.map((entry, index) => {
                  const value = entry[valueKey];
                  const checked = Boolean(
                    multiple
                      ? multipleResolveChecked(currentValue, value)
                      : equalCompare(value, currentValue)
                  );
                  const isLastFeatured = featuredList.length - 1 === index;

                  return (
                    <Fragment key={value}>
                      <ModalSelectListItem
                        item={entry}
                        key={`featured-${value}`}
                        value={value}
                        checked={checked}
                        renderFunction={renderListItem}
                        multiple={multiple}
                      />
                      {isLastFeatured && <Divider />}
                    </Fragment>
                  );
                })}
              {filteredOptions.map((entry) => {
                const value = entry[valueKey];
                const checked = Boolean(
                  multiple
                    ? multipleResolveChecked(currentValue, value)
                    : equalCompare(value, currentValue)
                );

                return (
                  <ModalSelectListItem
                    item={entry}
                    key={value}
                    value={value}
                    checked={checked}
                    renderFunction={renderListItem}
                    multiple={multiple}
                  />
                );
              })}
            </div>
          )}

          {isResultEmpty && <FormHelperText>{emptyResultText}</FormHelperText>}
          {isInitialState && <Placeholder />}
          {multiple && (
            <Box
              bgcolor="white"
              p={1.5}
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              zIndex="tooltip"
            >
              <Button
                classes={{root: s.confirmButton, disabled: s.disabled}}
                disabled={currentValue.length < 1}
                onClick={() => onClose()}
              >
                OK
              </Button>
            </Box>
          )}
        </CommonDialog>
      )}
    </>
  );
};

ModalSelect.propTypes = {
  label: PropTypes.string,
  modalTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  renderValue: PropTypes.func.isRequired,
  renderListItem: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  valueKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  filter: PropTypes.func,
  error: PropTypes.bool,
  multiple: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  customInput: PropTypes.func,
  noTranslate: PropTypes.bool,
  minSearchLength: PropTypes.number,
  placeholder: PropTypes.elementType,
  classes: PropTypes.shape({
    emptyContent: PropTypes.string,
  }),
  featuredValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  manualControl: PropTypes.bool,
  isManualOpen: PropTypes.bool,
  handleCloseManual: PropTypes.func,
  emptyResultText: PropTypes.string,
};

ModalSelect.defaultProps = {
  label: undefined,
  value: undefined,
  filter: undefined,
  error: null,
  helperText: null,
  valueKey: 'value',
  multiple: false,
  customInput: null,
  noTranslate: false,
  minSearchLength: undefined,
  modalTitle: '',
  placeholder: null,
  classes: {},
  featuredValues: null,
  manualControl: false,
  isManualOpen: false,
  handleCloseManual: undefined,
  emptyResultText: 'Sorry, nothing found for your query. Please try again',
};

export default ModalSelect;
