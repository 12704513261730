export enum createFormStepToId {
  relationship = 1,
  venue = 2,
  personal = 3,
  availability = 4, // not used
  about = 5,
  contact = 6,
  media = 7,
  location = 8,
  hours = 9,
  fee = 10,
}

export const defaultChatSettings = {
  isTextMessages: true,
  isOnlyUsersInMyArea: false,
  isOnlyPremiumUsers: false,
};

export default {
  createFormStepToId,
  defaultChatSettings,
};
