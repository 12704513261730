import {reduce} from 'lodash-es';
import {createSelector} from 'reselect';

import {TAppState} from '~/types/appTypes';
import {TLeafLocation, TLeafLocations, TLocationBreadcrumbs} from '~/types/LeafLocation';
import {TProfileType, allProfileTypesRegexp, profiles} from '~/constants/profiles';

import {LOCATION_TYPES_STATE} from './constants';

export const getLocationLink = (
  location: TLeafLocation,
  // eslint-ignore-next-line @typescript-eslint/no-unused-vars
  profileType: TProfileType
): string => {
  if (!location) {
    return '';
  }
  const url = [location.state_page || location.city_page || location.borough_page || location.url]
    .filter((x) => x)
    .join('/')
    // TODO: Remove after FL-512
    .replace(new RegExp(`/(${allProfileTypesRegexp})`), '');
  return [url, profiles[profileType]?.url].filter((x) => x).join('/');
};

export const getLocationById = (leafLocations: TLeafLocations, locationId: number): TLeafLocation =>
  leafLocations[locationId];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLocationByRoute = (
  leafLocations: TLeafLocations,
  url: string,
  profileType: TProfileType
) => {
  if (!leafLocations || !url || profileType == null) {
    return null;
  }
  return Object.values(leafLocations).find((location) => {
    return getLocationLink(location, profileType) === url;
  });
};

const LOCATIONS = {
  USA: 1,
};

const LOCATION_NAMES = {
  [LOCATIONS.USA]: 'USA',
};

export const getLeafLocationLabel = (location: TLeafLocation): string => {
  if (!location) {
    return '';
  }
  const result: string[] = [];
  const bindLocation = (locationParam: TLeafLocation) => {
    if (!locationParam) {
      return;
    }
    const {parent, type, name, code, id} = locationParam;
    if (LOCATION_NAMES[id]) {
      result.push(LOCATION_NAMES[id]);
    } else if (type === LOCATION_TYPES_STATE) {
      result.push(code);
    } else {
      result.push(name);
    }
    if (parent) {
      bindLocation(parent);
    }
  };
  bindLocation(location);
  return result.join(', ');
};

export const getLocationBreadcrumbsById = (
  leafLocations: TLeafLocations,
  locationId?: number
): TLocationBreadcrumbs => {
  if (!locationId) return {};
  const typeToKey: {[index: number]: keyof TLocationBreadcrumbs} = {
    1: 'country',
    2: 'state',
    3: 'city',
    4: 'borough',
  };
  const result: TLocationBreadcrumbs = {};
  const bindLocation = (locationParam: TLeafLocation) => {
    if (!locationParam) {
      return;
    }
    const {parent, ...location} = locationParam;
    const resultKey: keyof TLocationBreadcrumbs = typeToKey[location.type];
    result[resultKey] = location as TLeafLocation;
    if (parent) {
      bindLocation(parent);
    }
  };
  bindLocation(leafLocations[locationId]);
  return result;
};

function buildLeafLocations(locations: TLeafLocations): TLeafLocations {
  return reduce(
    locations,
    (rootAccum, countryLoc) => {
      // Country level
      const accTemp = reduce(
        countryLoc.children,
        (stateCityAccum, stateCityLoc) => ({
          ...stateCityAccum,
          [stateCityLoc.id]: {
            ...stateCityLoc,
            parent: countryLoc,
          },
          ...(stateCityLoc.children
            ? // state and has children
              reduce(
                stateCityLoc.children,
                (cityAccum, cityLoc) => ({
                  ...cityAccum,
                  [cityLoc.id]: {
                    ...cityLoc,
                    parent: {
                      ...stateCityLoc,
                      parent: countryLoc,
                    },
                  },
                  ...(cityLoc.children
                    ? // City and has boroughs
                      reduce(
                        cityLoc.children,
                        (accumBoroughs, borough) => ({
                          ...accumBoroughs,
                          [borough.id]: {
                            ...borough,
                            parent: {
                              ...cityLoc,
                              parent: {
                                ...stateCityLoc,
                                parent: countryLoc,
                              },
                            },
                          },
                        }),
                        {}
                      )
                    : {}),
                }),
                {}
              )
            : {}),
        }),
        {}
      );
      return {
        ...rootAccum,
        [countryLoc.id]: countryLoc,
        ...accTemp,
      };
    },
    {}
  );
}

export const locationSelector = (state: TAppState, locationId: number) => {
  return state.locations.locations[locationId];
};

export const leafLocationsSelector = createSelector(
  (state: TAppState) => state.locations.locations,
  (locations) => buildLeafLocations(locations)
);

export const locationsSelector = (state: TAppState) => state.locations.locations;

export const getHeaderLocationSelector = (state: TAppState) => state.app.headerLocation;

export const userLocationSelector = (state: TAppState) => state.locations.userLocation;

export const userNavigatorSelector = (state: TAppState) => state.app.navigator;

export const homeListLocationsSelector = (state: TAppState) => state.locations.homeLists;
