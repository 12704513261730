import {qaSignInTabEmail, qaSignInTabPhone, qaSignInTabUsername} from '~/constants/testClasses';

export enum TSignInTypes {
  username = 'username',
  email = 'email',
  phone = 'phone',
}

export const SignInTabsList = [
  {
    value: TSignInTypes.phone,
    label: 'Phone',
    qaTestClass: qaSignInTabPhone,
  },
  {
    value: TSignInTypes.email,
    label: 'Email',
    qaTestClass: qaSignInTabEmail,
  },
  {
    value: TSignInTypes.username,
    label: 'Username',
    qaTestClass: qaSignInTabUsername,
  },
];

export const allSignInTypesRegexp = Object.values(TSignInTypes).join('|');
