import {TThunkAction} from '~/types/appTypes';

import {setFilterProfileTypeActionCreator} from '../actionCreators';
import {setStoredProfileTypeFilter} from '../localStorage';

type TSetFilterProfileTypeAction = (profileType: number | null) => TThunkAction;

const setFilterProfileTypeAction: TSetFilterProfileTypeAction = (profileType) => (dispatch) => {
  setStoredProfileTypeFilter(profileType);
  dispatch(setFilterProfileTypeActionCreator(profileType));
};

export default setFilterProfileTypeAction;
