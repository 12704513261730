import {Divider} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useCallback} from 'react';
import {useField} from 'react-final-form';

import IosSlider from '~/components/ui/slider/IosSlider';

const useStyles = makeStyles((theme) => ({
  panel: {
    background: 'white',
    padding: '12px 10px',
    fontSize: 18,
    letterSpacing: 0.2,
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  grey: {
    color: '#898989',
  },
  sliderContainer: {
    margin: theme.spacing(1),
  },
}));

const DistanceControl = () => {
  const s = useStyles();

  const field = useField('distance');

  const handleChange = useCallback(
    (event, value) => {
      field.input.onChange(value);
    },
    [field.input]
  );

  return (
    <>
      <div className={s.panel}>
        <div className={s.label}>
          <div>Maximum distance</div>
          <div className={s.grey}>{field.input.value}mi.</div>
        </div>
        <div className={s.sliderContainer}>
          <IosSlider
            name={field.input.name}
            min={10}
            step={1}
            max={100}
            value={field.input.value}
            onChange={handleChange}
          />
        </div>
      </div>
      <Divider />
    </>
  );
};

export default DistanceControl;
