import logger from '~/utils/logger';
import {modalShowAction} from '~/rootStore/modals/modalsActions';
import {RegistrationType} from '~/types/CurrentUserState';
import {
  MODAL_ALLOW_TEXT_MESSAGES,
  MODAL_COMPLETE_PROFILE,
  MODAL_UPGRADE_TO_PAID,
  MODAL_REGISTER_ANONYMOUSLY,
  MODAL_VERIFY_PROFILE,
} from '~/rootStore/modals/modalsIds';
import {startLoader, stopLoader} from '~/rootStore/loaders/loadersReducer';
import {
  isAuthSelector,
  isProfileEscortSelector,
  isProfileExistsSelector,
  isProfileIdentityVerifiedSelector,
  isProfileTotallyCompletedSelector,
  isUserVipSelector,
  userProfileChatSettingsSelector,
  currentUserSelector,
} from '~/modules/CurrentUser/store/selectors';
import updateChatProfile from '~/modules/CurrentUser/store/updateChatProfileAction';
import {
  defineUserFingerPrintAction,
  removeUserFingerPrintAction,
} from '~/modules/App/store/actions/fingerprintActions';
import {userFingerprintSelector} from '~/modules/App/store/selectors';
import {LOADER_INIT_CHAT} from '~/modules/Payments/constants/loadersIds';
import {refreshNotificationsPermission} from '~/modules/CurrentUser/store/actions';

import {updateChatOnlineStatus} from './actions/updateChatOnlineStatusAction';
import {CHATS_SET_SESSION, CHATS_SET_LIST, CHATS_SET_OPPONENT_PROFILE} from './constants';
import ChatsService from '../ChatsService';

const log = logger.module('ChatActions');
export const chatsSetSessionAction = (value) => ({
  type: CHATS_SET_SESSION,
  payload: value,
});
export const chatsSetListAction = (chats) => ({
  type: CHATS_SET_LIST,
  payload: chats,
});

const setChatInitInProgress = () => (dispatch) => {
  dispatch(startLoader(LOADER_INIT_CHAT));
};

const setChatInitFinished = () => (dispatch) => {
  dispatch(stopLoader(LOADER_INIT_CHAT));
};

const chatInitActionWrapper = (thunkAction) => () => async (dispatch) => {
  dispatch(setChatInitInProgress());
  await dispatch(thunkAction);
  dispatch(setChatInitFinished());
};

export const chatsInitAction = chatInitActionWrapper(async (dispatch, getState) => {
  const store = getState();

  const isProfileExists = isProfileExistsSelector(store);

  if (!isProfileExists) {
    // TODO: TSP-1435 it's ok, after registration user has not profile
    // and profile will create at createProfileAction
    log.info('User has no profile for a chat service');
    return;
  }

  const fingerprint = userFingerprintSelector(getState());

  const chatOptions = {};
  if (fingerprint) {
    chatOptions.fingerprint = fingerprint;
  }

  try {
    const session = await ChatsService.initChat(chatOptions);
    await dispatch(chatsSetSessionAction(session));
    dispatch(updateChatProfile());
    dispatch(updateChatOnlineStatus());
    dispatch(removeUserFingerPrintAction());
    dispatch(refreshNotificationsPermission());
    log.info('Chat successful initialized');
  } catch (error) {
    log.error('Error during chat init', {error});
  }
});

export const guestChatsInitAction = chatInitActionWrapper(async (dispatch) => {
  log.debug('Chat start initialization');
  try {
    const fingerprint = await dispatch(defineUserFingerPrintAction());
    const session = await ChatsService.initChat({guest: true, fingerprint});
    await dispatch(chatsSetSessionAction(session));
    log.debug('Chat guest successful initialized');
  } catch (error) {
    log.warn('Guest user can not login in chat', {error});
  }
});

export const chatDestroyAction = () => (dispatch) => {
  dispatch(chatsSetSessionAction(null));
};

export const checkChatRestrictionsAction = (modalProps) => (dispatch, getState) => {
  const store = getState();
  const chatSettings = userProfileChatSettingsSelector(store);
  const isUserAuth = isAuthSelector(store);
  const currentUser = currentUserSelector(store);
  const isProfileCompleted = isProfileTotallyCompletedSelector(store);
  const isProfileEscort = isProfileEscortSelector(store);
  const isVip = isUserVipSelector(store);
  const isProfileIdentityVerified = isProfileIdentityVerifiedSelector(store);

  if (!isUserAuth) {
    dispatch(modalShowAction(MODAL_REGISTER_ANONYMOUSLY));
    return false;
  }

  if (!chatSettings.isTextMessages) {
    dispatch(modalShowAction(MODAL_ALLOW_TEXT_MESSAGES));
    return false;
  }

  if (
    !isProfileCompleted &&
    (!currentUser.registrationType || currentUser.registrationType !== RegistrationType.QUICK)
  ) {
    dispatch(modalShowAction(MODAL_COMPLETE_PROFILE));
    return false;
  }

  if (!isProfileIdentityVerified) {
    dispatch(modalShowAction(MODAL_VERIFY_PROFILE, modalProps));
    return false;
  }

  if (isProfileEscort && !isVip) {
    dispatch(modalShowAction(MODAL_UPGRADE_TO_PAID));
    return false;
  }

  return true;
};

export const setChatOpponentProfileAction = (opponentProfile) => (dispatch) => {
  dispatch({
    type: CHATS_SET_OPPONENT_PROFILE,
    payload: opponentProfile,
  });
};
