import routeByName from '~/constants/routes';

export const successMessages = {
  profileTopUp:
    'Your profile was moved to the top of the list.' +
    ' It might take up to one minute to update the city list.',
};

export const drawerInformationSection = [
  {
    label: 'Contact Us',
    to: routeByName.contact,
  },
  // {
  //   label: 'About Us',
  //   to: routeByName.aboutUs,
  // },
  {
    label: 'Privacy Policy',
    to: routeByName.privacyPolicy,
  },
  {
    label: 'Terms and conditions',
    to: routeByName.terms,
  },
];
