export const chatTypes = {
  publicGroup: 'publicGroup',
  group: 'group',
  private: 'private',
};

export const externalChatTypeToInternal = {
  1: chatTypes.publicGroup,
  2: chatTypes.group,
  3: chatTypes.private,
};

export const chatMessagesTypes = {
  text: 'text',
  image: 'image',
  audio: 'audio',
  file: 'file',
  location: 'location',
  unknown: 'unknown',
};

export const chatAttachmentTypes = {
  any: 'any',
  image: 'image',
};

export const quickbloxAttachmentTypes = {
  image: 'image',
  audio: 'audio',
};

export const chatReasonError = {
  PERMISSION_IS_NO_TEXT_MESSAGES: '',
  PERMISSION_IS_ONLY_PREMIUM_USERS:
    'Sorry, this Bendr friend accepts messages only from Gold members. You can upgrade to Gold membership to text Bendr friends all over the world.',
  PERMISSION_IS_ONLY_USERS_IN_MY_AREA:
    "Sorry, your text can't be sent to a Bendr friend from different city you are located in. You can upgrade to Gold membership to text Bendr friends in different cities.",
  PERMISSION_IS_ONLY_AVAILABLE_LOCATIONS:
    "Sorry, your text can't be sent to a Bendr friend from different country you are located in. You can upgrade to Gold membership to text Bendr friends all over the world.",
};

export const chatReasonDomainCodes = {
  NO_TEXT_MESSAGES: 'textMessages',
  ONLY_PREMIUM_USERS: 'onlyDatingMembership',
  ONLY_USERS_IN_MY_AREA: 'onlyUsersInMyArea',
  ONLY_AVAILABLE_LOCATIONS: 'onlyAvailableLocations',
};

export const chatReasonErrorType = {
  [chatReasonDomainCodes.NO_TEXT_MESSAGES]: chatReasonError.PERMISSION_IS_NO_TEXT_MESSAGES,
  [chatReasonDomainCodes.ONLY_PREMIUM_USERS]: chatReasonError.PERMISSION_IS_ONLY_PREMIUM_USERS,
  [chatReasonDomainCodes.ONLY_USERS_IN_MY_AREA]:
    chatReasonError.PERMISSION_IS_ONLY_USERS_IN_MY_AREA,
  [chatReasonDomainCodes.ONLY_AVAILABLE_LOCATIONS]:
    chatReasonError.PERMISSION_IS_ONLY_AVAILABLE_LOCATIONS,
};
