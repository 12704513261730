import Box from '@material-ui/core/Box';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper/Paper';
import {Divider} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';

import routeByName from '~/constants/routes';
import {
  currentProfileSelector,
  currentUserSelector,
  isProfileClientSelector,
  isProfileCompletedSelector,
  isProfileIdentityVerifiedSelector,
} from '~/modules/CurrentUser/store/selectors';
import ProfileActions from '~/modules/CurrentUser/Settings/components/ProfileActions';
import useModalById from '~/customHooks/useModalById';
import {MODAL_VERIFY_PROFILE} from '~/rootStore/modals/modalsIds';
import {navigateAction} from '~/actions/navigateActions';

import DrawerProfileTopUpPorfile from './DrawerProfileTopUpPorfile';
import ActionButton from './ActionButton';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&:before': {
      backgroundColor: theme.palette.primary.main,
      content: '""',
      height: '50%',
      width: '100%',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: -1,
    },
  },
  rootInner: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 12,
  },
  cardPrimaryTitle: {
    color: theme.palette.success.main,
    fontSize: 26,
    fontWeight: 600,
    lineHeight: 1,
    '@media (max-width: 320px)': {
      fontSize: 18,
    },
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  topUpProfile: {
    flexBasis: '100%',
  },
}));

const DrawerProfileActions: React.FC = () => {
  const s = useStyles();

  const {open} = useModalById(MODAL_VERIFY_PROFILE);

  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);
  const isProfileClient = useSelector(isProfileClientSelector);
  const isProfileVerified = useSelector(isProfileIdentityVerifiedSelector);
  const profile = useSelector(currentProfileSelector);
  const isProfileCompleted = useSelector(isProfileCompletedSelector);

  const isReady = user && profile && isProfileCompleted;
  const credits = user?.topUpCredits || 0;

  const handleTopUp = () => {
    if (!isProfileVerified) {
      open({verifyModalContent: 'You need to verify your profile first to buy Top Up'});
      return;
    }
    dispatch(navigateAction(routeByName.buyTopUps));
  };

  const handleRecharge = () => {
    if (!isProfileVerified) {
      open({verifyModalContent: 'You need to verify your profile first to Recharge Budget'});
      return;
    }
    dispatch(navigateAction(routeByName.chooseRechargeBudget));
  };
  return (
    <Box className={s.root}>
      <Box px={1.25}>
        {!isReady && <ProfileActions />}
        {isReady && (
          <Paper elevation={4} className={s.rootInner}>
            {!isProfileClient && [
              <Box flex={1} key="grid" textAlign="center" px={2} py={1.5}>
                <div className={s.cardPrimaryTitle}>{credits}</div>
                <div className="body2 mb-1">Top Up Credits</div>
                <ActionButton onClick={handleTopUp}>Top Up</ActionButton>
              </Box>,
              <Divider key="divider" className={s.divider} orientation="vertical" flexItem />,
            ]}
            <Box flex={1} textAlign="center" px={2} py={1.5}>
              <div className={s.cardPrimaryTitle}>${user?.budgetStr}</div>
              <div className="body2 mb-1">Current Budget</div>
              <ActionButton onClick={handleRecharge}>Recharge</ActionButton>
            </Box>
            {credits > 0 && (
              <Box className={s.topUpProfile} px={2} pb={1.5}>
                <DrawerProfileTopUpPorfile />
              </Box>
            )}
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default DrawerProfileActions;
