import {TThunkAction, TLocationFiltersParams} from '~/types/appTypes';

import {profilesCacheClearActionCreator} from '../actionCreators';
import {SET_LOCATION_FILTERS} from '../constants';

type TSetLocationFilters = (filters: TLocationFiltersParams) => TThunkAction;

const setLocationFilters: TSetLocationFilters = (filters) => (dispatch) => {
  // Clear preloaded profile and chat data catche for previous location
  dispatch(profilesCacheClearActionCreator());

  // Dispatch original event with new location info
  dispatch({
    type: SET_LOCATION_FILTERS,
    payload: filters,
  });
};

export default setLocationFilters;
