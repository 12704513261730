import {TProfile} from '~/types/Profile';
import {
  SortingMethodsType,
  TAction,
  TLastFiltersQuery,
  TProfileFiltersListParams,
} from '~/types/appTypes';
import {TChatUser} from '~/types/Chat';

import {
  NEXT_PROFILE,
  PREV_PROFILE,
  PROFILES_CACHE_BY_LOCATION_ID,
  PROFILES_CACHE_CHAT_PROFILE,
  PROFILE_CACHE_CHAT_DATA,
  PROFILES_CACHE_PROFILE,
  RESET_PROFILE_FILTERS,
  RESET_PROFILE_SWIPER,
  SET_FILTER_PROFILE_TYPE,
  SET_PROFILE_FILTERS,
  SET_PROFILES_LOCATION_ID,
  UPDATE_NAVIGATION_FILTERS,
  SET_LAST_FILTERS_QUERY,
  SET_SORTING_METHOD,
  PROFILES_CACHE_PROFILE_BATCH,
  SET_LOCATION_PROFILES,
  RESET_LOCATION_PROFILES,
  SET_TUTORIAL_PROPS,
  RESET_NAVIGATION_FILTERS,
  PROFILES_CACHE_CLEAR,
  PROFILE_CACHE_CLEAR,
  SET_CHAT_PROFILE_IS_LOADING,
  SET_PROFILE_FILTERS_OPTIONS,
  UPDATE_PROFILES_LAST_ACTIVITY,
} from './constants';
import {
  TNavigationFilters,
  SwiperDirectionType,
  TutorialProps,
  TprofileFiltersOptions,
} from './types';

export const profilesCacheByLocationIdActionCreator = (params: {
  id: number;
  profiles: TProfile[];
}) =>
  ({
    type: PROFILES_CACHE_BY_LOCATION_ID,
    payload: params,
  } as const);

export const cacheProfileActionCreator = (profile: TProfile) => {
  return {
    type: PROFILES_CACHE_PROFILE,
    payload: {profile},
  } as const;
};
export const setFilterProfileTypeActionCreator = (profileType: number | null) =>
  ({
    type: SET_FILTER_PROFILE_TYPE,
    payload: profileType,
  } as const);

export function setProfileFiltersActionCreator<T = TProfileFiltersListParams>(filters: T): TAction {
  return {
    type: SET_PROFILE_FILTERS,
    payload: filters,
  } as const;
}

export const resetProfileFiltersActionCreator = () =>
  ({
    type: RESET_PROFILE_FILTERS,
    payload: null,
  } as const);

export function updateNavigationFiltersActionCreator<T = TNavigationFilters>(filters: T): TAction {
  return {
    type: UPDATE_NAVIGATION_FILTERS,
    payload: filters,
  } as const;
}

export function resetNavigationFiltersActionCreator(): TAction {
  return {
    type: RESET_NAVIGATION_FILTERS,
  } as const;
}

export const profilesCacheChatProfileActionCreator = (params: {
  profileId: number;
  chatUser: TChatUser;
}) =>
  ({
    type: PROFILES_CACHE_CHAT_PROFILE,
    payload: {[params.profileId]: params.chatUser},
  } as const);

export const profileCacheChatDataActionCreator = (profileId: number, chatData: any) =>
  ({
    type: PROFILE_CACHE_CHAT_DATA,
    payload: {[profileId]: chatData},
  } as const);

export const setChatProfileLoading = (profileId: number, isLoading: boolean) =>
  ({
    type: SET_CHAT_PROFILE_IS_LOADING,
    payload: {[profileId]: isLoading},
  } as const);

export const profilesCacheClearActionCreator = () => ({type: PROFILES_CACHE_CLEAR} as const);

export const profileCacheClearActionCreator = (profileId: number) =>
  ({
    type: PROFILE_CACHE_CLEAR,
    payload: {profileId},
  } as const);

export const setProfileLocationIdActionCreator = (locationId: number) => {
  return {
    type: SET_PROFILES_LOCATION_ID,
    payload: {locationId},
  } as const;
};
export const resetProfileSwiperActionCreator = () => {
  return {
    type: RESET_PROFILE_SWIPER,
  } as const;
};
export const nextProfileActionCreator = (params: {profileId: number; like: boolean}) => {
  return {
    type: NEXT_PROFILE,
    payload: params,
  } as const;
};
export const prevProfileActionCreator = () => {
  return {
    type: PREV_PROFILE,
  } as const;
};

export const updateLastFiltersQueryActionCreator = (query: TLastFiltersQuery) => {
  return {
    type: SET_LAST_FILTERS_QUERY,
    payload: query,
  } as const;
};

export const setSortingMethodActionCreator = (payload: SortingMethodsType) => {
  return {
    type: SET_SORTING_METHOD,
    payload,
  };
};

export const cacheProfileBatchActionCreator = (profiles: TProfile[]) => {
  return {
    type: PROFILES_CACHE_PROFILE_BATCH,
    payload: {profiles},
  } as const;
};

export const updateProfilesLastActivityActionCreator = (lastActivities: any) => {
  return {
    type: UPDATE_PROFILES_LAST_ACTIVITY,
    payload: {lastActivities},
  } as const;
};

export const setLocationProfilesActionCreator = (
  profileIds: number[],
  direction: SwiperDirectionType
) => {
  return {
    type: SET_LOCATION_PROFILES,
    payload: {profileIds, direction},
  } as const;
};

export const resetLocationProfilesActionCreator = () => {
  return {
    type: RESET_LOCATION_PROFILES,
  };
};

export const setTutorialPropsActionCreator = (props: Partial<TutorialProps>) => {
  return {
    type: SET_TUTORIAL_PROPS,
    payload: props,
  } as const;
};

export const setProfilesFiltersActionCreator = (payload: TprofileFiltersOptions) => {
  return {
    type: SET_PROFILE_FILTERS_OPTIONS,
    payload,
  };
};
