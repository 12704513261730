import {createContext} from 'react';

import {LayoutContextType} from '../types';

const LayoutContext = createContext<LayoutContextType>({
  onBack: undefined,
  setOnBackHandler: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export default LayoutContext;
