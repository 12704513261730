import React from 'react';
import {MenuItem} from '@material-ui/core';
import {useField} from 'react-final-form';

import {profiles} from '~/constants/profiles';

import BaseControl from './BaseControl';
import BaseControlSelect from './BaseControlSelect';

const ProfileTypeControl: React.FC = () => {
  const field = useField('profileType');

  return (
    <BaseControl title="Show me">
      <BaseControlSelect {...field.input}>
        {Object.values(profiles).map((item) => {
          return (
            <MenuItem key={item.type} value={item.type}>
              {item.label}
            </MenuItem>
          );
        })}
      </BaseControlSelect>
    </BaseControl>
  );
};

export default ProfileTypeControl;
