import {StyleRules} from '@material-ui/core';
import {Palette} from '@material-ui/core/styles/createPalette';

export default (palette: Palette): StyleRules => ({
  root: {
    borderRadius: 10,
    '& input': {borderRadius: 10},
    '&.Mui-focused $notchedOutline': {
      borderWidth: 1,
    },
    '&.Mui-error $notchedOutline': {
      borderWidth: 1,
    },
    '&.Mui-valid $notchedOutline': {
      borderColor: palette.success.main,
    },
  },
});
