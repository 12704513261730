import Slider from '@material-ui/core/Slider';
import {Theme, withStyles} from '@material-ui/core/styles';

const iosStyles = (theme: Theme) => ({
  root: {
    height: 2,
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    marginTop: -14,
    marginLeft: -14,
    border: '1px solid #E2E2E2',
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: theme.palette.common.black,
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
});

const IosSlider = withStyles(iosStyles)(Slider);

export default IosSlider;
