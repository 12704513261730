"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LogListener = /** @class */ (function () {
    function LogListener(_a) {
        var _b = _a.levels, levels = _b === void 0 ? [] : _b, handler = _a.handler, filter = _a.filter;
        this.levels = levels;
        this.handler = handler;
        this.filter = filter || null;
    }
    LogListener.prototype.notify = function (logMessage) {
        if (this.filter) {
            this.filter(logMessage) ? this.handler(logMessage) : null;
            return;
        }
        if (this.levels.length === 0) {
            this.handler(logMessage);
            return;
        }
        if (this.levels.indexOf(logMessage.level) > -1) {
            this.handler(logMessage);
            return;
        }
    };
    return LogListener;
}());
exports.default = LogListener;
