import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import BenefitIcon from './BenefitIcon';
import {TBenefit} from '../benefitsList';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    title: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: theme.spacing(2),
    },
    description: {
      fontSize: 16,
    },
  }),
  {name: 'BenefitItem'}
);

const BenefitItem: React.FC<TBenefit> = ({icon, title, description}) => {
  const s = useStyles();

  return (
    <div className={s.root}>
      <BenefitIcon icon={icon} />
      <div className={s.title}>{title}</div>
      <div className={s.description}>{description}</div>
    </div>
  );
};

export default BenefitItem;
