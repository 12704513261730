import {AxiosError, AxiosRequestConfig} from 'axios';
import qs from 'qs';

import {TProfileImage, TProfile} from '~/types/Profile';
import {TAppProfileFieldOptions} from '~/types/appTypes';
import {AuthorizedAxiosInstance, BaseAxiosInstance} from '~/utils/axios';
import {TGeoLocation} from '~/types';
import {chatReasonErrorType} from '~/constants/chats';
import RequestError from '~/utils/RequestError';
import config from '~/constants/config';
import AuthService from '~/modules/Auth/AuthService';

import {
  profileResponseTransformer,
  reportProfileOptionsTransformer,
  verificationTransformer,
  singleLocationProfileTransformer,
} from './transformers';

type TCheckVideo = (videoId: number) => Promise<{
  processed: number;
  url: null | string;
  thumbnail_url: null | string;
}>;

type TUploadVideo = (
  profileId: number,
  formData: FormData,
  axiosOptions?: Record<string, string | number>
) => Promise<{video_id: number; url: string; thumbnailUrl: string}>;

type TDeleteVideo = (videoId: number) => Promise<[]>;

export interface TProfileListParamsCurrentLocation {
  current_location?: {
    lat: number;
    lng: number;
    distance: number;
  };
}

export interface TProfileListParamsGlobalLocation {
  location?: {id: number};
  position?: {
    lat: number;
    lng: number;
  };
}

export type TProfileListParams = TProfileListParamsCurrentLocation &
  TProfileListParamsGlobalLocation & {
    searchType?: string;
    limit?: number;
    offset?: number;
    profileType?: number;
    sortingMethod?: string;
  };

export type TProfileFiltersParams = TProfileListParamsGlobalLocation &
  TProfileListParamsCurrentLocation & {
    limit?: number;
    offset?: number;
    profileType?: number;
    searchType?: string;
  };

interface ProfileFiltersResponse {
  [key: string]: {
    name: string;
    value: string;
    options: {
      name: string;
      value: number;
    }[];
  };
}

type ProfilesLastActivity = Array<{
  profile_id: string;
  last_activity_stamp: string | null;
}>;

const handleCheckError = (error: AxiosError) => {
  const domainCode = error.response?.data?.error?.domainCode;

  let {message} = error;

  if (domainCode && chatReasonErrorType[domainCode] && chatReasonErrorType[domainCode].length) {
    message = chatReasonErrorType[domainCode];
  }

  throw new RequestError({
    message,
    // @ts-expect-error domainCode is missing
    domainCode,
  });
};

const ProfileService = {
  trackPhoneClick(profileId: number) {
    return BaseAxiosInstance.post(`/profile/${profileId}/phone-click`);
  },

  getProfileByIdAsLocation(id: number) {
    return BaseAxiosInstance.post(`/profile/${id}/sphinx`);
  },

  getProfileById(id: number): Promise<TProfile> {
    const authHeader = AuthService.getAuthHeader();
    return BaseAxiosInstance.get(`profiles/${id}`, {
      headers: {
        ...(authHeader && {Authorization: authHeader}),
      },
    }).then(({profile}) => profileResponseTransformer(profile));
  },
  getProfileStatsById(id: number) {
    return AuthorizedAxiosInstance.get(`profile/${id}/stats`);
  },
  updateProfile(profileId: number, formValues: Record<string, string>) {
    return AuthorizedAxiosInstance.post(`profiles/${profileId}/update`, formValues);
  },

  /**
   * {"message":"Video uploaded successfully","data":{"video_id":1094075}}
   * @param profileId
   * @param formData
   * @param axiosOptions
   */
  uploadVideo: <TUploadVideo>function uploadVideo(profileId, formData, options) {
    return AuthorizedAxiosInstance.post(`profiles/${profileId}/upload-video`, formData, {
      ...options,
      timeout: config.httpFileTimeout,
    });
  },

  /**
   * - {"message":"Successful","data":{"processed":0,"url":null,"thumbnail_url":null}}
   * - {"message":"Successful","data":{"processed":2,"url":"https:\/\/uat1.flash.chat\/video\/1.mp4","thumbnail_url":"https:\/\/uat1.flash.chat\/video\/1.jpeg"}}
   * @param videoId
   */
  checkVideo: <TCheckVideo>function checkVideo(videoId) {
    return AuthorizedAxiosInstance.post(`profiles/${videoId}/check-video`);
  },
  /**
   * {"message":"Video successfully deleted","data":[]}
   * @param videoId
   */
  deleteVideo: <TDeleteVideo>function deleteVideo(videoId: number) {
    return AuthorizedAxiosInstance.post(`profiles/${videoId}/delete-video`);
  },
  uploadImage(profileId: number, formData: FormData): Promise<void> {
    return AuthorizedAxiosInstance.post(`profiles/${profileId}/upload-image`, formData, {
      timeout: config.httpFileTimeout,
    });
  },
  deleteImage(imageId: number): Promise<TProfileImage[]> {
    return AuthorizedAxiosInstance.post(`profiles/${imageId}/delete-image`);
  },
  setThumbnail(profileId: number, imageId: number): Promise<string> {
    return AuthorizedAxiosInstance.post(`profiles/${profileId}/set-thumbnail/${imageId}`).then(
      ({new_thumbnail_url: newThumbnailUrl}) => newThumbnailUrl
    );
  },
  getPhoneVerificationStatus(
    userid: number,
    phone: string,
    params?: AxiosRequestConfig
  ): Promise<{isVerified: boolean; isAvailable: boolean}> {
    return AuthorizedAxiosInstance.post(
      'profile/verify-phone/is-verified',
      {user_id: userid, phone},
      params
    ).then(({is_verified, available}) => ({
      isVerified: Boolean(is_verified),
      isAvailable: Boolean(available),
    }));
  },
  sendPhoneVerificationSms(phone: string) {
    return AuthorizedAxiosInstance.post('profile/verify-phone/send-code', {
      phone,
    });
  },
  verifyPhoneByCode(phone: string, code: string) {
    return AuthorizedAxiosInstance.post('profile/verify-phone/apply-code', {
      phone,
      code,
    });
  },
  enableProfile(profileId: number) {
    return AuthorizedAxiosInstance.post(`profiles/${profileId}/enable`);
  },
  disableProfile(profileId: number) {
    return AuthorizedAxiosInstance.post(`profiles/${profileId}/disable`);
  },
  getProfileByUrl(profileUrl: string, isNeedAuth: boolean, roles: TAppProfileFieldOptions[]) {
    const axiosInstance = isNeedAuth ? AuthorizedAxiosInstance : BaseAxiosInstance;

    return axiosInstance
      .get(`profile?profileUrl=${profileUrl}`)
      .then(({profile}) => singleLocationProfileTransformer(roles, profile))
      .then((profile) => profileResponseTransformer(profile));
  },
  getMembershipOptions(locationId: number) {
    return AuthorizedAxiosInstance.post('memberships', {
      location_id: locationId,
    }).then(({memberships}) =>
      memberships.reduce((accum: Record<string, unknown>, membership: Record<string, string>) => {
        // eslint-disable-next-line no-param-reassign
        accum[membership.id] = membership;
        return accum;
      }, {})
    );
  },
  getGoldMembershipOptions() {
    return AuthorizedAxiosInstance.post('dating-memberships').then(({memberships}) =>
      memberships.reduce((accum: Record<string, unknown>, membership: Record<string, string>) => {
        // eslint-disable-next-line no-param-reassign
        accum[membership.id] = membership;
        return accum;
      }, {})
    );
  },
  changeLocation(profileId: number, locationId: number) {
    return AuthorizedAxiosInstance.post(`profiles/${profileId}/update-location`, {
      location_id: locationId,
    });
  },
  getProfilesByLocationId(
    locationId: number,
    currentLat: number | null,
    currentLng: number | null
  ): Promise<{profiles: TProfile[]}> {
    const url = [
      `profiles-by-location/${locationId}`,
      currentLat && currentLng && `currentLat=${currentLat}&currentLng=${currentLng}`,
    ]
      .filter((x) => x)
      .join('?');
    return BaseAxiosInstance.get(url);
  },
  loadProfilesByGeoPosition(
    params: TProfileListParams
  ): Promise<{profiles: TProfile[]; total: number}> {
    const queryGetParams = qs.stringify(params);
    return BaseAxiosInstance.get(`/profiles/list?${queryGetParams}`);
  },
  loadProfilesLastActivity(profile_ids: number[]) {
    return BaseAxiosInstance.get<ProfilesLastActivity>('/v1/whoisonline', {
      params: {
        profile_ids,
      },
    });
  },
  topUpProfile(profileId: number) {
    return AuthorizedAxiosInstance.post(`profiles/${profileId}/top-up`);
  },
  /**
   * Identity verification methods
   */
  getVerification(profileId: number) {
    return AuthorizedAxiosInstance.get(`profile/${profileId}/verification`);
  },
  getVerificationWithTransformer(profileId: number) {
    return AuthorizedAxiosInstance.get(`profile/${profileId}/verification`).then(
      verificationTransformer
    );
  },
  uploadVerificationVideo(profileId: string, formData: FormData, axiosOptions: AxiosRequestConfig) {
    return AuthorizedAxiosInstance.post(
      `profile/${profileId}/verification/video/upload`,
      formData,
      {...axiosOptions, timeout: config.httpFileTimeout}
    ).then(verificationTransformer);
  },
  deleteVerificationVideo(id: number) {
    return AuthorizedAxiosInstance.post(`profile/verification/${id}/video/delete`);
  },
  checkPromoCode({profileId, promocode}: {profileId: number; promocode: string}) {
    return AuthorizedAxiosInstance.post(`profile/${profileId}/promocode-valid`, {promocode});
  },
  applyPromoCode({profileId, promocode}: {profileId: number; promocode: string}) {
    const data = {profileId, promocode};
    return AuthorizedAxiosInstance.get(`profile/${profileId}/complimentary-promocode`, {data});
  },
  async getChatSettings(id: number) {
    return AuthorizedAxiosInstance.get(`profile/chat-settings?profileId=${id}`);
  },
  setChatSettings(
    profileId: number,
    formData: {profileId: number},
    axiosOptions: AxiosRequestConfig
  ) {
    const data: {profileId: number} = {...formData};
    data.profileId = profileId;

    return AuthorizedAxiosInstance.post(`profile/set-chat-settings`, data, axiosOptions);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,consistent-return
  async checkAnonymChatPermissions(
    geo: TGeoLocation | undefined,
    opponentId: string | number,
    axiosOptions?: AxiosRequestConfig
  ) {
    try {
      const check = await BaseAxiosInstance.post(
        `anonym/check-chat-permissions`,
        {
          currentGeoLat: geo?.lat,
          currentGeoLng: geo?.lng,
          recipientProfileId: opponentId,
        },
        axiosOptions
      );
      return check;
    } catch (error) {
      handleCheckError(error);
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,consistent-return
  async checkChatPermissions(
    senderId: number,
    opponentId: string | number,
    axiosOptions?: AxiosRequestConfig
  ) {
    try {
      const check = await AuthorizedAxiosInstance.post(
        `profile/check-chat-permissions`,
        {
          senderProfileId: senderId,
          recipientProfileId: opponentId,
        },
        axiosOptions
      );
      return check;
    } catch (error) {
      handleCheckError(error);
    }
  },
  getReportOptions() {
    try {
      return AuthorizedAxiosInstance.get(`report-profile/reasons`).then(
        reportProfileOptionsTransformer
      );
    } catch (error) {
      throw new RequestError({message: error.message});
    }
  },
  sendReportToProfile(formValues: Record<string, string>) {
    AuthorizedAxiosInstance.post('report-profile/create', formValues);
  },
  uploadProfileIdImages: (profileId: number, formValues: Record<string, string> | FormData) => {
    return AuthorizedAxiosInstance.post(`verification/${profileId}/id-upload`, formValues);
  },
  getProfileFilters: (params: TProfileFiltersParams): Promise<ProfileFiltersResponse> => {
    try {
      const query = qs.stringify(params);
      return BaseAxiosInstance.get(`profiles/list/filters?${query}`);
    } catch (error) {
      throw new RequestError({message: error.message});
    }
  },
};

export default ProfileService;
