import Cookie from 'js-cookie';

import {TThunkAsyncAction, TUtmData} from '~/types/appTypes';
import logger from '~/utils/logger';
import config from '~/constants/config';
import {COOKIE_UTMS} from '~/constants/cookies';

import {UPDATE_UTMS} from './constants';

const log = logger.module('utms');

export const saveUtmsAction: TThunkAsyncAction<TUtmData> = (utms) => (dispatch) => {
  const expires = new Date(Date.now() + parseInt(config.utmTTL.toString(), 10));
  Cookie.set(COOKIE_UTMS, JSON.stringify(utms), {expires});
  dispatch({
    type: UPDATE_UTMS,
    payload: {utms},
  });
};

export const restoreUtmsAction: TThunkAsyncAction = () => (dispatch) => {
  try {
    let utms = Cookie.get(COOKIE_UTMS);
    if (utms) {
      utms = JSON.parse(utms);
      dispatch({
        type: UPDATE_UTMS,
        payload: {
          utms,
        },
      });
    }
  } catch (error) {
    log.error('Restore Utms', error.message);
  }
};
