import {Action} from 'redux';

import {TLeafLocation} from '~/types/LeafLocation';

import {APP_HEADER_LOCATION_UPDATE} from '../constants';

type TUpdateHeaderLocationAction = (location: TLeafLocation | null) => Action;
export const updateHeaderLocationAction: TUpdateHeaderLocationAction = (location) => ({
  type: APP_HEADER_LOCATION_UPDATE,
  payload: {location},
});

export default {
  updateHeaderLocationAction,
};
