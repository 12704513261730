export interface TAdvice {
  title: string;
  description: string;
}
const advicesList: TAdvice[] = [
  {
    title: 'Use Correct Pronouns',
    description:
      'In the trans community, you never want to assume someone’s pronoun. You also never want to use an offensive pronoun. If you don’t know someone’s preferred pronouns, try engaging in conversation that allows you to avoid the use of pronouns altogether until you can gain more clarity. Alternatively, “they” is a safe pronoun that most would feel comfortable responding to until you know for sure. Addressing someone with their correct pronouns is an instant show of respect and understanding.',
  },
  {
    title: 'Don’t Ask Inappropriate Questions',
    description:
      'Never assume that because you’re comfortable discussing something personal that everyone else is. Bendr is full of people at different stages of their journey, and some would rather keep certain things to themselves. Avoid any conversations that might make people uncomfortable.',
  },
  {
    title: 'Break the Ice',
    description:
      'Try to kick off the conversation by showing people a little bit of your personality. A great way to start a chat on Bendr is by telling a joke or even playing a game. Want to get a little bit bolder? Tell the person why you chose to message them. Some people are attracted to that kind of honesty. More than anything, just talk to whoever you’re talking to like they’re any other human being. No one wants to be treated as an outsider, and that’s exactly why they come to Bendr.',
  },
  {
    title: 'Understand the Difference Between Sexuality and Gender',
    description:
      'On Bendr, and in the trans community, everyone is entitled to their own distinctive gender identity. These identities are completely different from how they might identify sexually. Understanding the difference can go a long way as you navigate the world of trans dating.',
  },
  {
    title: 'Don’t Fetishize or Oversexualize',
    description:
      'Most people take kindly to a compliment. Who doesn’t like it when someone tells them they look good? Just make sure that as you do, you’re not objectifying the person you’re speaking to. Unfortunately, trans folk are too often treated like some sort of novelty or fetish. Always remember to approach everyone you meet with dignity and respect.',
  },
  {
    title: 'Stick It to the Stereotype',
    description:
      'Trans women have been painted with a broad brush in the media. In reality, no two people are ever the same - and trans women certainly are no exception. You may be holding onto stereotypes of trans folk without even realizing it, so make sure to approach everyone with an open mind and a non-judgmental attitude.',
  },
  {
    title: 'Don’t Treat It Like a Learning Experience',
    description:
      'It’s only natural to take an interest in whoever you’re talking to. Ask them what they do for work, what their hobbies are, or where they grew up. Just try to avoid asking too many questions about their experience as a transgender person. Trans women are here to meet people and have fun just like you are, and the last thing they want to do is turn it into a lesson.',
  },
];

export default advicesList;
