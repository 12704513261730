import * as React from 'react';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';

import routeByName from '~/constants/routes';
import {navigateAction} from '~/actions/navigateActions';

interface Props {
  component?: React.ReactElement;
  cloneElement?: boolean;
  className?: string;
}
const CreateProfile: React.FC<Props> = ({
  component = <Button>Create profile</Button>,
  cloneElement = false,
  className,
}) => {
  const dispatch = useDispatch();
  const handleOnLick = useCallback(() => {
    dispatch(navigateAction(routeByName.chooseProfileType));
  }, [dispatch]);

  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  let control = (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={className} onClick={handleOnLick}>
      {component}
    </div>
  );

  if (cloneElement) {
    control = React.cloneElement(component, {className, onClick: handleOnLick});
  }

  return <>{control}</>;
};

export default CreateProfile;
