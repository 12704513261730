import {getFingerprint} from '~/helpers/fingerprint';
import {TThunkAction} from '~/types/appTypes';

import {SET_USER_FINGERPRINT} from '../constants';

type TDefineUserFingerPrint = () => TThunkAction;
export const defineUserFingerPrintAction: TDefineUserFingerPrint = () => async (dispatch) => {
  const fingerprint = await getFingerprint();

  await dispatch({type: SET_USER_FINGERPRINT, payload: {fingerprint}});

  return fingerprint;
};

type TRemoveUserFingerPrint = () => TThunkAction;
export const removeUserFingerPrintAction: TRemoveUserFingerPrint = () => async (dispatch) => {
  return dispatch({
    type: SET_USER_FINGERPRINT,
    payload: {fingerprint: null},
  });
};
