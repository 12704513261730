import logger from '~/utils/logger';
import {restorePromocodeAction} from '~/modules/promocode';
import {restoreUtmsAction} from '~/modules/utms';
import getQueryParam from '~/helpers/routes/getQueryParam';
import isStandalone from '~/helpers/navigator/isStandalone';
import {AppStatus} from '~/constants/app';
import {isIOS} from '~/helpers/navigator';

import {
  APP_CLEAR_CHAT_EASY_START_FROM,
  APP_HEADER_NEARBY_LOCATION_UPDATE,
  APP_SET_CHAT_EASY_START_FROM,
  APP_SET_OPEN_FILE_UPLOAD,
  APP_SET_PLAY_VIDEO,
  APP_SET_PROFILE_FIELDS,
  APP_SET_SHEMALE_PAGES_LIST,
  APP_SET_STANDALONE_MODE,
  APP_UPDATE_CONFIG,
  APP_UPDATE_STATUS,
} from './constants';
import {initAuthAction} from '../../Auth/store/actions';
import AppService from '../AppService';
import {getGeoLocationAction, getLocationsAction} from '../../Locations/store/actions';
import syncAppLanguagesAction from './syncAppLanguagesAction';
import LocationsService from '../../Locations/LocationsService';
import setCountryCodeAction from '../../CurrentUser/store/setCountryCodeAction';
import {setIPGeoLocationByAction} from '../../CurrentUser/store/actions';

const log = logger.module('App/actions');

export const updateAppStatus = (status) => ({
  type: APP_UPDATE_STATUS,
  payload: {status},
});

export const syncProfileFieldsAction = () => async (dispatch) => {
  const profileFields = await AppService.getProfileFields();

  dispatch({
    type: APP_SET_PROFILE_FIELDS,
    payload: {
      profileFields,
    },
  });
};

export const getShemalePagesAction = () => async (dispatch) => {
  const shemalePages = await AppService.getShemalePages();

  dispatch({
    type: APP_SET_SHEMALE_PAGES_LIST,
    payload: shemalePages,
  });

  return shemalePages;
};

export const defineStandaloneMode = () => (dispatch) => {
  const isStandaloneMode =
    getQueryParam(window.location, 'mode') === 'standalone' || isStandalone();

  if (isIOS && isStandaloneMode) {
    AppService.loadManifest();
    AppService.loadPwaIcon();
  }

  dispatch({
    type: APP_SET_STANDALONE_MODE,
    payload: isStandaloneMode,
  });
};

export const setVideoPlay = (isPlay) => (dispatch) => {
  dispatch({
    type: APP_SET_PLAY_VIDEO,
    payload: isPlay,
  });
};

export const setFileUploadOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: APP_SET_OPEN_FILE_UPLOAD,
    payload: isOpen,
  });
};

export const updateAppConfig = (appConfig) => (dispatch) => {
  dispatch({type: APP_UPDATE_CONFIG, payload: {config: appConfig}});
};

const loadUserCountryAction = () => async (dispatch) => {
  try {
    const geoLocation = await LocationsService.getGeoLocation();
    dispatch(setIPGeoLocationByAction(geoLocation));
    dispatch(setCountryCodeAction(geoLocation.countryCode));
  } catch (error) {
    log.info('Load country error', {error});
  }
};

export const initAppAction = () => async (dispatch) => {
  try {
    await Promise.all(
      [
        loadUserCountryAction(),
        defineStandaloneMode(),
        initAuthAction(),
        getLocationsAction(),
        syncAppLanguagesAction(),
        restorePromocodeAction(),
        restoreUtmsAction(),
        syncProfileFieldsAction(),
      ].map(dispatch)
    );

    await dispatch(getGeoLocationAction()).catch((error) => {
      log.info('Error during define user geolocation', {error});
    });
    dispatch(updateAppStatus(AppStatus.initialized));
  } catch (error) {
    dispatch(updateAppStatus(AppStatus.error));
    log.error('Error in initAppAction', {error});
  }
};

export const updateHeaderNearbyLocation = (locationId) => async (dispatch) => {
  try {
    const location = await LocationsService.getLocationById(locationId);
    const {nearby_locations: nearbyLocations} = location;
    dispatch({
      type: APP_HEADER_NEARBY_LOCATION_UPDATE,
      payload: nearbyLocations,
    });
  } catch (error) {
    log.info('Error UpdateHeaderNearbyLocation', {error});
  }
};

export const updateChatEasyStart = (data) => ({
  type: APP_SET_CHAT_EASY_START_FROM,
  payload: data,
});

export const clearChatEasyStart = () => ({
  type: APP_CLEAR_CHAT_EASY_START_FROM,
  payload: null,
});
