"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.array2Map = exports.array2MapWithTransform = void 0;
exports.array2MapWithTransform = function (array, byProp, fn) {
    return array.reduce(function (acc, item) {
        var key = String(item[byProp]);
        acc[key] = fn(item);
        return acc;
    }, {});
};
exports.array2Map = function (array, byProp) {
    return exports.array2MapWithTransform(array, byProp, function (i) { return i; });
};
