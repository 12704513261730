import {Reducer} from 'redux';

import {CHAT_USAGE_INFO_UPDATE} from './actionTypes';

export interface TStateChatUsageInfo {
  messageSendCount: number;
  lastMessageSendTime: number | null;
}

const initialState: TStateChatUsageInfo = {
  messageSendCount: 0,
  lastMessageSendTime: null,
};

const actionMap: Record<string, Reducer<TStateChatUsageInfo>> = {
  [CHAT_USAGE_INFO_UPDATE]: (state, action) => {
    const {payload} = action;

    return {
      ...state,
      messageSendCount: payload.messageSendCount,
      lastMessageSendTime: payload.lastMessageSendTime,
    };
  },
};

export const chatUsageInfoReducer: Reducer<TStateChatUsageInfo> = (
  state = initialState,
  action
) => {
  const reducer = actionMap[action.type];

  if (reducer) {
    return reducer(state, action);
  }

  return state;
};
