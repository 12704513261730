import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

import {BaseAxiosInstance} from '~/utils/axios';
import logger from '~/utils/logger';
import {ONE_YEAR} from '~/constants/dateTime';
import localStorageWrapper from '~/utils/localStorageWrapper';

const log = logger.module('AuthService');

const urlPrefix = '';
const tokenKey = 'authToken';
const refreshTokenKey = 'authRefreshToken';

const AuthService = {
  signUp(userFields) {
    const {
      username,
      password,
      password_confirmation: passwordConfirmation,
      ...restValues
    } = userFields;
    return BaseAxiosInstance.post(`${urlPrefix}/register`, {
      username: username.trim(),
      password: password.trim(),
      password_confirmation: passwordConfirmation.trim(),
      ...restValues,
    });
  },

  async signIn({rememberMe, ...userFields}) {
    const {identifier, password, ...restValues} = userFields;
    const {token, refresh_token: refreshToken} = await BaseAxiosInstance.post('login', {
      identifier: identifier.trim(),
      password: password.trim(),
      ...restValues,
      remember_me: rememberMe,
    });

    this.setToken(token);
    this.setRefreshToken(refreshToken);
  },

  async easySignUp(userFields) {
    const {phone} = userFields;
    const {
      token,
      // refresh_token: refreshToken,
      userId,
    } = await BaseAxiosInstance.post(`quick-registration`, {
      phone,
      remember_me: true,
    });

    return {
      token,
      // refreshToken,
      userId,
    };
  },

  async easyCheckVerificationCode(userFields) {
    const {
      code,
      signUpData: {userId, token},
    } = userFields;
    await BaseAxiosInstance.post(
      `${urlPrefix}/quick-registration/verify-sms`,
      {
        userId,
        code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((result) => {
        // this.setToken(token);
        // this.setRefreshToken(refreshToken);
        return result;
      })
      .catch((error) => {
        log.info('Error in verify', error);
      });
  },

  emailValidation(email) {
    return BaseAxiosInstance.post('register/email-available', {email})
      .then(({available}) => ({available, errorMessage: ''}))
      .catch((error) => {
        if (
          error.response &&
          error.response.status === 422 &&
          error.response.data.validation.email
        ) {
          return {
            available: false,
            errorMessage: error.response.data.validation.email,
          };
        }
        log.error('Error during validateEmail', {error});
        return {available: false, errorMessage: ''};
      });
  },

  async resetPassword({token, password}) {
    const response = await BaseAxiosInstance.post('resetting/reset', {
      token,
      password,
      remember_me: true,
    });

    this.setToken(response.token);
    this.setRefreshToken(response.refreshToken);

    return response;
  },

  usernameValidation(username) {
    return BaseAxiosInstance.post('register/username-available', {
      username,
    }).then(({available}) => available);
  },

  resetPasswordRequest(data) {
    return BaseAxiosInstance.post('resetting/request', data, {
      needMessage: true,
    });
  },

  refreshTokenPromise: null,

  requestRefreshToken() {
    if (this.refreshTokenPromise !== null) {
      return this.refreshTokenPromise;
    }

    const token = this.getClearToken();
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return false;
    }
    this.refreshTokenPromise = BaseAxiosInstance.post('token/refresh', {
      refresh_token: refreshToken,
    })
      .then((response) => {
        log.info('Refresh token success');
        return response;
      })
      .catch((error) => {
        const {data} = error.response;
        let parsedToken;
        try {
          parsedToken = jwtDecode(token);
        } catch (ex) {
          parsedToken = ex.message;
        }

        log.error('Error during refresh token', {
          error,
          responseData: JSON.stringify(data),
          token,
          refreshToken,
          parsedToken,
        });
        this.logout();

        return {};
      })
      .finally((response) => {
        this.refreshTokenPromise = null;
        return response;
      });

    return this.refreshTokenPromise;
  },

  async refreshToken() {
    try {
      const {token: accessToken, refresh_token: refreshToken} = await this.requestRefreshToken();
      this.setToken(accessToken);
      this.setRefreshToken(refreshToken);
      if (!accessToken) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  },

  logout() {
    this.removeToken();
    this.removeRefreshToken();

    const allWarnings = Object.keys(localStorage).filter(
      (item) => item.substring(0, 8) === 'warning-'
    );
    allWarnings.forEach((key) => localStorageWrapper.removeItem(key));
  },

  hasToken() {
    return !!this.getAuthHeader();
  },
  getClearToken() {
    return Cookie.get(tokenKey);
  },
  getAuthHeader() {
    const token = Cookie.get(tokenKey);
    return token && `Bearer ${token}`;
  },
  removeToken() {
    const expires = new Date(0);
    return Cookie.set(tokenKey, '', {expires});
  },

  removeRefreshToken() {
    const expires = new Date(0);
    return Cookie.set(refreshTokenKey, '', {expires});
  },

  getRefreshToken() {
    return Cookie.get(refreshTokenKey);
  },

  setToken(token) {
    if (!token) {
      const expires = new Date(0);
      return Cookie.set(tokenKey, '', {expires});
    }

    const expires = new Date(Date.now() + ONE_YEAR);
    return Cookie.set(tokenKey, token, {expires});
  },

  setRefreshToken(token) {
    if (!token) {
      const expires = new Date(0);
      return Cookie.set(refreshTokenKey, '', {expires});
    }

    const expires = new Date(Date.now() + ONE_YEAR);
    return Cookie.set(refreshTokenKey, token, {expires});
  },

  async changeEmailConfirm({token, email}) {
    const response = await BaseAxiosInstance.post(`user/confirm-change-email`, {
      token,
      email,
      remember_me: true,
    });

    this.setToken(response.token);
    this.setRefreshToken(response.refresh_token);

    return response;
  },

  async getImpersonateToken(code) {
    const response = await BaseAxiosInstance.get(`user/impersonate-token/${code}`);

    return response.token;
  },

  async autologin(code) {
    const response = await BaseAxiosInstance.post('autologin', {
      code,
      remember_me: true,
    });

    this.setToken(response.token);
    this.setRefreshToken(response.refresh_token);

    return response;
  },
};
export default AuthService;
