import * as React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      marginLeft: theme.spacing(1.25),
      marginTop: theme.spacing(1.25),
      '&.checked': {
        '& $checkMark': {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      },
      '&.disabled': {
        '& $checkMark': {
          opacity: '0.5',
        },
      },
    },
    input: {
      display: 'none',
    },
    checkMark: {
      border: `1px solid ${theme.palette.common.black}`,
      borderRadius: 10,
      padding: '9px 11px',
    },
  }),
  {name: 'CheckboxButton'}
);

interface Props {
  value?: string | number;
  label?: string | React.ReactNode;
  name?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent) => void;
  helperText?: React.ReactNode;
  showError?: boolean;
  inputProps?: Record<string | number, string | number>;
  disabled?: boolean;
}

const CheckboxButton: React.FC<Props> = ({
  value,
  label,
  name,
  checked,
  onChange,
  helperText,
  showError,
  inputProps,
  onClick,
  disabled = false,
  ...props
}) => {
  const s = useStyles();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/label-has-associated-control */}
      <label className={clsx(s.container, 'text-default', {checked, disabled})} onClick={onClick}>
        <input
          {...props}
          {...inputProps}
          value={value}
          name={name}
          onChange={onChange}
          readOnly={!onChange}
          type="checkbox"
          checked={checked}
          className={s.input}
          disabled={disabled}
        />
        <div className={s.checkMark}>{label && <div className="h2">{label}</div>}</div>
      </label>
      {showError && (
        <FormHelperText error margin="dense" variant="outlined">
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default CheckboxButton;
