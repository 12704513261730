import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import {Box} from '@material-ui/core';

import ButtonBlack from '~/components/ui/buttons/ButtonBlack';

const useStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginRight: theme.spacing(1),
    },
    label: {
      textTransform: 'uppercase',
    },
    globeIcon: {
      width: 21,
      height: 21,
    },
  }),
  {name: 'ChooseLanguageInput'}
);

interface Props {
  onFocus: () => void;
  renderValue: () => string;
}

const ChooseLanguageInput: React.FC<Props> = ({renderValue, onFocus}) => {
  const s = useStyles();
  return (
    <Grid className={s.container} onClick={onFocus}>
      <ButtonBlack className={clsx('notranslate', s.label)}>
        <Box fontWeight="500" fontSize={14}>
          {renderValue()}
        </Box>
      </ButtonBlack>
    </Grid>
  );
};

export default ChooseLanguageInput;
