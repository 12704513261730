import {ChangeEvent, useEffect, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {sortBy} from 'lodash-es';
import {Box, Button, Divider, Input, InputAdornment} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import makeStyles from '@material-ui/core/styles/makeStyles';

import logger from '~/utils/logger';
import {TLeafLocation} from '~/types/LeafLocation';
import useModalById from '~/customHooks/useModalById';
import {MODAL_SEARCH_CITY_BENDR} from '~/rootStore/modals/modalsIds';
import {SearchCityEmptyState} from '~/components/SearchCity';
import SpriteIcon from '~/components/ui/icons/SpriteIcon';
import {
  getLeafLocationLabel,
  getLocationLink,
  leafLocationsSelector,
} from '~/modules/Locations/store/selectors';
import {TLocationType} from '~/modules/Locations/constants/LocationTypes';
import {isProfileTransSelector} from '~/modules/CurrentUser/store/selectors';

import {filterProfileTypeSelector} from '../../../store/selectors';
import {parseLocations} from '../../helpers';

const log = logger.module('ModalSearchCity');

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      height: '100%',
      overflowY: 'scroll',
      backgroundColor: theme.palette.common.white,
    },
    Button: {
      background: '#FDFDFD',
      borderRadius: 0,
      color: 'black',
      fontSize: 18,
      justifyContent: 'flex-start',
      padding: 11,
    },
    listItem: {
      padding: theme.spacing(1.25, 2),
      fontSize: 18,
    },
    input: {
      padding: '4px 12px 4px 15px',
      border: 'solid 1px #8897A9',
      borderRadius: 10,
      fontSize: 18,
    },
    inputFocused: {
      borderColor: theme.palette.primary.main,
    },
    inputIcon: {
      color: theme.palette.common.black,
    },
  }),
  {name: 'SearchCityContainer'}
);

const minQueryLength = 3;

// TODO: https://slippery-snails.atlassian.net/browse/TSP-1025
// in case of push some result item up
const cityWeights: Record<number, number> = {
  2627: 1, // Orange County, CA, USA
};

const ModalSearchCity: React.FC = () => {
  const s = useStyles();
  const isProfileTrans = useSelector(isProfileTransSelector);
  const leafLocations = useSelector(leafLocationsSelector);
  const profileType = useSelector(filterProfileTypeSelector);
  const [query, setQuery] = useState('');
  const {isOpen: isSearchCityModalOpen, close: closeSearchCityModal} =
    useModalById(MODAL_SEARCH_CITY_BENDR);

  const parsedLeafLocations = useMemo(() => {
    const listLeafLocations: TLeafLocation[] = Object.values(leafLocations);

    return parseLocations(listLeafLocations);
  }, [leafLocations]); // eslint-disable-line react-hooks/exhaustive-deps

  const excludedLocationsFilter = useCallback(
    ({
      type,
      no_search: noSearch,
      active_provider: activeEscortsProfiles,
      active_profile: activeProfiles,
    }: TLeafLocation) => {
      if (isProfileTrans) {
        return (
          type !== TLocationType.Country &&
          type !== TLocationType.State &&
          !noSearch &&
          activeProfiles
        );
      }

      return (
        type !== TLocationType.Country &&
        type !== TLocationType.State &&
        !noSearch &&
        activeEscortsProfiles
      );
    },
    [isProfileTrans]
  );

  const locationsListFormatted = useMemo(() => {
    return parsedLeafLocations.filter(excludedLocationsFilter).map((loc: TLeafLocation) => ({
      label: getLeafLocationLabel(loc),
      value: getLocationLink(loc, profileType),
      ...loc,
      weight: cityWeights[loc.id],
    }));
  }, [excludedLocationsFilter, parsedLeafLocations, profileType]);

  useEffect(() => {
    // report empty list to Sentry (FL-1138)
    if (locationsListFormatted.length <= 0) {
      log.error(
        `The locations list is empty! Pre-filitered locations: ${parsedLeafLocations.length}`
      );
    }
  }, [locationsListFormatted, parsedLeafLocations]);

  const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => setQuery(e.target.value.trim());

  const filterByQuery = useCallback(
    ({name}) => name.toLowerCase().startsWith(query.toLowerCase()),
    [query]
  );

  const locationsListFilteredByQuery = useMemo(
    () => sortBy(locationsListFormatted.filter(filterByQuery), 'weight'),
    [locationsListFormatted, filterByQuery]
  );

  const handleCloseModal = useCallback(() => {
    closeSearchCityModal();
    setQuery('');
  }, [closeSearchCityModal]);

  const onChange = useCallback(
    (locationId) => {
      const location = leafLocations[locationId];

      closeSearchCityModal({location});
      setQuery('');
    },
    [leafLocations, closeSearchCityModal]
  );

  const isQueryLessThanMin = query.length < minQueryLength;

  const locations = isQueryLessThanMin ? locationsListFormatted : locationsListFilteredByQuery;
  return (
    <Modal disableScrollLock open={isSearchCityModalOpen} onClose={handleCloseModal}>
      <div className={s.wrapper}>
        <div>
          <Button variant="text" className={s.Button} onClick={handleCloseModal}>
            <SpriteIcon name="back" className="mr-1" fontSize="normal" color="black" />
            <span>Search for your City</span>
            <div className="flex-1" />
            <SpriteIcon name="gallery_close" />
          </Button>
          <Divider />
        </div>
        <div className="pt-1 px-2">
          <Input
            autoFocus
            disableUnderline
            placeholder="Type city name here"
            onChange={onQueryChange}
            defaultValue={query}
            fullWidth
            classes={{focused: s.inputFocused}}
            className={s.input}
            endAdornment={
              <InputAdornment position="end">
                <SpriteIcon name="search" fontSize="small" className={s.inputIcon} />
              </InputAdornment>
            }
          />
        </div>
        <div>
          {locations.length === 0 && (
            <Box mx={3}>
              <SearchCityEmptyState />
            </Box>
          )}
          {locations.length > 0 &&
            locations.map(({id, label, value}) => {
              return (
                <div
                  key={id}
                  role="button"
                  tabIndex={0}
                  onClick={() => onChange(id)}
                  data-value={value}
                  className={s.listItem}
                >
                  {label}
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};

export default ModalSearchCity;
