import {createFormStepToId} from '~/modules/CurrentUser/constants/createProfileConst';
import {PaymentMethods, PaymentTypes} from '~/constants/payments';
import {twoFATokenKey, VerificationMethods} from '~/modules/Auth/TwoFactorAuth/constants';

import {TProfile, TProfileTextSettings} from './Profile';
import {TLeafLocation} from './LeafLocation';

export type TCurrentUserProfile = TProfile & {
  isCompleted: boolean;
  isPhoneVerified: boolean;
  isVerified: boolean;
  isVerificationPending: boolean;
  isVerificationIdRequire: boolean;
  expiredAt: string;
};

export interface TProfileReportOption {
  value: string;
  label: string;
}

export enum RegistrationType {
  NORMAL = 'normal',
  QUICK = 'quick',
}

export interface TSubscription {
  amount: string;
  card_id: null | number;
  id: number;
  is_domestic: boolean;
  membership_id: number;
  next_renewal: {
    date: string;
    timezone: string;
    timezone_type: number;
  };
  profile_id: number;
  topup_credits?: number;
  type: string;
  location_id: number;
  location_label: string;
  payment_method: string;
  recurring: boolean;
  expires_at: string;
}

export interface TTransaction {
  amount: string;
  descriptions: string[];
  id: number;
  payment_id: number;
  refundable: boolean;
  timestamp: number;
  trans_id: string | null;
}

export interface TCurrentUserUser {
  id: number;
  username: string;
  hasBudgetSubscription: boolean;
  vip: {
    active: boolean;
    subscription: string;
    expiredAt: string;
    location: string;
  };
  profileId: number;
  isPhoneVerified: boolean;
  membership: {
    isMember: boolean;
    isComplimentary: boolean;
    active: boolean;
    subscription: boolean;
    expiredAt: string;
  };
  budget: number;
  lang: string;
  email: string;
  phone: string;
  is_admin: boolean; // not used
  admin_token?: string; // not used
  registrationType: RegistrationType;
  topUpCredits?: number; // amount of credits user can spend on top ups
  subscriptions: TSubscription[];
  transactions: TTransaction[];
  budgetStr: string;
  topup_validity_interval?: number; // wait interval before user will be able to topup profile
  last_topup: number | null; // timestamp of last profile topup
}

export interface TPendingPayments {
  paymentId: number;
  membershipId: number;
  paymentType: PaymentTypes;
  amount: number;
  currentAmount: number;
  paymentMethod: PaymentMethods;
}

export interface TProfileStatsValue {
  1: number;
  7: number;
  30: number;
  lifetime: number;
}
export interface TProfileStats {
  clicks: TProfileStatsValue;
  views: TProfileStatsValue;
}

export interface TCurrentUserState {
  location: {
    country: TLeafLocation;
    city: TLeafLocation;
    state: TLeafLocation;
    borough?: TLeafLocation;
  };
  profile: TCurrentUserProfile | null;
  user: TCurrentUserUser | null;
  currentLocation: {
    lat: number;
    lng: number;
    timestamp: number;
  };
  currentIpGeoLocation: {
    id: number;
    countryCode: string;
    label: string;
    lat: string;
    lng: string;
    name: string;
    path: string;
  };
  twoFA: {
    [twoFATokenKey]: string;
    phone: string | null;
    email: string | null;
    attemptsCount: number | null;
    login: string;
    verificationMethod: VerificationMethods;
  } | null;
  createProfileStep: createFormStepToId;
  utms: Record<string, string>;
  profileStats: TProfileStats;
  profileChatSettings: TProfileTextSettings;
  promocode: string | null;
  profileReportOptions: TProfileReportOption[];
  favorites: TProfile[];
  profileVerification: {
    height: number;
    id: number;
    video_path: string;
    video_thumbnail_path: string;
    width: number;
  };
  pendingPayments: TPendingPayments[];
}
