import ReactGA, {EventArgs, TimingArgs} from 'react-ga';

import logger from '~/utils/logger';
import config from '~/constants/config';

const log = logger.module('App/actions');

export const logEvent = (data: EventArgs): void => {
  if (config.googleAnalyticsId) {
    ReactGA.event(data);
  }

  log.info('Goal reach', data);
};

export const logTiming = (data: TimingArgs): void => {
  if (config.googleAnalyticsId) {
    ReactGA.timing(data);
  }

  log.info('Timing reach', data);
};
