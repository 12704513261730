"use strict";
/**
 * @packageDocumentation @module Dialogs
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUnreadDialogCounter = void 0;
var react_1 = require("react");
var useAll_1 = require("./useAll");
var dialogCounter = function (dialogs) {
    return Object.values(dialogs).filter(function (i) { return i.unread_messages_count > 0; }).length;
};
/**
 * To get counter of unread dialogs as the react hook.
 */
exports.useUnreadDialogCounter = function () {
    var dialogs = useAll_1.useAll();
    return react_1.useMemo(function () { return dialogCounter(dialogs); }, [dialogs]);
};
