import Sentry from '~/utils/Sentry';
import logger from '~/utils/logger';
import {TThunkAction} from '~/types/appTypes';
import {layoutOpenFullScreenLoaderAction} from '~/components/Layout';

const log = logger.module('updateServiceWorkerAction');

type TDefineUserFingerPrint = () => TThunkAction;
// eslint-disable-next-line import/prefer-default-export
export const updateServiceWorkerAction: TDefineUserFingerPrint = () => async (dispatch) => {
  dispatch(layoutOpenFullScreenLoaderAction());

  const serviceWorkerRegistration = await window.navigator.serviceWorker.getRegistration();

  if (!serviceWorkerRegistration) {
    log.info('SW not exist');
    return;
  }

  if (serviceWorkerRegistration.update) {
    await serviceWorkerRegistration.update();
  }

  if (!serviceWorkerRegistration.waiting) {
    log.info('SW has other status, reload page', {serviceWorkerRegistration});
    window.location.reload();
    return;
  }

  const registrationWaiting = serviceWorkerRegistration.waiting;
  log.info('Update SW -- start');
  registrationWaiting.postMessage({type: 'SKIP_WAITING'});
  registrationWaiting.addEventListener('statechange', () => {
    log.info('Change state', {state: registrationWaiting.state});
    if (registrationWaiting.state === 'activated' || registrationWaiting.state === 'redundant') {
      log.info('reload page');
      Sentry.captureMessage('Update app, reload.page');
      window.location.reload();
    }
  });
};
