import {useEffect, useState} from 'react';

import config from '~/constants/config';

import useVisibilityChange from './useVisibilityChange';

const HIDE_TIME = 'hide_time';
const PIN_CHECK_TIME = parseInt(config.pinCheckTime.toString(), 10);

const checkPinIsExpired = (now: number, storedDate: number) => now - storedDate > PIN_CHECK_TIME;

const useExpiredPin = (pinOk: boolean): boolean => {
  const [hidedTime, setHideTime] = useState(() => {
    const storedDate = sessionStorage.getItem(HIDE_TIME);

    return storedDate ? parseInt(storedDate, 10) : null;
  });

  const isPinExpiredByStart = hidedTime ? checkPinIsExpired(new Date().getTime(), hidedTime) : true;
  const [isPinExpired, setPinExpired] = useState(isPinExpiredByStart);
  const documentVisible = useVisibilityChange();

  useEffect(() => {
    const date = new Date().getTime();

    if (documentVisible) {
      if (hidedTime && checkPinIsExpired(date, hidedTime)) {
        setPinExpired(true);
      } else {
        sessionStorage.removeItem(HIDE_TIME);
        setHideTime(null);
      }
    } else if (pinOk) {
      setHideTime(date);
      sessionStorage.setItem(HIDE_TIME, date.toString());
    }
    // must be triggered only by visible variable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentVisible]);

  return isPinExpired;
};

export default useExpiredPin;
