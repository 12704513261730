import * as React from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import {layoutOpenDrawerAction, layoutHideHeaderSelector} from '~/components/Layout';
import routeByName from '~/constants/routes';
import {Hamburger} from '~/modules/Profiles/ProfileListPage/components/ProfileListTopBar';
import {isAuthSelector} from '~/modules/CurrentUser/store/selectors';
import ProfileChooseLocationButton from '~/modules/Profiles/ProfileListPage/components/ProfileListTopBar/ProfileChooseLocationButton';

import {ReactComponent as HomePageLogo} from './images/home_logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    padding: '20px 18px',
    top: 0,
    left: 0,
    right: 0,
  },
  signInBtn: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
}));

const TopBarHome: React.FC = () => {
  const s = useStyles();
  const dispatch = useDispatch();
  const isAuth = useSelector(isAuthSelector);
  const layoutHideHeader = useSelector(layoutHideHeaderSelector);
  const layoutOpenDrawer = () => dispatch(layoutOpenDrawerAction());

  if (!layoutHideHeader) {
    return null;
  }
  return (
    <div className={s.root}>
      <HomePageLogo />
      <div className="d-flex flex-1" />
      <div className="mr-1">
        <ProfileChooseLocationButton />
      </div>
      {isAuth && <Hamburger onClick={layoutOpenDrawer} />}
      {!isAuth && (
        <Button
          fullWidth={false}
          size="small"
          to={routeByName.signIn}
          component={Link}
          className={s.signInBtn}
        >
          Log in
        </Button>
      )}
    </div>
  );
};

export default TopBarHome;
