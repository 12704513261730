import {isEmpty} from 'lodash-es';

import logger from '~/utils/logger';
import {
  SortingMethodsType,
  TLocationFiltersParams,
  TProfileFiltersListParams,
  TThunkAction,
} from '~/types/appTypes';
import {formSubmitFullScreenControl} from '~/components/Layout';
import loadCurrentUserAction from '~/modules/CurrentUser/store/actions/loadCurrentUserAction';

import ProfileService from '../ProfileService';
import {
  cacheProfileActionCreator,
  resetNavigationFiltersActionCreator,
  resetProfileFiltersActionCreator,
  updateNavigationFiltersActionCreator,
  setProfileFiltersActionCreator,
  setSortingMethodActionCreator,
  setTutorialPropsActionCreator,
  resetLocationProfilesActionCreator,
} from './actionCreators';
import setLocationFilters from './actions/setLocationFilters';
import {
  setStoredLocationFilters,
  setStoredNavigationFilters,
  setStoredProfileFilters,
  setStoredTutorial,
} from './localStorage';
import {TNavigationFilters, TutorialProps} from './types';
import {profileFiltersSelector} from './selectors';
import loadProfileFiltersAction from './actions/loadProfilesFiltersAction';

const log = logger.module('Profile.actions');

export interface TProfileRouteParams {
  state: string;
  city: string;
  borough: string;
  phoneUrl: string;
}

export function updateNavigationFiltersAction<T = TNavigationFilters | null>(
  filters: T
): TThunkAction {
  return (dispatch, getState) => {
    const {
      profiles: {
        filters: {navigationFilters},
      },
    } = getState();

    setStoredNavigationFilters<T>({...navigationFilters, ...filters});
    dispatch(updateNavigationFiltersActionCreator<T>(filters));
  };
}

export function resetNavigationFiltersAction(): TThunkAction {
  return (dispatch) => {
    setStoredNavigationFilters(null);
    dispatch(resetNavigationFiltersActionCreator());
  };
}

type TLoadProfileByIdAction = (profileId: number) => TThunkAction;
export const loadProfileByIdAction: TLoadProfileByIdAction = (profileId) => async (dispatch) => {
  try {
    const profile = await ProfileService.getProfileById(profileId);
    dispatch(cacheProfileActionCreator(profile));
  } catch (e) {
    log.error('Cant load profile by id', {profileId});
  }
};

const topUpActionOrigin: TThunkAction = async (dispatch, getState) => {
  const {
    currentUser: {user},
  } = getState();

  if (!user || !user.profileId) {
    return;
  }
  await ProfileService.topUpProfile(user.profileId);

  dispatch(loadCurrentUserAction());
};

export const topUpProfileAction = () => formSubmitFullScreenControl(topUpActionOrigin);

type TTrackProfilePhoneClickAction = (profileId: number) => TThunkAction;
export const trackProfilePhoneClickAction: TTrackProfilePhoneClickAction =
  (profileId) => (dispatch, getState) => {
    const {
      currentUser: {profile},
    } = getState();

    if (profile && profile.id === profileId) {
      return;
    }

    ProfileService.trackPhoneClick(profileId).catch((error: Error) => {
      log.info('Error during track phone click', {error});
    });
  };
export function updateProfileFiltersAction(params: TProfileFiltersListParams): TThunkAction {
  return (dispatch) => {
    setStoredProfileFilters(params);
    dispatch(setProfileFiltersActionCreator(params));
    dispatch(loadProfileFiltersAction());
  };
}

export function setProfileFiltersAction(params: {
  fieldName: string;
  value: string;
  valueName: string;
}): TThunkAction {
  return (dispatch, getState) => {
    const {fieldName, value, valueName} = params;
    const state = getState();
    const filters = profileFiltersSelector(state) ?? {};
    const {[fieldName]: currentFilter, ...restFilters} = filters;

    if (!currentFilter) {
      dispatch(updateProfileFiltersAction({...restFilters, [fieldName]: {[valueName]: value}}));
      return;
    }

    const {[valueName]: currentFilterValue, ...restValues} = currentFilter;

    if (!currentFilterValue) {
      dispatch(
        updateProfileFiltersAction({
          ...restFilters,
          [fieldName]: {...restValues, [valueName]: value},
        })
      );
      return;
    }

    if (!isEmpty(restValues)) {
      dispatch(updateProfileFiltersAction({...restFilters, [fieldName]: {...restValues}}));
      return;
    }

    dispatch(updateProfileFiltersAction({...restFilters}));
  };
}

type TResetProfileFiltersAction = () => TThunkAction;
export const resetProfileFiltersAction: TResetProfileFiltersAction = () => (dispatch) => {
  setStoredProfileFilters(null);
  dispatch(resetProfileFiltersActionCreator());
  dispatch(loadProfileFiltersAction());
};

export function setProfileLocationFiltersAction<T = TLocationFiltersParams | null>(
  filters: T
): TThunkAction {
  return (dispatch) => {
    setStoredLocationFilters<T>(filters);
    dispatch(setLocationFilters(filters));
  };
}

export const setProfilesSortingMethodAction =
  (payload: SortingMethodsType): TThunkAction =>
  (dispatch) => {
    // changing sort mode resets navigation fileter so swiper goes back to first slide
    dispatch(resetNavigationFiltersAction());
    dispatch(resetLocationProfilesActionCreator());
    dispatch(setSortingMethodActionCreator(payload));
  };

export const setTutorialPropsAction = (props: Partial<TutorialProps>): TThunkAction => {
  return (dispatch, getState) => {
    const {
      profiles: {tutorial},
    } = getState();

    setStoredTutorial({...tutorial, ...props});
    dispatch(setTutorialPropsActionCreator(props));
  };
};

export const setNavigationFiltersOnStartAction = (): TThunkAction => {
  return (dispatch) => {
    dispatch(
      updateNavigationFiltersAction({
        currentProfileId: undefined,
        currentProfileIndex: 0,
      })
    );
  };
};
