import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useEffect, useRef} from 'react';

import config from '~/constants/config';
import logger from '~/utils/logger';
import useModalById from '~/customHooks/useModalById';
import {MODAL_ALLOW_LOCATION} from '~/rootStore/modals/modalsIds';
import useVisibilityChange from '~/customHooks/useVisibilityChange';

import {refreshCurrentGeoLocationAction} from '../CurrentUser/store/actions';
import {currentProfileSelector, isProfileCompletedSelector} from '../CurrentUser/store/selectors';

const log = logger.module('RefreshGeoTimer');

const RefreshGeoTimer: React.FC = () => {
  const isProfileCompleted = useSelector(isProfileCompletedSelector);
  const profile = useSelector(currentProfileSelector);
  const dispatch = useDispatch();
  const {isOpen} = useModalById(MODAL_ALLOW_LOCATION);
  const intervalRef = useRef<number>();
  const documentVisible = useVisibilityChange();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestGeo = useCallback(() => {
    if (!isProfileCompleted) {
      log.warn('Profile is not complete yet');
      return;
    }
    try {
      if (!profile) {
        log.warn('No profile to refreshCurrentGeoLocationAction');
        return;
      }
      dispatch(refreshCurrentGeoLocationAction(profile.id));
    } catch (error) {
      log.error('Error refresh geo: ', error.message);
    }
  }, [dispatch, profile, isProfileCompleted]);

  const stopInterval = useCallback(() => {
    window.clearInterval(intervalRef.current);
  }, [intervalRef]);

  const startInterval = useCallback(() => {
    intervalRef.current = window.setInterval(() => {
      requestGeo();
    }, config.refreshGeoLocationDelay);
  }, [requestGeo]);

  useEffect(() => {
    if (!documentVisible) {
      stopInterval();
      return undefined;
    }

    if (isProfileCompleted && !isOpen) {
      if (!intervalRef.current) {
        requestGeo();
      }
      startInterval();
      return () => stopInterval();
    }
    return undefined;
  }, [documentVisible, requestGeo, isProfileCompleted, isOpen, stopInterval, startInterval]);
  return null;
};

export default RefreshGeoTimer;
