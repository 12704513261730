"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.consoleBrowser = void 0;
function consoleBrowser(logObject) {
    var prefix = logObject.loggerName.length > 0 ? "[" + logObject.loggerName + "]" : '';
    var message = prefix + "[" + logObject.moduleName + "] " + logObject.message;
    var args = [message, logObject.extendedData].filter(Boolean);
    console.log.apply(console, args);
}
exports.consoleBrowser = consoleBrowser;
