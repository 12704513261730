import zerg from 'zerg';
import {TLogMessage} from 'zerg/dist/types';

import Sentry from '../Sentry';

const SENTRY_LEVEL_MAP = {
  debug: Sentry.Severity.Debug,
  verbose: Sentry.Severity.Log,
  info: Sentry.Severity.Info,
  warn: Sentry.Severity.Warning,
  error: Sentry.Severity.Error,
  fatal: Sentry.Severity.Fatal,
};

interface TCopyErrorsParams {
  name: string;
  message: string;
  originalError: Error;
}
function copyError({name, message, originalError}: TCopyErrorsParams) {
  const error = new Error();

  error.name = name;
  error.message = message;
  error.stack = originalError.stack;

  return error;
}

function extractException(logMessage: TLogMessage) {
  if (logMessage.extendedData && logMessage.extendedData.error) {
    return logMessage.extendedData.error;
  }
  return null;
}

export function sentryHandler(logMessage: TLogMessage): void {
  const level = SENTRY_LEVEL_MAP[logMessage.level];

  Sentry.withScope((scope) => {
    scope.setLevel(level);
    scope.setTag('module', logMessage.moduleName);

    if (logMessage.extendedData) {
      Object.entries(logMessage.extendedData).forEach(([key, value]) => {
        scope.setExtra(key, JSON.stringify(value));
      });
    }

    const error = extractException(logMessage);

    if (error && error instanceof Error) {
      const errorCopy = copyError({
        name: logMessage.message,
        message: `${error.name} ${error.message || ''}`,
        originalError: error,
      });
      Sentry.captureException(errorCopy);
      return;
    }

    Sentry.captureMessage(logMessage.message);
  });
}

const sentryTransport = zerg.createListener({
  handler: sentryHandler,
  levels: ['warn', 'error'],
});

export default sentryTransport;
