import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SpriteIcon from '~/components/ui/icons/SpriteIcon';

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      position: 'relative',
      boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.353256)',
      fontSize: 14,
    },
    tab: {
      flex: '1 1 auto',
      position: 'relative',
      borderBottom: 'solid 2px transparent',
      '&.selected': {
        borderBottomColor: theme.palette.primary.main,
        '&:after': {
          display: 'block',
        },
      },
    },
  }),
  {name: 'TabsRadios'}
);

const TabsRadios = ({name, options, onChange, value: inputValue}) => {
  const s = useStyles();
  return (
    <div className={clsx('d-flex align-end', s.wrapper)}>
      {options.map(({label, value, icon}) => (
        <div
          role="presentation"
          key={`feedback-type-${value}`}
          className={clsx('d-flex align-center p-1 justify-center', s.tab, {
            'text-primary selected': inputValue === value,
          })}
          onClick={() => onChange({target: {name, value}})}
        >
          <SpriteIcon name={icon} fontSize="small" className="mr-1" />
          <div>{label}</div>
        </div>
      ))}
    </div>
  );
};

TabsRadios.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

TabsRadios.defaultProps = {
  value: undefined,
};

export default TabsRadios;
