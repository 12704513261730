import {
  SortingMethodsType,
  TLastFiltersQuery,
  TLocationFiltersParams,
  TProfileFiltersListParams,
} from '~/types/appTypes';
import {TChatSession, TChatUser} from '~/types/Chat';
import {TProfile} from '~/types/Profile';

export enum TTabs {
  CHAT = 'chat',
  ABOUT = 'about',
  INFO = 'info',
  PHOTOS = 'photos',
  VIDEO = 'video',
}

export interface TNavigationFilters {
  totalProfilesCount: number;
  currentProfileIndex: number;
  currentProfileId?: number;
  prevProfileIndex: number;
  activeTab: TTabs;
}

export type TutorialProps = {
  show: boolean;
  started: boolean;
};

export interface TprofileFiltersOptions {
  [key: string]: {
    name: string;
    value: string;
    options: {
      [key: string]: {
        name: string;
        value: number;
        disabled: boolean;
      };
    };
  };
}

export interface TProfilesState {
  filters: {
    profileType: number | null;
    profileFiltersOptions: TprofileFiltersOptions;
    profileFilters: TProfileFiltersListParams;
    locationFilters: TLocationFiltersParams;
    navigationFilters: TNavigationFilters;
  };
  sortingMethod: SortingMethodsType;
  lastFiltersQuery?: TLastFiltersQuery;
  routeToProfileId: Record<string, number>;
  profileIdToOpponent: Record<number, TChatUser>;
  profileIdToChat: Record<number, TChatSession>;
  profileIdToChatLoading: Record<number, boolean>;
  profiles: Record<number | string, TProfile>;
  locationProfiles: number[];
  locationId: number | null;
  tutorial: TutorialProps;
}

export type SwiperDirectionType = 'forward' | 'backward';
