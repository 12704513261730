"use strict";
/**
 * @packageDocumentation @module Messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMessages = void 0;
var chat_1 = require("@yeobill/chat");
var useByDialog_1 = require("./useByDialog");
/**
 * To simplify work with specific dialog (send a message, get all messages) as the reacthook.
 *
 * **Remember before you can use this hook you needed get dialog history for that dialog by [[getHistory]].**
 */
function useMessages(dialogId) {
    var messages = useByDialog_1.useByDialog(dialogId);
    return {
        sendMessage: chat_1.Messages.sendMessage(dialogId),
        messages: messages,
    };
}
exports.useMessages = useMessages;
