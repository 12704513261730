import {AnyAction} from 'redux';

import {MODAL_SHOW, MODAL_HIDE} from './modalsActionTypes';
import {TModalIds} from './types';

type TSingleModal = {
  id: TModalIds;
  isOpen: boolean;
  props?: Record<string, any>;
};

export type TModalsState = TSingleModal[];

const initialState: TModalsState = [];

export const modalsReducer = (state = initialState, {type, payload}: AnyAction) => {
  switch (type) {
    case MODAL_SHOW: {
      const {id, props} = payload;
      const newState = state.filter((modal) => modal?.id !== id);

      return [...newState, {id, isOpen: true, props}];
    }
    case MODAL_HIDE: {
      const {id} = payload;

      return state.filter((modal) => modal?.id !== id);
    }
    default:
      return state;
  }
};
