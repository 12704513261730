import {BaseAxiosInstance} from '~/utils/axios';

const LocationsService = {
  getLocations() {
    return BaseAxiosInstance.get('locations');
  },
  getLocationById(locationId) {
    return BaseAxiosInstance.get(`locations/${locationId}`);
  },
  getGeoLocation() {
    return BaseAxiosInstance.get('geo-location').then(({location}) => location);
  },
};

export default LocationsService;
