"use strict";
/**
 * @packageDocumentation @module Messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteMessage = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To delete a message
 */
function deleteMessage(params) {
    return chat_1.Messages.deleteMessage(params);
}
exports.deleteMessage = deleteMessage;
