import createPalette from '@material-ui/core/styles/createPalette';

export default createPalette({
  primary: {
    main: '#C22DD6',
    light: '#CA64D8', // hsl + L25%
    dark: '#AA36EA',
  },
  success: {
    main: '#0EA400',
    light: '#05FF00',
  },
  text: {
    primary: '#000000',
    secondary: '#808080',
    icon: '#2d3742',
  },
  background: {
    default: '#FFFFFF',
    transparent: 'transparent',
    input: {
      borderOutlined: '#808080',
      filled: '#f7f7f9',
    },
    vip: 'linear-gradient(280deg, #C78C1A 6.57%, #DCA342 25.01%, #C78C1A 42.95%, #FFD583 64.39%, #DCA342 80.84%, #C78C1A 99.28%)',
    noImage: '#BCC6D0',
    yellow1: '#FFC700',
    green1: '#14E29A',
  },
  action: {
    disabledBackground: '#d4d4d4',
  },
  error: {
    main: '#EE032D',
  },
});
