import {JIVO_DESTROYED, JIVO_INITIALIZED} from './constants';

const initialState = {
  available: false,
};

export default (state = {...initialState}, {type}) => {
  switch (type) {
    case JIVO_INITIALIZED:
      return {...state, available: true};
    case JIVO_DESTROYED:
      return {...state, available: false};
    default:
      return state;
  }
};
