import {useState} from 'react';
import * as React from 'react';
import {omit} from 'lodash-es';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Text from '~/components/ui/Text';
import config from '~/constants/config';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1, 0, 2, 3),
  },
}));

const buildInfo = omit(config.build, ['version', 'commit']);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function keys<O>(o: O) {
  return Object.keys(o) as (keyof O)[];
}

const BuildInfo: React.FC = () => {
  const s = useStyles();

  const [clickCount, setClickCount] = useState(0);
  const handleVersionClick = () => {
    setClickCount((prev) => prev + 1);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={s.wrapper} onClick={handleVersionClick}>
      <Text fs="16">Version: {config.version}</Text>
      {clickCount > 5 && (
        <div className="my-1">
          {keys(buildInfo).map((key) => (
            <Text key={key} fs="16">
              {key}: {buildInfo[key]}
            </Text>
          ))}
        </div>
      )}
    </div>
  );
};

export default BuildInfo;
