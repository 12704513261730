import * as React from 'react';
import {useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';

import VerifyProfileIdentityBtn from '~/components/buttonWarnings/VerifyProfileIdentityBtn';
import PurchaseGoldBtn from '~/components/buttonWarnings/PurchaseGoldBtn';

import {
  currentUserSelector,
  currentProfileSelector,
  isProfileClientSelector,
  isProfileIdentityVerifiedSelector,
  isProfileCompletedSelector,
} from '../../store/selectors';

const ButtonWarnings: React.FC = () => {
  const {dating_membership: isProfileGoldMember} = useSelector(currentProfileSelector) || {};
  const isProfileClient = useSelector(isProfileClientSelector);
  const user = useSelector(currentUserSelector);
  const profile = useSelector(currentProfileSelector);
  const isProfileCompleted = useSelector(isProfileCompletedSelector);
  const isReady = user && profile && isProfileCompleted;

  const isProfileIdentityVerified = useSelector(isProfileIdentityVerifiedSelector);
  if (!isReady) {
    return null;
  }
  if (!isProfileIdentityVerified) {
    return (
      <Box px={1.25} mt={2.25}>
        <VerifyProfileIdentityBtn />
      </Box>
    );
  }
  if (user && isProfileClient && !isProfileGoldMember) {
    return (
      <Box px={1.25} mt={2.25}>
        <PurchaseGoldBtn />
      </Box>
    );
  }
  return null;
};

export default ButtonWarnings;
