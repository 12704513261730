import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';

import SpriteIcon from './icons/SpriteIcon';

const maxStars = 5;

const useStyles = makeStyles(
  (theme) => ({
    star: {
      color: theme.palette.grey[300],
      '&.selected': {
        color: theme.palette.background.yellow1,
      },
    },
  }),
  {name: 'StarRating'}
);

const StarRating = ({name, value, onChange}) => {
  const s = useStyles();
  const stars = [];

  for (let i = 1; i <= maxStars; i += 1) {
    stars.push(
      <SpriteIcon
        key={i}
        name="star-filled"
        fontSize="large"
        className={clsx('mr-1', s.star, {selected: value && value >= i})}
        onClick={() => onChange({target: {name, value: i}})}
      />
    );
  }

  return <div className="d-flex align-center">{stars}</div>;
};

StarRating.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

StarRating.defaultProps = {
  value: undefined,
};

export default StarRating;
