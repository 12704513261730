import {useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactGA from 'react-ga';
import PWAPrompt from 'react-ios-pwa-prompt';
import {useLocation} from 'react-router-dom';

import Sentry from '~/utils/Sentry';
import {isIOS, isChrome} from '~/helpers/navigator';
import useModalById from '~/customHooks/useModalById';
import useWebsiteViewCounter from '~/customHooks/useWebsiteViewCounter';
import localStorageWrapper from '~/utils/localStorageWrapper';
import {profilePageViewNumberSelector} from '~/rootStore/others/selectors';
import {isRouteEqual} from '~/helpers/routes/routes';
import routeByName from '~/constants/routes';
import usePwaInstalled from '~/customHooks/usePwaInstalled';
import ModalAndroidPWAGuide from '~/components/Modals/ModalAndroidPWAGuide';
import {PWA_BANNER_CANCEL_COUNT, TOTAL_WEBSITE_VIEWS} from '~/constants/localStorage';
import {
  MODAL_ANDROID_PWA,
  MODAL_PWA_BANNER_MEDIUM,
  MODAL_PWA_BANNER_SMALL,
} from '~/rootStore/modals/modalsIds';

import {openSmallPwaModalAction} from '../../store/actions/pwaModalsActions';
import PwaBannerMediumComponent from './PwaBannerMediumComponent';
import {isStandaloneModeSelector} from '../../store/selectors';
import {successMessages} from '../../constants/messages';
import PwaBannerSmallComponent from './PwaBannerSmallComponent';

interface TDeferPrompt extends Event {
  prompt?: () => void;
  userChoice?: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
}

const PwaBannerContainer: React.FC = () => {
  const [pwaPrompt, setPwaPrompt] = useState<TDeferPrompt | null>(null);
  const [showSimplePrompt, setShowSimplePrompt] = useState(false);
  const isStandalone = useSelector(isStandaloneModeSelector);
  const totalProfilePageViews = useSelector(profilePageViewNumberSelector);
  const installedPwa = usePwaInstalled();
  const dispatch = useDispatch();

  const {open: openAndroidPWAModal} = useModalById(MODAL_ANDROID_PWA);
  const {isOpen: isOpenSmallPWAModal, close: closeSmallPWAModal} =
    useModalById(MODAL_PWA_BANNER_SMALL);
  const {
    isOpen: isOpenMediumPWAModal,
    open: openMediumPWAModal,
    close: closeMediumPWAModal,
  } = useModalById(MODAL_PWA_BANNER_MEDIUM);

  const isIosAndNotStandAlone = !isStandalone && isIOS;
  const isIosChrome = !isStandalone && isIOS && isChrome;
  const totalWebsiteViews = parseInt(localStorageWrapper.getItem(TOTAL_WEBSITE_VIEWS) || '0', 10);

  const location = useLocation();
  const isChatPage = isRouteEqual(location.pathname, routeByName.chats.index);

  const hasInstalledPwa = useMemo(() => {
    return installedPwa.length > 0;
  }, [installedPwa]);

  useWebsiteViewCounter();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    closeSmallPWAModal();

    const beforeInstallPromptListener = (e: Event) => {
      e.preventDefault();
      setPwaPrompt(e);
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptListener);
    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptListener);
    };
  }, [closeSmallPWAModal, isStandalone]);

  useEffect(() => {
    if (!hasInstalledPwa && !isStandalone && (totalWebsiteViews > 1 || isChatPage)) {
      dispatch(openSmallPwaModalAction());
    }
  }, [totalWebsiteViews, isChatPage, isStandalone, hasInstalledPwa, dispatch]);

  useEffect(() => {
    if (!isStandalone && totalProfilePageViews === 1 && !hasInstalledPwa) {
      openMediumPWAModal();
    }
  }, [isStandalone, totalProfilePageViews, openMediumPWAModal, hasInstalledPwa]);

  const sentryInfo = (message: string) => {
    Sentry.captureMessage(message);
  };

  const handleCloseModals = () => {
    if (isOpenSmallPWAModal) {
      closeSmallPWAModal();
    }
    if (isOpenMediumPWAModal) {
      closeMediumPWAModal();
    }
  };

  const handleCancelModals = () => {
    const totalModalsCancelCount = parseInt(
      localStorageWrapper.getItem(PWA_BANNER_CANCEL_COUNT) || '0',
      10
    );

    localStorageWrapper.setItem(PWA_BANNER_CANCEL_COUNT, String(totalModalsCancelCount + 1));
    handleCloseModals();
  };

  const handleClickInstall = () => {
    if (pwaPrompt && pwaPrompt.prompt && pwaPrompt.userChoice) {
      pwaPrompt.prompt();
      handleCloseModals();
      pwaPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          ReactGA.event({
            category: 'PWA',
            action: successMessages.pwaInstall,
            label: 'PWA_INSTALLATION',
          });
          sentryInfo(successMessages.pwaInstall);
        }
      });
    } else {
      handleCloseModals();

      if (isIosAndNotStandAlone) {
        setShowSimplePrompt(true);
      } else {
        openAndroidPWAModal();
      }
    }
  };

  const handleCloseSimplePrompt = () => {
    setShowSimplePrompt(false);
  };

  return (
    <>
      {isOpenSmallPWAModal && (
        <PwaBannerSmallComponent onInstall={handleClickInstall} onCancel={handleCancelModals} />
      )}
      {isOpenMediumPWAModal && (
        <PwaBannerMediumComponent onInstall={handleClickInstall} onCancel={handleCancelModals} />
      )}
      {showSimplePrompt && (
        <PWAPrompt
          copyBody={
            isIosChrome
              ? 'Chrome browser doesn’t support adding to the Home page option, so please do the following:'
              : 'Chat with users. This website has app functionality. Add it to your home screen to use it in fullscreen and while offline.'
          }
          copyShareButtonLabel={
            isIosChrome
              ? '1) Go to Safari browser & Press “Share” button on the menu bar below'
              : undefined
          }
          timesToShow={1000}
          delay={100}
          permanentlyHideOnDismiss={false}
          onClose={handleCloseSimplePrompt}
        />
      )}
      <ModalAndroidPWAGuide />
    </>
  );
};

export default PwaBannerContainer;
