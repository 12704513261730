import {AuthorizedAxiosInstance} from '~/utils/axios';
import {TMembership} from '~/types/Membership';
import {TPendingPayments} from '~/types/CurrentUserState';
import {BTCWalletOptions} from '~/constants/payments';

import {TPaymentStatus} from './BitcoinPaymentCreation/BitcoinPaymentCreation';
import {creditCardsResponseTransformer, paymentsTransformer} from './transformers';

interface TPaymentTransformer {
  payment_item: {
    type: string;
    membership_id: number;
  };
  promocode: {code: string};
  id: string;
  amount: string;
}

interface TPaymentInfo {
  id: string;
  amount: string;
  type: string;
  itemId: number;
  hasPromoCode: boolean;
  promoCode: string;
}

export interface TCreditCardValidation {
  firstname: string;
  lastname: string;
  email: string;
  cardno: string;
  exp_year: string;
  exp_month: string;
  csc: string;
  address: string;
  city: string;
  zip: string;
  country: string;
  state?: string;
}

interface TVipOptions {
  amount: number;
  available: boolean;
  budget: number;
  days: number;
  id: string;
  label: string;
  recurring: boolean;
  secondaryLabel: boolean | string;
}

const paymentTransformer = ({
  payment_item: {type, membership_id: itemId},
  promocode,
  id,
  amount,
  ...payment
}: TPaymentTransformer) => ({
  id,
  amount,
  type,
  itemId,
  hasPromoCode: Boolean(promocode),
  promoCode: promocode && promocode.code,
  ...payment,
});

const PaymentsService = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createCreditCard(cardData: TCreditCardValidation): Promise<{card_id: number}> {
    return AuthorizedAxiosInstance.post('payment/card/new', {
      ...cardData,
      cardno: cardData.cardno.replace(/\s/g, ''),
    });
  },

  deleteCreditCard: (cardId: number): Promise<() => void> =>
    AuthorizedAxiosInstance.post('payment/card/delete', {card_id: cardId}),

  getCardsList: (): Promise<Record<string, unknown>> =>
    AuthorizedAxiosInstance.get('payment/get-cards').then(creditCardsResponseTransformer),

  createPayment(params: {
    type: string;
    profile_id: string;
    external_worker_id: string;
    membership_id?: string;
    recurring?: boolean;
    topup_credits?: number;
    amount?: boolean;
  }): Promise<string> {
    return AuthorizedAxiosInstance.post('payment/create', params).then(
      ({payment_id: paymentId}) => paymentId
    );
  },

  getPaymentById: (paymentId: string, btcWallet?: BTCWalletOptions): Promise<TPaymentInfo> =>
    AuthorizedAxiosInstance.post('payment/get-info', {
      payment_id: paymentId,
      btc_wallet: btcWallet,
    }).then(paymentTransformer),

  applyPromoCode: (paymentId: string, promocode: string): Promise<TPaymentInfo> =>
    AuthorizedAxiosInstance.post(`payment/${paymentId}/apply-promocode`, {
      promocode,
    }).then(paymentTransformer),

  deletePromoCode: (paymentId: string): Promise<TPaymentInfo> =>
    AuthorizedAxiosInstance.post('/payment/remove-promocode', {
      payment_id: paymentId,
    }).then(paymentTransformer),

  payPayment(
    paymentId: string,
    params: {
      email: string;
      country: number;
      card_id?: number;
      processor: string;
    }
  ): Promise<() => void> {
    return AuthorizedAxiosInstance.post(`payment/${paymentId}/pay`, params);
  },

  getVipOptions: (params: {profile_id: number}): Promise<Record<string, unknown>> =>
    AuthorizedAxiosInstance.post('vip/options', params).then(({options}) =>
      options.reduce(
        (accum: {[index: string]: TVipOptions}, {days, recurring, ...vipOption}: TVipOptions) => {
          const id = `${days}-${recurring ? 1 : 0}`;
          // eslint-disable-next-line no-param-reassign
          accum[id] = {
            ...vipOption,
            id,
            recurring,
            days,
            secondaryLabel:
              recurring && `Payment are recurring every ${days} days. You can cancel anytime`,
          };
          return accum;
        },
        {}
      )
    ),

  purchaseVip: (params: TVipOptions, config: Record<string, unknown>): Promise<() => void> =>
    AuthorizedAxiosInstance.post('payment/vip', params, config),

  refundTransaction: (transactionId: number, reason: string): Promise<() => void> =>
    AuthorizedAxiosInstance.post(`transaction/${transactionId}/request-refund`, {reason}),

  cancelSubscription: (
    subscriptionId: number,
    formData: {reason: string; customReason: string}
  ): Promise<() => void> =>
    AuthorizedAxiosInstance.post(`payment/${subscriptionId}/cancel`, formData, {
      needMessage: true,
    }),

  changeSubscriptionCard: (subscriptionId: number, cardId: number): Promise<() => void> =>
    AuthorizedAxiosInstance.post(`payment/${subscriptionId}/update`, {
      card_id: cardId,
    }),

  getTopUpPrice: (): Promise<{amount: number}> =>
    AuthorizedAxiosInstance.get('topups').then(({amount}) => amount),

  loadGoldmembershipList: (): Promise<{
    memberships: TMembership[];
  }> => AuthorizedAxiosInstance.get('dating-memberships'),

  purchaseTopUp: (quantity: number): Promise<() => void> =>
    AuthorizedAxiosInstance.post('payment/topup', {topup_credits: quantity}),

  isPaid: (paymentId: number): Promise<TPaymentStatus> =>
    AuthorizedAxiosInstance.post('payment/is-paid', {payment_id: paymentId}),

  getProfilePendingPayments: (profileId: number): Promise<TPendingPayments[]> =>
    AuthorizedAxiosInstance.post('payments', {
      profileId,
      status: 'progress',
    }).then(paymentsTransformer),

  // not used now, but left for future needs
  // getProfileCompletedPayments: (profileId) =>
  //   AuthorizedAxiosInstance.post('payments', {profileId, status: 'completed'}),
};

export default PaymentsService;
