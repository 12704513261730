import {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {profileTypes} from '~/constants/profiles';
import {MODAL_COMPLETE_CHAT_SETTINGS} from '~/rootStore/modals/modalsIds';
import {currentProfileSelector, currentUserSelector} from '~/modules/CurrentUser/store/selectors';

import useModalById from './useModalById';

const localKey = 'chatSettings';

const useChatSettings = () => {
  const user = useSelector(currentUserSelector);
  const profile = useSelector(currentProfileSelector);
  const id = user?.id;
  const stringId = id ? id.toString() : '';
  const chatSettings = localStorage.getItem(localKey);
  const isClient = profile?.type === profileTypes.client;

  const {open} = useModalById(MODAL_COMPLETE_CHAT_SETTINGS);

  const chatSettingsJSON = JSON.parse(chatSettings ?? '{}');
  const isShown = useMemo(() => {
    if (!stringId) return false;
    const {[stringId]: shown} = chatSettingsJSON;
    return shown ?? false;
  }, [chatSettingsJSON, stringId]);
  const showPopUp = !isShown && !isClient && id && profile?.id && profile.isCompleted;
  useEffect(() => {
    if (showPopUp) {
      const newData = {...chatSettingsJSON, [stringId]: true};
      localStorage.setItem(localKey, JSON.stringify(newData));
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id, id, profile?.isCompleted]);
};
export default useChatSettings;
