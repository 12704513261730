import {useEffect, useState} from 'react';

import isIframe from '~/helpers/isIframe';

interface TNavigator extends Navigator {
  getInstalledRelatedApps: () => Promise<unknown[]>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const usePwaInstalled = () => {
  const [installed, setInstalled] = useState<unknown[]>([]);

  useEffect(() => {
    // getInstalledRelatedApps is only supported in top-level browsing contexts.
    if (!isIframe() && typeof window.Cypress === 'undefined') {
      const nav = window.navigator as TNavigator;
      const getInstalledApps = async () => {
        const apps = await nav.getInstalledRelatedApps();
        setInstalled(apps);
      };

      if ('getInstalledRelatedApps' in window.navigator) {
        getInstalledApps();
      }
    }
  }, []);

  return installed;
};

export default usePwaInstalled;
