import * as React from 'react';
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import useModal from '~/customHooks/useModal';
import {updateServiceWorkerAction} from '~/modules/App/store/actions/updateServiceWorkerAction';
import {serviceWorkerSelector} from '~/modules/App/store/selectors';
import logger from '~/utils/logger';
import Sentry from '~/utils/Sentry';

import ModalUpdateApp from './Modals/ModalUpdateApp';

const log = logger.module('UpdateApp');

const UpdateApp: React.FC = () => {
  const {hasUpdate} = useSelector(serviceWorkerSelector);

  const dispatch = useDispatch();
  const {openModal, isOpen, closeModal} = useModal();

  const handleClickUpdate = useCallback(() => {
    Sentry.captureMessage('User click "Update app"');
    dispatch(updateServiceWorkerAction());
  }, [dispatch]);

  useEffect(() => {
    if (!hasUpdate) {
      log.info('SW has no update');
      return;
    }
    log.info('SW has update');
    Sentry.setTag('swHasUpdate', 'true');
    openModal();
  }, [hasUpdate, handleClickUpdate, openModal]);

  return <ModalUpdateApp open={isOpen} onClick={handleClickUpdate} onClose={closeModal} />;
};

export default UpdateApp;
