import React, {Suspense, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import * as PropTypes from 'prop-types';

import FullScreenPreloader from '~/components/preloaders/FullScreenPreloader';
import {isAuthSelector} from '~/modules/CurrentUser/store/selectors';
import FeedbackModal from '~/modules/Feedback/FeedbackModal/FeedbackModal';

import TopBar from '../../TopBar';
import RightDrawerContainer from '../../RightDrawer';
import LayoutContext from './LayoutContext';
import ContentWrapper from './ContentWrapper';
import Seo from './Seo';

const BaseLayout = ({children}) => {
  const isUserAuth = useSelector(isAuthSelector);
  const [onBack, setOnBackHandlerLocal] = useState();

  const contextValues = useMemo(() => {
    return {
      // back button handler for TopBar
      setOnBackHandler: (handler) => {
        setOnBackHandlerLocal(() => handler);
      },
      onBack,
    };
  }, [onBack]);

  return (
    <LayoutContext.Provider value={contextValues}>
      <Seo />
      <TopBar />
      <ContentWrapper>{children}</ContentWrapper>
      {isUserAuth && (
        <Suspense fallback={<div />}>
          <RightDrawerContainer />
        </Suspense>
      )}
      <FullScreenPreloader />
      <Suspense fallback={<div />}>
        <FeedbackModal />
      </Suspense>
    </LayoutContext.Provider>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

BaseLayout.defaultProps = {};

export default BaseLayout;
