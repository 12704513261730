import localStorageWrapper from '~/utils/localStorageWrapper';
import {
  LOCATION_FILTERS,
  LOCATION_PROFILE_FILTERS,
  LOCATION_PROFILE_FILTERS_OPTIONS,
  LOCATION_PROFILE_TYPE_FILTER,
  NAVIGATION_PROFILE_FILTERS,
  SWIPE_TUTORIAL_STATE,
} from '~/constants/localStorage';
import {TLocationFiltersParams, TProfileFiltersListParams} from '~/types/appTypes';

import {TNavigationFilters, TprofileFiltersOptions, TutorialProps} from './types';

export const getStoredProfileTypeFilter = (): number | null => {
  const rawValue = localStorageWrapper.getItem(LOCATION_PROFILE_TYPE_FILTER);
  return rawValue !== null ? JSON.parse(rawValue) : null;
};

export const setStoredProfileTypeFilter = (value: number | null): void => {
  localStorageWrapper.setItem(LOCATION_PROFILE_TYPE_FILTER, JSON.stringify(value));
};

export const getStoredProfileFilters = (): TProfileFiltersListParams => {
  const rawValue = localStorageWrapper.getItem(LOCATION_PROFILE_FILTERS);
  return rawValue !== null ? JSON.parse(rawValue) : {};
};

export function setStoredProfileFilters<T = TProfileFiltersListParams>(value: T): void {
  localStorageWrapper.setItem(LOCATION_PROFILE_FILTERS, JSON.stringify(value));
}

export const setStoredProfileFiltersOptions = (value: TprofileFiltersOptions) => {
  localStorageWrapper.setItem(LOCATION_PROFILE_FILTERS_OPTIONS, JSON.stringify(value));
};

export const getStoredProfileFiltersOptions = (): TprofileFiltersOptions => {
  const rawValue = localStorageWrapper.getItem(LOCATION_PROFILE_FILTERS_OPTIONS);
  return rawValue !== null ? JSON.parse(rawValue) : {};
};

export const getStoredLocationFilters = (): TLocationFiltersParams | null => {
  const rawValue = localStorageWrapper.getItem(LOCATION_FILTERS);
  return rawValue !== null ? JSON.parse(rawValue) : null;
};

export function setStoredLocationFilters<T = TLocationFiltersParams>(value: T): void {
  localStorageWrapper.setItem(LOCATION_FILTERS, JSON.stringify(value));
}

export const getStoredNavigationFilters = (
  defaultValue: TNavigationFilters
): TNavigationFilters => {
  const rawValue = localStorageWrapper.getItem(NAVIGATION_PROFILE_FILTERS);

  try {
    return rawValue?.startsWith('{') ? JSON.parse(rawValue) : defaultValue;
  } catch (error) {
    console.error(error);

    return defaultValue;
  }
};

export function setStoredNavigationFilters<T = TNavigationFilters>(value: T): void {
  localStorageWrapper.setItem(NAVIGATION_PROFILE_FILTERS, JSON.stringify(value));
}

export function setStoredTutorial<T = TutorialProps>(props: T): void {
  localStorageWrapper.setItem(SWIPE_TUTORIAL_STATE, JSON.stringify(props));
}

export const getStoredTutorial = (defaultValue: TutorialProps): TutorialProps => {
  const rawValue = localStorageWrapper.getItem(SWIPE_TUTORIAL_STATE);

  try {
    return rawValue?.startsWith('{') ? JSON.parse(rawValue) : defaultValue;
  } catch {
    return defaultValue;
  }
};
