import {useCallback, useMemo} from 'react';
import {useCookies} from 'react-cookie';
import {useSelector} from 'react-redux';

import {isGoogleTranslateAvailableSelector} from '~/modules/App/store/selectors';
import countriesList from '~/constants/countriesList';
import translate from '~/constants/translate';
import ModalSelect from '~/components/ui/inputs/ModalSelect/ModalSelect';
import sortListByLabel from '~/helpers/sortListByLabel';
import languagesList from '~/constants/languagesList';

import ChooseLanguageInput from './ChooseLanguageInput';

const {defaultLanguage, cookieName, cookieOptions} = translate;
const hostElements = window.location.hostname.split('.').reverse();
const rootDomain =
  hostElements.length > 2 ? `${hostElements[1]}.${hostElements[0]}` : window.location.hostname;

const ChooseLanguageContainer = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['googtrans']);
  const isGoogleTranslateAvailable = useSelector(isGoogleTranslateAvailableSelector);

  const currentLanguage = useMemo(() => {
    let language = defaultLanguage;
    const cookieLanguage = cookies[cookieName];

    if (cookieLanguage) {
      language = cookieLanguage.split('/').pop();
    }

    return language;
  }, [cookies]);

  const languagesListFormatted = useMemo(() => {
    const options = [];

    const languagesCodesArr = Object.keys(languagesList);

    for (let i = 0; i < languagesCodesArr.length; i += 1) {
      const code = languagesCodesArr[i];
      const {name, country} = languagesList[code];

      if (name) {
        const {emoji} = countriesList[country];

        options.push({
          value: code,
          label: name,
          emoji,
        });
      }
    }

    return options.sort(sortListByLabel);
  }, []);

  const renderValue = useCallback(() => currentLanguage, [currentLanguage]);

  const renderListItem = useCallback(({label, emoji}) => `${emoji} ${label}`, []);

  const filter = useCallback((search, {label}) => label.toLowerCase().startsWith(search), []);

  const onChange = useCallback(
    (value) => {
      removeCookie(cookieName, {
        ...cookieOptions,
        domain: rootDomain,
      });

      removeCookie(cookieName, {
        ...cookieOptions,
      });

      if (value !== defaultLanguage) {
        setCookie(cookieName, `/${defaultLanguage}/${value}`, cookieOptions);
      }

      window.location.reload();
    },
    [removeCookie, setCookie]
  );

  if (!isGoogleTranslateAvailable) {
    removeCookie(cookieName, {
      ...cookieOptions,
      domain: rootDomain,
    });
    return null;
  }

  return (
    <ModalSelect
      name="language"
      onChange={onChange}
      value={currentLanguage}
      options={languagesListFormatted}
      featuredValues={['en', 'es']}
      renderValue={renderValue}
      filter={filter}
      renderListItem={renderListItem}
      modalTitle="Choose Language"
      customInput={ChooseLanguageInput}
      noTranslate
    />
  );
};

ChooseLanguageContainer.propTypes = {};

ChooseLanguageContainer.defaultProps = {};

export default ChooseLanguageContainer;
