export default {
  badge: {
    height: 16,
    minWidth: 16,
    padding: '0 3px',
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'bottom',
    lineHeight: '1px',
    marginTop: 4,
    marginLeft: 1,
  },
};
