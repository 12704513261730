"use strict";
/**
 * @packageDocumentation @module Users
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.findByArea = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To find the user by the area.
 */
function findByArea(_a) {
    var longitude = _a.longitude, latitude = _a.latitude, radius = _a.radius;
    var redEndpoint = chat_1.Chat.Chat.getState().redEndpoint;
    if (!redEndpoint) {
        return Promise.reject('No redEndpoint');
    }
    return chat_1.Users.getByArea({
        url: redEndpoint,
        longitude: longitude,
        latitude: latitude,
        radius: radius,
    });
}
exports.findByArea = findByArea;
