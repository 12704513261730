import React from 'react';
import {makeStyles, Select} from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

const useStyles = makeStyles({
  gray: {
    color: '#898989',
  },
});

export const ExpandIcon: React.FC = (props) => {
  const s = useStyles();
  return <ExpandMoreRoundedIcon {...props} className={s.gray} />;
};

const BaseControlSelect: React.FC = (props) => {
  const s = useStyles();
  return (
    <Select {...props} fullWidth disableUnderline IconComponent={ExpandIcon} className={s.gray} />
  );
};

export default BaseControlSelect;
