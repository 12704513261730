import * as React from 'react';
import Button, {ButtonProps} from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {Box} from '@material-ui/core';
import {Link} from 'react-router-dom';

import SpriteIcon, {TSpriteIconName} from '../icons/SpriteIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 12,
    padding: 0,
    overflow: 'hidden',
    lineHeight: 1.2,
    alignItems: 'stretch',
    '&.gold': {
      '& $iconBlock': {
        background: 'linear-gradient(180deg, #CB9020 0%, #F4C46C 50.52%, #D1972C 100%)',
      },
    },
    '&.warn': {
      '& $iconBlock': {
        background: 'linear-gradient(180deg, #F70404 0%, #DB0505 100%)',
      },
    },
  },
  iconBlock: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    padding: 18,
  },
  icon: {
    color: theme.palette.common.white,
    width: 34,
    height: 34,
  },
  nextIcon: {
    width: 7,
    height: 12,
    color: '#808080',
    opacity: 0.4,
    marginLeft: 8,
    marginRight: 18,
  },
  subtitle: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,
  },
}));

interface WarningButtonProps extends ButtonProps {
  to: string;
  title: string;
  subtitle: string;
  className?: string;
  backgroundVariant?: 'gold' | 'warn';
  iconName?: TSpriteIconName;
}

const WarningButton: React.FC<WarningButtonProps> = ({
  to,
  title,
  subtitle,
  className,
  backgroundVariant = 'gold',
  iconName = 'star-warning',
}) => {
  const s = useStyles();

  return (
    <Button
      component={Link}
      to={to}
      color="default"
      variant="text"
      className={clsx(s.root, className, backgroundVariant)}
    >
      <div className={s.iconBlock}>
        <SpriteIcon
          name={iconName}
          fontSize="custom"
          className={clsx(s.icon, {backgroundVariant})}
        />
      </div>
      <Box ml={1.5} pt={1} pb={1.5} flex={1} display="flex" alignItems="center">
        <Box flex={1}>
          <div className="semibold">{title}</div>
          <div className={s.subtitle}>{subtitle}</div>
        </Box>
        <SpriteIcon name="next-bold" fontSize="custom" className={s.nextIcon} />
      </Box>
    </Button>
  );
};

export default WarningButton;
