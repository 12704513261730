import {matchPath} from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const isRouteEqual = (
  pathName?: string,
  compareWith?: string,
  options: Record<string, string> = {}
): boolean => {
  if (!pathName || !compareWith) {
    return false;
  }
  return Boolean(matchPath(pathName, {path: compareWith, exact: true, ...options}));
};
