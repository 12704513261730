import {ErrorInfo, PureComponent} from 'react';

import config from '~/constants/config';
import logger from '~/utils/logger';

const log = logger.module('BaseErrorBoundary');

class BaseErrorBoundary extends PureComponent {
  public state = {
    error: false,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const exceptionErrors = ['ChunkLoadError'];
    if (exceptionErrors.indexOf(error.name) === -1) {
      log.error('Catch error', {error, errorInfo});
    }
    this.setState({error});
  }

  public render(): React.ReactNode {
    const {error} = this.state;

    if (error) {
      return (
        <div>
          <div>Something went wrong :(</div>
          <div>
            <a href={config.refreshAppUrl}>Refresh</a>
          </div>
          <div>
            app version: {config.build.version}@{config.build.commit}
          </div>
        </div>
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default BaseErrorBoundary;
