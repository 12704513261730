import {AuthorizedAxiosInstance, BaseAxiosInstance} from '~/utils/axios';
import logger from '~/utils/logger';

import {meResponseTransformer} from './transformers';

const newPhoneKey = 'newPhone';

const log = logger.module('Current User Service');

const CurrentUserService = {
  getMe() {
    return AuthorizedAxiosInstance.get('current-user').then(meResponseTransformer);
  },
  sendUserPhoneVerificationSms(params) {
    return AuthorizedAxiosInstance.post('register/sms-verify', params);
  },
  checkUserPhoneVerificationCode({userId, code}) {
    return AuthorizedAxiosInstance.post('register/sms-verify-check', {
      user_id: userId,
      code,
    });
  },
  changeEmail(userId, email) {
    return AuthorizedAxiosInstance.post(`user/${userId}/email`, {email});
  },
  changeLanguage(language) {
    return AuthorizedAxiosInstance.post('change-language', {lang: language});
  },
  changePassword(userId, data) {
    return AuthorizedAxiosInstance.post(`user/${userId}/password`, data);
  },
  closeAccount(userId, data) {
    return AuthorizedAxiosInstance.post(`user/${userId}/delete`, data);
  },
  createProfile(type) {
    return AuthorizedAxiosInstance.post('profiles/create', {type});
  },
  sendEmailVerification(userId) {
    return AuthorizedAxiosInstance.post('resend-verification-mail', {
      id: userId,
    });
  },
  getFavorites() {
    return AuthorizedAxiosInstance.get('favorite');
  },
  addToFavorites(profileId) {
    return AuthorizedAxiosInstance.post('favorite/add', {
      profile_id: profileId,
    });
  },
  removeFromFavorites(profileId) {
    return AuthorizedAxiosInstance.post('favorite/delete', {
      profile_id: profileId,
    });
  },
  setNewPhone(phone) {
    if (!phone) {
      return window.sessionStorage.removeItem(newPhoneKey);
    }

    return window.sessionStorage.setItem(newPhoneKey, phone);
  },
  getNewPhone() {
    return window.sessionStorage.getItem(newPhoneKey);
  },
  changePhone(phone, code) {
    return AuthorizedAxiosInstance.post('change-phone', {phone, code});
  },
  async refreshCurrentGeoLocation(profileId, latitude, longitude) {
    try {
      const response = await AuthorizedAxiosInstance.post('profile/refresh-geo-coordinates', {
        profileId,
        latitude,
        longitude,
      });
      return response;
    } catch (error) {
      log.error('Error refresh coordinates: ', {error});
      return false;
    }
  },
  sendContactMessage(name, email, phone, message, captcha) {
    return BaseAxiosInstance.post('contact', {
      name,
      email,
      phone,
      message,
      captcha,
    });
  },
};

export default CurrentUserService;
