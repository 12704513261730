"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effector_1 = require("effector");
var QBAdapter_1 = require("../QBAdapter");
var array2map_1 = require("../utils/array2map");
var types_1 = require("../types");
exports.default = (function (Dialogs$) {
    var get = effector_1.createEffect().use(QBAdapter_1.getDialogs);
    Dialogs$.on(get.done, function (_state, _a) {
        var result = _a.result;
        return __assign({}, array2map_1.array2Map(result.items, '_id'));
    });
    Dialogs$.watch(function (dialogs) {
        Object.values(dialogs).forEach(function (_a) {
            var type = _a.type, xmpp_room_jid = _a.xmpp_room_jid;
            if (type !== types_1.DialogType.PRIVATE && xmpp_room_jid) {
                QBAdapter_1.joinChatByJid(xmpp_room_jid);
            }
        });
    });
    return get;
});
