export const getCardLastNumbers = (number: string): string => {
  if (!number) {
    return '';
  }

  return String(number).slice(-4);
};

export const getCardType = (number: string): {type: string; label: string} => {
  if (!number) return {type: '', label: ''};
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null) return {type: 'visa', label: 'VISA'};

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  re = new RegExp(
    '^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$'
  );
  if (number.match(re) != null) {
    return {type: 'mastercard', label: 'Mastercard'};
  }
  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null) return {type: 'amex', label: 'AMEX'};

  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(re) != null) return {type: 'discover', label: 'Discover'};

  // Diners
  re = new RegExp('^36');
  if (number.match(re) != null) return {type: 'diners', label: 'Diners'};

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (number.match(re) != null) return {type: 'diners', label: 'Diners'};

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (number.match(re) != null) return {type: 'jcb', label: 'JCB'};

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (number.match(re) != null) return {type: 'visa', label: 'VISA'};

  // Maestro
  re = new RegExp('^(5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,15}$');
  if (number.match(re) != null) return {type: 'maestro', label: 'Maestro'};

  return {type: '', label: ''};
};

export const formatCardNumber = (value: string): string => {
  const number = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = number.match(/\d{4,}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];

  for (let i = 0; i < match.length; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  }

  return number;
};
