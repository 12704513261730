import {Reducer} from 'redux';

import {AUTH_LOGOUT} from '~/modules/Auth/store/constants';
import {TAction} from '~/types/appTypes';

const LOADER_START = 'LOADER_START';
const LOADER_STOP = 'LOADER_STOP';
const LOADER_RESET = 'LOADER_RESET';

const initialState = {};
export interface TLoadersState {
  [key: string]: boolean;
}

export const loadersReducer: Reducer<TLoadersState> = (
  state = initialState,
  {type, loaderName}
) => {
  switch (type) {
    case LOADER_START: {
      return {...state, [loaderName]: true};
    }
    case LOADER_STOP: {
      return {...state, [loaderName]: false};
    }
    case LOADER_RESET: {
      return {...state, [loaderName]: undefined};
    }
    case AUTH_LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const startLoader = (loaderName: string): TAction => ({
  type: LOADER_START,
  loaderName,
});

export const stopLoader = (loaderName: string): TAction => ({
  type: LOADER_STOP,
  loaderName,
});

export const resetLoader = (loaderName: string): TAction => ({
  type: LOADER_RESET,
  loaderName,
});
