import * as React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Divider, useTheme} from '@material-ui/core';

import SpriteIcon from './ui/icons/SpriteIcon';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
      width: '100%',
      height: '100%',
      '&.positionTop': {
        justifyContent: 'flex-start',
      },
      '&.positionBottom': {
        justifyContent: 'flex-end',
      },
    },
    paper: {
      position: 'relative',
      maxWidth: 320,
      margin: '0 auto',
      borderRadius: theme.spacing(2),
      '&.positionTop': {
        marginTop: theme.spacing(4),
      },
      '&.positionBottom': {
        marginBottom: theme.spacing(4),
      },
      '&.dark': {
        backgroundColor: theme.palette.grey.A400,
        color: theme.palette.common.white,
      },
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(0),
      right: theme.spacing(0),
      zIndex: 1,
    },
    closeIcon: {
      padding: theme.spacing(1),
      border: 0,
      borderRadius: theme.spacing(2),
      '&.dark': {
        color: theme.palette.common.white,
      },
    },
    skipButton: {
      marginTop: theme.spacing(2),
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    buttonsWrapper: {
      display: 'flex',
      '& > button': {
        height: 32,
        padding: 0,
        border: 0,
        borderRadius: 0,
        '&:first-child': {borderBottomLeftRadius: theme.spacing(2)},
        '&:last-child': {borderBottomRightRadius: theme.spacing(2)},
      },
    },
    backdrop: {
      // in case of stacked dialog the overlaping texts will be seen better
      // cannot be customized through theme
      backgroundColor: 'rgba(0, 0, 0, 0.7) !important',
    },
  }),
  {name: 'ModalBase'}
);

interface Props {
  open: boolean;
  onClose: () => void;
  showSkipButton?: boolean;
  skipButtonText?: string;
  showOkButton?: boolean;
  okBtnText?: string;
  onButtonClick?: () => void;
  showCloseButton?: boolean;
  contentBelow?: JSX.Element;
  buttons?: JSX.Element[] | JSX.Element;
  modalPosition?: 'bottom' | 'top';
  dark?: boolean;
  abovePinCode?: boolean;
}

const getActionButtons = ({
  buttons,
  onButtonClick,
  showOkButton,
  okBtnText = 'OK',
}: {
  buttons?: JSX.Element | JSX.Element[];
  onButtonClick?: () => void;
  showOkButton?: boolean;
  okBtnText?: string;
}): JSX.Element[] | null => {
  if (buttons || showOkButton) {
    const okButton = showOkButton && (
      <Button key="modal-ok-button" variant="text" fullWidth onClick={onButtonClick}>
        <Box fontWeight="normal">{okBtnText}</Box>
      </Button>
    );

    return ([] as JSX.Element[]).concat(buttons || []).concat(okButton || []);
  }
  return null;
};

const ModalBase: React.FC<Props> = ({
  abovePinCode,
  buttons,
  children,
  contentBelow = null,
  dark = false,
  modalPosition,
  onButtonClick,
  onClose,
  open,
  showCloseButton = false,
  showOkButton = false,
  okBtnText,
  showSkipButton = false,
  skipButtonText = 'Skip',
}) => {
  const s = useStyles();
  const theme = useTheme();
  const modalStyle = abovePinCode ? {zIndex: theme.zIndex.pinCodeComponent + 1} : undefined;

  const rootClassName = clsx(s.root, {
    positionBottom: modalPosition === 'bottom',
    positionTop: modalPosition === 'top',
  });
  const paperClassName = clsx(s.paper, {
    positionBottom: modalPosition === 'bottom',
    positionTop: modalPosition === 'top',
    dark,
  });
  const closeIconClassName = clsx(s.closeIcon, {dark});

  const actionButtons = getActionButtons({
    buttons,
    showOkButton,
    onButtonClick: onButtonClick || onClose,
    okBtnText,
  });

  return (
    <Modal style={modalStyle} open={open} onClose={onClose} BackdropProps={{className: s.backdrop}}>
      <div className={rootClassName}>
        <Box m={0.5}>
          <Paper elevation={3} className={paperClassName}>
            {showCloseButton && (
              <div className={s.closeButton}>
                <Button
                  variant="text"
                  fullWidth={false}
                  onClick={onClose}
                  className={closeIconClassName}
                >
                  <SpriteIcon fontSize="normal" name="close" />
                </Button>
              </div>
            )}
            <Box position="relative">{children}</Box>
            {actionButtons && (
              <>
                <Divider />
                <div className={s.buttonsWrapper}>
                  {actionButtons.map((actionButton, i) => (
                    <React.Fragment key={actionButton?.key || `key-${i}`}>
                      {actionButton}
                      {i < actionButtons.length - 1 && <Divider orientation="vertical" flexItem />}
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}
          </Paper>
          {showSkipButton && (
            <Button onClick={onClose} variant="text" className={s.skipButton}>
              {skipButtonText}
            </Button>
          )}
          {contentBelow}
        </Box>
      </div>
    </Modal>
  );
};

export default ModalBase;
