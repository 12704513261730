const meProfileTransformer = ({
  id,
  active,
  location,
  has_active_subscriptions: hasMembershipSubscription,
  is_subscription_domestic: isDomestic,
  expired_membership_ad: membershipExpiredAt,
  vip,
  vip_location_label: vipLocation,
  has_vip_subscription: hasVipSubscription,
  expired_vip_ad: vipExpiredAt,
  is_membership_complimentary: isMembershipComplimentary,
  ...rest
}) => ({
  ...rest,
  profileId: id,
  membership: {
    active,
    location,
    subscription: hasMembershipSubscription,
    isDomestic,
    isComplimentary: isMembershipComplimentary,
    expiredAt: membershipExpiredAt,
  },
  vip: {
    active: vip,
    location: vipLocation,
    subscription: hasVipSubscription,
    expiredAt: vipExpiredAt,
  },
});

// eslint-disable-next-line import/prefer-default-export
export const meResponseTransformer = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  status,
  is_phone_verified: isPhoneVerified,
  is_email_verified: isEmailVerified,
  has_active_budget_subscriptions: hasBudgetSubscription,
  budget,
  profiles,
  uname,
  topup_credits,
  registration_type,
  ...rest
}) => ({
  hasBudgetSubscription,
  username: uname,
  isPhoneVerified,
  isEmailVerified,
  budgetStr: budget ? budget.toFixed(2) : '0.00',
  budget: Number.parseFloat(budget) || 0,
  topUpCredits: Number.parseFloat(topup_credits) || 0,
  profileId: null,
  membership: {
    active: false,
  },
  registrationType: registration_type,
  vip: {
    active: false,
  },
  ...(profiles && profiles.length ? meProfileTransformer(profiles.shift()) : {}),
  ...rest,
});
