"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effector_1 = require("effector");
var QBAdapter_1 = require("../QBAdapter");
var voidStream_1 = require("../utils/voidStream");
var array2map_1 = require("../utils/array2map");
exports.default = (function (get, Dialogs$) {
    var create = effector_1.createEffect().use(QBAdapter_1.createDialog);
    effector_1.forward({
        from: create.done.map(voidStream_1.voidStream),
        to: get,
    });
    Dialogs$.on(create.done, function (state, _a) {
        var result = _a.result;
        return __assign(__assign({}, state), array2map_1.array2Map([result], '_id'));
    });
    return create;
});
