import axios from 'axios';

import {BaseAxiosInstance} from '~/utils/axios';

export const transformToSelectOptions = (fieldData) => {
  if (!fieldData) {
    return [];
  }

  return Object.keys(fieldData).reduce((accum, value) => {
    const val = Number.parseInt(value, 10);
    accum.push({
      value: Number.isNaN(val) ? value : val,
      label: fieldData[value],
    });
    return accum;
  }, []);
};

const AppService = {
  getProfileFields() {
    return BaseAxiosInstance.get('profile-field-options').then(({currencies, ...res}) => {
      const transformedFields = Object.keys(res).reduce((accum, fieldKey) => {
        if (fieldKey === 'language') {
          // eslint-disable-next-line no-param-reassign
          accum.languages = transformToSelectOptions(res[fieldKey]);
          return accum;
        }
        // eslint-disable-next-line no-param-reassign
        accum[fieldKey] = transformToSelectOptions(res[fieldKey]);
        return accum;
      }, {});

      transformedFields.currencies = Object.values(currencies).map(({code, id}) => ({
        value: id,
        label: code,
      }));

      return transformedFields;
    });
  },
  getShemalePages() {
    return BaseAxiosInstance.get('terms').then(({terms}) => (Array.isArray(terms) ? terms : []));
  },
  getAppLanguages() {
    return BaseAxiosInstance.get('languages').then(({languages}) =>
      languages.map(({code, title}) => ({value: code, label: title}))
    );
  },
  getPriceRange(currencyId) {
    return BaseAxiosInstance.get(`currency/${currencyId}/price-range`).then(({priceRange}) =>
      Object.entries(priceRange).map(([value, label]) => ({value, label}))
    );
  },
  loadManifest() {
    return axios.get('/manifest.json').then((manifest) => {
      document.querySelector(
        'link[rel=manifest]'
      ).href = `data:application/manifest+json;charset=utf-8,${manifest.data}`;
    });
  },
  loadPwaIcon() {
    return axios.get('/img/pwa/icon-180x180.png').then((response) => {
      document.querySelector('link[rel="apple-touch-icon"][sizes="180x180"]').href = response.data;
    });
  },
};

export default AppService;
