import {AUTH_LOGOUT} from '../../Auth/store/constants';
import {
  COMPLIMENTARY_ACTIVATION_PAGE_UPDATE,
  PAYMENTS_SET_CARDS_LIST,
  PAYMENTS_SET_MEMBERSHIPS_LIST,
  PAYMENTS_SET_RECHARGE_BUDGET_OPTIONS,
  PAYMENTS_SET_TOP_UP_PRICE,
  PAYMENTS_SET_VIP_OPTIONS,
  PAYMENTS_SET_RECHARGE_BACK_ROUTE,
  PAYMENTS_SET_FROM_PAGE_GOLD,
} from './constants';

const initialState = {
  cards: {},
  rechargeBudgetOptions: {},
  vipOptions: {},
  availableMemberships: null,
  topUpPrice: undefined,
  rechargeBudgetFromRoute: null,

  complimentaryActivationPage: {
    promocode: '',
    promocodeInfo: null,
    loading: false,
  },

  pageFromGoldRequested: null,
};

const paymentsReducer = (state = {...initialState}, {type, payload}) => {
  switch (type) {
    case PAYMENTS_SET_CARDS_LIST:
      if (JSON.stringify(state.cards) === JSON.stringify(payload)) {
        return state;
      }
      return {...state, cards: payload};
    case PAYMENTS_SET_RECHARGE_BUDGET_OPTIONS:
      return {...state, rechargeBudgetOptions: payload};
    case PAYMENTS_SET_RECHARGE_BACK_ROUTE:
      return {...state, rechargeBudgetFromRoute: payload};
    case PAYMENTS_SET_VIP_OPTIONS:
      return {...state, vipOptions: payload};
    case PAYMENTS_SET_MEMBERSHIPS_LIST:
      return {...state, availableMemberships: payload};
    case PAYMENTS_SET_TOP_UP_PRICE:
      return {...state, topUpPrice: payload};
    case AUTH_LOGOUT:
      return {...initialState};
    case COMPLIMENTARY_ACTIVATION_PAGE_UPDATE: {
      return {...state, complimentaryActivationPage: {...payload}};
    }
    case PAYMENTS_SET_FROM_PAGE_GOLD: {
      return {...state, pageFromGoldRequested: payload};
    }
    default:
      return state;
  }
};

export default paymentsReducer;
