import config from '~/constants/config';

let initilized = false;
let initializeIntervalId;

const JivoChat = {
  initialize(user, profile) {
    if (initilized) {
      return Promise.resolve(true);
    }
    if (initializeIntervalId || !config.jivoId || !user.isPhoneVerified || !profile) {
      return Promise.resolve(false);
    }
    window.jivo_onLoadCallback = this.onLoad;
    (() => {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `//code.jivosite.com/script/widget/${config.jivoId}`;
      const ss = document.getElementsByTagName('script')[0];
      ss.parentNode.insertBefore(s, ss);
    })();

    return new Promise((resolve) => {
      initializeIntervalId = setInterval(() => {
        if (initilized) {
          clearInterval(initializeIntervalId);
          initializeIntervalId = undefined;
          resolve(true);
        }
      }, 1000);
    });
  },

  destroy() {
    window.jivo_api.setContactInfo({});
    window.jivo_api.setCustomData([]);
  },

  onLoad: () => {
    initilized = true;
    const jivoCstmWidget = document.createElement('div');
    jivoCstmWidget.setAttribute('id', 'jivo_custom_widget');
    document.body.appendChild(jivoCstmWidget);
  },

  open(user, profile) {
    if (!initilized || !user || !profile) {
      return;
    }
    window.jivo_api.setContactInfo({
      email: user.email,
      phone: user.phone,
    });

    const data = [];

    data.push({
      title: 'User',
      content: user.phone,
      link: `https://www.tsescorts.com/admin/user/${user.id}/view`,
    });

    if (profile && profile.isCompleted) {
      data.push({
        title: 'Profile',
        content: `${profile.name} - ${profile.phone}`,
        link: `https://www.tsescorts.com/p/${profile.id}`,
      });
      data.push({
        title: 'Profile Details',
        content: `Details of ${profile.name}`,
        link: `https://www.tsescorts.com/admin/profile/${profile.id}/details`,
      });
    }

    window.jivo_api.setCustomData(data);
    window.jivo_api.open();
  },
};

export default JivoChat;
