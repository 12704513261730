import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      background: theme.palette.background.default,
    },
    textarea: {
      outline: 'none',
      background: 'none',
      resize: 'none',
      border: 'none',
      height: 140,
      width: '100%',
      fontSize: 16,
    },
  }),
  {name: 'FeedbackTextarea'}
);

const FeedbackTextarea = (props) => {
  const s = useStyles();
  return (
    <div className={s.wrapper}>
      <textarea className={s.textarea} {...props} />
    </div>
  );
};

FeedbackTextarea.propTypes = {};

FeedbackTextarea.defaultProps = {};

export default FeedbackTextarea;
