"use strict";
/**
 * @packageDocumentation @module Users
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.findByName = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To find the user by the name.
 */
function findByName(name) {
    return chat_1.Users.getByName(name);
}
exports.findByName = findByName;
