import {TAppState} from '~/types/appTypes';

export const layoutSelector = (state: TAppState) => {
  return state.layout;
};

export const layoutFullScreenLoaderSelector = (state: TAppState): boolean => {
  return state.layout.showFullScreenLoader;
};

export const layoutHideHeaderSelector = (state: TAppState): boolean => {
  return state.layout.hideHeader;
};
