import routeByName from '~/constants/routes';
import logger from '~/utils/logger';
import localStorageWrapper from '~/utils/localStorageWrapper';
import {modalShowAction} from '~/rootStore/modals/modalsActions';
import {MODAL_PWA_BANNER_MEDIUM} from '~/rootStore/modals/modalsIds';
import {TAppState, TThunkDispatch, TThunkAsyncAction} from '~/types/appTypes';
import authReasonDomainCodes, {TAuthReasonDomainCodes} from '~/constants/auth';
import {navigateAction, navigateReplaceAction} from '~/actions/navigateActions';
import {formSubmitFullScreenControl} from '~/components/Layout';

import {AUTH_SIGN_IN} from './constants';
import AuthService from '../AuthService';
import {
  sendUserPhoneVerificationSmsAction,
  syncProfileAction,
  syncUserAndProfileDataAction,
  getFavoritesAction,
  syncProfileStatsAction,
  showUpgradePaidPopup,
  setupTwoFADataAction,
} from '../../CurrentUser/store/actions';
import {syncCardsListAction} from '../../Payments/store/actions';
import syncAppLanguagesAction from '../../App/store/syncAppLanguagesAction';
import {chatsInitAction, guestChatsInitAction} from '../../Chats/store/actions';
import {
  currentUserSelector,
  isProfileEscortSelector,
  utmsSelector,
} from '../../CurrentUser/store/selectors';
import {logEvent} from '../../App/store/gaActions';
import loadCurrentUserAction from '../../CurrentUser/store/actions/loadCurrentUserAction';
import * as guestCookieSession from '../../Chats/guestCookieSession';
import {SHOW_UPGRADE_PAID_POPUP} from '../../CurrentUser/store/constants';
import {isChatEnabledSelector} from '../../App/store/selectors';

const log = logger.module('AuthActions');

export const initAuthAction: TThunkAsyncAction<void> = () => async (dispatch, getState) => {
  const token = AuthService.getAuthHeader();
  const isGuestChatExist = guestCookieSession.get();
  const isChatEnabled = isChatEnabledSelector();

  if (isChatEnabled && !token && isGuestChatExist) {
    dispatch(guestChatsInitAction());
    return;
  }

  if (token) {
    try {
      await dispatch(loadCurrentUserAction());
      const user = currentUserSelector(getState());

      await dispatch(syncProfileAction());
      dispatch(syncProfileStatsAction());
      if (isChatEnabled) {
        dispatch(chatsInitAction());
      }
      dispatch(syncAppLanguagesAction());
      if (user?.isPhoneVerified) {
        dispatch(syncCardsListAction());
      }

      await dispatch(getFavoritesAction());
    } catch (error) {
      log.error('Error during initAuthAction', {error});
    }
  }
};

/**
 * @param formValues: TSignInFormValues
 * {
 *  username: string,
 *  password: string,
 *  rememberMe: boolean,
 * }
 * @param options
 * {
 *   signUp: false
 * }
 */
type TSignInValues = {
  identifier: string;
  password: string;
  rememberMe: boolean;
};
export const signInAction: (
  formValues?: TSignInValues | Record<string, unknown>,
  options?: {signUp?: boolean; autoRoute?: boolean}
) => (dispatch: TThunkDispatch, getState: () => TAppState) => Promise<void> = (
  formValues = {},
  {signUp, autoRoute} = {signUp: false, autoRoute: true}
) =>
  formSubmitFullScreenControl(async (dispatch: TThunkDispatch, getState: () => TAppState) => {
    const token = AuthService.getAuthHeader();
    if (!token) {
      try {
        // @ts-expect-error need to move AuthService TS, not time now
        await AuthService.signIn(formValues);
      } catch (error: unknown) {
        const {
          response: {
            data: {error: {domainCode} = {}, payload},
          },
        } = <
          {
            response: {
              data: {
                error: {domainCode: TAuthReasonDomainCodes};
                payload: unknown;
              };
            };
          }
        >error;

        if (domainCode && domainCode === authReasonDomainCodes.TWO_FACTOR_AUTH) {
          await dispatch(setupTwoFADataAction(payload));
          dispatch(navigateAction(routeByName.twoFactorAuth));
          return;
        }

        log.error('Error during login', {error, ...formValues});
        throw error;
      }
    }

    const {user, profile} = await dispatch(syncUserAndProfileDataAction());

    await dispatch(chatsInitAction());
    await dispatch(syncProfileAction());
    dispatch(syncProfileStatsAction()).catch((e) => {
      log.error('Error during sync ProfileStatsAction', {error: e});
    });
    if (user.isPhoneVerified) {
      dispatch(syncCardsListAction()).catch((e) => {
        log.error('Error during sync CardsListAction', {error: e});
      });
    }

    const isEscort = isProfileEscortSelector(getState());

    if (!user.membership.active && profile && isEscort) {
      localStorageWrapper.setItem(SHOW_UPGRADE_PAID_POPUP, (!user.membership.active).toString());
      await dispatch(showUpgradePaidPopup());
    }

    dispatch({
      type: AUTH_SIGN_IN,
      payload: {
        token: AuthService.getAuthHeader(),
        user,
      },
    });

    if (user.isPhoneVerified) {
      if (autoRoute) {
        dispatch(navigateReplaceAction(routeByName.home));
      }
      return;
    }

    log.verbose('Go to phone verification', {user});
    dispatch(navigateReplaceAction(routeByName.userPhoneVerification));

    if (!signUp) {
      dispatch(sendUserPhoneVerificationSmsAction()).catch((e) => {
        // This catch block placed here for prevent unhandled exception
        // Error here should not prevent redirect to verification screen from sign in
        log.info('Error during sync sendUserPhoneVerificationSmsAction', {
          error: e,
        });
      });
    }
  });

/**
 * @param formValues
 * {
 *  username: 'Username',
 *  password: 'qweqwe',
 *  phone: +380501234567
 * phone: +380501234567
 * }
 */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const signUpAction = (formValues: {phone: string; password: string}) =>
  formSubmitFullScreenControl(async (dispatch: TThunkDispatch, getState: () => TAppState) => {
    const utms = utmsSelector(getState());
    let values = {...formValues};
    if (utms) {
      values = {...values, ...utms};
    }
    await AuthService.signUp(values);
    await dispatch(
      signInAction(
        {
          identifier: values.phone,
          password: values.password,
          rememberMe: true,
        },
        {signUp: true}
      )
    );
    dispatch(modalShowAction(MODAL_PWA_BANNER_MEDIUM));

    logEvent({
      category: 'signup',
      action: 'success',
    });
  });

const authActions = {
  signInAction,
  signUpAction,
};

export default authActions;
