import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {makeModalByIdSelector} from '~/rootStore/modals/modalsSelectors';
import {TModalIds} from '~/rootStore/modals/types';
import {modalHideAction, modalShowAction} from '~/rootStore/modals/modalsActions';

type CallbackType = (result: any) => void;
type CallbacksStorageType = Record<TModalIds, CallbackType>;
const callbacks = {} as CallbacksStorageType;

const useModalById = (modalId: TModalIds, onClose?: CallbackType) => {
  const dispatch = useDispatch();
  const {isOpen, props} = useSelector(makeModalByIdSelector(modalId));

  useEffect(() => {
    if (onClose) {
      callbacks[modalId] = onClose;
    }
  }, [onClose, modalId]);

  const close = useCallback(
    (result?: Record<string, any>) => {
      if (callbacks[modalId]) {
        const callback = callbacks[modalId];

        callback(result);
      }

      dispatch(modalHideAction(modalId));

      delete callbacks[modalId];
    },
    [dispatch, modalId]
  );

  const open = useCallback(
    (modalProps?: Record<string, unknown>) => {
      dispatch(modalShowAction(modalId, modalProps));
    },
    [dispatch, modalId]
  );

  return {isOpen, props, close, open};
};

export default useModalById;
