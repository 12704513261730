import React, {useCallback} from 'react';
import * as PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import logger from '~/utils/logger';

const log = logger.module('PrimaryDialog');

const useStyles = makeStyles((theme) => ({
  headerClass: {
    background: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  contentClass: {
    padding: theme.spacing(1, 0),
  },
}));

const PrimaryDialog = ({
  title,
  children,
  handleOk,
  onClose,
  submitTitle,
  closeTitle,
  hideActions,
  disableSubmit,
  ...rest
}) => {
  const onHandleOk = useCallback(async () => {
    if (handleOk) {
      try {
        await handleOk();
        // eslint-disable-next-line no-empty
      } catch (error) {
        log.error('Error during onHandleOk in PrimaryDialog component', {
          error,
        });
      }
    }
  }, [handleOk]);

  const {headerClass, contentClass} = useStyles();

  return (
    <Dialog {...rest} onClose={onClose} scroll="paper">
      <DialogTitle classes={{root: headerClass}} disableTypography>
        {title}
      </DialogTitle>

      <DialogContent classes={{root: contentClass}}>{children}</DialogContent>
      {!hideActions && (
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Button onClick={onClose} color="default">
                {closeTitle}
              </Button>
            </Grid>
            <Grid item xs={8}>
              {handleOk && (
                <Button onClick={onHandleOk} disabled={disableSubmit}>
                  {submitTitle}
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
};

PrimaryDialog.propTypes = {
  hideActions: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  title: PropTypes.node.isRequired,
  submitTitle: PropTypes.string,
  closeTitle: PropTypes.string,
  handleOk: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  resetTitle: PropTypes.string,
  resetAction: PropTypes.func,
  subTitle: PropTypes.node,
};

PrimaryDialog.defaultProps = {
  disableSubmit: false,
  handleOk: null,
  submitTitle: 'Submit',
  closeTitle: 'Close',
  hideActions: false,
  resetTitle: 'Reset',
  resetAction: null,
  subTitle: null,
};

export default PrimaryDialog;
