import logger from '~/utils/logger';

import {JIVO_DESTROYED, JIVO_INITIALIZED} from './constants';
import JivoChat from '../JivoChat';
import {currentUserSelector, currentProfileSelector} from '../../CurrentUser/store/selectors';

const log = logger.module('JivoChat actions');

export const jivoInitializeAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const result = await JivoChat.initialize(
      currentUserSelector(state),
      currentProfileSelector(state)
    );
    if (result) {
      dispatch({
        type: JIVO_INITIALIZED,
      });
    }
  } catch (error) {
    log.error('Error during jivoInitializeAction', {
      error,
    });
  }
};

export const jivoOpenAction = () => (dispatch, getState) => {
  const state = getState();
  JivoChat.open(currentUserSelector(state), currentProfileSelector(state));
};

export const jivoDestroyAction = () => ({
  type: JIVO_DESTROYED,
});
