"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onMessageErrorOut = exports.onMessageError = void 0;
var effector_1 = require("effector");
var index_1 = require("./index");
exports.onMessageError = effector_1.createEvent();
exports.onMessageErrorOut = effector_1.sample(index_1.Messages$, exports.onMessageError, function (messages, _a) {
    var messageId = _a.messageId, error = _a.error;
    var message = messages[messageId];
    return { message: message, messageId: messageId, error: error };
});
