import {useEffect, useState, useCallback, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router-dom';
import {Messages, Chat} from '@yeobill/react-chat';
import {
  Subscription,
  TAttachmentsLink,
  TMessageToServer,
  TNewMessage,
} from '@yeobill/chat/lib/types';
import {Howl} from 'howler';

import routeByName from '~/constants/routes';

import {currentProfileSelector, userTypeSelector} from '../../CurrentUser/store/selectors';
import {chatSessionSelector, getCurrentChatOpponentProfileSelector} from '../store/selectors';
import ChatsService from '../ChatsService';
import notificationFX from './assets/notification.mp3';
import MessageNotificationBanner from './MessageNotificationBanner';

const MessageNotification: React.FC = () => {
  const [newMessage, setNewMessage] = useState<TNewMessage>();
  const subscription = useRef<Subscription | null>(null);
  const currentProfile = useSelector(currentProfileSelector);
  const opponentProfile = useSelector(getCurrentChatOpponentProfileSelector);
  const userType = useSelector(userTypeSelector);
  const chatSession = useSelector(chatSessionSelector);
  const routeMatch = useRouteMatch<{chatId: string}>(routeByName.chats.single());

  const audioNotificationFX = useMemo(() => {
    return new Howl({
      src: [notificationFX],
    });
  }, []);

  const handleMessage = useCallback(
    (message: TNewMessage) => {
      const userId = Chat.getUserId();

      const messageToServer: TMessageToServer = {
        opponentId: opponentProfile ? opponentProfile.id : '',
        messageId: message.message._id,
        message: message.message.message,
        attachment: message.message.attachments[0] as unknown as TAttachmentsLink,
        dialogId: message.message.chat_dialog_id,
        dialogType: 1,
        customParams: {
          senderId: opponentProfile ? opponentProfile.id.toString() : '',
          profileUrl: currentProfile ? currentProfile.profileUrl : '',
          profilePhoneNumber: currentProfile ? currentProfile.phone : '',
          flashUsername: currentProfile ? currentProfile.name : '',
          recepientId: currentProfile ? currentProfile.id.toString() : '',
          userType,
          needNotification: document.hidden.toString(),
        },
      };

      if (userId) {
        ChatsService.notificationEventReceived(messageToServer, userId);
      }

      const isCurrentDialog =
        routeMatch && routeMatch.params.chatId === message.message.chat_dialog_id;

      if (isCurrentDialog) {
        return;
      }

      audioNotificationFX.play();
      setNewMessage(message);
    },
    [opponentProfile, currentProfile, userType, routeMatch, audioNotificationFX]
  );

  const handleCloseMessage = useCallback(() => {
    setNewMessage(undefined);
  }, [setNewMessage]);

  useEffect(() => {
    if (chatSession) {
      subscription.current = Messages.onNewMessage(handleMessage);
    } else {
      subscription.current?.unsubscribe();
      subscription.current = null;
    }

    return () => {
      subscription.current?.unsubscribe();
    };
  }, [handleMessage, chatSession]);

  return (
    <>
      {newMessage && (
        <MessageNotificationBanner
          key={newMessage?.message._id}
          newMessage={newMessage}
          closeMessage={handleCloseMessage}
        />
      )}
    </>
  );
};

export default MessageNotification;
