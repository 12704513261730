"use strict";
/**
 * @packageDocumentation @module Messages
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.onSendMessage = void 0;
var chat_1 = require("@yeobill/chat");
/**
 * To react to a send message action.
 */
function onSendMessage(cb) {
    return chat_1.Messages.onSendMessage(cb);
}
exports.onSendMessage = onSendMessage;
