"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendMessageToServer = void 0;
var QBAdapter = __importStar(require("../QBAdapter"));
var effector_1 = require("effector");
var types_1 = require("../types");
var index_1 = require("./index");
// helpers
var prepareAttachment = function (_a, messageId) {
    var blob = _a.blob, type = _a.type;
    switch (type) {
        case 'audio':
            return {
                type: type,
                file: new File([blob], "voice-" + messageId, { type: 'audio/wav' }),
            };
        case 'image': {
            var file = blob;
            return {
                type: type,
                // quickblox has file name max 100 symbols restriction
                file: new File([file], file.name.slice(0, 99), { type: 'image/jpeg' }),
            };
        }
        default:
            return {
                type: type,
                file: blob,
            };
    }
};
var uploadFile = function (_a) {
    var attachment = _a.attachment;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!attachment) return [3 /*break*/, 2];
                    _c = {};
                    _d = {};
                    return [4 /*yield*/, QBAdapter.uploadFile(attachment.file)];
                case 1:
                    _b = (_c.attachment = (_d.id = (_e.sent()).uid,
                        _d.type = attachment.type,
                        _d),
                        _c);
                    return [3 /*break*/, 3];
                case 2:
                    _b = {};
                    _e.label = 3;
                case 3: return [2 /*return*/, _b];
            }
        });
    });
};
var sendMessageToOpponent = function (_a) {
    var opponentId = _a.opponentId, messageId = _a.messageId, _b = _a.message, message = _b === void 0 ? '' : _b, attachment = _a.attachment, dialogId = _a.dialogId, dialogType = _a.dialogType, _c = _a.customParams, customParams = _c === void 0 ? {} : _c;
    return QBAdapter.sendMessageToOpponent(opponentId, message, messageId, dialogId, dialogType === types_1.DialogType.PRIVATE ? 'chat' : 'groupchat', attachment && [attachment], customParams);
};
var buildedMessageWithAttachment = function (_a) {
    var _b = _a.params, attachment = _b.attachment, rest = __rest(_b, ["attachment"]), result = _a.result;
    return (__assign(__assign({}, rest), result));
};
var withTempAttachmentLink = function (_a) {
    var type = _a.type, file = _a.file;
    return ({
        type: type,
        link: URL.createObjectURL(file),
    });
};
// events and effects
var uploadAttachment = effector_1.createEffect().use(uploadFile);
exports.sendMessageToServer = effector_1.createEffect().use(sendMessageToOpponent);
exports.default = (function (Dialogs$, Messages$, MessagesOrder$, send, getSession, getTalker) {
    var buildMessage = function (dialogs, _a) {
        var dialogId = _a.dialogId, _b = _a.payload, message = _b.message, attachment = _b.attachment, customParams = _b.customParams;
        var messageId = QBAdapter.genMessageId();
        var attach = attachment
            ? {
                attachment: __assign({}, prepareAttachment(attachment, messageId)),
            }
            : {};
        var dialog = dialogs[dialogId];
        return Object.assign(attach, {
            message: message,
            dialogId: dialogId,
            messageId: messageId,
            dialogType: dialog.type,
            opponentId: dialog.xmpp_room_jid || getTalker(dialog),
            customParams: customParams,
        });
    };
    // stream
    var buildedMessage = effector_1.sample(Dialogs$, send, buildMessage);
    effector_1.forward({
        from: buildedMessage,
        to: uploadAttachment,
    });
    effector_1.forward({
        from: uploadAttachment.done.map(buildedMessageWithAttachment),
        to: exports.sendMessageToServer,
    });
    // stores
    Messages$.on(buildedMessage, function (state, params) {
        var _a;
        var user_id = getSession().user_id;
        var message = index_1.fillNewMessage(__assign({ _id: params.messageId, message: params.message || '', recipient_id: params.opponentId, sender_id: user_id, chat_dialog_id: params.dialogId, attachments: params.attachment
                ? [withTempAttachmentLink(params.attachment)]
                : [] }, params.customParams));
        return __assign(__assign({}, state), (_a = {}, _a[message._id] = message, _a));
    });
    Messages$.on(exports.sendMessageToServer.done, function (state, _a) {
        var _b;
        var params = _a.params, result = _a.result;
        // TODO: Prevent upd msg if we have that msg. Change this logic in the future.
        if (state[result]) {
            return state;
        }
        var _c = getSession(), user_id = _c.user_id, _d = _c.token, token = _d === void 0 ? '' : _d;
        var message = index_1.fillNewMessage(__assign({ _id: result, message: params.message, recipient_id: params.opponentId, sender_id: user_id, chat_dialog_id: params.dialogId, attachments: params.attachment
                ? [index_1.withAttachmentLink(token)(params.attachment)]
                : [] }, params.customParams));
        return __assign(__assign({}, state), (_b = {}, _b[message._id] = message, _b));
    });
    MessagesOrder$.on(buildedMessage, function (state, _a) {
        var _b;
        var dialogId = _a.dialogId, messageId = _a.messageId;
        var oldMessageIds = state[dialogId] || [];
        if (oldMessageIds.includes(messageId)) {
            return state;
        }
        return __assign(__assign({}, state), (_b = {}, _b[dialogId] = __spreadArrays(oldMessageIds, [messageId]), _b));
    });
});
