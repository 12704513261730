import {CDNParams} from '~/types/Media';
import config from '~/constants/config';

export const getImageSizeByFile: (file: File) => Promise<[number, number]> = (file) => {
  const url = URL.createObjectURL(file);
  const img = new window.Image();

  return new Promise((resolve) => {
    img.onload = () => {
      resolve([img.width, img.height]);
    };

    img.src = url;
  });
};

export const CHAT_MAX_IMAGE_SIZE = 280;

export const getMessageImageSize = (
  message: {imageSize: string},
  maxSize: number = CHAT_MAX_IMAGE_SIZE
): number[] => {
  if (!message.imageSize) {
    return [maxSize, maxSize];
  }

  return message.imageSize.split(',').map((item) => parseInt(item, 10));
};

export const getScaledImageSize = (
  [width, height]: [number, number],
  maxSize = CHAT_MAX_IMAGE_SIZE
): [number, number] => {
  const max = Math.max(width, height);
  const ratio = max / maxSize;
  const nWidth = Math.round(width / ratio);
  const nHeight = Math.round(height / ratio);
  return [nWidth, nHeight];
};

export const getImageUrl = (
  cdn_slug: string,
  {format, width, height, crop, gravity}: CDNParams
) => {
  if (!cdn_slug) {
    return null;
  }

  const baseUrl = config.cdnImageBaseUrl;
  let transformers = '';
  if (width) {
    transformers += transformers ? `, w_${width}` : `w_${width}`;
  }
  if (height) {
    transformers += transformers ? `,h_${height}` : `h_${height}`;
  }
  if (crop) {
    transformers += transformers ? `,c_${crop}` : `c_${crop}`;
  }
  if (gravity) {
    transformers += transformers ? `,g_${gravity}` : `g_${gravity}`;
  }
  if (transformers) {
    transformers += '/';
  }

  return `${baseUrl}/${transformers}${cdn_slug}.${format}`;
};

export const getVideoUrl = (
  cdn_slug: string,
  {format, width, height, crop, gravity}: CDNParams
) => {
  if (!cdn_slug) {
    return null;
  }
  const baseUrl = config.cdnVideoBaseUrl;
  let transformers = '';
  if (width) {
    transformers += transformers ? `, w_${width}` : `w_${width}`;
  }
  if (height) {
    transformers += transformers ? `,h_${height}` : `h_${height}`;
  }
  if (crop) {
    transformers += transformers ? `,c_${crop}` : `c_${crop}`;
  }
  if (gravity) {
    transformers += transformers ? `,g_${gravity}` : `g_${gravity}`;
  }
  if (transformers) {
    transformers += '/';
  }

  return `${baseUrl}/${transformers}${cdn_slug}.${format}`;
};
