import {useCallback, useState} from 'react';
import * as PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import CommonDialog from '~/components/ui/Dialogs/CommonDialog';
import logger from '~/utils/logger';
import {
  addErrorNotificationAction,
  addSuccessNotificationAction,
} from '~/rootStore/notifications/notificationsReducer';

import {currentProfileSelector} from '../../store/selectors';
import {disableProfileAction, enableProfileAction} from '../../store/actions';

const log = logger.module('ToggleProfile');

const ToggleProfile = ({item, DefaultComponent, ...props}) => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const profile = useSelector(currentProfileSelector);
  const {disabled: isProfileDisabled, phone_nice_format: formattedPhone} = profile || {};

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => setModalOpen(false), []);
  const onSubmit = useCallback(async () => {
    try {
      if (isProfileDisabled) {
        await dispatch(enableProfileAction());
      } else {
        await dispatch(disableProfileAction());
      }
      closeModal();
      dispatch(
        addSuccessNotificationAction(
          `Profile successfully ${isProfileDisabled ? 'enabled' : 'disabled'}`
        )
      );
    } catch (error) {
      log.error('Error during Toggle profile disable/enable', {
        error,
      });
      dispatch(addErrorNotificationAction(error.generalError));
    }
  }, [dispatch, closeModal, isProfileDisabled]);
  const label = `${isProfileDisabled ? 'Enable' : 'Disable'} Profile`;

  if (!profile) {
    return null;
  }

  return (
    <>
      <DefaultComponent {...item} {...props} label={label} onClick={openModal} />

      <CommonDialog
        open={isModalOpen}
        onClose={closeModal}
        title={`${label}?`}
        handleOk={onSubmit}
        fullScreen={false}
        submitTitle={isProfileDisabled ? 'Turn ad on' : 'Turn ad off'}
      >
        <div className="px-2 py-3">
          {isProfileDisabled
            ? `Your ad ${formattedPhone} is currently turned off and therefore your profile is not displayed in listings`
            : `Your ad ${formattedPhone} is currently turned on and therefore your profile is displayed in listings`}
        </div>
      </CommonDialog>
    </>
  );
};

ToggleProfile.propTypes = {
  item: PropTypes.shape({}).isRequired,
  DefaultComponent: PropTypes.func.isRequired,
};

ToggleProfile.defaultProps = {};

export default ToggleProfile;
