import React from 'react';

import Logo from '../Logo';

const WelcomeScreen: React.FC = () => {
  return (
    <>
      <style>
        {`body, html {height: 100%;margin: 0;} .root {height: 100%;} .loader-with-menu {margin-top:-104px;}`}
      </style>
      <div className="loader-container loader-with-menu">
        <Logo size="extraLarge" className="loader" />
      </div>
    </>
  );
};

export default WelcomeScreen;
