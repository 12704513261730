import React from 'react';
import ReactDOM from 'react-dom';
import MediaRecorder from 'audio-recorder-polyfill';
import ReactGA from 'react-ga';

import '~/utils/Sentry';
import '~/helpers/overrideBrowserApi';
import config from '~/constants/config';
import logger from '~/utils/logger';
import {BaseErrorBoundary} from '~/components/Errors';
import * as serviceWorker from '~/serviceWorker/serviceWorkerRegistration';
import store from '~/rootStore/store';
import {APP_SERVICE_WORKER_HAS_UPDATE} from '~/modules/App/store/constants';

import Bootstrap from './Bootstrap';

const log = logger.module('EntryPoint');
log.info('Start app', {
  transactionId: config.transactionId,
  ...config.build,
  url: window.location.toString(),
});

if (!window.MediaRecorder) {
  window.MediaRecorder = MediaRecorder;
}

if (config.googleAnalyticsId) {
  ReactGA.initialize(config.googleAnalyticsId, {debug: config.isDev});
  ReactGA.ga('send', 'pageview');
}

if (!config.isDev && !config.isCypressEnv) {
  serviceWorker.register({
    onUpdate: () => store.dispatch({type: APP_SERVICE_WORKER_HAS_UPDATE}),
  });
}

ReactDOM.render(
  <BaseErrorBoundary>
    <Bootstrap />
  </BaseErrorBoundary>,
  document.getElementById('root')
);
