import {createSelector} from 'reselect';

import routeByName from '~/constants/routes';
import {buildNavigateUrl} from '~/helpers/routes/navigate';
import {TAppState} from '~/types/appTypes';
import {TProfile, TProfileTextSettings} from '~/types/Profile';
import {TPromoCodeInfo} from '~/types';
import {profileTypes, profileTypeNames, TProfileType} from '~/constants/profiles';
import {
  TProfileStats,
  TCurrentUserProfile,
  TCurrentUserState,
  RegistrationType,
} from '~/types/CurrentUserState';

export const currentUserSelector = (state: TAppState): TCurrentUserState['user'] =>
  state.currentUser.user;

export const currentUserIdSelector = (state: TAppState): number | undefined =>
  state.currentUser.user?.id;

export const currentProfileSelector = (state: TAppState): TCurrentUserProfile | null =>
  state.currentUser.profile;

export const currentProfileIdSelector = (state: TAppState): number | undefined =>
  state.currentUser.profile?.id;

export const isAuthSelector = (state: TAppState): boolean => Boolean(currentUserSelector(state));

export const isUserPhoneVerifiedSelector = (state: TAppState): boolean => {
  const user = currentUserSelector(state);
  return user ? user.isPhoneVerified : false;
};

export const isEasyLoginSelector = (state: TAppState): boolean => {
  const currentUser = currentUserSelector(state);
  if (!currentUser) {
    return false;
  }
  return currentUser.registrationType === RegistrationType.QUICK;
};

export const isRegistrationTypeQuickSelector = (state: TAppState): boolean => {
  const currentUser = currentUserSelector(state);
  if (!currentUser) {
    return false;
  }
  return currentUser.registrationType === RegistrationType.QUICK;
};

export const isRegistrationTypeDefaultSelector = (state: TAppState): boolean => {
  const currentUser = currentUserSelector(state);
  if (!currentUser) {
    return false;
  }
  return currentUser.registrationType === RegistrationType.NORMAL;
};

export const isProfileExistsSelector = (state: TAppState): boolean => {
  const profile = currentProfileSelector(state);
  if (!profile) {
    return false;
  }
  return Boolean(profile && profile.id);
};

export const isProfileCompletedSelector = (state: TAppState): boolean => {
  const profile = currentProfileSelector(state);
  if (!profile) {
    return false;
  }
  return Boolean(profile && profile.isCompleted);
};

export const isProfilePhoneVerifiedSelector = (state: TAppState): boolean => {
  const profile = currentProfileSelector(state);
  if (!profile) {
    return false;
  }
  return profile.isPhoneVerified;
};

export const isProfileTotallyCompletedSelector = (state: TAppState): boolean =>
  Boolean(
    isProfileExistsSelector(state) &&
      isProfileCompletedSelector(state) &&
      isProfilePhoneVerifiedSelector(state)
  );

/**
 * Is Straight Male?
 */
export const isProfileClientSelector = (state: TAppState): boolean => {
  const profile = currentProfileSelector(state);
  if (!profile) {
    return false;
  }
  return Boolean(profile.type === TProfileType.MALE);
};

export const isProfileTransSelector = (state: TAppState): boolean => {
  const profile = currentProfileSelector(state);
  if (!profile) {
    return false;
  }
  return Boolean(profile.type === TProfileType.TRANS);
};

export const isProfileIdentityVerifiedSelector = (state: TAppState): boolean => {
  const profile = currentProfileSelector(state);
  return Boolean(profile && profile.isVerified);
};

export const isProfileIdentityVerificatonPendingSelector = (state: TAppState): boolean => {
  const profile = currentProfileSelector(state);
  return Boolean(profile && profile.isVerificationPending);
};

export const isUserExistsSelector = (state: TAppState): boolean => !!currentUserSelector(state);

export const isUserComplimentarySelector = (state: TAppState): boolean => {
  const user = currentUserSelector(state);
  if (!user) {
    return false;
  }
  return Boolean(user.membership.isComplimentary);
};

// display if user bought basic membership with recurrent payment
export const userHasMembershipSubsSelector = (state: TAppState): boolean => {
  const user = currentUserSelector(state);
  if (!user) {
    return false;
  }

  return Boolean(user.membership.subscription);
};

export const userSubscriptionsSelector = (state: TAppState) => {
  const user = currentUserSelector(state);
  if (!user) {
    return [];
  }

  return user.subscriptions;
};

export const userTransactionsSelector = (state: TAppState) => {
  const user = currentUserSelector(state);
  if (!user) {
    return [];
  }

  return user.transactions;
};

/**
 * @deprecated returns `false`
 */
export const isUserVipSelector = (): boolean => {
  return false;
  // const user = currentUserSelector(state);
  // if (!user) {
  //   return false;
  // }

  // return user.vip.active;
};

export const userBudgetSelector = (state: TAppState): number => {
  const user = currentUserSelector(state);
  return user?.budget || 0;
};

export const isEnoughBudgetSelector = (
  state: TAppState,
  selectedOptionAmount: number,
  promocodeInfo?: TPromoCodeInfo
): boolean => {
  const userBudgetAmount = userBudgetSelector(state);
  return promocodeInfo && promocodeInfo.price
    ? parseFloat(promocodeInfo.price) <= userBudgetAmount
    : selectedOptionAmount < userBudgetAmount;
};

export const isUserBudgetExistsSelector = (state: TAppState): boolean => {
  const user = currentUserSelector(state);
  if (!user) {
    return false;
  }
  return Boolean(user.budget);
};

export const hasBudgetSubscriptionSelector = (state: TAppState): boolean => {
  const user = currentUserSelector(state);
  if (!user) {
    return false;
  }
  return user.hasBudgetSubscription;
};

export const userProfileStatsSelector = ({currentUser: {profileStats}}: TAppState): TProfileStats =>
  profileStats;

export const userProfileChatSettingsSelector = ({
  currentUser: {profileChatSettings},
}: TAppState): TProfileTextSettings => profileChatSettings;

export const currentLanguageSelector = (state: TAppState): string => {
  const user = currentUserSelector(state);
  return user?.lang || '';
};

export const currentUserFavoritesSelector = ({currentUser: {favorites}}: TAppState): TProfile[] =>
  favorites;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isProfileFavoriteSelector = (profile: TProfile) =>
  createSelector(currentUserFavoritesSelector, (favorites) => {
    return profile && profile.id && favorites
      ? Boolean(favorites.find(({id}) => id === profile.id))
      : false;
  });

export const userEmailSelector = (state: TAppState): undefined | string => {
  const user = currentUserSelector(state);
  if (!user || !user.email) {
    return undefined;
  }
  return user.email;
};

export const userPhoneSelector = (state: TAppState): boolean | string => {
  const user = currentUserSelector(state);
  if (!user) {
    return false;
  }
  return user.phone;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const currentLocationSelector = ({
  currentUser,
}: TAppState): TCurrentUserState['currentLocation'] => currentUser.currentLocation;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const currentUserLocationSelector = ({currentUser}: TAppState) => currentUser.location;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCreateProfileStepSelector = ({currentUser: {createProfileStep}}: TAppState) =>
  createProfileStep;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const cachedPromoCodeSelector = (state: TAppState) => {
  return state.currentUser.promocode;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const utmsSelector = ({currentUser}: TAppState) => {
  return currentUser.utms;
};

export const profileEditUrlSelector = (state: TAppState): string => {
  const profile = currentProfileSelector(state) as TCurrentUserProfile;

  if (
    !profile ||
    !profile.id ||
    (profile.type === profileTypes.MALE && profile.looking_for === null)
  ) {
    return routeByName.createProfile;
  }

  return buildNavigateUrl({
    route: routeByName.profileForm,
    params: {
      profileId: profile.id,
    },
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasGoldMembershipSelector = (state: TAppState) => {
  const profile = currentProfileSelector(state);

  return Boolean(profile && profile.dating_membership);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const profileVideoVerificationSelector = (state: TAppState) => {
  return state.currentUser.profileVerification;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isVideoVerificationPendingSelector = (state: TAppState) => {
  const profileVerificationData = profileVideoVerificationSelector(state);
  return !!profileVerificationData?.id && !!profileVerificationData?.video_path;
};

export const userTypeSelector = (state: TAppState): string => {
  if (state.chats.session && !currentUserSelector(state)) {
    return profileTypeNames.ANONYMOUS;
  }
  const profile = currentProfileSelector(state);
  if (!profile) return profileTypeNames.ANONYMOUS;
  return profileTypeNames[profile.type];
};

export const userIpGeoLocationSelector = (
  state: TAppState
): TCurrentUserState['currentIpGeoLocation'] => {
  return state.currentUser.currentIpGeoLocation || {};
};

/**
 * @deprecated returns `false`
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isProfileEscortSelector = (state: TAppState): boolean => false;

/**
 * @deprecated returns `false`
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isProfileBusinessSelector = (state: TAppState): boolean => false;
